import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { EmptyState } from '@/components/empty-state/EmptyState';
import { EmptyStateIcon } from '@/components/empty-state/EmptyStateIcons';
import { SelectFilter } from '@/components/filters-bar/FilterBar.type';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { getFilterValueIdsByKey } from '@/components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { EmployeeSkillSearchRequest } from '@/domain/employee-skill/EmployeeSkill.model';
import { useSearchEmployeeSkills } from '@/hooks/employee-skill/EmployeeSkill.hook';
import { useEmployeeSkillFilter } from '@/hooks/employee-skill/EmployeeSkillFilters.hook';
import { EmployeeSkillData, useSkillsMatrixGrid } from '@/page/employee-skill/skills-matrix/SkillsMatrixGrid.hook';
import { Paper, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const SkillsMatrixPage: FC = () => {
    const { t } = useTranslation();
    const agGridWrapper = useAgGridWrapper<EmployeeSkillData>();
    const { filters: availableFilters } = useEmployeeSkillFilter();
    const [filters, setFilters] = useFiltersStorage('employee-skill-filters', availableFilters);

    const mapFiltersToSearchRequest = (filters: SelectFilter[]) => {
        const filtersFilled = filters?.filter(filter => !!filter.value?.length);

        const search: EmployeeSkillSearchRequest = {
            employeeIds: getFilterValueIdsByKey(filtersFilled, 'employeeIds'),
            departmentIds: getFilterValueIdsByKey(filtersFilled, 'departmentIds'),
            jobIds: getFilterValueIdsByKey(filtersFilled, 'jobIds'),
            locationIds: getFilterValueIdsByKey(filtersFilled, 'locationIds'),
            skillCategoryIds: getFilterValueIdsByKey(filtersFilled, 'skillCategoryIds'),
            employeeJobFamilyIds: getFilterValueIdsByKey(filtersFilled, 'jobFamilyIds'),
        };
        return search;
    };

    const {
        data: employeeSkills = [],
        isLoading,
        isError,
        error,
    } = useSearchEmployeeSkills(mapFiltersToSearchRequest(filters as SelectFilter[]), {
        enabled: !!filters.length,
    });

    const { columnDefs, rowData, pinnedBottomRowData } = useSkillsMatrixGrid(employeeSkills);

    const emptyStateComponent = <EmptyState flex={1} icon={<EmptyStateIcon />} title={t('skills_matrix.empty_state_title')} />;
    const hasOnlyEmployeeColumn = columnDefs.length === 1;
    const isEmpty = !employeeSkills.length || hasOnlyEmployeeColumn;

    return (
        <Stack gap={1} flex={1}>
            <Stack component={Paper} p={1}>
                <FiltersBar filters={filters} onFiltersChange={setFilters} />
            </Stack>
            <StateHandler isLoading={isLoading} isError={isError} error={error} isEmpty={isEmpty} emptyStateComponent={emptyStateComponent}>
                <AgGridWrapper<EmployeeSkillData>
                    columnDefs={columnDefs}
                    rowData={rowData}
                    initRef={agGridWrapper.setGridRef}
                    loading={false}
                    disableAutoSize
                    defaultColDef={{
                        autoHeight: false,
                    }}
                    pinnedBottomRowData={pinnedBottomRowData}
                />
            </StateHandler>
        </Stack>
    );
};
