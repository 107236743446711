import { getDepartmentNodeOrganizations, searchDepartmentNodes, searchDepartments } from '@/domain/department/Department.service';
import { createQueryHook } from '@/page/Query.type';

export const useGetDepartments = createQueryHook('departments', searchDepartments);

// use this hook if you want to get the department nodes with their employees
// if you don't need to load employees, use or create a different hook to get only department nodes
export const useGetDepartmentNodeOrganizations = createQueryHook('departmentNodeOrganizations', getDepartmentNodeOrganizations);

export const useGetDepartmentsNodes = createQueryHook('departmentsNodes', searchDepartmentNodes);
