import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { Document } from '@/domain/document/Document.model';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FieldText } from '@/components/form/field-text/FieldText';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { getLabelTranslation } from '@/utils/language.util';
import { useGetDocumentTags } from '@/hooks/document-tag/DocumentTag.hook';

export type DocumentLinkDialogProps = Omit<DialogWrapperProps, 'onSave'> & {
    document?: Document;
    onSave: (values: DocumentLinkFormValues) => void;
};

export const DocumentLinkDialog: FC<DocumentLinkDialogProps> = ({ onSave, document, ...rest }) => {
    const { t } = useTranslation();
    const { data: documentTags = [] } = useGetDocumentTags();

    const { control, handleSubmit } = useForm<DocumentLinkFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: document?.name,
            externalUrl: document?.externalUrl,
            tagIds: document?.tags?.map(dt => dt.id) ?? [],
        },
    });

    const handleSave = (values: DocumentLinkFormValues) => {
        let url = values.externalUrl;
        if (!/^https?:\/\//i.test(values.externalUrl)) {
            url = 'https://' + url;
        }
        onSave({ ...values, externalUrl: url });
    };

    return (
        <DialogWrapper header={t('folders.add_link_dialog.title')} {...rest}>
            <Stack gap={1} component={DialogContent}>
                <FormControlLabel label={t('folders.link_form.name')} control={<FieldText control={control} name={'name'} fullWidth={true} />} />
                <FormControlLabel label={t('folders.link_form.url')} control={<FieldText control={control} name={'externalUrl'} fullWidth={true} />} />
                <FormControlLabel
                    control={
                        <FieldSelect
                            multiple={true}
                            control={control}
                            options={documentTags.map(dt => dt.id)}
                            name={'tagIds'}
                            getOptionLabel={optionId => getLabelTranslation(documentTags.find(dt => dt.id === optionId)?.name)}
                            fullWidth={true}
                        />
                    }
                    label={t('documents.upload_document_dialog.tags_field')}
                />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(handleSave)}>{t('general.save')}</Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const schema = yup.object().shape({
    name: yup.string().trim().required(),
    externalUrl: yup.string().trim().required(),
    tagIds: yup.array().of(yup.number().required()).default([]),
});

export type DocumentLinkFormValues = yup.InferType<typeof schema>;
