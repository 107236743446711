import { announcementAPI } from '@/api/announcement/Announcement.api';
import { Announcement, AnnouncementSearch, CreateAnnouncementMutation, EditAnnouncementMutation } from '@/domain/announcement/Announcement.model';

export const getAnnouncements = (searchRequest: AnnouncementSearch = {}): Promise<Announcement[]> => {
    return announcementAPI.getAnnouncements(searchRequest);
};

export const getAnnouncementsAsEditor = (searchRequest: AnnouncementSearch = {}): Promise<Announcement[]> => {
    return announcementAPI.getAnnouncementsAsEditor(searchRequest);
};

export const createAnnouncement = ({ imageFile: _, ...rest }: CreateAnnouncementMutation): Promise<Announcement> => {
    return announcementAPI.createAnnouncement(rest);
};

export const editAnnouncement = (mutation: EditAnnouncementMutation): Promise<Announcement> => {
    return announcementAPI.updateAnnouncement(mutation);
};

export const deleteAnnouncement = (announcementId: number): Promise<void> => {
    return announcementAPI.deleteAnnouncement(announcementId);
};

export const editAnnouncementImage = (id: Announcement['id'], image: File): Promise<Announcement> => {
    return announcementAPI.editAnnouncementImage(id, image);
};

export const deleteAnnouncementImage = (announcementId: Announcement['id']): Promise<void> => {
    return announcementAPI.deleteAnnouncementImage(announcementId);
};

export const getAnnouncementImageUrl = (announcementId: Announcement['id']): Promise<string> => {
    return announcementAPI.getAnnouncementImageUrl(announcementId);
};
