import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { Button, Checkbox, DialogActions, FormControlLabel, Stack } from '@mui/material';
import DialogContent from '@mui/material/DialogContent/DialogContent';

export type DepartmentEmployeeSelectionDialogProps = {
    employees: EmployeeAvatar[];
    defaultValues: EmployeeAvatar[];
    onSave: (employees: EmployeeAvatar[]) => void;
} & DialogWrapperProps;

export const DepartmentEmployeeSelectionDialog: FC<DepartmentEmployeeSelectionDialogProps> = ({ employees, defaultValues, onSave, ...restDialog }) => {
    const { t } = useTranslation();

    const [employeesSelected, setEmployeesSelected] = useState<EmployeeAvatar[]>(defaultValues);

    const handleToggleEmployee = (employee: EmployeeAvatar, checked: boolean) => {
        setEmployeesSelected(prev => (checked ? [...prev, employee] : prev.filter(e => e.id !== employee.id)));
    };

    return (
        <DialogWrapper header={t('settings_organization.departments.select_employees_dialog.title')} {...restDialog}>
            <Stack component={DialogContent}>
                {employees.map(employee => (
                    <FormControlLabel
                        key={employee.id}
                        label={employee.displayName}
                        labelPlacement={'end'}
                        control={
                            <Checkbox
                                checked={employeesSelected.some(e => e.id == employee.id)}
                                onChange={(_, checked) => {
                                    handleToggleEmployee(employee, checked);
                                }}
                            />
                        }
                    />
                ))}
            </Stack>
            <DialogActions>
                <Button onClick={() => onSave(employeesSelected)} fullWidth>
                    {t('general.confirm')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
