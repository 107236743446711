import { CheckboxAutocompleteOption } from '@/components/autocomplete-wrapper/CheckboxAutocompleteOption';
import { SelectProps } from '@/components/form/field-select/Select';
import { AutocompleteProps, createFilterOptions, FilterOptionsState } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type UseSelectAllProps<TValue, Multiple extends boolean | undefined = true, DisableClearable extends boolean | undefined = false> = Pick<
    SelectProps<TValue, Multiple, DisableClearable>,
    'onChange' | 'value' | 'getOptionLabel' | 'getOptionKey'
>;

export type UseSelectAllReturnValue<TValue> = Pick<
    AutocompleteProps<TValue, true, false, false>,
    'renderOption' | 'getOptionKey' | 'getOptionLabel' | 'filterOptions' | 'onChange'
>;
export const useSelectAll = <TValue,>({
    getOptionLabel: getOptionLabelProp,
    getOptionKey: getOptionKeyProp,
    onChange: onChangeProp,
    value: valueProp = [],
}: UseSelectAllProps<TValue>): UseSelectAllReturnValue<TValue> => {
    const { t } = useTranslation();
    // Type for the select all option added to the list of options
    type ToggleAllOptions = {
        key: typeof TOGGLE_ALL_OPTIONS;
        filtered: TValue[];
    };
    const isToggleAllOptions = (value: unknown): value is ToggleAllOptions => {
        return (value as ToggleAllOptions)?.key === TOGGLE_ALL_OPTIONS;
    };

    const getOptionLabel = (option: TValue, selected?: boolean): string => {
        if (isToggleAllOptions(option)) {
            return selected ? t('general.deselect_all') : t('general.select_all');
        }

        if (getOptionLabelProp) {
            return getOptionLabelProp(option);
        }
        return typeof option === 'string' ? option : '';
    };

    const getOptionKey = (option: TValue): string | number => {
        if (isToggleAllOptions(option)) {
            return TOGGLE_ALL_OPTIONS as string;
        }
        if (getOptionKeyProp) {
            return getOptionKeyProp(option);
        }

        switch (typeof option) {
            case 'string':
                return option;
            case 'number':
                return option;
            default:
                return '';
        }
    };

    const filterOptions = (options: TValue[], state: FilterOptionsState<TValue>) => {
        const filter = createFilterOptions<TValue>();
        const filtered = filter(options, state) ?? [];

        if (!filtered.length) {
            return filtered;
        }
        const selectAllOption: ToggleAllOptions = { key: TOGGLE_ALL_OPTIONS, filtered };

        // This is a hack to add the select all option to the list of options
        return [selectAllOption as TValue, ...filtered];
    };

    const onChange: AutocompleteProps<TValue, true, false, false>['onChange'] = (_, value, reason, detail) => {
        if (isToggleAllOptions(detail?.option)) {
            if (reason === 'selectOption') {
                const values = valueProp ?? [];
                const toggleOption = (detail?.option as ToggleAllOptions).filtered ?? [];

                const isAllVisibleOptionsChecked = toggleOption?.every(f => values.findIndex(v => getOptionKey(v) === getOptionKey(f)) !== -1);
                if (isAllVisibleOptionsChecked) {
                    const newValues = values.filter(v => toggleOption?.findIndex(f => getOptionKey(v) === getOptionKey(f)) === -1);
                    onChangeProp?.(newValues, reason);
                    return;
                }

                const newOptions = toggleOption.filter(f => values.findIndex(v => getOptionKey(v) === getOptionKey(f)) === -1);

                onChangeProp?.([...values, ...newOptions], reason);
                return;
            }
        }
        return onChangeProp?.(value, reason, detail);
    };

    const renderOption: AutocompleteProps<TValue, true, false, false>['renderOption'] = (props, option, { selected }) => {
        const key = getOptionKey(option);

        const isSelectAllSelected =
            (isToggleAllOptions(option) && option.filtered.every(f => valueProp.findIndex(v => getOptionKey(v) === getOptionKey(f)) !== -1)) ?? false;

        const optionSelect = selected || isSelectAllSelected;

        const label = getOptionLabel(option, optionSelect) ?? '';
        return <CheckboxAutocompleteOption {...props} key={key} selected={optionSelect} label={label} />;
    };

    return {
        getOptionLabel,
        getOptionKey,
        filterOptions,
        onChange,
        renderOption,
    };
};

const TOGGLE_ALL_OPTIONS = 'TOGGLE_ALL_OPTIONS';
