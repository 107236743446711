import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { Employment } from '@/domain/employment/Employment.model';
import { useGetEmployments } from '@/hooks/employment/Employment.hook';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useState } from 'react';

type EmployeeAvatarExtended = EmployeeAvatar & { employments: Employment[] };
const useGetEmployeesInDepartment = (
    departmentId: number,
    effectiveDate?: LocalDate,
    { enabled = true } = {},
): {
    employeesInDepartment: EmployeeAvatarExtended[];
    isFetching: boolean;
} => {
    const { data: employments = [], isFetching: isEmploymentsFetching } = useGetEmployments(
        { departmentIds: [departmentId], fromDate: effectiveDate, principal: true },
        { enabled },
    );
    const { data: employees = [], isFetching: isEmployeesFetching } = useGetEmployees(undefined, { enabled });
    const isFetching = isEmploymentsFetching || isEmployeesFetching;

    // merge employee data with his employments in one object
    const employeesInDepartment = employments.reduce<EmployeeAvatarExtended[]>((acc, employment) => {
        const existing = acc.find(e => e.id === employment.employeeId);
        if (existing) {
            existing.employments.push(employment);
            return acc;
        }

        const employee = employees.find(e => e.id === employment.employeeId);
        if (employee) {
            return [...acc, { ...employee, employments: [employment] }];
        }
        return acc;
    }, []);

    return { employeesInDepartment, isFetching };
};

export const useGetEmployeesAffectedByDepartmentChange = (
    departmentId: number,
    effectiveDate?: LocalDate,
    { enabled = true } = {},
): {
    employeesInDepartment: EmployeeAvatarExtended[];
    selectedEmployees: EmployeeAvatarExtended[];
    onSelectedEmployeesChange: (employees: EmployeeAvatar[]) => void;
    isFetching: boolean;
} => {
    const [affectedEmployees, setAffectedEmployees] = useState<EmployeeAvatarExtended[]>([]);
    const { employeesInDepartment, isFetching: isEmployeesFetching } = useGetEmployeesInDepartment(departmentId, effectiveDate, { enabled });

    useDeepCompareEffect(() => {
        if (!isEmployeesFetching) {
            // init the affected employees with all employees in the department
            setAffectedEmployees(employeesInDepartment);
        }
    }, [employeesInDepartment, isEmployeesFetching]);

    const handleSelectedEmployeesChange = (selectedEmployees: EmployeeAvatar[]) => {
        setAffectedEmployees(employeesInDepartment.filter(e => selectedEmployees.some(s => s.id === e.id)));
    };

    return {
        employeesInDepartment,
        selectedEmployees: affectedEmployees,
        onSelectedEmployeesChange: handleSelectedEmployeesChange,
        isFetching: isEmployeesFetching,
    };
};
