import { FilterType } from '@/components/filters-bar/FilterBar.type';
import { TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { useFiltersDirectory } from '@/page/common/filters-directory/useFiltersDirectory';
import { activeMonth } from '@/page/employee-profile/employee-profile-timesheet/timesheets-history/TimesheetsHistory.util';
import { formatToLocalDate, getCurrentLocalDate, getEndOfYear, getStartOfMonth, MONTHS, setMonth, toDate } from '@/utils/datetime.util';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

export const useBalanceTimesheetPageFilters = (
    timesheetSettings: TimesheetSetting[],
    defaultTimesheetSetting: TimesheetSetting,
    haveDifferentCycleStartMonth: boolean,
): { filters: FilterType[] } => {
    const { t } = useTranslation();

    const { filters: filtersFromDirectory } = useFiltersDirectory(['LOCATION', 'DEPARTMENT', 'JOB', 'MANAGER']);

    const filters: FilterType[] = [
        {
            filterName: t('balance_page.filters.cycle'),
            type: 'select',
            selectMode: 'SYNC',
            value: [
                {
                    label: getFormattedMonth(defaultTimesheetSetting.cycleStartMonth),
                    value: defaultTimesheetSetting.cycleStartMonth,
                },
            ],
            //only unique months should be displayed
            options: Array.from(new Set(timesheetSettings?.map(timesheetSetting => timesheetSetting.cycleStartMonth))).map(month => ({
                label: getFormattedMonth(month),
                value: month,
            })),
            key: 'startCycleMonth',
            rule: 'EQUALS',
            availableRules: [],
            clearable: false,
            hide: !haveDifferentCycleStartMonth,
        },
        {
            key: 'endDate',
            filterName: t('timesheets.period'),
            type: 'date',
            value: getEndOfYear(),
            dateType: 'MORE_THAN',
            availableRules: ['MORE_THAN'],
            clearable: false,
        },
        ...filtersFromDirectory,
    ];

    return {
        filters,
    };
};

const getFormattedMonth = (month: MONTHS): string => {
    const date = toDate(getStartOfMonth(getCurrentLocalDate()));
    const startDate = formatToLocalDate(setMonth(date, activeMonth(month)));

    return format(startDate, 'MMM');
};
