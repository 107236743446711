import { EmployeeAvatar } from '@/domain-ui/employee/employee-avatar/EmployeeAvatar';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { Employee } from '@/domain/employee/Employee.model';
import { OffboardingAllStepFormValues } from '@/page/people/OffboardingPage';
import { SectionContainer } from '@/page/people/on-boarding-form/SectionContainer';
import { addDaysAndFormat } from '@/utils/datetime.util';
import { Checkbox, FormControlLabel, FormGroup, Link, Stack, Typography } from '@mui/material';
import { Copy01Icon } from 'hugeicons-react';
import { ChangeEvent, FC, useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getLabelTranslation } from '@/utils/language.util';

type CleanupFormProps = {
    employeeId?: number;
    allEmployees: Employee[];
    employeeReviews?: EmployeeReview[];
};

export const CleanupStepForm: FC<CleanupFormProps> = ({ employeeId, allEmployees = [], employeeReviews = [] }) => {
    const { control, watch, getValues, setValue } = useFormContext<OffboardingAllStepFormValues>();
    const { t } = useTranslation();

    const { fields: assignmentsFields, update: updateAssignment } = useFieldArray({
        control,
        name: 'assignments',
    });

    useEffect(() => {
        const { endDate } = getValues();
        setValue('effectiveDate', addDaysAndFormat(endDate, 1));
    }, [getValues, setValue]);

    const assignments = watch('assignments');

    const handleCopyFirstManagerToAll = () => {
        const firstManager = assignments[0]?.manager;
        assignmentsFields.forEach((assignment, index) => {
            // avoid copying the same manager to the same employee
            if (assignment.employee.id !== firstManager?.id) {
                updateAssignment(index, { ...assignment, manager: firstManager });
            }
        });
    };
    const handleChange =
        (ongoingReviewIds: number[], employeeReviewId: number, onChange: (ids: number[]) => void) => (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                onChange([...(ongoingReviewIds ?? []), employeeReviewId]);
            } else {
                onChange(ongoingReviewIds?.filter(id => id !== employeeReviewId));
            }
        };

    return (
        <SectionContainer>
            <Stack gap={4}>
                {!!employeeReviews.length && (
                    <Stack gap={2}>
                        <Typography variant='h1'>{t('offboarding_form.cleanup_step.cancel_ongoing_reviews')}</Typography>

                        <Controller
                            name={'ongoingReviewIds'}
                            control={control}
                            render={({ field: { value: ongoingReviewIds, onChange, ...restField } }) => {
                                return (
                                    <FormGroup>
                                        {employeeReviews.map(employeeReview => (
                                            <FormControlLabel
                                                key={employeeReview.id}
                                                control={
                                                    <Checkbox
                                                        {...restField}
                                                        checked={ongoingReviewIds?.some(id => id === employeeReview.id)}
                                                        onChange={handleChange(ongoingReviewIds, employeeReview.id, onChange)}
                                                    />
                                                }
                                                label={getLabelTranslation(employeeReview.review?.name) ?? ''}
                                                labelPlacement='end'
                                            />
                                        ))}
                                    </FormGroup>
                                );
                            }}
                        />
                    </Stack>
                )}
                {!!assignmentsFields.length && (
                    <Stack gap={2}>
                        <Typography variant='h1'>{t('offboarding_form.cleanup_step.assign_new_manager.title')}</Typography>

                        <Stack gap={2}>
                            <Stack direction='row' justifyContent={'space-between'}>
                                <Stack direction='row' gap={5}>
                                    <Typography variant='body1bold' width={'200px'}>
                                        {t('offboarding_form.cleanup_step.assign_new_manager.direct_reports_label')}
                                    </Typography>
                                    <Typography variant='body1bold' flex={1}>
                                        {t('offboarding_form.cleanup_step.assign_new_manager.new_manager_label')}
                                    </Typography>
                                </Stack>

                                <Link
                                    component='button'
                                    color='primary'
                                    onClick={handleCopyFirstManagerToAll}
                                    sx={{
                                        display: assignments[0].manager && assignments.length > 1 ? 'flex' : 'none',
                                        alignItems: 'center',
                                        gap: 0.5,
                                    }}
                                >
                                    <Copy01Icon /> {t('offboarding_form.cleanup_step.assign_new_manager.copy_to_all')}
                                </Link>
                            </Stack>
                            {assignmentsFields.map((assignmentField, index) => (
                                <Stack direction='row' key={assignmentField.id} gap={5}>
                                    <Stack direction='row' gap={0.5} alignItems={'center'} width={'200px'}>
                                        <EmployeeAvatar employeeAvatar={assignmentField.employee} />
                                        <Typography variant='body1' noWrap>
                                            {assignmentField.employee.displayName}
                                        </Typography>
                                    </Stack>
                                    <FieldSelect
                                        name={`assignments.${index}.manager`}
                                        control={control}
                                        options={allEmployees.filter(e => e.id !== employeeId && e.id !== assignmentField.employee.id)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionLabel={option => option.displayName}
                                        getOptionKey={option => option.id}
                                        fullWidth
                                    />
                                </Stack>
                            ))}
                        </Stack>
                        <Stack direction='row' gap={5} alignItems={'center'}>
                            <Typography variant='body1bold' width={'200px'} noWrap>
                                {t('offboarding_form.cleanup_step.assign_new_manager.effective_date_label')}
                            </Typography>

                            <FieldLocalDate name='effectiveDate' control={control} sx={{ flex: 1 }} />
                        </Stack>

                        <Stack>
                            <Controller
                                name={'assignNewManagerReviews'}
                                control={control}
                                render={({ field: { value, ...restField } }) => {
                                    return (
                                        <FormControlLabel
                                            control={<Checkbox {...restField} checked={value} />}
                                            label={t('offboarding_form.cleanup_step.assign_new_manager.to_all_ongoing_reviews')}
                                            labelPlacement='end'
                                        />
                                    );
                                }}
                            />
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </SectionContainer>
    );
};
