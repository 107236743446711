import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { FieldSelectJobFamily } from '@/domain-ui/job-family/FieldSelectJobFamily';
import { Department } from '@/domain/department/Department.model';
import { LocationWithoutCircularReference } from '@/domain/location/Location.model';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { CostCenterAssignmentListField } from '@/page/cost-center/CostCenterAssignmentListField';
import {
    DepartmentAutocompleteController,
    JobsAutocompleteController,
    LocationAutocompleteController,
    ManagerAutocompleteController,
} from '@/domain-ui/employment/employment-dialog/EmploymentDialogCommonFields';
import { OnboardingProfileStepFormValues } from '@/page/people/on-boarding-form/EmployeeInformationsForm/EmployeeInformationForm';
import { useAppSelector } from '@/stores/store';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SectionContainer } from '../../SectionContainer';
import { SectionFieldContainer } from '../../SectionFieldContainer';
import { usePreFillJobFamily } from '@/page/employee-profile/employee-profile-info/EmploymentSection/usePrefillJobFamily';
import { useRealmFeatureEnabled } from '@/hooks/realm/useRealmFeatureEnabled';

export const EmploymentSection: FC = () => {
    const { t } = useTranslation();
    const realm = useAppSelector(state => state.ui.currentRealm);
    const { setValue, getFieldState, watch } = useFormContext<OnboardingProfileStepFormValues>();

    // Watch job to fill job family field
    usePreFillJobFamily<OnboardingProfileStepFormValues>('job', 'jobFamily');

    const isCostCenterFeatureActive = hasRealmFeatureEnabled(realm?.realmFeatures, RealmFeaturesType.COST_CENTERS);

    const location: LocationWithoutCircularReference | undefined = watch('location');

    // Autofill work pattern calendar field when location changes
    useEffect(() => {
        if (location?.calendar && !getFieldState('calendarId').isDirty) {
            const calendar = location.calendar;
            setValue('calendarId', calendar.id);
        }
    }, [location, setValue, getFieldState]);

    // Autofill cost centers and manager fields when department changes
    const handleDepartmentChange = (department: Department | null) => {
        setValue('managers', department?.managers ?? []);

        if (isCostCenterFeatureActive) {
            setValue('employmentCostCenters', department?.departmentCostCenters ?? []);
        }
    };
    const hasJobFamilyFeature = useRealmFeatureEnabled(RealmFeaturesType.JOB_FAMILIES);

    return (
        <SectionContainer title={t('employee.sections.employment')}>
            <SectionFieldContainer formValueName={'location'} title={t('employee.employment.location')} required={true}>
                <LocationAutocompleteController name='location' />
            </SectionFieldContainer>
            <SectionFieldContainer formValueName={'department'} title={t('employee.employment.department')} required={true}>
                <DepartmentAutocompleteController onDepartmentChange={handleDepartmentChange} name='department' />
            </SectionFieldContainer>
            <SectionFieldContainer formValueName={'job'} title={t('employee.employment.job')} required={true}>
                <JobsAutocompleteController name='job' />
            </SectionFieldContainer>

            {hasJobFamilyFeature && (
                <SectionFieldContainer formValueName={'jobFamily'} title={getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_JOB_FAMILY' })}>
                    <FieldSelectJobFamily name='jobFamily' />
                </SectionFieldContainer>
            )}
            <SectionFieldContainer formValueName={'managers'} title={t('employee.employment.manager')}>
                <ManagerAutocompleteController name='managers' />
            </SectionFieldContainer>
            {isCostCenterFeatureActive && (
                <SectionFieldContainer formValueName={'employmentCostCenters'} title={t('employee.employment.cost_centers')}>
                    <CostCenterAssignmentListField fieldName={'employmentCostCenters'} fieldsRowGap={1} displayLabel={false} />
                </SectionFieldContainer>
            )}
        </SectionContainer>
    );
};
