import { IconButton, useTheme } from '@mui/material';
import { ArrowLeft01Icon } from 'hugeicons-react';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';

export const HeaderBackButton: FC = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();

    const handleBackButtonClick = (): void => {
        navigate(-1);
    };

    const hasHistory = (): boolean => {
        return location?.key !== 'default';
    };

    return (
        hasHistory() && (
            // Todo: improve theming of IconButton
            <IconButton
                onClick={() => handleBackButtonClick()}
                sx={{
                    border: '1px solid',
                    borderRadius: 1,
                    borderColor: theme.palette.grey[300],
                    height: 32,
                    width: 32,
                }}
            >
                <ArrowLeft01Icon color={theme.palette.text.primary} />
            </IconButton>
        )
    );
};
