import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { MonthlyTimesheetReport, TimesheetSearch } from '@/domain/timesheet/Timesheet.model';
import { useGetEmployeeMonthlyTimesheets } from '@/hooks/timesheet/Timesheet.hook';
import { DurationUnit } from '@/i18n/i18n';
import { MissingCount } from '@/page/timesheet/missing-count/MissingCount';
import { formatInDefaultDate, getEndOfYear, MONTHS } from '@/utils/datetime.util';

import { FilterType } from '@/components/filters-bar/FilterBar.type';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { getFilterValueIdsByKey, getSelectFilterStringValuesByKey } from '@/components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { Employment } from '@/domain/employment/Employment.model';
import { getCycleDates } from '@/domain/leave-type/LeaveType.service';
import { TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { getCycleStartDate, isDifferentLifeCycleStartMonth } from '@/domain/timesheet-setting/TimesheetSetting.service';
import { useBalanceTimesheetPageFilters } from '@/hooks/timesheet/BalanceTimesheetPageFilters.hook';
import { AddAdjustmentDialog } from '@/page/employee-timesheet/adjustment-dialog/AddAdjustmentDialog';
import { BulkAddAdjustmentDialog, EmployeeBalance } from '@/page/employee-timesheet/bulk-adjustment-dialog/BulkAddAdjustmentDialog';
import { useGetTimesheetSettings } from '@/page/setting/time-management/TimesheetSettings.hook';
import { handleError } from '@/utils/api.util';
import { getNonEmptyArrayOrError, isNonEmptyArray } from '@/utils/collections.util';
import { getLabelTranslation } from '@/utils/language.util';
import { Button, Paper, Stack } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-enterprise';
import i18next from 'i18next';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const TimesheetsBalancePage: FC = () => {
    const { data: timesheetSettings = [], isLoading, isError, error } = useGetTimesheetSettings();
    const defaultTimesheetSetting = timesheetSettings?.[0];

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error} isEmpty={!defaultTimesheetSetting}>
            <TimesheetsBalanceTable timesheetSettings={timesheetSettings} defaultTimesheetSetting={defaultTimesheetSetting} />
        </StateHandler>
    );
};

type TimesheetsBalanceTableProps = {
    timesheetSettings: TimesheetSetting[];
    defaultTimesheetSetting: TimesheetSetting;
};

const TimesheetsBalanceTable: FC<TimesheetsBalanceTableProps> = ({ timesheetSettings, defaultTimesheetSetting }) => {
    const { t } = useTranslation();
    const [bulkAdjustmentDialogEmployees, setBulkAdjustmentDialogEmployees] = useState<{
        employeeBalances: EmployeeBalance[];
        isFixedAmount: boolean;
    }>();
    const haveDifferentCycleStartMonth = isDifferentLifeCycleStartMonth(timesheetSettings);
    // FILTERS
    const { filters: availableFilters } = useBalanceTimesheetPageFilters(timesheetSettings, defaultTimesheetSetting, haveDifferentCycleStartMonth);

    const [filters, setFilters] = useBalanceTimesheetFiltersStorage('timesheet-balance-filters', availableFilters);

    const setFilterConditions = (newFilters: FilterType[]) => {
        const copyFilters = [...newFilters];

        const newCycleMonth = (getSelectFilterStringValuesByKey(newFilters, 'startCycleMonth')?.[0] ?? MONTHS.JANUARY) as MONTHS;
        const oldCycleMonth = (getSelectFilterStringValuesByKey(filters, 'startCycleMonth')?.[0] ?? MONTHS.JANUARY) as MONTHS;
        //if the cycle month changes, we need to update the endDate so that we have the current cycle and not a future one
        const updateEndDate = newCycleMonth !== oldCycleMonth;

        copyFilters.forEach(filter => {
            if (filter.key === 'endDate') {
                const { startDate, endDate } = getCycleDates(getEndDate(copyFilters), newCycleMonth, updateEndDate);
                filter.filterName = formatInDefaultDate(startDate);
                if (updateEndDate) {
                    filter.value = endDate;
                }
            }
        });

        setFilters(copyFilters);
    };

    //the filters will have an undefined date, and we need to always have an endDate (string Date or undefined)
    const cycleMonth = (getSelectFilterStringValuesByKey(filters, 'startCycleMonth')?.[0] ?? MONTHS.JANUARY) as MONTHS;
    const endDate = getEndDate(filters);
    const search: TimesheetSearch = {
        startDate: getCycleStartDate(endDate, cycleMonth),
        endDate: endDate,
        cycleMonth: haveDifferentCycleStartMonth ? cycleMonth : undefined,
        onlyEmployeesWithActiveContract: true,
        locationIds: getFilterValueIdsByKey(filters, 'locationIds'),
        departmentIds: getFilterValueIdsByKey(filters, 'departmentIds'),
        jobIds: getFilterValueIdsByKey(filters, 'jobIds'),
        managerIds: getFilterValueIdsByKey(filters, 'managerIds'),
    };

    const {
        data: monthlyTimesheetReports = [],
        isFetching,
        isError,
        error,
        refetch: refetchEmployeeTimesheets,
    } = useGetEmployeeMonthlyTimesheets(search, {
        enabled: !!filters.length,
    });

    const navigate = useNavigate();
    const { gridRef, setGridRef, quickFilter, selectedRows } = useAgGridWrapper<MonthlyTimesheetReport>();

    const columnDefs = getColumnDefs();

    const onBtnExport = () => {
        gridRef.current?.api?.exportDataAsExcel({
            allColumns: true,
        });
    };

    const handleOpenBulkAdjustmentDialog = (selectedRows: MonthlyTimesheetReport[], isFixedAmount: boolean) => {
        const employees: EmployeeBalance[] = selectedRows.map(row => {
            return {
                employee: row.employee,
                balanceInMinutes: row.forecastedBalance,
            };
        });
        setBulkAdjustmentDialogEmployees({
            employeeBalances: employees,
            isFixedAmount,
        });
    };

    const handleOnCloseBulkAdjustmentDialog = () => {
        setBulkAdjustmentDialogEmployees(undefined);
    };

    const handleOnSaveBulkAdjustmentDialog = async () => {
        gridRef.current?.api?.deselectAll();
        handleOnCloseBulkAdjustmentDialog();
        try {
            await refetchEmployeeTimesheets();
        } catch (error) {
            handleError(error);
        }
    };

    const goToEmployeeTimesheets = (employeeId: number) => navigate(`/profile/${employeeId}/timesheets`);

    const shouldShowBulkAdjustmentDialog = !!bulkAdjustmentDialogEmployees?.employeeBalances?.length && !bulkAdjustmentDialogEmployees?.isFixedAmount;
    const shouldShowAddAdjustmentDialog =
        !!bulkAdjustmentDialogEmployees?.employeeBalances?.length &&
        isNonEmptyArray(bulkAdjustmentDialogEmployees.employeeBalances) &&
        bulkAdjustmentDialogEmployees?.isFixedAmount;

    return (
        <Stack gap={2} flex={1}>
            <StateHandler
                // We should improve state handler component and remove requirement on loading state
                isLoading={false}
                isError={isError}
                error={error}
            >
                <Stack flexGrow={1} gap={2}>
                    <Stack component={Paper} p={1} direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                        <FiltersBar filters={filters} onFiltersChange={setFilterConditions} />
                        <DatatableAdditionalAction quickFilter={quickFilter} onBtnExport={onBtnExport} disabled={isFetching} />
                    </Stack>
                    <Stack component={Paper} flex={1}>
                        <AgGridWrapper<MonthlyTimesheetReport>
                            initRef={setGridRef}
                            onRowClicked={({ data }) => (data?.employee.id ? goToEmployeeTimesheets(data.employee.id) : undefined)}
                            rowData={monthlyTimesheetReports}
                            columnDefs={columnDefs}
                            loading={isFetching}
                            rowSelection={{
                                mode: 'multiRow',
                            }}
                            toolbarActions={
                                <Stack direction='row' gap={1}>
                                    <Button onClick={() => handleOpenBulkAdjustmentDialog(selectedRows, true)}>
                                        {t('timesheets.open_bulk_adjustment_dialog_fixed')}
                                    </Button>
                                    <Button onClick={() => handleOpenBulkAdjustmentDialog(selectedRows, false)}>
                                        {t('timesheets.open_bulk_adjustment_dialog_reduce_balance')}
                                    </Button>
                                </Stack>
                            }
                        />
                    </Stack>
                </Stack>
                {shouldShowBulkAdjustmentDialog && (
                    <BulkAddAdjustmentDialog
                        open={shouldShowBulkAdjustmentDialog}
                        employeeBalances={bulkAdjustmentDialogEmployees?.employeeBalances ?? []}
                        date={getEndDate(filters)}
                        onClose={handleOnCloseBulkAdjustmentDialog}
                        onSave={handleOnSaveBulkAdjustmentDialog}
                    />
                )}
                {shouldShowAddAdjustmentDialog && (
                    <AddAdjustmentDialog
                        open={shouldShowAddAdjustmentDialog}
                        onClose={handleOnCloseBulkAdjustmentDialog}
                        onSave={handleOnSaveBulkAdjustmentDialog}
                        employeeIds={getNonEmptyArrayOrError(bulkAdjustmentDialogEmployees.employeeBalances.map(employee => employee.employee.id))}
                        isBulkCreation
                    />
                )}
            </StateHandler>
        </Stack>
    );
};

const getColumnDefs = (): ColDef<MonthlyTimesheetReport>[] => [
    {
        field: 'employee.email',
        headerName: 'Email',
        hide: true,
    },
    {
        field: 'employee',
        type: 'employee',
        headerName: i18next.t('general.employee'),
    },

    {
        field: 'employee.currentEmployments',
        colId: 'jobTitle',
        headerName: i18next.t('timesheets.table_headers.job_title'),
        valueFormatter: ({ value }: { value: Employment[] }) => value?.flatMap(employment => getLabelTranslation(employment.job.name)).join(', '),
    },
    {
        field: 'employee.currentEmployments',
        colId: 'location',
        headerName: i18next.t('timesheets.table_headers.main_location'),
        valueFormatter: ({ value }: { value: Employment[] }) => value?.flatMap(employment => employment.location.name).join(', '),
    },
    {
        field: 'employee.currentEmployments',
        colId: 'managers',
        headerName: i18next.t('timesheets.table_headers.manager'),
        valueGetter: ({ data }) => data?.employee.currentEmployments?.flatMap(employment => employment.managers),
        type: 'stackedAvatars',
    },
    {
        field: 'totalWorkedCount',
        headerName: i18next.t('timesheets.table_headers.totalWorkedCount'),
        type: 'minutesToHours',
    },
    {
        field: 'totalLeaveCount',
        headerName: i18next.t('timesheets.table_headers.totalLeaveCount'),
        valueGetter: data => ((data.data?.totalForecastedLeaveCount ?? 0) + (data.data?.totalPublicHolidayCount ?? 0)) / 60,
        valueFormatter: params =>
            params.value
                ? i18next.t('duration.formatDuration', {
                      duration: params.value,
                      unit: DurationUnit.HOURS,
                  })
                : '-',
    },
    {
        field: 'totalContractCount',
        headerName: i18next.t('timesheets.table_headers.totalContractCount'),
        valueGetter: data => ((data.data?.totalContractCount ?? 0) + (data.data?.totalPublicHolidayCount ?? 0)) / 60,
        valueFormatter: params =>
            i18next.t('duration.formatDuration', {
                duration: params.value ?? 0,
                unit: DurationUnit.HOURS,
            }),
    },
    {
        field: 'previousCarryover',
        headerName: i18next.t('timesheets.table_headers.carryoverPreviousYear'),
        type: 'minutesToHours',
    },
    {
        field: 'totalForecastedDifference',
        headerName: i18next.t('timesheets.table_headers.totalDifference'),
        type: 'minutesToHours',
    },
    {
        field: 'totalBonusCount',
        headerName: i18next.t('timesheets.table_headers.totalBonusCount'),
        type: 'minutesToHours',
    },
    {
        field: 'totalForecastedCompensationCount',
        headerName: i18next.t('timesheets.table_headers.totalCompensation'),
        valueGetter: data => (data.data ? -(data.data.totalForecastedCompensationCount ?? 0) : 0) / 60,
        valueFormatter: params =>
            params.value
                ? i18next.t('duration.formatDuration', {
                      duration: params.value,
                      unit: DurationUnit.HOURS,
                  })
                : '-',
    },
    {
        field: 'totalForecastedPaymentCount',
        headerName: i18next.t('timesheets.payments'),
        valueGetter: data => (data.data?.totalPaymentCount ? -data.data?.totalPaymentCount : 0) / 60,
        valueFormatter: params =>
            params.value
                ? i18next.t('duration.formatDuration', {
                      duration: params.value,
                      unit: DurationUnit.HOURS,
                  })
                : '-',
    },
    {
        field: 'totalForecastedAdjustmentCount',
        headerName: i18next.t('timesheets.adjustments'),
        type: 'minutesToHours',
    },
    {
        field: 'forecastedBalance',
        headerName: i18next.t('timesheets.table_headers.balance'),
        type: 'minutesToHours',
    },
    {
        headerName: i18next.t('timesheets.table_headers.missing'),
        field: 'missingCount',
        cellClass: ['display-flex'],
        cellRenderer: ({ data }: ICellRendererParams<MonthlyTimesheetReport>) => <MissingCount missingCount={data?.missingCount} />,
    },
    {
        field: 'employee.employeeCode',
        headerName: i18next.t('payroll.id'),
        hide: true,
    },
];

const getEndDate = (filters: FilterType[]): LocalDate => {
    const filterEndDate = filters.find(filter => filter.key === 'endDate')?.value as LocalDate | undefined;
    return filterEndDate ? filterEndDate : getEndOfYear();
};

/**
 * hook to manage the filter name for the cycle. Especially here, we need to rename the endDate filter name
 * @param key
 * @param availableFilters
 */
const useBalanceTimesheetFiltersStorage = (key: string, availableFilters: FilterType[]): [FilterType[], (filters: FilterType[]) => void] => {
    const [filters, setFilters] = useFiltersStorage(key, availableFilters);

    const filtersUpdated = filters.map(filter => {
        if (filter.key !== 'endDate') {
            return filter;
        }

        const cycleMonth = (getSelectFilterStringValuesByKey(filters, 'startCycleMonth')?.[0] ?? MONTHS.JANUARY) as MONTHS;
        const endDate = getEndDate(filters);
        filter.filterName = formatInDefaultDate(getCycleStartDate(endDate, cycleMonth));

        return { ...filter, filterName: formatInDefaultDate(getCycleStartDate(endDate, cycleMonth)) };
    });
    return [filtersUpdated, setFilters];
};
