import { FilterType } from '@/components/filters-bar/FilterBar.type';
import { TimesheetType } from '@/domain/timesheet/Timesheet.model';
import { useGetLeaveTypes } from '@/hooks/leave-type/LeaveType.hook';
import { useFiltersDirectory } from '@/page/common/filters-directory/useFiltersDirectory';
import { getLabelTranslation } from '@/utils/language.util';
import { useTranslation } from 'react-i18next';

export const useAllTimesheetTableFilters = (): { filters: FilterType[] } => {
    const { t } = useTranslation();

    const { refetch: refetchLeaveTypes } = useGetLeaveTypes(undefined, { enabled: false });

    const { filters: filtersFromDirectory } = useFiltersDirectory(['LOCATION', 'DEPARTMENT', 'JOB', 'MANAGER', 'AREA']);

    const defaultTimesheetTypeOptions = [TimesheetType.TIMESHEET_ADJUSTMENT, TimesheetType.TIMESHEET, TimesheetType.TIMESHEET_PAYMENT].map(type => ({
        label: t(`timesheets.all_page.timesheet_types.enum_${type}`),
        value: type,
    }));
    const filters: FilterType[] = [
        {
            filterName: 'Type',
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const { data: leaveTypes = [] } = await refetchLeaveTypes();
                return [
                    ...defaultTimesheetTypeOptions,
                    ...leaveTypes.map(leaveType => ({
                        label: getLabelTranslation(leaveType.name),
                        value: leaveType.id,
                    })),
                ];
            },
            key: 'leaveTimesheet.leaveTypeId',
            rule: 'EQUALS',
            availableRules: [],
        },
        ...filtersFromDirectory,
    ];

    return {
        filters,
    };
};
