import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DocumentMoreButton } from '@/domain-ui/document/DocumentMoreButton';
import { getAppConfig } from '@/config/config';
import { Document, DocumentType } from '@/domain/document/Document.model';
import { FC } from 'react';
import { File01Icon, Link01Icon } from 'hugeicons-react';
import { useTranslation } from 'react-i18next';
import { ColDef } from 'ag-grid-community';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { getLabelTranslation } from '@/utils/language.util';
import { DocumentTag } from '@/domain/document-tag/DocumentTag.model';
import Chip, { chipClasses } from '@mui/material/Chip';

const config = getAppConfig();
type Props = {
    folderDocuments: Document[];
    onDeleteClicked: (documentId: number) => void;
    onDownloadClicked: (documentData: Document) => void;
    onPreviewClicked: (documentData: Document, type: string) => void;
    onEditClicked: (documentData: Document) => void;
    canManageDocument: boolean;
    canDeleteDocument: boolean;
    isLoading: boolean;
};

export const DocumentTable: FC<Props> = ({
    onDeleteClicked,
    onPreviewClicked,
    onDownloadClicked,
    folderDocuments,
    onEditClicked,
    canDeleteDocument,
    canManageDocument,
    isLoading,
}) => {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const { t } = useTranslation();
    const handlePreviewClick = (document: Document) => {
        if (config.MIME_TYPES.MICROSOFT.some((mimeType: string) => mimeType === document.mimeType)) {
            onPreviewClicked(document, 'OFFICE');
        } else {
            onPreviewClicked(document, 'STANDARD');
        }
    };

    const handLeDownloadClick = (document: Document) => {
        onDownloadClicked(document);
    };

    const agGridWrapper = useAgGridWrapper<Document>();

    const menuRenderer = ({ data }: { data?: Document }) => {
        if (!data) {
            return;
        }
        return (
            <DocumentMoreButton
                documentType={data.documentType}
                onDeleteClicked={
                    canDeleteDocument
                        ? () => {
                              onDeleteClicked(data.id);
                          }
                        : undefined
                }
                onDownloadClicked={() => {
                    handLeDownloadClick(data);
                }}
                onPreviewClicked={() => {
                    if (data.documentType === 'DOCUMENT') {
                        handlePreviewClick(data);
                    }
                }}
                onEditClicked={canManageDocument ? () => onEditClicked(data) : undefined}
            />
        );
    };

    const columnDefs: ColDef<Document>[] = [
        {
            field: 'name',
            headerName: t('documents.table_header.name'),
            flex: 1,
            sort: 'asc',
        },
        {
            field: 'tags',
            headerName: t('documents.table_header.tags'),
            cellRenderer: DocumentTagCell,
            cellClass: ['display-flex'],
            hide: isMobile,
        },
        {
            field: 'createdAt',
            headerName: t('documents.table_header.created_at'),
            type: 'date',
            hide: isMobile,
        },
        {
            field: 'documentType',
            headerName: '',
            maxWidth: 50,
            cellRenderer: DocumentTypeCell,
            cellClass: ['display-flex'],
            hide: isMobile,
        },
        {
            type: 'actionMenu',
            cellRenderer: menuRenderer,
            hide: isMobile,
        },
    ];

    return (
        <AgGridWrapper<Document>
            gridId='document-table'
            initRef={agGridWrapper.setGridRef}
            rowData={folderDocuments}
            columnDefs={columnDefs}
            onRowClicked={row => {
                if (!row.event?.defaultPrevented) {
                    if (row.data?.documentType === 'DOCUMENT') {
                        handlePreviewClick(row.data);
                    } else if (row.data?.documentType === 'LINK') {
                        handLeDownloadClick(row.data);
                    }
                }
            }}
            compact={false}
            disableAutoSize
            loading={isLoading}
        />
    );
};

const DocumentTypeCell = ({ value }: { value: DocumentType }) => {
    return value === 'DOCUMENT' ? <File01Icon /> : <Link01Icon />;
};

const DocumentTagCell = ({ value: tags = [] }: { value?: DocumentTag[] }) => {
    return (
        <Stack direction={'row'} gap={1} alignItems={'center'} flexWrap={'wrap'} py={5}>
            {tags.map(tag => (
                <Chip
                    key={tag.id}
                    label={getLabelTranslation(tag.name)}
                    color={'primary'}
                    sx={{
                        height: '20px',
                        p: 0.5,
                        borderRadius: 0.5,
                        [`& .${chipClasses.label}`]: {
                            padding: 0,
                            fontSize: '12px',
                            fontWeight: 500,
                        },
                    }}
                />
            ))}
        </Stack>
    );
};
