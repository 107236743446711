import { View } from '@react-pdf/renderer';
import { FC } from 'react';
import { Objective } from '@/domain/objective/Objective.model';
import { useTheme } from '@mui/material';
import { EmployeeReviewManagerSummary } from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import { SummaryFeedbackQuestionPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/SummaryFeedbackQuestionPDF';
import { FeedbackSectionPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackSectionPDF';
import { FeedbackObjectivesPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackObjectivePDF';
import { SummaryFeedbackSkillsPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/SummaryFeedbackSkillsPDF';
import { FeedbackLayoutPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackLayoutPDF';
import { showCompletedReviewSummary } from '@/domain/employee-review/EmployeeReview.service';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { SummaryCompletedPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/SummaryCompletedPDF';
import { UserLanguage } from '@/utils/language.util';
import { FeedbackObjectivesActionsPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackObjectiveActionsPDF';
import { EmployeeReviewAction } from '@/domain/employee-review-action/EmployeeReviewAction.model';
import { SummaryFeedbackItemFormType } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';

type SummaryFeedbackPDFProps = {
    managerSummary: EmployeeReviewManagerSummary;
    summaryFeedbackItems: SummaryFeedbackItemFormType[];
    objectives: Objective[];
    employeeReview: EmployeeReview;
    userLanguage: UserLanguage;
    actions: EmployeeReviewAction[];
};

export const SummaryFeedbackPDF: FC<SummaryFeedbackPDFProps> = ({
    objectives,
    summaryFeedbackItems,
    managerSummary,
    employeeReview,
    userLanguage,
    actions,
}) => {
    const theme = useTheme();

    return (
        <FeedbackLayoutPDF feedback={managerSummary} employeeReview={employeeReview}>
            {showCompletedReviewSummary(employeeReview, managerSummary) && <SummaryCompletedPDF managerSummary={managerSummary} userLanguage={userLanguage} />}
            <View
                style={{
                    gap: theme.spacing(2),
                }}
            >
                {!!summaryFeedbackItems.length &&
                    summaryFeedbackItems.map(summaryFeedbackItem => {
                        switch (summaryFeedbackItem.type) {
                            case 'QUESTION':
                            case 'SKILL':
                                return (
                                    <SummaryFeedbackQuestionPDF
                                        key={summaryFeedbackItem.id}
                                        questionItem={summaryFeedbackItem}
                                        rating={summaryFeedbackItem.rating}
                                    />
                                );
                            case 'SECTION':
                                return <FeedbackSectionPDF key={summaryFeedbackItem.id} sectionItem={summaryFeedbackItem} />;
                            case 'REVIEW_OBJECTIVES':
                                return <FeedbackObjectivesPDF key={summaryFeedbackItem.id} objectives={objectives} objectiveItem={summaryFeedbackItem} />;
                            case 'SKILLS':
                                return <SummaryFeedbackSkillsPDF key={summaryFeedbackItem.id} skills={summaryFeedbackItem} />;
                            case 'OBJECTIVE_ACTIONS':
                                return (
                                    <FeedbackObjectivesActionsPDF
                                        key={summaryFeedbackItem.id}
                                        objectives={actions.map(action => action.objective)}
                                        objectiveItem={summaryFeedbackItem}
                                    />
                                );
                            default:
                                return <></>;
                        }
                    })}
            </View>
        </FeedbackLayoutPDF>
    );
};
