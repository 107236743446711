import { AuthenticationResponse } from '@/domain/authentication/Authentication.model';

export const getUserAuthentication = (): AuthenticationResponse | undefined => {
    const item = localStorage.getItem('userAuthentication');
    return item ? (JSON.parse(item) as AuthenticationResponse) : undefined;
};

export const setUserAuthentication = (authenticatedUser: AuthenticationResponse): void => {
    localStorage.setItem('userAuthentication', JSON.stringify(authenticatedUser));
};

export const clearUserAuthentication = (): void => {
    localStorage.removeItem('userAuthentication');
};

export const getImpersonatorAuthentication = (): AuthenticationResponse | undefined => {
    const item = localStorage.getItem('impersonatorAuthentication');
    return item ? (JSON.parse(item) as AuthenticationResponse) : undefined;
};

export const setImpersonatorAuthentication = (impersonatedUser: AuthenticationResponse): void => {
    localStorage.setItem('impersonatorAuthentication', JSON.stringify(impersonatedUser));
};

export const clearImpersonatorAuthentication = (): void => {
    localStorage.removeItem('impersonatorAuthentication');
};
