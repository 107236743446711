import * as yup from 'yup';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { UserLanguage } from '@/utils/language.util';
import { getCostCenterAssignmentListObjectSchema } from '@/page/cost-center/CostCentersAssignment.schema';
import { treeSelectNumberValueSchema } from '@/components/tree-select/TreeSelectValue.schema';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';

const departmentManagerObjectSchema = yup.object() as yup.ObjectSchema<EmployeeAvatar>;

export const getDepartmentFormSchema = (translationLanguage: UserLanguage) =>
    yup
        .object()
        .shape({
            name: getLabelFormSchema(translationLanguage).required(),
            parent: treeSelectNumberValueSchema.optional().nullable(),
            managers: yup.array().of(departmentManagerObjectSchema).default([]),
            departmentCostCenters: getCostCenterAssignmentListObjectSchema(),
        })
        .required();

export type DepartmentFormValues = yup.InferType<ReturnType<typeof getDepartmentFormSchema>>;
