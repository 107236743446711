import { getNull } from '@/utils/object.util';
import { Button, ButtonGroup, ButtonGroupProps, ButtonProps, ClickAwayListener, Grow, MenuItem, MenuItemProps, MenuList, Paper, Popper } from '@mui/material';
import { ArrowDown01Icon } from 'hugeicons-react';
import { FC, MouseEvent, MouseEventHandler, useRef, useState } from 'react';

type Props = ButtonGroupProps & {
    buttonProps?: ButtonProps;
    menuItemsProps: MenuItemProps[];
    ariaLabel: string;
};

// Inspired by https://mui.com/material-ui/react-button-group/#split-button
export const SplitButtonGroup: FC<Props> = ({ buttonProps, menuItemsProps, ariaLabel, ...rest }) => {
    const [open, setOpen] = useState<boolean>(false);
    const anchorRef = useRef<HTMLButtonElement>(getNull());

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const firstMenuItem = menuItemsProps[0];

    if (!firstMenuItem) {
        return;
    }
    const { title, onClick = () => {}, disabled = false } = firstMenuItem;

    const restMenuItems = menuItemsProps?.slice(1);

    const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
        // TODO find a better way to handle this
        return onClick(event as unknown as MouseEvent<HTMLLIElement>);
    };

    return (
        <>
            <ButtonGroup variant='contained' style={{ boxShadow: 'none' }} aria-label='split button' {...rest}>
                <Button {...buttonProps} onClick={handleClick} disabled={disabled}>
                    {title}
                </Button>
                {!!restMenuItems?.length && (
                    <Button
                        ref={anchorRef}
                        size='small'
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label={ariaLabel}
                        aria-haspopup='menu'
                        onClick={handleToggle}
                        style={{ flex: 0 }} //used so the button does not grow
                    >
                        <ArrowDown01Icon size={20} />
                    </Button>
                )}
            </ButtonGroup>
            <Popper
                style={{
                    zIndex: 2,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper elevation={4}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id='split-button-menu' autoFocusItem>
                                    {restMenuItems.map(({ title, onClick, key, ...restProps }) => (
                                        <MenuItem
                                            {...restProps}
                                            key={key}
                                            style={{
                                                marginTop: '0px',
                                            }}
                                            onClick={event => {
                                                setOpen(false);
                                                onClick?.(event);
                                            }}
                                        >
                                            {title}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
