import { Page } from '@/page/Pages';

const dashboardPage = {
    labelKey: 'sidebar.dashboard',
    path: '/dashboard',
};

export const getDashboardPages = (): Page[] => {
    return [dashboardPage];
};
