import { getApiBaseUrl, getAppConfig } from '@/config/config';
import { ImportRequest } from '@/domain/import/Import.model';
import axios from 'axios';

const API_VERSION = 'v1';
export const API_BASE_URL = getApiBaseUrl() + '/' + API_VERSION;
export const client = axios.create();

export const buildImportFormData = ({ file, ...rest }: ImportRequest): FormData => {
    const formData = new FormData();
    formData.append('importRequest', new Blob([JSON.stringify(rest)], { type: getAppConfig().MIME_TYPES.JSON }));
    formData.append('file', file);
    return formData;
};
