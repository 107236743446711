import { EmployeeWorkingPattern } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.model';
import { Employment, EmploymentStatus } from '@/domain/employment/Employment.model';
import { PolicyPermission, PolicyType } from '@/domain/permission/Permission.model';
import { PlanningPosition } from '@/domain/planning-position/PlanningPosition.model';
import { PlanningTag } from '@/domain/planning-tag/PlanningTag.model';
import { LoginMethod, LoginMethodType } from '@/domain/realm/Realm.model';

import { UserLanguage } from '@/utils/language.util';

export type Employee = {
    id: number;
    employeeCode?: string;
    firstName: string;
    lastName: string;
    maidenName: string;
    displayName: string;
    email: string;
    phoneNumber?: string;
    status: EmployeeStatus;
    language: UserLanguage;
    avatarImageUrl?: string;
    employmentStatus?: EmploymentStatus;
    /**
     * Employee can have multiple current employments
     * One of them is the main employment
     *
     * Warning: Current employment could be empty
     */
    currentEmployments: Employment[];
    currentWorkingPattern?: EmployeeWorkingPattern;
    planningTags?: PlanningTag[];
    planningPositions?: PlanningPosition[];
    contractStartDate?: LocalDate;
    contractProbationEndDate?: LocalDate;
    contractEndDate?: LocalDate;
    subordinates?: Employee[];
    seniorityDate?: LocalDate;
};

export type EmployeeAuthentication = {
    employee: Employee;
    loginMethod: LoginMethod;
    grantedPolicies: EmployeePolicy[];
};

export type EmployeePolicy = {
    policy: PolicyPermission;
    policyType: PolicyType;
    targetEmployeeIds: number[];
    targetResourceId?: number;
};

export type EmployeeAvatar = Pick<Employee, 'id' | 'firstName' | 'lastName' | 'displayName' | 'avatarImageUrl' | 'email'>;

export type EmployeeSyncRequest = {
    employeeIds: number[];
};
export type EmployeeSearch = {
    email?: string;
    employeeCode?: string;
    employeeIds?: number[];
    planningPositionIds?: number[];
    planningTagIds?: number[];
    locationIds?: number[];
    jobIds?: number[];
    departmentIds?: number[];
    managerIds?: number[];
    statuses?: EmploymentStatus[];
    quickSearch?: string;
};

export type EmployeeAnniversary = Omit<EmployeeAvatar, 'id'> & {
    employeeId: Employee['id'];
    anniversaryDate: LocalDate;
};

export type EmployeeAnniversaryRequest = {
    employeeIds: number[];
    startDate: LocalDate;
    endDate: LocalDate;
};

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    NB = 'NB',
}

export enum EmployeeStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    WAITING_FOR_ACTIVATION = 'WAITING_FOR_ACTIVATION',
}

export enum MaritalStatus {
    SINGLE = 'SINGLE',
    MARRIED = 'MARRIED',
    WIDOWED = 'WIDOWED',
    DIVORCED = 'DIVORCED',
    SEPARATED = 'SEPARATED',
    REGISTERED_PARTNERSHIP = 'REGISTERED_PARTNERSHIP',
}

export type EmployeeSearchLoginMethodRequest = {
    email: string;
    tenantId: string;
};

export type EmployeeLoginMethod = {
    loginMethodType: LoginMethodType;
};

export type EmployeeLoginMethodUpdateMutation = {
    loginMethodId: number;
};

export type EmployeeBasicInfo = {
    employeeId: number;
    firstName: string;
    lastName: string;
    maidenName?: string;
    displayName: string;
    email: string;
    phoneNumber?: string;
    employeeCode?: string;
};

export type EmployeeBasicInfoUpdateMutation = Omit<EmployeeBasicInfo, 'employeeId' | 'email'>;

export type EmployeeEmailUpdateMutation = {
    email: string;
};

export type EmployeeShiftSettings = {
    employeeId: number;
    planningTagIds: number[];
    planningPositionIds: number[];
};

export type EmployeeShiftSettingsUpdateMutation = Omit<EmployeeShiftSettings, 'employeeId'>;

export type EmployeeLanguageUpdateRequest = {
    language: UserLanguage;
};

export type EmployeeStatusUpdate =
    | 'PENDING'
    | 'APPROVED'
    | 'DELETED'
    // Use to mark data as exported for payroll exported for payroll
    | 'REVIEWED';
