import { FC } from 'react';
import { RatingFormType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import { useTheme } from '@mui/material';
import { FeedbackSkillPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackSkillPDF';
import { FeedbackSummarySkillFormType } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';
import { SkillFeedbackFromPreparationPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/SkillFeedbackFromPreparationPDF';
import { Text } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { assignLocalId } from '@/utils/object.util';

type SummaryFeedbackSkillPDFProps = {
    questionItem: FeedbackSummarySkillFormType;
    rating: RatingFormType | undefined;
};

export const SummaryFeedbackSkillPDF: FC<SummaryFeedbackSkillPDFProps> = ({ questionItem, rating }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <FeedbackSkillPDF questionItem={questionItem} rating={rating}>
            {questionItem.selfFeedbackQuestion && <SkillFeedbackFromPreparationPDF employeeReviewFeedbackForm={questionItem.selfFeedbackQuestion} />}

            {questionItem.managerFeedbackQuestion?.map(managerFeedbackSkillQuestion => (
                <SkillFeedbackFromPreparationPDF
                    key={assignLocalId(managerFeedbackSkillQuestion).localId}
                    employeeReviewFeedbackForm={managerFeedbackSkillQuestion}
                />
            ))}

            {questionItem.upwardFeedbackQuestion?.map(upwardFeedbackSkillQuestion => (
                <SkillFeedbackFromPreparationPDF
                    key={assignLocalId(upwardFeedbackSkillQuestion).localId}
                    employeeReviewFeedbackForm={upwardFeedbackSkillQuestion}
                />
            ))}
            {questionItem.peerFeedbackQuestion?.map(peerFeedbackSkillQuestion => (
                <SkillFeedbackFromPreparationPDF
                    key={assignLocalId(peerFeedbackSkillQuestion).localId}
                    employeeReviewFeedbackForm={peerFeedbackSkillQuestion}
                />
            ))}

            <Text
                style={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    paddingTop: theme.spacing(1),
                }}
            >
                {t('reviews.summary.summary_subtitle')}
            </Text>
        </FeedbackSkillPDF>
    );
};
