import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import {
    employeeReviewService,
    getEmployeeReview,
    searchEmployeeReviewAsContributor,
    searchEmployeeReviews,
} from '@/domain/employee-review/EmployeeReview.service';
import { Employee } from '@/domain/employee/Employee.model';
import { ReviewStatus } from '@/domain/review/Review.model';
import {
    getEmployeeReviewFeedbacksManagerSummary,
    getEmployeeReviewFeedbacksSelfSummary,
    getEmployeeReviewFeedbacksSummary,
} from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.service';

export const useGetEmployeeReviewAsContributor = createQueryHook('employeeReviewAsContributor', searchEmployeeReviewAsContributor);

export const useGetEmployeeReview = createRequiredParamsQueryHook('employeeReview', getEmployeeReview);

export const useGetEmployeeReviews = createQueryHook('employeeReviews', searchEmployeeReviews);

export const useGetOngoingEmployeeReviews = (employeeId: number): ReturnType<typeof useGetEmployeeReviews> => {
    return useGetEmployeeReviews({
        reviewStatuses: [ReviewStatus.STARTED],
        employeeId,
    });
};

const countOngoingEmployeeReviews = async (employee: Employee): Promise<number> => {
    return await employeeReviewService.countEmployeeReviews({ reviewStatuses: [ReviewStatus.STARTED] }, employee);
};
export const useCountOngoingEmployeeReviews = createRequiredParamsQueryHook('countOngoingEmployeeReviews', countOngoingEmployeeReviews);

export const useGetEmployeeReviewManagerSummary = createRequiredParamsQueryHook('feedbackManagerSummary', getEmployeeReviewFeedbacksManagerSummary);

export const useGetEmployeeReviewSelfSummary = createRequiredParamsQueryHook('feedbackSelfSummary', getEmployeeReviewFeedbacksSelfSummary);

export const useGetEmployeeReviewSummary = createRequiredParamsQueryHook('feedbackSummary', getEmployeeReviewFeedbacksSummary);
