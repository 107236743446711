import { useParams } from 'react-router';

export const useEmployeeReviewId = (): number => {
    const params = useParams();
    if (!params.employeeReviewId) {
        throw new Error('Employee review should have an id');
    }

    return Number(params.employeeReviewId);
};
