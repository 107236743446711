import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { DateRangePicker } from '@/components/date-range-picker/DateRangePicker';
import { useDateRangeStorage } from '@/components/date-range-picker/DateRangePicker.hook';
import { getDateRange } from '@/components/date-range-picker/DateRangePicker.util';
import { isSelectFilter } from '@/components/filters-bar/FilterBar.type';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { canViewTimesheets } from '@/domain/permission/Permission.service';
import { DailyTimesheetReport } from '@/domain/timesheet/Timesheet.model';
import { useGetEmployeeTimesheets } from '@/hooks/timesheet/Timesheet.hook';
import { DurationUnit } from '@/i18n/i18n';
import { useFiltersDirectory } from '@/page/common/filters-directory/useFiltersDirectory';
import { AddAdjustmentDialog } from '@/page/employee-timesheet/adjustment-dialog/AddAdjustmentDialog';
import { BulkAddAdjustmentDialog, EmployeeBalance } from '@/page/employee-timesheet/bulk-adjustment-dialog/BulkAddAdjustmentDialog';
import { MissingCount } from '@/page/timesheet/missing-count/MissingCount';
import { useCurrentEmployee, useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getNonEmptyArrayOrError, isNonEmptyArray } from '@/utils/collections.util';
import { formatDate, formatInDefaultDate, isBeforeOrEqualDate, isValidDate, toDate } from '@/utils/datetime.util';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { enGB } from 'date-fns/locale';
import i18next from 'i18next';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const TimesheetsMonthlySummaryPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { gridRef, setGridRef, quickFilter, selectedRows } = useAgGridWrapper<DailyTimesheetReport>();
    const [bulkAdjustmentDialogEmployees, setBulkAdjustmentDialogEmployees] = useState<{
        employeeBalances: EmployeeBalance[];
        isFixedAmount: boolean;
    }>();

    const policies = useCurrentPolicies();
    const realm = useCurrentRealm();
    const currentEmployee = useCurrentEmployee();

    // date range and view type
    const { dateRangeViewType, dateRange, onDateRangeChange } = useDateRangeStorage({
        storageKey: 'monthlyTimesheetPage',
    });

    const { filters: availableFilters } = useFiltersDirectory(['LOCATION', 'DEPARTMENT', 'JOB', 'MANAGER']);

    const [filters, setFilters] = useFiltersStorage('timesheet-filters', availableFilters);

    const onBtnExport = () =>
        gridRef.current?.api?.exportDataAsExcel({
            allColumns: true,
        });

    const goToEmployeeHistoryPage = (employeeId: number) => {
        const year = toDate(dateRange[0]).getFullYear();
        const month = formatDate(dateRange[0], 'MMMM', {
            locale: enGB,
        }).toUpperCase();
        navigate(`/profile/${employeeId}/timesheets/history/${year}/${month}`);
    };
    const getFilterIds = (filterKey: string): number[] => {
        const filter = filters.find(filter => filter.key === filterKey);
        return (isSelectFilter(filter) && filter?.value?.map(option => option.value as number)) || [];
    };

    // It's important to wait for the filters to be loaded before enabling the search, this is to avoid multiple requests to the server
    const isFiltersLoaded = !!filters.length;
    const isValidRange = isValidDate(dateRange[0]) && !!dateRange[1] && isValidDate(dateRange[1]) && isBeforeOrEqualDate(dateRange[0], dateRange[1]);

    const canRenderMonthlyTimesheetSummary =
        !!realm?.realmFeatures && !!currentEmployee.id && canViewTimesheets(realm?.realmFeatures, policies, currentEmployee.id);
    const searchEnabled = isFiltersLoaded && isValidRange && canRenderMonthlyTimesheetSummary;
    const {
        data: pendingTimesheets = [],
        isFetching = true,
        refetch: refetchEmployeeTimesheets,
    } = useGetEmployeeTimesheets(
        {
            startDate: dateRange[0],
            endDate: dateRange[1],
            locationIds: getFilterIds('locationIds'),
            jobIds: getFilterIds('jobIds'),
            departmentIds: getFilterIds('departmentIds'),
            managerIds: getFilterIds('managerIds'),
            onlyEmployeesWithActiveContract: true,
        },
        {
            enabled: searchEnabled,
        },
    );

    const columnDefs = getTimesheetsMonthlySummaryColumns(dateRange);
    const endLocalDate = dateRange[1];

    const handleOpenBulkAdjustmentDialog = (selectedRows: DailyTimesheetReport[], isFixedAmount: boolean) => {
        const employees: EmployeeBalance[] = selectedRows.map(row => {
            return {
                employee: row.employee,
                balanceInMinutes: row.totalDifference,
            };
        });
        setBulkAdjustmentDialogEmployees({
            employeeBalances: employees,
            isFixedAmount,
        });
    };

    const handleOnCloseBulkAdjustmentDialog = () => {
        setBulkAdjustmentDialogEmployees(undefined);
    };

    const handleOnSaveBulkAdjustmentDialog = async () => {
        gridRef.current?.api?.deselectAll();
        handleOnCloseBulkAdjustmentDialog();
        try {
            await refetchEmployeeTimesheets();
        } catch (error) {
            handleError(error);
        }
    };

    const shouldShowBulkAdjustmentDialog = !!bulkAdjustmentDialogEmployees?.employeeBalances?.length && !bulkAdjustmentDialogEmployees?.isFixedAmount;
    const shouldShowAddAdjustmentDialog =
        !!bulkAdjustmentDialogEmployees?.employeeBalances?.length &&
        isNonEmptyArray(bulkAdjustmentDialogEmployees.employeeBalances) &&
        bulkAdjustmentDialogEmployees?.isFixedAmount;

    return (
        <Stack gap={2} flex={1}>
            <Stack component={Paper} p={1} alignItems='center' direction='row' justifyContent='space-between'>
                <Stack direction='row' alignItems='flex-start' gap={1.5} flexWrap={'wrap'}>
                    <Stack direction='row' gap={0.5}>
                        <DateRangePicker
                            dates={dateRange}
                            onDatesChanged={onDateRangeChange}
                            defaultViewType={dateRangeViewType}
                            availableViews={['MONTH', 'RANGE']}
                        />
                        <Button
                            variant={'outlined'}
                            onClick={() => {
                                onDateRangeChange(getDateRange(dateRangeViewType), dateRangeViewType);
                            }}
                        >
                            {t('planning.today')}
                        </Button>
                    </Stack>
                    <FiltersBar filters={filters} onFiltersChange={setFilters} flex={1} />
                </Stack>
                <DatatableAdditionalAction quickFilter={quickFilter} onBtnExport={onBtnExport} disabled={isFetching} />
            </Stack>
            <Stack component={Paper} flex={1}>
                <AgGridWrapper<DailyTimesheetReport>
                    rowData={pendingTimesheets}
                    initRef={setGridRef}
                    onRowClicked={data => {
                        if (data?.data?.employee?.id) {
                            goToEmployeeHistoryPage(data.data.employee.id);
                        }
                    }}
                    columnDefs={columnDefs}
                    loading={isFetching}
                    rowSelection={{
                        mode: 'multiRow',
                    }}
                    toolbarActions={
                        <Stack direction='row' gap={1}>
                            <Button onClick={() => handleOpenBulkAdjustmentDialog(selectedRows, true)}>
                                {t('timesheets.open_bulk_adjustment_dialog_fixed')}
                            </Button>
                            <Button onClick={() => handleOpenBulkAdjustmentDialog(selectedRows, false)}>
                                {t('timesheets.open_bulk_adjustment_dialog_reduce_balance')}
                            </Button>
                        </Stack>
                    }
                />
            </Stack>
            {shouldShowBulkAdjustmentDialog && (
                <BulkAddAdjustmentDialog
                    open={shouldShowBulkAdjustmentDialog}
                    employeeBalances={bulkAdjustmentDialogEmployees?.employeeBalances ?? []}
                    date={endLocalDate}
                    onClose={handleOnCloseBulkAdjustmentDialog}
                    onSave={handleOnSaveBulkAdjustmentDialog}
                />
            )}
            {shouldShowAddAdjustmentDialog && (
                <AddAdjustmentDialog
                    open={shouldShowAddAdjustmentDialog}
                    onClose={handleOnCloseBulkAdjustmentDialog}
                    onSave={handleOnSaveBulkAdjustmentDialog}
                    employeeIds={getNonEmptyArrayOrError(bulkAdjustmentDialogEmployees.employeeBalances.map(employee => employee.employee.id))}
                    isBulkCreation
                />
            )}
        </Stack>
    );
};

const getTimesheetsMonthlySummaryColumns = (dateRange: [LocalDate, LocalDate]) => {
    const missingCountRenderer = ({ value }: { value: number }) => <MissingCount missingCount={value} />;

    const pendingCountRenderer = ({ value }: { value: number }) => {
        return value ? (
            <Typography variant='body1' color={'warning.main'}>
                {value} {i18next.t('timesheets.pending')}
            </Typography>
        ) : (
            '-'
        );
    };

    const columnDefs: ColDef<DailyTimesheetReport>[] = [
        {
            field: 'employee.email',
            headerName: 'Email',
            hide: true,
        },
        {
            field: 'employee',
            type: 'employee',
            headerName: i18next.t('general.employee'),
        },
        {
            field: 'totalWorkedCount',
            headerName: i18next.t('timesheets.table_headers.totalWorkedCount'),
            type: 'minutesToHours',
        },
        {
            colId: 'totalLeaveCount',
            headerName: i18next.t('timesheets.table_headers.totalLeaveCount'),
            valueGetter: ({ data }) => {
                if (!data) {
                    return 0;
                }
                const minutes = data?.totalLeaveCount + data?.totalPublicHolidayCount + data?.totalFutureLeaveCount;
                return minutes / 60;
            },
            valueFormatter: ({ value }) =>
                value
                    ? i18next.t('duration.formatDuration', {
                          duration: value,
                          unit: DurationUnit.HOURS,
                      })
                    : '-',
        },
        {
            field: 'totalContractCount',
            headerName: i18next.t('timesheets.table_headers.totalContractCount'),
            valueGetter: ({ data }) => {
                return ((data?.totalContractCount ?? 0) + (data?.totalPublicHolidayCount ?? 0)) / 60;
            },
            valueFormatter: ({ value }) =>
                i18next.t('duration.formatDuration', {
                    duration: value ?? 0,
                    unit: DurationUnit.HOURS,
                }),
        },
        {
            field: 'totalDifference',
            headerName: i18next.t('timesheets.table_headers.totalDifference'),
            type: 'minutesToHours',
        },
        {
            field: 'totalBonusCount',
            headerName: i18next.t('timesheets.table_headers.totalBonusCount'),
            type: 'minutesToHours',
        },
        {
            colId: 'totalCompensationCount',
            headerName: i18next.t('timesheets.table_headers.totalCompensation'),
            valueGetter: ({ data }) => {
                if (!data) {
                    return 0;
                }
                const minutes = -((data?.totalCompensationCount ?? 0) + (data?.totalFutureCompensationCount ?? 0));
                return minutes / 60;
            },
            valueFormatter: ({ value }) =>
                value
                    ? i18next.t('duration.formatDuration', {
                          duration: value,
                          unit: DurationUnit.HOURS,
                      })
                    : '-',
        },
        {
            field: 'totalPaymentCount',
            headerName: i18next.t('timesheets.payments'),
            type: 'minutesToHours',
        },
        {
            field: 'totalAdjustmentCount',
            headerName: i18next.t('timesheets.adjustments'),
            type: 'minutesToHours',
        },
        {
            field: 'pendingCount',
            headerName: i18next.t('timesheets.table_headers.approvals'),
            cellRenderer: pendingCountRenderer,
            cellClass: ['display-flex'],
        },
        {
            field: 'missingCount',
            headerName: i18next.t('timesheets.table_headers.submissions'),
            valueGetter: ({ data }) => (data?.missingCount ? data.missingCount : 0),
            cellRenderer: missingCountRenderer,
            cellClass: ['display-flex'],
        },
        {
            // Column for exporting the month end date,
            // we need to add a column that is not visible in the table
            colId: 'exportMonthEndDate',
            headerName: i18next.t('timesheets.table_headers.exportMonthEndDate'),
            hide: true,
            valueGetter: () => (dateRange?.length === 2 && dateRange[1] ? formatInDefaultDate(dateRange[1]) : ''),
        },
        {
            field: 'employee.employeeCode',
            headerName: i18next.t('payroll.id'),
            hide: true,
        },
    ];
    return columnDefs;
};
