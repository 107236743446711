import {
    getCompanyDocuments,
    getCompanyFolders,
    getEmployeeDocuments,
    getEmployeeFolders,
    getFolderById,
    getFolders,
    getFoldersByType,
} from '@/domain/document/Document.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';

export const useGetFoldersByType = createRequiredParamsQueryHook('foldersByType', getFoldersByType);

export const useGetFolders = createQueryHook('folders', getFolders);

export const useGetFolderById = createRequiredParamsQueryHook('folderById', getFolderById);

export const useGetEmployeeDocuments = createRequiredParamsQueryHook('employeeDocuments', getEmployeeDocuments);

export const useGetEmployeeFolders = createRequiredParamsQueryHook('employeeFolders', getEmployeeFolders);

export const useGetCompanyFolders = createQueryHook('companyFolders', getCompanyFolders);

export const useGetCompanyDocuments = createRequiredParamsQueryHook('companyDocuments', getCompanyDocuments);
