import { searchEmployeePayrollLock, searchLatestEmployeePayrollLock } from '@/domain/employee-payroll-lock/EmployeePayrollLock.service';
import { getPayrollEmployeeProfileChanges, getPayrollTransactions } from '@/domain/payroll/Payroll.service';
import { createQueryHook } from '@/page/Query.type';

export const useGetLatestEmployeePayrollLock = createQueryHook('latestEmployeePayrollLock', searchLatestEmployeePayrollLock);

export const useGetEmployeePayrollLock = createQueryHook('employeePayrollLock', searchEmployeePayrollLock);

export const useGetPayrollProfileChanges = createQueryHook('payrollProfileChanges', getPayrollEmployeeProfileChanges);

export const useGetPayrollTransactions = createQueryHook('payrollTransactions', getPayrollTransactions);
