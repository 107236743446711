import { Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import { FeedbackSectionFormType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import { useTheme } from '@mui/material';
import { getLabelTranslation } from '@/utils/language.util';
import { htmlToPlainText } from '@/utils/strings.util';

type FeedbackSectionPDFProps = {
    sectionItem: FeedbackSectionFormType | undefined;
};

export const FeedbackSectionPDF: FC<FeedbackSectionPDFProps> = ({ sectionItem }) => {
    const theme = useTheme();

    if (!sectionItem) {
        return <></>;
    }

    return (
        <View
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: theme.spacing(1),
                paddingLeft: theme.spacing(2),
            }}
        >
            <Text
                style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                }}
            >
                {getLabelTranslation(sectionItem.title)}
            </Text>
            {sectionItem.instruction && (
                <Text
                    style={{
                        fontSize: 12,
                    }}
                >
                    {htmlToPlainText(getLabelTranslation(sectionItem.instruction))}
                </Text>
            )}
        </View>
    );
};
