import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';
import { TemporaryFile } from '@/components/file-picker/FilePicker';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { EmployeeDocumentCreationMutation, Folder, PreviewData } from '@/domain/document/Document.model';
import { createEmployeeDocuments } from '@/domain/document/Document.service';
import { Employee, EmployeeSearch } from '@/domain/employee/Employee.model';
import { buildEmployeeFilterPredicate } from '@/domain/employee/Employee.service';
import { EmploymentStatus } from '@/domain/employment/Employment.model';
import { useGetFoldersByType } from '@/hooks/document/Document.hook';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { DocumentPreviewDialog } from '@/page/document/document-preview-dialog/DocumentPreviewDialog';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { Footer } from '@/page/layout/Footer';
import { MassDocumentImportDialog } from '@/page/setting/import/mass-document-import-dialog/MassDocumentImportDialog';
import { SelectResourceDialog } from '@/page/setting/import/mass-document-import-dialog/SelectResourceDialog';
import { handleError } from '@/utils/api.util';
import { getTodayDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { CellClassParams, ColDef } from 'ag-grid-community';
import { Button, CircularProgress, Link, Paper, Stack, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Row = {
    file: TemporaryFile;
    folder: Folder | undefined;
    employee: Employee | undefined;
    isSetManually: boolean;
};

type ValidRow = {
    folder: NonNullable<Row['folder']>;
    employee: NonNullable<Row['employee']>;
} & Omit<Row, 'folder' | 'employee'>;

export const ImportDocumentsPage: FC = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    // We have to load all the employees to do the matching with uploaded files
    const employeeSearch: EmployeeSearch = {
        statuses: [EmploymentStatus.EMPLOYED, EmploymentStatus.HIRED, EmploymentStatus.ON_LONG_LEAVE, EmploymentStatus.TERMINATED],
    };
    const { data: allEmployees = [], isLoading: isLoadingEmployees, isError: isErrorEmployees, error: errorEmployees } = useGetEmployees(employeeSearch);
    const {
        data: allEmployeesFolders = [],
        isLoading: isLoadingEmployeesFolder,
        isError: isErrorEmployeesFolders,
        error: errorEmployeesFolders,
    } = useGetFoldersByType('EMPLOYEE');

    const [documentsToImport, setDocumentsToImport] = useState<Row[]>([]);

    const [dialogToSetEmployeeIsOpen, setDialogToSetEmployeeIsOpen] = useState<boolean>(false);
    const [dialogToSetFolderIsOpen, setDialogToSetFolderIsOpen] = useState<boolean>(false);

    const [documentPreviewDialogOpen, setDocumentPreviewDialogOpen] = useState<boolean>(false);
    const [previewData, setPreviewData] = useState<PreviewData>();

    const agGridWrapper = useAgGridWrapper<Row>();

    const handleDocumentsAdded = ({
        documents,
        checkByName,
        checkByEmployeeCode,
    }: {
        documents: TemporaryFile[];
        checkByName: boolean;
        checkByEmployeeCode: boolean;
    }) => {
        const documentWithEmployeeMatching = documents.map<Row>(doc => {
            const predicate = buildEmployeeFilterPredicate(doc.name ?? '', {
                checkFirstName: checkByName,
                checkLastName: checkByName,
                checkEmployeeCode: checkByEmployeeCode,
            });
            const employee = allEmployees.find(predicate);

            const row: Row = {
                employee: employee,
                folder: undefined,
                file: doc,
                isSetManually: employee === undefined,
            };
            return row;
        });

        setDocumentsToImport(documents => [...documents, ...documentWithEmployeeMatching]);
    };
    const emptyCellColor = theme.palette.error.light;

    // set the background color of the cell based on the value
    const getCellBgColor = ({ value, data }: CellClassParams<Row>) => {
        if (!value) {
            return emptyCellColor;
        }
        if (data?.isSetManually) {
            // highlight the cell if the employee is set manually
            return theme.palette.warning.light;
        }
        // no background
        return '';
    };

    const documentNameCellRenderer = ({ value }: { value: string }) => (
        <Link component='button' color={theme.palette.primary.main} underline={'always'}>
            {value}
        </Link>
    );

    const columnsDef: ColDef<Row>[] = [
        {
            field: 'employee',
            type: 'employee',
            headerName: t('import_page.import_documents_page.matched_employee'),
            cellStyle: params => {
                return {
                    display: 'flex',
                    backgroundColor: getCellBgColor(params),
                };
            },
        },
        {
            field: 'folder.name',
            type: 'label',
            headerName: t('import_page.import_documents_page.matched_folder'),
            cellStyle: ({ value }) => ({
                backgroundColor: value ? '' : emptyCellColor,
            }),
        },
        {
            headerName: t('import_page.import_documents_page.document_name'),
            field: 'file.name',
            onCellClicked: ({ data }) => {
                if (!data) {
                    return;
                }

                setPreviewData({
                    // id is set to 0 because the document it's not yet imported
                    document: {
                        id: 0,
                        name: data.file.name ?? '',
                        documentType: 'DOCUMENT',
                        mimeType: data.file.type,
                        createdAt: getTodayDate(),
                    },
                    url: data.file.preview ?? '',
                });
                setDocumentPreviewDialogOpen(true);
            },
            cellRenderer: documentNameCellRenderer,
        },
    ];

    const handleImportDocumentsSubmit = async () => {
        try {
            setLoading(true);
            const isValidMutation = (doc: Row): doc is ValidRow => !!doc.employee && !!doc.folder;
            const convertRowToMutation = ({ folder, employee, ...doc }: ValidRow) => {
                return {
                    ...doc,
                    folderId: folder.id,
                    employeeId: employee.id,
                };
            };
            const rows = documentsToImport.filter(isValidMutation).map(convertRowToMutation);

            // group by folder and employeeId
            const folders = rows.reduce((acc, row) => {
                const groupIndex = acc.findIndex(f => f.folderId === row.folderId && f.employeeId === row.employeeId);
                if (groupIndex !== -1) {
                    return acc.map((f, index) => {
                        return {
                            ...f,
                            files: index === groupIndex ? [...f.files, row.file] : f.files,
                        };
                    });
                } else {
                    acc.push({
                        folderId: row.folderId,
                        employeeId: row.employeeId,
                        files: [row.file],
                        tagIds: [],
                    });
                    return acc;
                }
            }, [] as EmployeeDocumentCreationMutation[]);

            // create promise for each folder
            const employeeDocuments = folders.map(folder => {
                return createEmployeeDocuments(folder);
            });

            await Promise.all(employeeDocuments);
            showSnackbar(t('import_page.import_documents_page.employee_documents_created'), 'success');
            setDocumentsToImport([]);
        } catch (error) {
            handleError(error);
        }
        setLoading(false);
    };

    const selectedRow = agGridWrapper?.selectedRows;

    const clearSelection = () => {
        agGridWrapper?.gridRef.current?.api.deselectAll();
    };

    const handleEmployeeSelected = (employee: Employee) => {
        setDocumentsToImport(documents => {
            return documents.map<Row>(doc => {
                if (findSelectedRow(doc)) {
                    return {
                        ...doc,
                        employee,
                        isSetManually: true,
                    };
                }
                return doc;
            });
        });
        clearSelection();
        setDialogToSetEmployeeIsOpen(false);
    };

    const findSelectedRow = (doc: Row) => selectedRow.find(d => d.file.id === doc.file.id);

    const handleFolderSelected = (folder: Folder) => {
        setDocumentsToImport(documents => {
            return documents.map<Row>(doc => {
                if (findSelectedRow(doc)) {
                    return {
                        ...doc,
                        folder,
                    };
                }
                return doc;
            });
        });
        clearSelection();
        setDialogToSetFolderIsOpen(false);
    };

    const handleDownloadClick = async (previewData: PreviewData) => {
        const file = await fetch(previewData.url);
        const blob = await file.blob();

        // force download to avoid just displaying the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = previewData.document.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const hasValidRows = documentsToImport.some(doc => doc.employee && doc.folder);

    const handleRemoveSelected = () => {
        setDocumentsToImport(documents => {
            return documents.filter(doc => !findSelectedRow(doc));
        });
        clearSelection();
    };

    const validToImportCount = documentsToImport.filter(doc => doc.employee && doc.folder).length;

    const invalidToImportCount = documentsToImport.length - validToImportCount;

    return (
        <>
            <ContentContainer>
                {/* Without employee this page can't work */}
                <StateHandler
                    isLoading={isLoadingEmployees || isLoadingEmployeesFolder}
                    isError={isErrorEmployees || isErrorEmployeesFolders}
                    error={errorEmployees || errorEmployeesFolders}
                >
                    <Stack gap={2} flex={1}>
                        <Stack component={Paper} p={2} flex={1}>
                            <AgGridWrapper<Row>
                                initRef={agGridWrapper.setGridRef}
                                rowData={documentsToImport}
                                columnDefs={columnsDef}
                                rowSelection={{
                                    mode: 'multiRow',
                                }}
                                getRowId={({ data }) => data.file.id}
                                loading={false}
                                toolbarActions={
                                    <Stack direction='row' gap={1}>
                                        <Button size='small' onClick={() => setDialogToSetEmployeeIsOpen(true)}>
                                            {t('import_page.import_documents_page.set_employee')}
                                        </Button>
                                        {dialogToSetEmployeeIsOpen && (
                                            <SelectResourceDialog
                                                header={t('import_page.import_documents_page.select_employee')}
                                                open={dialogToSetEmployeeIsOpen}
                                                options={allEmployees.map(employee => ({
                                                    label: employee.displayName,
                                                    value: employee,
                                                }))}
                                                onSelectValue={handleEmployeeSelected}
                                                onClose={() => setDialogToSetEmployeeIsOpen(false)}
                                                label={t('import_page.import_documents_page.set_employee_label')}
                                            />
                                        )}

                                        <Button size='small' onClick={() => setDialogToSetFolderIsOpen(true)}>
                                            {t('import_page.import_documents_page.set_folder')}
                                        </Button>
                                        {dialogToSetFolderIsOpen && (
                                            <SelectResourceDialog
                                                header={t('import_page.import_documents_page.select_folder')}
                                                open={dialogToSetFolderIsOpen}
                                                options={allEmployeesFolders.map(folder => ({
                                                    label: getLabelTranslation(folder.name),
                                                    value: folder,
                                                }))}
                                                onSelectValue={handleFolderSelected}
                                                onClose={() => setDialogToSetFolderIsOpen(false)}
                                                label={t('import_page.import_documents_page.set_folder_label')}
                                            />
                                        )}

                                        {/* Button to remove selected row */}
                                        <Button size='small' onClick={handleRemoveSelected}>
                                            {t('import_page.import_documents_page.remove_selected')}
                                        </Button>
                                    </Stack>
                                }
                            />
                            {documentPreviewDialogOpen && !!previewData && (
                                <DocumentPreviewDialog
                                    onClose={() => setDocumentPreviewDialogOpen(false)}
                                    previewData={previewData}
                                    onDownloadClick={() => handleDownloadClick(previewData)}
                                />
                            )}
                        </Stack>
                    </Stack>
                </StateHandler>
                {dialogIsOpen && (
                    <MassDocumentImportDialog
                        open={true}
                        onSave={documents => {
                            handleDocumentsAdded(documents);
                            setDialogIsOpen(false);
                        }}
                        onClose={() => setDialogIsOpen(false)}
                    />
                )}
            </ContentContainer>
            <Footer>
                <Button variant='outlined' onClick={() => setDialogIsOpen(true)}>
                    {t('import_page.import_documents_page.upload_again')}
                </Button>

                <ConfirmDialog
                    title={t('import_page.import_documents_page.confirm_import')}
                    content={
                        t('import_page.import_documents_page.confirm_import_content', {
                            count: validToImportCount,
                        }) +
                        ' ' +
                        t('import_page.import_documents_page.confirm_import_content_invalid', { count: invalidToImportCount })
                    }
                    onConfirm={handleImportDocumentsSubmit}
                >
                    <Button disabled={!hasValidRows || loading}>
                        {loading ? <CircularProgress size={20} /> : t('import_page.import_documents_page.confirm_import')}
                    </Button>
                </ConfirmDialog>
            </Footer>
        </>
    );
};
