import * as yup from 'yup';
import { getNull } from '@/utils/object.util';

export const treeSelectValueSchema = yup
    .object()
    .shape({
        id: yup.lazy(value => (typeof value === 'number' ? yup.number().required() : yup.string().required())),
        label: yup.string().required(),
        children: yup.array().required().default([]),
    })
    .default(getNull());

/**
 * Schema to force id is a number
 */
export const treeSelectNumberValueSchema = treeSelectValueSchema.omit(['id']).concat(yup.object({ id: yup.number().required() }));
