import { FormControlLabelProps, FormHelperText, RadioGroup, Stack } from '@mui/material';
import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { ReactNode } from 'react';

export type FieldRadioGroupProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    children: ReactNode;
} & Omit<ControllerProps<TFieldValues, TName>, 'render'> &
    Omit<FormControlLabelProps, 'control' | 'label' | 'onChange'> & {
        label?: ReactNode;
    };

export const FieldRadioGroup = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    props: FieldRadioGroupProps<TFieldValues, TName>,
): JSX.Element => {
    return (
        <Controller
            {...props}
            render={({ field, fieldState }) => (
                <Stack>
                    <RadioGroup aria-label={field.name} row {...field}>
                        {props.children}
                    </RadioGroup>

                    {!!fieldState.error && (
                        <FormHelperText sx={{ pl: 2 }} error>
                            {fieldState?.error?.message}
                        </FormHelperText>
                    )}
                </Stack>
            )}
        />
    );
};
