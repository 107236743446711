import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { FilterType, isSelectFilter } from '@/components/filters-bar/FilterBar.type';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { getFilterValueIdsByKey, getSelectFilterStringValues } from '@/components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { EmployeePayrollLockLatestSearchRequest, LatestPayrollLocks } from '@/domain/employee-payroll-lock/EmployeePayrollLock.model';
import { EmploymentStatus } from '@/domain/employment/Employment.model';
import { useEmployeePayrollLocksPageFilters } from '@/hooks/payroll/EmployeePayrollLocksPageFilters.hook';
import { useGetLatestEmployeePayrollLock } from '@/hooks/payroll/Payroll.hook';
import { LockTimesheetDialog } from '@/page/payroll/lock-timesheet-dialog/LockTimesheetDialog';
import { UnlockEmployeePayrollLocksDialog } from '@/page/payroll/unlock-employee-payroll-locks-dialog/UnlockEmployeePayrollLocksDialog';
import { handleError } from '@/utils/api.util';
import { Button, Paper, Stack } from '@mui/material';
import { ColDef } from 'ag-grid-enterprise';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const EmployeesPayrollLocksPage: FC = () => {
    const { filters: availableFilters } = useEmployeePayrollLocksPageFilters();
    const [filters, setFilters] = useFiltersStorage('employees-payroll-locks-filters', availableFilters);

    const handleFilterChange = (filters: FilterType[]) => {
        setFilters(filters);
    };

    const mapFiltersToSearchRequest = (filters: FilterType[]) => {
        const filtersFilled = filters?.filter(filter => isSelectFilter(filter) && !!filter.value?.length);

        const search: EmployeePayrollLockLatestSearchRequest = {
            locationIds: getFilterValueIdsByKey(filtersFilled, 'locationIds'),
            departmentIds: getFilterValueIdsByKey(filtersFilled, 'departmentIds'),
            jobIds: getFilterValueIdsByKey(filtersFilled, 'jobIds'),
            employeeIds: getFilterValueIdsByKey(filtersFilled, 'employeeIds'),
            employmentStatus: getSelectFilterStringValues(filtersFilled.find(filter => filter.key === 'employmentStatuses'))?.[0] as EmploymentStatus,
        };
        return search;
    };

    const {
        data: latestEmployeePayrollLocks = [],
        isFetching = false,
        refetch,
    } = useGetLatestEmployeePayrollLock(mapFiltersToSearchRequest(filters), { enabled: !!filters?.length });

    const handleRefetch = () => {
        if (refetch) {
            refetch().catch(handleError);
        }
    };

    return (
        <Stack direction='column' gap={2} flex={1}>
            <Stack component={Paper} p={1} gap={1} direction='row' justifyContent='space-between' alignItems='center'>
                <Stack direction='row' alignItems='flex-end' gap={1}>
                    <FiltersBar filters={filters} onFiltersChange={handleFilterChange} />
                </Stack>
            </Stack>
            <EmployeesPayrollLocksTable
                latestEmployeePayrollLocks={latestEmployeePayrollLocks}
                refetchEmployeePayrollLocks={handleRefetch}
                isLoading={isFetching}
            />
        </Stack>
    );
};

type EmployeesPayrollLocksTableProps = {
    latestEmployeePayrollLocks: LatestPayrollLocks[];
    refetchEmployeePayrollLocks: () => void;
    isLoading: boolean;
};

const EmployeesPayrollLocksTable: FC<EmployeesPayrollLocksTableProps> = ({ latestEmployeePayrollLocks, refetchEmployeePayrollLocks, isLoading }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const agGridWrapper = useAgGridWrapper<LatestPayrollLocks>();
    const selectedRows = agGridWrapper?.selectedRows;

    const [employeeIdsToUnlock, setEmployeeIdsToUnlock] = useState<number[]>([]);
    const [employeeIdsToLock, setEmployeeIdsToLock] = useState<number[]>([]);

    // We memoized the column definitions to avoid re-rendering the table on every render, leading to issues like losing the sorting
    const columnDefs: ColDef<LatestPayrollLocks>[] = useMemo(
        () => [
            {
                headerName: t('payroll_locks_page.table_headers.employee'),
                field: 'employee',
                type: 'employee',
                width: 350,
                cellRendererParams: () => ({
                    cellNavDisabled: true,
                }),
            },
            {
                field: 'employeePayrollLock.lockedAt',
                headerName: t('payroll_locks_page.table_headers.lockedAt'),
                type: 'date',
            },
            {
                field: 'employeePayrollLock.comment',
                headerName: t('payroll_locks_page.table_headers.comment'),
            },
            {
                field: 'employeePayrollLock.createdBy.displayName',
                headerName: t('payroll_locks_page.table_headers.lockedBy'),
            },
        ],
        [t],
    );

    const handleUnlockEmployeeLockButton = () => {
        if (!selectedRows) {
            return;
        }
        const employeeIds = selectedRows?.map(latestPayrollLock => latestPayrollLock.employee.id) ?? [];
        setEmployeeIdsToUnlock(employeeIds);
    };

    const handleLockEmployeeLockButton = () => {
        if (!selectedRows) {
            return;
        }
        const employeeIds = selectedRows?.map(latestPayrollLock => latestPayrollLock.employee.id) ?? [];
        setEmployeeIdsToLock(employeeIds);
    };

    const handleOnSaveUnlock = () => {
        setEmployeeIdsToUnlock([]);
        refetchEmployeePayrollLocks();
    };

    const handleOnCancelUnlock = () => {
        setEmployeeIdsToUnlock([]);
    };
    const handleOnSaveLock = () => {
        setEmployeeIdsToLock([]);
        refetchEmployeePayrollLocks();
    };

    const handleOnCloseLock = () => {
        setEmployeeIdsToLock([]);
    };

    return (
        <Stack direction='column' gap={2} flex={1}>
            <Paper elevation={0} sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                <AgGridWrapper<LatestPayrollLocks>
                    compact
                    gridId='employees-payroll-locks-table'
                    columnDefs={columnDefs}
                    rowData={latestEmployeePayrollLocks}
                    initRef={agGridWrapper.setGridRef}
                    getRowId={params => params.data.employee.id.toString()}
                    onRowClicked={({ event, data }) => {
                        if (!event?.defaultPrevented && data) {
                            navigate(`/payroll/locks/${data.employee.id}`);
                        }
                    }}
                    rowSelection={{
                        mode: 'multiRow',
                    }}
                    loading={isLoading}
                    toolbarActions={
                        <Stack direction='row' gap={1}>
                            <Button onClick={handleLockEmployeeLockButton}>{t('payroll_locks_page.action_button.lock')}</Button>
                            <Button onClick={handleUnlockEmployeeLockButton}>{t('payroll_locks_page.action_button.unlock')} </Button>
                        </Stack>
                    }
                />
            </Paper>

            {!!employeeIdsToUnlock.length && (
                <UnlockEmployeePayrollLocksDialog onSave={handleOnSaveUnlock} onCancel={handleOnCancelUnlock} employeeIds={employeeIdsToUnlock} />
            )}
            {!!employeeIdsToLock.length && <LockTimesheetDialog onClose={handleOnCloseLock} onSave={handleOnSaveLock} employeeIds={employeeIdsToLock} />}
        </Stack>
    );
};
