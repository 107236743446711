import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { EmployeeReviewFeedback, EmployeeReviewFeedbackMutation } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { ContributorType } from '@/domain/review/Review.model';
import { Objective } from '@/domain/objective/Objective.model';
import { Employee } from '@/domain/employee/Employee.model';
import {
    feedbackFormSchema,
    FeedbackFormType,
    FeedbackItemForm,
    FeedbackObjectiveFormType,
    FeedbackObjectivesActionFormType,
    FeedbackQuestionFormType,
    FeedbackSkillFormType,
    FeedbackSkillQuestionFormType,
    FeedbackSkillsFormType,
} from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import { FC, useRef, useState } from 'react';
import { Button, DialogActions, DialogContent, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { getEmployeeReviewContributorType } from '@/domain/employee-review/EmployeeReview.service';
import { Controller, FieldError, SubmitErrorHandler, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { handleError } from '@/utils/api.util';
import { FeedbackSection } from '@/page/review/employee-review-feedback-form/FeedbackSection';
import { FeedbackQuestion } from '@/page/review/employee-review-feedback-form/FeedbackQuestion';
import { FeedbackSkills } from '@/page/review/employee-review-feedback-form/FeedbackSkills';
import { FeedbackObjectives } from '@/page/review/employee-review-feedback-form/FeedbackObjectives';
import { nonNullable } from '@/utils/object.util';
import { linkRef } from '@/utils/dom.util';
import { FeedbackFormHeader } from '@/page/review/employee-review-feedback-form/FeedbackFormHeader';
import { feedbackFormUtil } from '@/page/review/employee-review-feedback-form/FeedbackForm.util';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { Skill } from '@/domain/skill/Skill.model';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { EmployeeReviewAction } from '@/domain/employee-review-action/EmployeeReviewAction.model';
import { FeedbackObjectiveActions } from '@/page/review/employee-review-feedback-form/FeedbackObjectiveActions';
import { useScrollToFirstError } from '@/hooks/ErrorScroll.hook';
import { OnboardingProfileStepFormValues } from '@/page/people/on-boarding-form/EmployeeInformationsForm/EmployeeInformationForm';
import { updateEmployeeReviewFeedbackItem } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';
import { submitFeedback } from '@/api/employee-review-feedback/EmployeeReviewFeedback.api';

type FeedbackFormProps = {
    employeeReview: EmployeeReview;
    feedback: EmployeeReviewFeedback | undefined;
    employeeId: number;
    isPreview: boolean;
    previewRole: ContributorType | undefined;
    objectives: Objective[];
    refetchObjectives: () => void;
    isFormDisabled: boolean;
    currentEmployee: Employee;
    skills: Skill[];
    actions: EmployeeReviewAction[];
    refetchActions: () => void;
};

export const FeedbackForm: FC<FeedbackFormProps> = ({
    employeeReview,
    previewRole,
    isPreview,
    employeeId,
    feedback,
    objectives,
    refetchObjectives,
    isFormDisabled,
    currentEmployee,
    skills,
    actions,
    refetchActions,
}) => {
    const itemsRefs = useRef<HTMLDivElement[]>([]);
    const { t } = useTranslation();
    const [confirmSubmitFeedbackDialogOpen, setConfirmSubmitFeedbackDialogOpen] = useState(false);
    const navigate = useNavigate();

    const getConfirmSubmitFeedbackDialogSubtitle = () => {
        if (!employeeReview || !currentEmployee) {
            return;
        }
        const contributorType = getEmployeeReviewContributorType(employeeReview, currentEmployee);
        return t('reviews.write_feedback.confirm_submit_feedback_dialog_subtitle', {
            context: contributorType,
        });
    };

    const formMethods = useForm<FeedbackFormType>({
        resolver: yupResolver(feedbackFormSchema),
        defaultValues: feedbackFormUtil.getDefaultValues(feedback?.feedbacks ?? [], skills, previewRole, employeeReview, isPreview, currentEmployee),
    });

    const { control, formState, watch, handleSubmit } = formMethods;
    const { isSubmitting } = formState;
    const { resetFocus } = useScrollToFirstError(formState);

    const { fields } = useFieldArray({
        control,
        name: 'feedbackItems',
    });

    const onSaveItem = async (feedbackFormItem: FeedbackItemForm, feedbackSkillForm?: FeedbackSkillFormType) => {
        // we don't want to save the feedback if we are in preview mode
        if (isPreview) {
            return;
        }
        let feedbackMutation: EmployeeReviewFeedbackMutation;

        switch (feedbackFormItem.type) {
            case 'SECTION':
                return;
            case 'SKILL':
                feedbackMutation = {
                    reviewItemId: feedbackFormItem.id,
                    score: feedbackFormItem.score,
                    comment: feedbackFormItem.comment,
                    skillId: feedbackFormItem.skillId,
                    objectiveId: undefined,
                };
                break;
            case 'QUESTION':
                feedbackMutation = {
                    reviewItemId: feedbackFormItem.id,
                    score: feedbackFormItem.score,
                    comment: feedbackFormItem.comment,
                    skillId: undefined,
                    objectiveId: undefined,
                };
                break;
            case 'SKILLS':
                feedbackMutation = {
                    reviewItemId: feedbackFormItem.id,
                    score: feedbackSkillForm?.score,
                    comment: feedbackSkillForm?.comment,
                    skillId: feedbackSkillForm?.skill.id,
                    objectiveId: undefined,
                };
                break;
            default:
                return;
        }

        try {
            await updateEmployeeReviewFeedbackItem(employeeReview.id, feedbackMutation);
        } catch (error) {
            handleError(error);
        }
    };

    const redirectAfterSubmit = () => {
        if (!currentEmployee || !employeeReview) {
            return;
        }

        const contributorType = getEmployeeReviewContributorType(employeeReview, currentEmployee);

        if (contributorType === 'EMPLOYEE') {
            navigate('/profile/reviews');
        } else {
            navigate('/reviews/tasks');
        }
    };

    const onSave = async (feedbackFormData: FeedbackFormType) => {
        const feedbacksMutation: EmployeeReviewFeedbackMutation[] = feedbackFormData.feedbackItems
            .filter(
                (item): item is FeedbackSkillQuestionFormType | FeedbackQuestionFormType | FeedbackSkillsFormType =>
                    item.type === 'SKILL' || item.type === 'QUESTION' || item.type === 'SKILLS',
            )
            .map(item => {
                switch (item.type) {
                    case 'SKILL':
                        return {
                            reviewItemId: item.id,
                            score: item.score,
                            comment: item.comment,
                            skillId: item.skillId,
                            objectiveId: undefined,
                        };
                    case 'QUESTION':
                        return {
                            reviewItemId: item.id,
                            score: item.score,
                            comment: item.comment,
                            skillId: undefined,
                            objectiveId: undefined,
                        };
                    case 'SKILLS':
                        return item.skills
                            .filter(skill => skill.score)
                            .map(skill => {
                                return {
                                    reviewItemId: item.id,
                                    score: skill.score,
                                    comment: skill.comment,
                                    skillId: skill.skill.id,
                                    objectiveId: undefined,
                                };
                            });
                }
            })
            .filter(nonNullable)
            .flat();

        if (!feedbacksMutation || !employeeReview) {
            return;
        }

        try {
            await submitFeedback(employeeReview.id, feedbacksMutation);
            redirectAfterSubmit();
        } catch (error) {
            handleError(error);
        }
    };

    const openConfirmSubmitFeedbackDialog = () => {
        setConfirmSubmitFeedbackDialogOpen(true);
    };

    const updateFeedbackSkill =
        (value: FeedbackSkillsFormType, onChange: (v: FeedbackSkillsFormType) => void) => (feedbackSkillForm: FeedbackSkillFormType) => {
            const skillsItem = value;
            const updatedSkills = skillsItem.skills.map(skill => {
                if (skill.skill.id === feedbackSkillForm.skill.id) {
                    return feedbackSkillForm;
                }
                return skill;
            });
            onChange({ ...skillsItem, skills: updatedSkills });
            onSaveItem(skillsItem, feedbackSkillForm).catch(console.error);
        };

    const onError: SubmitErrorHandler<OnboardingProfileStepFormValues> = errors => {
        console.error(errors);
        resetFocus();
    };

    return (
        <>
            <ContentContainer>
                <Stack
                    direction='column'
                    gap={2}
                    id={'employee-review-feedback-form'}
                    component={'form'}
                    onSubmit={handleSubmit(openConfirmSubmitFeedbackDialog, console.error)}
                >
                    <Stack
                        sx={{
                            width: {
                                lg: '1000px',
                                xs: '100%',
                            },
                        }}
                        direction='column'
                        alignSelf={'center'}
                        gap={2}
                    >
                        <FeedbackFormHeader
                            feedback={feedback}
                            feedbackItems={watch('feedbackItems')}
                            employeeReview={employeeReview}
                            isPreview={isPreview}
                            objectives={objectives}
                            currentEmployee={currentEmployee}
                            actions={actions}
                        />

                        <Stack gap={2} direction='column'>
                            {fields.map((item, index) => {
                                switch (item?.type) {
                                    case 'SECTION':
                                        return <FeedbackSection sectionItem={item} key={item.id} />;
                                    case 'SKILLS':
                                        if (isPreview) {
                                            return (
                                                <Stack component={Paper} key={item.id} p={2}>
                                                    <Typography variant={'h2'}>{t('reviews.write_feedback.skills_preview')}</Typography>
                                                </Stack>
                                            );
                                        }

                                        return (
                                            <Controller
                                                name={`feedbackItems.${index}`}
                                                control={control}
                                                key={item.id}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <FeedbackSkills
                                                        innerRef={linkRef(index, itemsRefs)}
                                                        // Todo: fix the as EmployeeReviewFeedbackSkillsFormType
                                                        skillsItem={value as FeedbackSkillsFormType}
                                                        handleSkillChanged={updateFeedbackSkill(value as FeedbackSkillsFormType, onChange)}
                                                        error={error}
                                                        disabled={isFormDisabled}
                                                    />
                                                )}
                                            />
                                        );
                                    case 'REVIEW_OBJECTIVES':
                                        return (
                                            <Controller
                                                name={`feedbackItems.${index}`}
                                                control={control}
                                                key={item.id}
                                                render={({ field: { value } }) => (
                                                    <FeedbackObjectives
                                                        feedbackObjectiveForm={value as FeedbackObjectiveFormType}
                                                        objectives={objectives}
                                                        employeeId={employeeId}
                                                        disabled={isFormDisabled}
                                                        key={item.id}
                                                        refetchObjectives={refetchObjectives}
                                                    />
                                                )}
                                            />
                                        );
                                    case 'OBJECTIVE_ACTIONS':
                                        return (
                                            <Controller
                                                name={`feedbackItems.${index}`}
                                                control={control}
                                                key={item.id}
                                                render={({ field: { value } }) => (
                                                    <FeedbackObjectiveActions
                                                        feedbackObjectiveActionsForm={value as FeedbackObjectivesActionFormType}
                                                        actions={actions}
                                                        employeeReview={employeeReview}
                                                        employeeId={employeeId}
                                                        refetchActions={refetchActions}
                                                        disabled={isFormDisabled}
                                                    />
                                                )}
                                            />
                                        );
                                    case 'QUESTION':
                                    case 'SKILL':
                                    default:
                                        return (
                                            <Controller
                                                name={`feedbackItems.${index}`}
                                                control={control}
                                                key={item.id}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <FeedbackQuestion
                                                        p={2}
                                                        innerRef={linkRef(index, itemsRefs)}
                                                        questionItem={value as FeedbackQuestionFormType}
                                                        disabled={isFormDisabled}
                                                        // have to cast the error because the type of the error in the output of fieldState error is not the right one
                                                        error={
                                                            error as {
                                                                comment?: FieldError;
                                                                score?: FieldError;
                                                            }
                                                        }
                                                        key={item.id}
                                                        onUpdate={value => {
                                                            onChange(value);
                                                            onSaveItem(value).catch(console.error);
                                                        }}
                                                        isSummary={false}
                                                    />
                                                )}
                                            />
                                        );
                                }
                            })}
                        </Stack>
                    </Stack>
                </Stack>
            </ContentContainer>

            {confirmSubmitFeedbackDialogOpen && (
                <DialogWrapper
                    open={true}
                    onClose={() => {
                        setConfirmSubmitFeedbackDialogOpen(false);
                    }}
                    header={t('reviews.write_feedback.confirm_submit_feedback_dialog_title')}
                >
                    <DialogContent>
                        <Typography variant='body2'>{getConfirmSubmitFeedbackDialogSubtitle()}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button fullWidth onClick={handleSubmit(onSave, onError)} disabled={isSubmitting}>
                            {t('reviews.write_feedback.confirm_submit_feedback_dialog_confirm')}
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            )}
        </>
    );
};
