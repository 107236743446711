import { FilterType } from '@/components/filters-bar/FilterBar.type';
import { UnitType } from '@/domain/date/Date.model';
import { getUnitTypeTranslationKey } from '@/domain/date/Date.service';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';
import { TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { useFiltersDirectory } from '@/page/common/filters-directory/useFiltersDirectory';
import { activeMonth } from '@/page/employee-profile/employee-profile-timesheet/timesheets-history/TimesheetsHistory.util';
import {
    formatInDefaultDate,
    formatToLocalDate,
    getCurrentLocalDate,
    getEndOfYear,
    getStartOfMonth,
    getStartOfYear,
    MONTHS,
    setMonth,
    toDate,
} from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

export const useLeaveBalancePageFilters = (
    leaveTypes: LeaveType[],
    defaultLeaveType: LeaveType,
    timesheetSettings: TimesheetSetting[],
    defaultTimesheetSetting: TimesheetSetting,
    haveDifferentCycleStartMonth: boolean,
): {
    filters: FilterType[];
} => {
    const { t } = useTranslation();

    const { filters: filtersFromDirectory } = useFiltersDirectory(['LOCATION', 'JOB', 'DEPARTMENT', 'MANAGER']);

    const filters = [
        {
            filterName: t('balance_page.filters.cycle'),
            type: 'select',
            selectMode: 'SYNC',
            value: [
                {
                    label: getFormattedMonth(defaultTimesheetSetting.cycleStartMonth),
                    value: defaultTimesheetSetting.cycleStartMonth,
                },
            ],
            //only unique months should be displayed
            options: Array.from(new Set(timesheetSettings?.map(timesheetSetting => timesheetSetting.cycleStartMonth))).map(month => ({
                label: getFormattedMonth(month),
                value: month,
            })),
            key: 'startCycleMonth',
            rule: 'EQUALS',
            availableRules: [],
            clearable: false,
            hide: !haveDifferentCycleStartMonth,
        },
        {
            key: 'endCycle',
            filterName: formatInDefaultDate(getStartOfYear()),
            type: 'date',
            value: getEndOfYear(),
            dateType: 'MORE_THAN',
            availableRules: ['MORE_THAN'],
            clearable: false,
        },
        {
            filterName: t('balance_page.filters.Leave_type'),
            type: 'select',
            selectMode: 'SYNC',
            options: leaveTypes?.map(leaveType => ({
                label: getLabelTranslation(leaveType.name),
                value: leaveType.id,
            })),
            value: [{ label: getLabelTranslation(defaultLeaveType.name), value: defaultLeaveType.id }],
            key: 'leaveTypeId',
            rule: 'EQUALS',
            availableRules: [],
            clearable: false,
        },
        {
            filterName: t('balance_page.filters.display_in'),
            type: 'select',
            selectMode: 'SYNC',
            options: Object.values(UnitType)
                .filter(unit => unit !== UnitType.BOTH)
                .map(unit => ({
                    label: t(getUnitTypeTranslationKey(unit)),
                    value: unit,
                })),
            value: [{ label: t(getUnitTypeTranslationKey(UnitType.DAYS)), value: UnitType.DAYS }],
            key: 'displayInUnitType',
            rule: 'EQUALS',
            availableRules: [],
            clearable: false,
            hide: defaultLeaveType.displayUnitType !== UnitType.BOTH,
        },
        ...filtersFromDirectory,
    ] satisfies FilterType[];

    return {
        filters,
    };
};

const getFormattedMonth = (month: MONTHS): string => {
    const date = toDate(getStartOfMonth(getCurrentLocalDate()));
    const startDate = formatToLocalDate(setMonth(date, activeMonth(month)));

    return format(startDate, 'MMM');
};
