import { useLocalStorage } from '@/hooks/Storage.hook';
import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { FilterType } from '@/components/filters-bar/FilterBar.type';

export const useFiltersStorage = <T extends FilterType = FilterType>(storageKey: string, availableFilters: T[]): [T[], (filters: T[]) => void] => {
    // TODO challenge typing because we get strings from the session storage instead of date for example
    const [filterInStorage, setFilterInStorage] = useLocalStorage<FilterType[]>(storageKey, []);
    const [filters, setFilters] = useState<T[]>([]);

    const handleFilterChange = (filters: T[]) => {
        setFilterInStorage(filters);
        setFilters(filters);
    };

    // get the filters from the local storage
    useDeepCompareEffect(() => {
        if (filters?.length) {
            return;
        }

        if (filterInStorage.length) {
            // We are basing the filters on the available filters to avoid having old from local storage
            const currentFilters = availableFilters.map(filter => {
                const filterFromStorage = filterInStorage.find(filterFromStorage => filterFromStorage.key === filter.key);
                return {
                    ...filter,
                    // Improve this part to use available values from the filter instead of the filterFromStorage
                    value: filterFromStorage?.value,
                    // If we have values in the storage (from the previous session) we should show the filter
                    hide: !filterFromStorage?.value && filter.hide,
                };
            });

            setFilters(currentFilters);
            return;
        }
        setFilters(availableFilters);
    }, [availableFilters, filters]);

    return [filters, handleFilterChange];
};
