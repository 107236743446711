import { FC } from 'react';
import { FeedbackFormType } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';
import { Stack, Typography, useTheme } from '@mui/material';
import { EmployeeAvatar } from '@/domain-ui/employee/employee-avatar/EmployeeAvatar';
import { RatingItemFormType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import { getRatingBackground, getRatingBackgroundOpacity } from '@/page/review/employee-review-summary-feedback-form/FeedbackFromPreparation.util';

type FeedbackFromPreparationProps = {
    feedbackForm: FeedbackFormType;
    ratingItems: RatingItemFormType[];
};

export const FeedbackFromPreparation: FC<FeedbackFromPreparationProps> = ({ feedbackForm, ratingItems }) => {
    const theme = useTheme();

    if (!feedbackForm) {
        return <></>;
    }

    const ratingItem = (ratingItems ?? []).find(ratingItem => ratingItem && ratingItem.score === feedbackForm.score);

    const getRatingTitleFontColor = (ratingItem: RatingItemFormType, ratingItems: RatingItemFormType[]) => {
        const backgroundOpacity = getRatingBackgroundOpacity(ratingItem, ratingItems);
        return backgroundOpacity > 0.65 ? theme.palette.common.white : theme.palette.text.primary;
    };

    return (
        <Stack direction={ratingItem?.label ? 'column' : 'row'} alignItems={ratingItem?.label ? 'flex-start' : 'center'} gap={ratingItem?.label ? 0.25 : 1}>
            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <EmployeeAvatar employeeAvatar={feedbackForm.reviewer} />
                {ratingItem?.label && ratingItems && (
                    <Stack p={0.5} px={1} width={250} bgcolor={getRatingBackground(ratingItem, ratingItems)} borderRadius={1}>
                        <Typography color={getRatingTitleFontColor(ratingItem, ratingItems)} variant='body1'>
                            {ratingItem.label}
                        </Typography>
                    </Stack>
                )}
            </Stack>
            {/* Properly display line breaks and multiple spaces in the comment */}
            <Typography sx={{ whiteSpace: 'pre-line' }} variant='body1'>
                {feedbackForm.comment}
            </Typography>
        </Stack>
    );
};
