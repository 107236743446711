// generate CRUD functions like Job.service.ts

import { DocumentTag, DocumentTagMutation, DocumentTagSearch } from '@/domain/document-tag/DocumentTag.model';
import { documentTagAPI } from '@/api/document-tag/DocumentTag.api';

export const getDocumentTags = async (search: DocumentTagSearch = {}): Promise<DocumentTag[]> => {
    return documentTagAPI.getDocumentTags(search);
};

export const createDocumentTag = async (mutation: DocumentTagMutation): Promise<DocumentTag> => {
    return documentTagAPI.createDocumentTag(mutation);
};

export const updateDocumentTag = async (tagId: number, mutation: DocumentTagMutation): Promise<DocumentTag> => {
    return documentTagAPI.updateDocumentTag(tagId, mutation);
};

export const deleteDocumentTag = async (tagId: number): Promise<void> => {
    return documentTagAPI.deleteDocumentTag(tagId);
};
