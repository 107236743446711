import { Paper, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { TemporaryFile } from '@/components/file-picker/FilePicker';
import { getAppConfig } from '@/config/config';
import { Document, EmployeeDocumentCreationMutation, EmployeeDocumentLinkCreationMutation, Folder, PreviewData } from '@/domain/document/Document.model';
import { DocumentTable } from '@/domain-ui/document/DocumentTable';
import {
    createEmployeeDocuments,
    createEmployeeDocumentsLinks,
    deleteEmployeeDocument,
    getEmployeeDocumentDownloadUrl,
    updateEmployeeDocument,
} from '@/domain/document/Document.service';
import { canDeleteDocumentsInEmployeeFolder, canManageDocumentsInEmployeeFolder } from '@/domain/permission/Permission.service';
import { useGetEmployeeDocuments } from '@/hooks/document/Document.hook';
import { DocumentDetailHeader } from '@/page/document/document-header/DocumentDetailHeader';
import { DocumentLinkDialog, DocumentLinkFormValues } from '@/page/document/document-link-dialog/DocumentLinkDialog';
import { DocumentNameDialog, EditDocumentFormValues } from '@/page/document/document-name-dialog/DocumentNameDialog';
import { DocumentPreviewDialog } from '@/page/document/document-preview-dialog/DocumentPreviewDialog';
import { useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { getFilterValueIdsByKey } from '@/components/filters-bar/FiltersBar.util';
import { useDocumentFilters } from '@/page/document/document-filter/DocumentFilter.hook';
import { DocumentUploadDialog } from '@/page/document/document-upload-dialog/DocumentUploadDialog';

const config = getAppConfig();

type Props = {
    folder: Folder;
    employeeId: number;
};

export const EmployeeDocumentDetails: FC<Props> = ({ folder, employeeId }) => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [previewData, setPreviewData] = useState<PreviewData>();
    const [addLinkDialogOpen, setAddLinkDialogOpen] = useState<boolean>(false);
    const [documentToEdit, setDocumentToEdit] = useState<Document>();
    const [isUploadDocumentDialogOpen, setIsUploadDocumentDialogOpen] = useState<boolean>(false);
    const realm = useCurrentRealm();
    const policies = useCurrentPolicies();

    const { filters: availableFilters } = useDocumentFilters();
    const [filters, setFilters] = useFiltersStorage('employee-document-tags', availableFilters);

    const {
        data: documents = [],
        isLoading,
        refetch,
    } = useGetEmployeeDocuments({ folderId: folder.id, employeeId, tagIds: getFilterValueIdsByKey(filters, 'DOCUMENT_TAG_IDS') });

    const canManageEmployeeFolder = canManageDocumentsInEmployeeFolder(realm.realmFeatures, policies, employeeId, folder?.id);
    const canDeleteEmployeeFolder = canDeleteDocumentsInEmployeeFolder(realm.realmFeatures, policies, employeeId, folder?.id);

    const onClosePreviewDialog = () => {
        setDialogOpen(false);
    };

    const handleAddLink = async (values: DocumentLinkFormValues) => {
        try {
            const employeeDocumentCreationRequest: EmployeeDocumentLinkCreationMutation = {
                employeeId: employeeId,
                folderId: folder.id,
                ...values,
            };
            await createEmployeeDocumentsLinks([employeeDocumentCreationRequest]);
            await refetch();
            setAddLinkDialogOpen(false);
        } catch (e) {
            handleError(e);
        }
    };

    const handleEdit = async (values: DocumentLinkFormValues | EditDocumentFormValues) => {
        if (!documentToEdit?.id) {
            return;
        }
        try {
            await updateEmployeeDocument(documentToEdit.id, values);
            await refetch();
            setDocumentToEdit(undefined);
        } catch (e) {
            handleError(e);
        }
    };

    const handleCloseUploadDocumentDialog = () => {
        setIsUploadDocumentDialogOpen(false);
    };

    const handleEmployeeDocumentUpload = async (values: { files: TemporaryFile[]; tagIds: number[]; folderId: number; employeeId: number }) => {
        try {
            const mutation = {
                ...values,
            } satisfies EmployeeDocumentCreationMutation;
            await createEmployeeDocuments(mutation);
            await refetch();
            handleCloseUploadDocumentDialog();
        } catch (error) {
            handleError(error);
        }
    };

    const handleDownloadClick = (documentId: number) => {
        getEmployeeDocumentDownloadUrl(documentId, 'ATTACHMENT')
            .then(documentDownloadUrl => {
                // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
                setTimeout(() => {
                    window.open(documentDownloadUrl, '_blank');
                });
            })
            .catch(handleError);
    };
    return (
        <Stack component={Paper} direction='column' flex={1} p={2} gap={2}>
            <DocumentDetailHeader
                folder={folder}
                openFilePicker={() => setIsUploadDocumentDialogOpen(true)}
                onAddLinkClick={() => setAddLinkDialogOpen(true)}
                canManageDocument={canManageEmployeeFolder}
                filters={filters}
                onFiltersChange={setFilters}
            />
            <Stack flex='1'>
                <DocumentTable
                    canManageDocument={canManageEmployeeFolder}
                    canDeleteDocument={canDeleteEmployeeFolder}
                    folderDocuments={documents}
                    onDeleteClicked={documentId => {
                        deleteEmployeeDocument(documentId)
                            .then(() => {
                                refetch();
                            })
                            .catch(handleError);
                    }}
                    onPreviewClicked={(documentData, type: string) => {
                        getEmployeeDocumentDownloadUrl(documentData.id, 'INLINE')
                            .then(documentDownloadUrl => {
                                const previewUrl =
                                    type === 'OFFICE' ? `${config.OFFICE_PREVIEW_URL}${encodeURIComponent(documentDownloadUrl)}` : `${documentDownloadUrl}`;
                                setDialogOpen(true);
                                setPreviewData({
                                    document: documentData,
                                    url: previewUrl,
                                });
                            })
                            .catch(handleError);
                    }}
                    onDownloadClicked={doc => handleDownloadClick(doc.id)}
                    onEditClicked={setDocumentToEdit}
                    isLoading={isLoading}
                />
            </Stack>

            {dialogOpen && !!previewData && (
                <DocumentPreviewDialog onClose={onClosePreviewDialog} previewData={previewData} onDownloadClick={doc => handleDownloadClick(doc.id)} />
            )}

            {addLinkDialogOpen && <DocumentLinkDialog open={true} onClose={() => setAddLinkDialogOpen(false)} onSave={handleAddLink} />}
            {documentToEdit?.documentType === 'LINK' && (
                <DocumentLinkDialog open={true} onClose={() => setDocumentToEdit(undefined)} onSave={handleEdit} document={documentToEdit} />
            )}

            {documentToEdit?.documentType === 'DOCUMENT' && (
                <DocumentNameDialog open={true} onClose={() => setDocumentToEdit(undefined)} onSave={handleEdit} document={documentToEdit} />
            )}
            {isUploadDocumentDialogOpen && !!folder?.id && (
                <DocumentUploadDialog
                    open={true}
                    onClose={handleCloseUploadDocumentDialog}
                    onSave={formValues => handleEmployeeDocumentUpload({ ...formValues, folderId: folder.id, employeeId })}
                />
            )}
        </Stack>
    );
};
