import { Button, DialogActions, DialogContent, FormControlLabel, FormHelperText, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';

import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { Survey, SurveyUpdateMutation } from '@/domain/survey/Survey.model';
import { updateSurvey } from '@/domain/survey/Survey.service';
import { useGetSurvey, useGetSurveyEmployeesIds } from '@/hooks/survey/Survey.hook';
import { EditSurveySetupFormSchemaType, getEditSurveySetupFormSchema } from '@/page/survey/edit-survey-setup-dialog/EditSurveySetupFormDialog.schema';
import { addMinutes, formatToLocalDate, getTimeFormatFromDate, getTodayDate, isFutureDate, setTime } from '@/utils/datetime.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldTime } from '@/components/form/field-time/FieldTime';
import { Employee } from '@/domain/employee/Employee.model';
import { AddTeamIcon } from 'hugeicons-react';
import { StackedAvatars } from '@/domain-ui/employee/stacked-avatar/StackedAvatars';
import { EmployeesSelectionDialog } from '@/domain-ui/employee/employee-selection-dialog/EmployeesSelectionDialog';
import { filterEmployeesByIds } from '@/domain/employee/Employee.service';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';

type EditSurveySetupFormDialogProps = {
    surveyId: number;
    onClose: () => void;
    onSave: () => void;
};

export const EditSurveySetupFormDialog: FC<EditSurveySetupFormDialogProps> = ({ surveyId, onClose, onSave }) => {
    const { t } = useTranslation();
    const { data: survey, isLoading: isSurveyLoading, isError: isSurveyError, error: surveyError } = useGetSurvey(surveyId);
    const {
        data: surveyEmployeesIds,
        isLoading: isSurveyEmployeesLoading,
        isError: isSurveyEmployeesError,
        error: surveyEmployeesError,
    } = useGetSurveyEmployeesIds(surveyId);
    const { data: employees, isLoading: isEmployeesLoading, isError: isEmployeesError, error: employeesError } = useGetEmployees();

    return (
        <DialogWrapper onClose={onClose} open={true} maxWidth={'sm'} header={t('edit_survey_form_dialog.title')}>
            <DialogContent>
                <StateHandler
                    isLoading={isSurveyLoading || isSurveyEmployeesLoading || isEmployeesLoading}
                    isError={isSurveyError || isSurveyEmployeesError || isEmployeesError}
                    error={surveyError ?? surveyEmployeesError ?? employeesError}
                >
                    {survey && (
                        <EditSurveySetupForm
                            survey={survey}
                            surveyRecipientsEmployeeIds={surveyEmployeesIds ?? []}
                            employees={employees ?? []}
                            onSave={onSave}
                        />
                    )}
                </StateHandler>
            </DialogContent>
            <DialogActions>
                <Button type={'submit'} form={'editSurveyForm'} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

type EditSurveySetupFormType = {
    survey: Survey;
    employees: Employee[];
    surveyRecipientsEmployeeIds: number[];
    onSave: () => void;
};

const EditSurveySetupForm: FC<EditSurveySetupFormType> = ({ survey, employees, surveyRecipientsEmployeeIds, onSave }) => {
    const { t } = useTranslation();
    const [appliesDialogOpen, setAppliesDialogOpen] = useState(false);

    const isSurveyStartDateEditable = !!survey.startDate && isFutureDate(survey.startDate);

    const getDefaultValue = (survey: Survey, employeesIds: number[]): Partial<EditSurveySetupFormSchemaType> => {
        return {
            sendNotificationNow: false,
            startDate: formatToLocalDate(survey.startDate),
            endDate: formatToLocalDate(survey.endDate),
            startTime: getTimeFormatFromDate(survey.startDate),
            endTime: getTimeFormatFromDate(survey.endDate),
            recipientIds: employeesIds,
        };
    };

    const {
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        control,
    } = useForm<EditSurveySetupFormSchemaType>({
        resolver: yupResolver(getEditSurveySetupFormSchema(isSurveyStartDateEditable)),
        defaultValues: getDefaultValue(survey, surveyRecipientsEmployeeIds),
    });

    const handleOnSave = async (survey: Survey, data: EditSurveySetupFormSchemaType) => {
        if (!survey.id) {
            return;
        }

        const startDateTime = !data.sendNotificationNow && data.startDate && data.startTime ? setTime(data.startDate, data.startTime) : undefined;
        const endDateTime = data.endDate && data.endTime ? setTime(data.endDate, data.endTime) : undefined;
        const realStartDateTime = data.sendNotificationNow ? addMinutes(getTodayDate(), 1) : startDateTime; // add 1 minutes to the current time to be sure it is after now

        const mutation: SurveyUpdateMutation = {
            startDate: isSurveyStartDateEditable ? realStartDateTime : undefined,
            endDate: endDateTime,
            recipientIds: data.recipientIds,
        };
        try {
            await updateSurvey(survey.id, mutation);
            onSave();
            showSnackbar(t('edit_survey_form_dialog.survey_edit_success_toast'), 'success');
        } catch {
            showSnackbar(t('edit_survey_form_dialog.survey_edit_error_toast'), 'error');
        }
    };

    const sendNotificationNow = watch('sendNotificationNow');

    return (
        <Stack
            gap={2}
            component={'form'}
            id={'editSurveyForm'}
            onSubmit={handleSubmit(
                data => handleOnSave(survey, data),
                err => console.error(err),
            )}
        >
            <Stack gap={1}>
                <Typography variant='body1bold'>{t('survey_templates_page.send_survey_to')}</Typography>
                <Stack gap={1} alignItems={'flex-start'} direction={'row'}>
                    <Stack>
                        <Button
                            variant='contained'
                            endIcon={<AddTeamIcon />}
                            color='primary'
                            onClick={() => setAppliesDialogOpen(true)}
                            sx={{ width: 'fit-content' }}
                        >
                            {t('permissions_setting_page.select_members')}
                        </Button>
                        <FormHelperText error={!!errors?.recipientIds?.message} sx={{ pl: 2, pt: 0.25 }}>
                            {errors?.recipientIds?.message}
                        </FormHelperText>
                    </Stack>

                    <StackedAvatars employeeAvatars={filterEmployeesByIds(watch('recipientIds'), employees ?? [])} />
                </Stack>
            </Stack>
            {isSurveyStartDateEditable && (
                <Stack>
                    <FormControlLabel
                        label={t('survey_templates_page.cycles.send_now')}
                        labelPlacement='end'
                        control={
                            <FieldSwitch
                                name='sendNotificationNow'
                                control={control}
                                onChange={() => {
                                    // if we don't reset the values, the validation will fail if startTime or endTime is not a valid date
                                    setValue('startTime', undefined);
                                    setValue('startDate', undefined);
                                }}
                            />
                        }
                    />
                    {!sendNotificationNow && (
                        <Stack direction={'row'} gap={2} alignItems={'flex-start'}>
                            <FormControlLabel
                                sx={{ width: '320px' }}
                                label={t('survey_templates_page.cycles.send_on')}
                                control={<FieldLocalDate name='startDate' control={control} />}
                            />

                            <FormControlLabel label={t('general.time')} control={<FieldTime name={'startTime'} control={control} />} />
                        </Stack>
                    )}
                </Stack>
            )}
            <Stack direction={'row'} gap={2} alignItems={'flex-start'}>
                <FormControlLabel
                    sx={{ width: '320px' }}
                    label={t('survey_templates_page.cycles.end_on')}
                    control={<FieldLocalDate name='endDate' control={control} />}
                />

                <FormControlLabel label={t('general.time')} control={<FieldTime name={'endTime'} control={control} />} />
            </Stack>

            {appliesDialogOpen && (
                <Controller
                    name='recipientIds'
                    control={control}
                    render={({ field }) => (
                        <EmployeesSelectionDialog
                            assignedEmployees={filterEmployeesByIds(field.value, employees ?? [])}
                            allEmployees={employees ?? []}
                            open={appliesDialogOpen}
                            onClose={() => setAppliesDialogOpen(false)}
                            onSave={(assigned: Employee[]) => {
                                const assignedIds = assigned.map((employee: Employee) => employee.id);
                                field.onChange(assignedIds);
                                setAppliesDialogOpen(false);
                            }}
                        />
                    )}
                />
            )}
        </Stack>
    );
};
