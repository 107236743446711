import { Controller, ControllerProps, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import { JSX } from 'react';

type TextRootProps = Pick<TextFieldProps, 'variant' | 'autoFocus' | 'fullWidth' | 'placeholder' | 'disabled'>;

export type FieldTextProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    textFieldProps?: RemoveFromType<RemoveFromType<TextFieldProps, TextRootProps>, ControllerRenderProps>;
} & TextRootProps &
    Omit<ControllerProps<TFieldValues, TName>, 'render'>;

export const FieldText = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    props: FieldTextProps<TFieldValues, TName>,
): JSX.Element => {
    const { variant, textFieldProps, autoFocus, fullWidth, placeholder, disabled, ...controllerProps } = props;
    const { slotProps, ...restTextFieldProps } = textFieldProps ?? {};

    return (
        <Controller
            {...controllerProps}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    id={controllerProps.name}
                    variant={variant}
                    autoFocus={autoFocus}
                    fullWidth={fullWidth}
                    placeholder={placeholder}
                    disabled={disabled}
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                    slotProps={{
                        htmlInput: {
                            'aria-label': controllerProps.name,
                        },
                        ...slotProps,
                    }}
                    {...restTextFieldProps}
                />
            )}
        />
    );
};
