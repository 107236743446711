import { API_BASE_URL, client } from '@/api/common';
import { EmployeeSkill, EmployeeSkillSearchRequest } from '@/domain/employee-skill/EmployeeSkill.model';
import { EmployeeAvatarDTO } from '@/api/employee/Employee.api';
import { SkillDTO } from '@/api/skill/Skill.api';
import { convertUTCIsoStringToDate } from '@/utils/datetime.util';
import { AxiosResponse } from 'axios';

export type EmployeeSkillDTO = StrictOmit<EmployeeSkill, 'employee' | 'updatedAt' | 'skill'> & {
    employee: EmployeeAvatarDTO;
    skill: SkillDTO;
    updatedAt?: string;
};

export type EmployeeSkillSearchRequestDTO = EmployeeSkillSearchRequest;

export const searchEmployeeSkills = async (searchRequest: EmployeeSkillSearchRequest): Promise<EmployeeSkill[]> => {
    const url = API_BASE_URL + '/employee-skill/search';
    const { data } = await client.post<EmployeeSkillDTO[], AxiosResponse<EmployeeSkillDTO[]>, EmployeeSkillSearchRequestDTO>(url, searchRequest);
    return mapEmployeeSkills(data);
};
const getEmployeeSkills = async (employeeId: number): Promise<EmployeeSkill[]> => {
    const url = API_BASE_URL + `/employee-skill/${employeeId}`;
    return mapEmployeeSkills((await client.get<EmployeeSkillDTO[]>(url)).data);
};

export const employeeSkillAPI = {
    getEmployeeSkills,
    searchEmployeeSkills,
};

const mapEmployeeSkills = (employeeSkills: EmployeeSkillDTO[]): EmployeeSkill[] => {
    return employeeSkills.map(employeeSkill => ({
        ...employeeSkill,
        updatedAt: employeeSkill.updatedAt ? convertUTCIsoStringToDate(employeeSkill.updatedAt) : undefined,
    }));
};
