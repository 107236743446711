import { mapRatingScale } from '@/page/review/employee-review-feedback-form/FeedbackForm.util';
import {
    FeedbackFormType,
    FeedbackSummarySkillFormType,
    ObjectiveFeedbackFormType,
    QuestionFeedbackFormType,
    SkillQuestionFeedbackFormType,
    SummaryFeedbackItemFormType,
    SummaryFeedbackSectionFormType,
    SummaryFeedbackSkillsFormType,
} from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';
import { EmployeeReviewManagerSummary, EmployeeReviewSummaryItem } from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import { getLabelTranslation } from '@/utils/language.util';
import {
    isReviewTemplateItemObjectives,
    isReviewTemplateItemObjectivesAction,
    isReviewTemplateItemPrivateEmployeeQuestion,
    isReviewTemplateItemPrivateManagerQuestion,
    isReviewTemplateItemQuestion,
    isReviewTemplateItemSection,
    isReviewTemplateItemSkill,
    isReviewTemplateItemSkills,
} from '@/domain/review-template/ReviewTemplate.service';
import { ReviewFeedback, ReviewItem } from '@/domain/review/Review.model';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { FeedbackObjectivesActionFormType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';

const mapReviewFeedback = (reviewFeedback: ReviewFeedback): Exclude<FeedbackFormType, null | undefined> => {
    return {
        score: reviewFeedback.score,
        comment: reviewFeedback.comment,
        reviewer: {
            firstName: reviewFeedback.reviewer?.firstName ?? '',
            lastName: reviewFeedback.reviewer?.lastName ?? '',
            displayName: reviewFeedback.reviewer?.displayName ?? '',
            avatarImageUrl: reviewFeedback.reviewer?.avatarImageUrl ?? '',
            id: reviewFeedback.reviewer?.id ?? 0,
            email: reviewFeedback.reviewer?.email ?? '',
        },
    };
};

export const mapSummaryFeedbackItem = (reviewItem: ReviewItem, managerSummary: EmployeeReviewManagerSummary): SummaryFeedbackItemFormType | undefined => {
    switch (reviewItem.type) {
        case 'SECTION':
            return mapSummaryFeedbackSectionForm(reviewItem);
        case 'SKILL':
            return mapSummaryFeedbackSkillQuestionForm(reviewItem, managerSummary);
        case 'REVIEW_OBJECTIVES':
            return mapSummaryFeedbackObjectiveForm(reviewItem);
        case 'OBJECTIVE_ACTIONS':
            return mapSummaryObjectivesActionForm(reviewItem);
        case 'SKILLS':
            return mapSummaryFeedbackSkillsForm(reviewItem, managerSummary);
        case 'QUESTION':
        case 'PRIVATE_EMPLOYEE_QUESTION':
        case 'PRIVATE_MANAGER_QUESTION':
            return mapSummaryFeedbackQuestionForm(reviewItem, managerSummary);
        default:
            throw new Error(`Unsupported item type: ${reviewItem.type}`);
    }
};

const mapSummaryFeedbackSectionForm = (item: ReviewItem): SummaryFeedbackSectionFormType => {
    if (!isReviewTemplateItemSection(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        id: item.id,
        instruction: item?.instruction ?? createDefaultLabel(),
        order: item.order,
        title: item?.title ?? createDefaultLabel(),
        type: 'SECTION',
    };
};

const getSummaryItem = (itemId: number, managerSummary: EmployeeReviewManagerSummary): EmployeeReviewSummaryItem | undefined => {
    return managerSummary.items.find(item => item.reviewItemId === itemId);
};

const mapSummaryFeedbackSkillQuestionForm = (item: ReviewItem, managerSummary: EmployeeReviewManagerSummary): SkillQuestionFeedbackFormType | undefined => {
    if (!isReviewTemplateItemSkill(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    const summaryItem = getSummaryItem(item.id, managerSummary);

    if (!summaryItem) {
        return;
    }

    return {
        required: item.required,
        id: item.id,
        description: getLabelTranslation(item?.skill?.description),
        order: item.order,
        rating: item.rating ? mapRatingScale(item.rating) : undefined,
        selfFeedbackQuestion: summaryItem.feedbackSkillQuestionSummary?.self ? mapReviewFeedback(summaryItem.feedbackSkillQuestionSummary?.self) : undefined,
        managerFeedbackQuestion: (summaryItem.feedbackSkillQuestionSummary?.managers ?? []).map(mapReviewFeedback),
        upwardFeedbackQuestion: (summaryItem.feedbackSkillQuestionSummary?.upwards ?? []).map(mapReviewFeedback),
        peerFeedbackQuestion: (summaryItem.feedbackSkillQuestionSummary?.peers ?? []).map(mapReviewFeedback),
        score: summaryItem.feedbackSkillQuestionSummary?.summaryScore,
        comment: summaryItem.feedbackSkillQuestionSummary?.summaryComment ?? '',
        type: 'SKILL',
        title: getLabelTranslation(item?.skill?.name),
    };
};

const mapSummaryFeedbackObjectiveForm = (item: ReviewItem): ObjectiveFeedbackFormType => {
    if (!isReviewTemplateItemObjectives(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        id: item.id,
        type: 'REVIEW_OBJECTIVES',
        order: item.order,
        instruction: item?.instruction ?? createDefaultLabel(),
    };
};

const mapSummaryObjectivesActionForm = (item: ReviewItem): FeedbackObjectivesActionFormType => {
    if (!isReviewTemplateItemObjectivesAction(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        id: item.id,
        type: 'OBJECTIVE_ACTIONS',
        order: item.order,
        instruction: item?.instruction ?? createDefaultLabel(),
    };
};

const mapSummaryFeedbackSkillsForm = (item: ReviewItem, managerSummary: EmployeeReviewManagerSummary): SummaryFeedbackSkillsFormType | undefined => {
    if (!isReviewTemplateItemSkills(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    const summaryItem = getSummaryItem(item.id, managerSummary);

    if (!summaryItem) {
        return undefined;
    }

    return {
        id: item.id,
        type: 'SKILLS',
        rating: item.rating ? mapRatingScale(item.rating) : undefined,
        minSkills: item?.minSkills ?? undefined,
        maxSkills: item?.maxSkills ?? undefined,
        allSkillsRequired: item?.allSkillsRequired ?? false,
        instruction: item?.instruction ?? createDefaultLabel(),
        order: item.order,
        skills: (summaryItem.feedbackSkillsSummary?.skills ?? [])
            .sort((a, b) => a.skill.category.order - b.skill.category.order)
            .map(
                skill =>
                    ({
                        score: skill.summaryScore ?? undefined,
                        comment: skill.summaryComment ?? '',
                        skill: {
                            id: skill.skill.id,
                            name: getLabelTranslation(skill.skill.name),
                            description: getLabelTranslation(skill.skill.description),
                            levels: skill.skill.category.levels.map(level => ({
                                id: level.id,
                                description: getLabelTranslation(level.description) ?? '',
                                score: level.score,
                                commentRequired: level.commentRequired,
                                name: getLabelTranslation(level.name),
                            })),
                        },
                        selfFeedbackQuestion: skill.self ? mapReviewFeedback(skill.self) : undefined,
                        managerFeedbackQuestion: skill.managers?.map(mapReviewFeedback) ?? [],
                        upwardFeedbackQuestion: skill.upwards?.map(mapReviewFeedback) ?? [],
                        peerFeedbackQuestion: skill.peers?.map(mapReviewFeedback) ?? [],
                        category: {
                            id: skill.skill.category.id,
                            name: getLabelTranslation(skill.skill.category.name),
                        },
                    }) satisfies FeedbackSummarySkillFormType,
            ),
    };
};
const hasItemPreparation = (item: ReviewItem): boolean => {
    return !!item.contributorTypes.length;
};

const mapSummaryFeedbackQuestionForm = (item: ReviewItem, managerSummary: EmployeeReviewManagerSummary): QuestionFeedbackFormType | undefined => {
    if (
        !isReviewTemplateItemQuestion(item.type) &&
        !isReviewTemplateItemPrivateEmployeeQuestion(item.type) &&
        !isReviewTemplateItemPrivateManagerQuestion(item.type)
    ) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    const summaryItem = getSummaryItem(item.id, managerSummary);

    /* The response to the question is mandatory in the manager-summary if:
    - The 'required' checkbox is checked, and
    - One of the following conditions is true:
      1. The question has no preparation,
      2. The 'manager' checkbox is checked in the template,
      3. The question is a private manager or employee question.
    This ensures that questions intended only for the employee are not mandatory for the manager, reducing unnecessary input for managers.
    It's also required if it's a private question or if there is no preparation.
    */
    const isRequired =
        item.required &&
        (!hasItemPreparation(item) ||
            item.contributorTypes.includes('MANAGER') ||
            item.type === 'PRIVATE_MANAGER_QUESTION' ||
            item.type === 'PRIVATE_EMPLOYEE_QUESTION');

    if (!summaryItem) {
        return;
    }

    return {
        rating: item?.rating ? mapRatingScale(item.rating) : undefined,
        /* The response to the question is mandatory in the manager-summary only if both the 'required' checkbox and the 'manager' checkbox are checked in the template.
           This ensures that questions intended only for the employee are not mandatory for the manager, reducing unnecessary input for managers. */
        required: isRequired,
        order: item.order,
        id: item.id,
        // todo : complete the description, the backend should return this value
        description: '',
        score: summaryItem.feedbackQuestionSummary?.summaryScore ?? undefined,
        comment: summaryItem.feedbackQuestionSummary?.summaryComment ?? '',
        title: item?.title ?? createDefaultLabel(),
        instruction: item?.instruction ?? createDefaultLabel(),
        selfFeedbackQuestion: summaryItem.feedbackQuestionSummary?.self ? mapReviewFeedback(summaryItem.feedbackQuestionSummary?.self) : undefined,
        managerFeedbackQuestion: (summaryItem.feedbackQuestionSummary?.managers ?? []).map(mapReviewFeedback),
        upwardFeedbackQuestion: (summaryItem.feedbackQuestionSummary?.upwards ?? []).map(mapReviewFeedback),
        peerFeedbackQuestion: (summaryItem.feedbackQuestionSummary?.peers ?? []).map(mapReviewFeedback),
        type: item.type,
    };
};

export const hasManagerFeedbackOnly = (
    managerFeedbackQuestion: FeedbackFormType[],
    selfFeedbackQuestion: FeedbackFormType,
    upwardFeedbackQuestion: FeedbackFormType[],
    peerFeedbackQuestion: FeedbackFormType[],
): boolean => {
    return managerFeedbackQuestion.length > 0 && !!selfFeedbackQuestion && upwardFeedbackQuestion.length === 0 && peerFeedbackQuestion.length === 0;
};
