import { createRequiredParamsQueryHook } from '@/page/Query.type';
import {
    getEmployeeReviewActions,
    getEmployeeReviewActionSummary,
    getEmployeeReviewManagerActionSummary,
    getEmployeeReviewSelfActionSummary,
} from '@/domain/employee-review-action/EmployeeReviewAction.service';

export const useGetEmployeeReviewActions = createRequiredParamsQueryHook('actions', getEmployeeReviewActions);

export const useGetEmployeeReviewActionSummary = createRequiredParamsQueryHook('actionSummary', getEmployeeReviewActionSummary);
export const useGetEmployeeReviewActionSelfSummary = createRequiredParamsQueryHook('actionSelfSummary', getEmployeeReviewSelfActionSummary);
export const useGetEmployeeReviewAction = createRequiredParamsQueryHook('actionManagerSummary', getEmployeeReviewManagerActionSummary);
