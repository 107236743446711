import { Department, DepartmentNodeOrganization } from '@/domain/department/Department.model';
import { DepartmentTreeItemLabel, ItemLabelProps } from '@/page/setting/organization/department/department-tree/DepartmentTreeItemLabel';
import { alpha } from '@mui/material';
import { TreeItem2Content, TreeItem2GroupTransition, TreeItem2IconContainer, TreeItem2Root } from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { unstable_useTreeItem2 as useTreeItem2, UseTreeItem2Parameters } from '@mui/x-tree-view/useTreeItem2';
import { forwardRef, HTMLAttributes, useState } from 'react';
import { TreeViewItem } from '@/components/tree-view/TreeView';
import { TreeItem2DragAndDropOverlay } from '@mui/x-tree-view';

export type CustomTreeViewBaseItem = TreeViewItem & { originalData: DepartmentNodeOrganization; parent: Department | undefined };

export type CustomTreeItemProps = Omit<UseTreeItem2Parameters, 'rootRef'> &
    Omit<HTMLAttributes<HTMLLIElement>, 'onFocus'> & {
        onAddClick?: ItemLabelProps['onAddClick'];
        onEditClick?: ItemLabelProps['onEditClick'];
        onDeleteClick?: ItemLabelProps['onDeleteClick'];
        onArchiveClick?: ItemLabelProps['onArchiveClick'];
        onUnarchiveClick?: ItemLabelProps['onUnarchiveClick'];
    };

export const DepartmentTreeItem = forwardRef<HTMLLIElement, CustomTreeItemProps>((props, ref) => {
    const {
        id,
        itemId,
        label,
        disabled,
        children,
        onAddClick = () => {},
        onEditClick = () => {},
        onDeleteClick = () => {},
        onArchiveClick = () => {},
        onUnarchiveClick = () => {},
        ...other
    } = props;

    // we need the state to display or hide the extra data on hover
    // we can do it in css (see history) but there is an issue when clicking on surplus on StackedAvatar
    const [displayExtraData, setDisplayExtraData] = useState(false);

    const useTreeItem2Value = useTreeItem2({
        id,
        itemId,
        children,
        label,
        disabled,
        rootRef: ref,
    });
    const { getRootProps, getContentProps, getIconContainerProps, getLabelProps, getDragAndDropOverlayProps, getGroupTransitionProps, status, publicAPI } =
        useTreeItem2Value;

    // get the department data to use it for adding, updating ...
    // The RichTreeView component use its own data structure, so we need to get the original data
    // from publicAPI returned by the useTreeItem2 hook
    const { originalData: departmentNode, parent } = publicAPI?.getItem(itemId) as CustomTreeViewBaseItem;

    return (
        <TreeItem2Provider itemId={itemId}>
            <TreeItem2Root {...getRootProps(other)}>
                <TreeItem2Content
                    {...getContentProps()}
                    onMouseEnter={() => setDisplayExtraData(true)}
                    onMouseLeave={() => setDisplayExtraData(false)}
                    sx={{
                        minHeight: '44px',
                        gap: 0.5,
                    }}
                >
                    <TreeItem2IconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </TreeItem2IconContainer>
                    <DepartmentTreeItemLabel
                        {...getLabelProps()}
                        item={departmentNode}
                        parent={parent}
                        label={label}
                        displayExtraData={displayExtraData}
                        onAddClick={onAddClick}
                        onEditClick={onEditClick}
                        onDeleteClick={onDeleteClick}
                        onArchiveClick={onArchiveClick}
                        onUnarchiveClick={onUnarchiveClick}
                    />
                    <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
                </TreeItem2Content>
                {children && (
                    <TreeItem2GroupTransition
                        {...getGroupTransitionProps()}
                        sx={theme => ({ marginLeft: '15px', borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}` })}
                    />
                )}
            </TreeItem2Root>
        </TreeItem2Provider>
    );
});

DepartmentTreeItem.displayName = 'DepartmentTreeItem';
