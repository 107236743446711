import { FeedbackSkillFormType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import { SkillReviewDialog } from '@/page/review/employee-review-skill-review-dialog/SkillReviewDialog';
import { FC, useState } from 'react';
import { DisabledSkillButton, NotReviewedSkillButton, ReviewedSkillButton, SummaryMeetingSkillButton } from '@/domain-ui/skill/SkillButton';

type FeedbackSkillFormProps = {
    skillState: SkillState;
    feedbackSkillItem: FeedbackSkillFormType;
    handleSkillChanged: (skillAndCategory: FeedbackSkillFormType) => void;
    disabled?: boolean;
};

export type SkillState = 'reviewed' | 'not_reviewed' | 'summary_meeting' | 'disabled';

export const FeedbackSkill: FC<FeedbackSkillFormProps> = ({ skillState, feedbackSkillItem, handleSkillChanged, disabled = false }) => {
    const [activeSkill, setActiveSkill] = useState<FeedbackSkillFormType>();

    const handleSkillClicked = (skillAndCategory: FeedbackSkillFormType) => {
        setActiveSkill(skillAndCategory);
    };

    const onClose = () => {
        setActiveSkill(undefined);
    };

    const renderSkillItem = (skillState: SkillState, feedbackSkillItem: FeedbackSkillFormType) => {
        switch (skillState) {
            case 'reviewed':
                return (
                    <ReviewedSkillButton
                        skillScore={feedbackSkillItem.score}
                        skillName={feedbackSkillItem.skill.name ?? ''}
                        handleSkillClicked={() => handleSkillClicked(feedbackSkillItem)}
                    />
                );
            case 'not_reviewed':
                return (
                    <NotReviewedSkillButton skillName={feedbackSkillItem.skill.name ?? ''} handleSkillClicked={() => handleSkillClicked(feedbackSkillItem)} />
                );
            case 'summary_meeting':
                return (
                    <SummaryMeetingSkillButton
                        skillName={feedbackSkillItem.skill.name ?? ''}
                        handleSkillClicked={() => handleSkillClicked(feedbackSkillItem)}
                    />
                );
            case 'disabled':
                return <DisabledSkillButton skillName={feedbackSkillItem.skill.name ?? ''} handleSkillClicked={() => handleSkillClicked(feedbackSkillItem)} />;
        }
    };

    return (
        <>
            {renderSkillItem(skillState, feedbackSkillItem)}
            {activeSkill && <SkillReviewDialog skillAndCategory={activeSkill} onConfirm={handleSkillChanged} onClose={onClose} disabled={disabled} />}
        </>
    );
};
