import { EmployeeReviewActionStatus } from '@/domain/employee-review-action/EmployeeReviewAction.model';
import { EmployeeReviewStatus } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { useTranslation } from 'react-i18next';

type GetMenuItemsParams = {
    onEdit: () => void;
    onDelete: () => void;
    onArchived: () => void;
    onUnarchived: () => void;
    actionStatus: EmployeeReviewActionStatus;
    employeeReviewStatus: EmployeeReviewStatus;
};

export const useFeedbackObjectiveActionsMenuItems = ({
    onEdit,
    onDelete,
    onArchived,
    onUnarchived,
    actionStatus,
    employeeReviewStatus,
}: GetMenuItemsParams): BasicMenuItem[] => {
    const { t } = useTranslation();

    if ([EmployeeReviewStatus.EMPLOYEE_APPROVED, EmployeeReviewStatus.CANCELLED, EmployeeReviewStatus.CLOSED].includes(employeeReviewStatus)) {
        return [];
    }

    const editAction = { title: t('general.edit'), onClick: onEdit };
    const deleteAction = { title: t('general.delete'), onClick: onDelete };
    const archivedAction = { title: t('general.archive'), onClick: onArchived };
    const unarchivedAction = { title: t('general.unarchive'), onClick: onUnarchived };

    if (actionStatus === 'DRAFT') {
        if (employeeReviewStatus === 'INPUT_NEEDED') {
            return [editAction, deleteAction];
        }
        if ([EmployeeReviewStatus.DISCUSSION_STARTED, EmployeeReviewStatus.DISCUSSION_SUBMITTED].includes(employeeReviewStatus)) {
            return [editAction, archivedAction];
        }
    }

    if (actionStatus === 'ARCHIVED' && [EmployeeReviewStatus.DISCUSSION_STARTED, EmployeeReviewStatus.DISCUSSION_SUBMITTED].includes(employeeReviewStatus)) {
        return [unarchivedAction, deleteAction];
    }

    return [];
};
