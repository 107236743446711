import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';
import { EmptyState } from '@/components/empty-state/EmptyState';
import { AnnouncementEmptyStateIcon } from '@/components/empty-state/icons/AnnouncementEmptyStateIcon';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { Announcement, EditAnnouncementMutation } from '@/domain/announcement/Announcement.model';
import { deleteAnnouncement } from '@/domain/announcement/Announcement.service';
import { canManageAnnouncements, canSeeAnnouncements } from '@/domain/permission/Permission.service';
import { useEditAnnouncement, useGetAnnouncements } from '@/hooks/annoucement/Announcement.hook';
import { AnnouncementDialog } from '@/page/announcement/AnnouncementDialog';
import { AnnouncementFormValues, mapAnnouncementFormValuesToEditMutation } from '@/page/announcement/AnnouncementForm.schema';
import { AnnouncementItem } from '@/page/home/announcement/AnnouncementItem';
import { useCurrentPolicies } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import Stack from '@mui/material/Stack/Stack';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Announcements: FC = () => {
    const [announcementToUpdate, setAnnouncementToUpdate] = useState<Announcement>();
    const [announcementToDelete, setAnnouncementToDelete] = useState<Announcement>();

    const { t } = useTranslation();
    const grantedPolicies = useCurrentPolicies();

    const canShowAnnouncements = canSeeAnnouncements(grantedPolicies);
    const canPostAnnouncement = canManageAnnouncements(grantedPolicies);
    const {
        data: announcements,
        isLoading: isAnnouncementsLoading,
        isError: isAnnouncementsError,
        error: announcementsError,
        refetch: refetchAnnouncements,
    } = useGetAnnouncements(undefined, { enabled: canShowAnnouncements });

    const { mutate: editAnnouncement, isPending: isEditPending } = useEditAnnouncement();

    // DELETE
    const handleDeleteClick = (announcement: Announcement) => {
        setAnnouncementToDelete(announcement);
    };
    const closeConfirmationDialog = () => {
        setAnnouncementToDelete(undefined);
    };
    const handleDeleteAnnouncement = async (announcementId: number) => {
        try {
            await deleteAnnouncement(announcementId);
            closeConfirmationDialog();
            refetchAnnouncements();
        } catch (error) {
            handleError(error);
        }
    };

    // EDIT
    const handleEditClick = (announcement: Announcement) => {
        setAnnouncementToUpdate(announcement);
    };
    const closeEditAnnouncementDialog = () => {
        setAnnouncementToUpdate(undefined);
    };
    const handleEditAnnouncement = async (announcement: Announcement, data: AnnouncementFormValues) => {
        const announcementId = announcement.id;
        try {
            const mutation: EditAnnouncementMutation = mapAnnouncementFormValuesToEditMutation(announcementId, data);
            await editAnnouncement(mutation);
            closeEditAnnouncementDialog();
            refetchAnnouncements();
        } catch (error) {
            handleError(error);
        }
    };

    const emptyStateComponent = <EmptyState icon={<AnnouncementEmptyStateIcon />} title={t('home_page.announcements.empty')} flex={1} />;

    if (!canShowAnnouncements) {
        return;
    }

    return (
        <Stack gap={2} flex={1}>
            <StateHandler
                isLoading={isAnnouncementsLoading}
                isError={isAnnouncementsError}
                isEmpty={!announcements?.length}
                emptyStateComponent={emptyStateComponent}
                error={announcementsError}
            >
                {announcements?.map(announcement => (
                    <AnnouncementItem
                        key={announcement.id}
                        announcement={announcement}
                        displayMenu={canPostAnnouncement}
                        onEdit={() => {
                            handleEditClick(announcement);
                        }}
                        onDelete={() => {
                            handleDeleteClick(announcement);
                        }}
                    />
                ))}
            </StateHandler>
            {!!announcementToUpdate && (
                <AnnouncementDialog
                    open={true}
                    defaultValue={announcementToUpdate}
                    onClose={() => setAnnouncementToUpdate(undefined)}
                    onSubmit={data => handleEditAnnouncement(announcementToUpdate, data)}
                    isPending={isEditPending}
                />
            )}
            {!!announcementToDelete && (
                <ConfirmDialog
                    open={true}
                    title={t('home_page.announcements.delete_dialog.title')}
                    content={t('home_page.announcements.delete_dialog.confirmation_text', { announcementTitle: announcementToDelete.title })}
                    onConfirm={() => handleDeleteAnnouncement(announcementToDelete.id)}
                    onClose={() => closeConfirmationDialog()}
                />
            )}
        </Stack>
    );
};
