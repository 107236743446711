import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { FieldText } from '@/components/form/field-text/FieldText';
import { TimesheetMissingModeArray, TimesheetMode } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { TimesheetSettingForm } from '@/page/setting/time-management/time-management-setting-form/TimeManagementSettingForm';
import { MONTHS } from '@/utils/datetime.util';
import { Collapse, FormControlLabel, formHelperTextClasses, Paper, Radio, Stack, Switch, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRealmFeatureEnabled } from '@/hooks/realm/useRealmFeatureEnabled';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { FieldRadioGroup } from '@/components/form/field-radio-group/FieldRadioGroup';

export const TimeManagementBasics: FC = () => {
    const { t } = useTranslation();
    const { control, watch, setValue } = useFormContext<TimesheetSettingForm>();

    const [isCustomLifeCycle, setIsCustomLifeCycle] = useState(watch('cycleStartMonth') !== MONTHS.JANUARY);

    const canDisplayPlanningSettings = useRealmFeatureEnabled(RealmFeaturesType.PLANNING);
    const isAutoApproveDifferenceThresholdInMinutesEnabled = watch('isAutoApproveDifferenceThresholdInMinutesEnabled');
    const isMissingTimesheetThresholdInMinutesEnabled = watch('isMissingTimesheetThresholdInMinutesEnabled');
    const isAutoFill = watch('autofillTimesheet');

    return (
        <Stack component={Paper} p={3} gap={2}>
            <Typography variant={'h2'}>{t('time_management_settings_page.time_management_configuration.time_management_basics')}</Typography>

            <Stack gap={2}>
                <Stack width={'430px'}>
                    <FormControlLabel
                        label={t('time_management_settings_page.time_management_configuration.name')}
                        control={<FieldText fullWidth name='name' control={control} />}
                        sx={{ width: '100%' }}
                    />
                </Stack>

                <FormControlLabel
                    name={'timesheetMode'}
                    label={t('timesheets.timesheet_mode.timesheet_mode')}
                    control={
                        <FieldRadioGroup name={'timesheetMode'}>
                            <>
                                <FormControlLabel
                                    value={TimesheetMode.NORMAL}
                                    control={<Radio />}
                                    label={t('timesheets.timesheet_mode.mode_normal')}
                                    labelPlacement={'end'}
                                />
                                <FormControlLabel
                                    value={TimesheetMode.SIMPLIFIED}
                                    control={<Radio />}
                                    label={t('timesheets.timesheet_mode.mode_simplified')}
                                    labelPlacement={'end'}
                                />
                            </>
                        </FieldRadioGroup>
                    }
                />

                <FormControlLabel
                    label={t('time_management_settings_page.time_management_configuration.auto_fill_timesheet')}
                    labelPlacement='end'
                    control={<FieldSwitch name='autofillTimesheet' control={control} />}
                    sx={{ mr: 0 }}
                />

                {!isAutoFill && (
                    <FormControlLabel
                        name={'timesheetMissingMode'}
                        label={t('time_management_settings_page.time_management_configuration.timesheet_missing_mode')}
                        control={
                            <FieldRadioGroup name={'timesheetMissingMode'}>
                                <>
                                    {Object.values(TimesheetMissingModeArray).map(mode => (
                                        <FormControlLabel
                                            key={mode}
                                            value={mode}
                                            control={<Radio />}
                                            label={t('time_management_settings_page.time_management_configuration.enum', { context: mode })}
                                            labelPlacement={'end'}
                                        />
                                    ))}
                                </>
                            </FieldRadioGroup>
                        }
                    />
                )}

                <FieldSwitch
                    invert
                    name={'countDailyDifference'}
                    labelPlacement='end'
                    label={t('time_management_settings_page.time_management_configuration.count_daily_difference')}
                    control={control}
                />

                {canDisplayPlanningSettings && (
                    <FormControlLabel
                        label={t('time_management_settings_page.time_management_configuration.enable_copying_planning_shifts_to_timesheets')}
                        labelPlacement='end'
                        control={<FieldSwitch name='includeShiftsIntoTimesheets' control={control} />}
                        sx={{ mr: 0 }}
                    />
                )}

                <Stack direction={'column'}>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <FormControlLabel
                            label={t('time_management_settings_page.time_management_configuration.custom_life_cycle')}
                            labelPlacement='end'
                            control={
                                <Switch
                                    color='primary'
                                    checked={isCustomLifeCycle}
                                    onChange={value => {
                                        if (!value.target.checked) {
                                            setValue('cycleStartMonth', MONTHS.JANUARY, { shouldDirty: true });
                                        }
                                        setIsCustomLifeCycle(value.target.checked);
                                    }}
                                />
                            }
                            sx={{ mr: 0 }}
                        />
                    </Stack>
                    <Collapse in={isCustomLifeCycle}>
                        <Stack direction={'row'} gap={1} alignItems={'center'} mt={2}>
                            <Typography width={200} variant={'body1bold'}>
                                {t('time_management_settings_page.time_management_configuration.life_cycle_start_month')}
                            </Typography>
                            <Stack width={'200px'}>
                                <FieldSelect
                                    name='cycleStartMonth'
                                    control={control}
                                    disableClearable
                                    options={Object.values(MONTHS)}
                                    getOptionLabel={option => option}
                                    fullWidth
                                />
                            </Stack>
                        </Stack>
                    </Collapse>
                </Stack>

                <Stack direction={'row'} gap={1} alignItems={'center'}>
                    <FieldSwitch
                        name='isAutoApproveDifferenceThresholdInMinutesEnabled'
                        control={control}
                        label={t('time_management_settings_page.time_management_configuration.auto_approve_timesheets_within_minutes', {
                            context: isAutoApproveDifferenceThresholdInMinutesEnabled ? 'on' : 'off',
                        })}
                        labelPlacement='end'
                        sx={{ mr: 0 }}
                    />

                    {isAutoApproveDifferenceThresholdInMinutesEnabled && (
                        <>
                            <FieldNumber name={'autoApproveDifferenceThresholdInMinutes'} control={control} min={1} step={5} sx={fieldNumberSX} precision={2} />

                            <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.minutes')}</Typography>
                        </>
                    )}
                </Stack>

                <Stack direction={'row'} gap={1} alignItems={'center'}>
                    <FieldSwitch
                        name='isMissingTimesheetThresholdInMinutesEnabled'
                        control={control}
                        label={t('time_management_settings_page.time_management_configuration.display_missing_when_gap_bigger_than')}
                        labelPlacement='end'
                        sx={{ mr: 0 }}
                    />

                    {isMissingTimesheetThresholdInMinutesEnabled && (
                        <>
                            <FieldNumber name={'missingTimesheetThresholdInMinutes'} control={control} min={0} step={5} sx={fieldNumberSX} precision={2} />

                            <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.minutes')}</Typography>
                        </>
                    )}
                </Stack>

                <Stack direction='row'>
                    <FormControlLabel
                        label={t('time_management_settings_page.time_management_configuration.maximum_weekly_additional_working_time')}
                        labelPlacement='start'
                        control={
                            <FieldNumber
                                name={'maximumWeeklyAdditionalWorkingTime'}
                                control={control}
                                min={0}
                                max={100}
                                step={5}
                                defaultValue={0}
                                sx={fieldNumberSX}
                            />
                        }
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

const fieldNumberSX = {
    width: '8em',
    [`& .${formHelperTextClasses.root}`]: {
        whiteSpace: 'nowrap',
    },
};
