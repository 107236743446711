import { EmployeeAvatarDTO } from '@/api/employee/Employee.api';
import { OrderMutation } from '@/domain/common';
import {
    Department,
    DepartmentCreationMutation,
    DepartmentNode,
    DepartmentNodesSearchRequest,
    DepartmentSearchRequest,
    DepartmentUpdateMutation,
} from '@/domain/department/Department.model';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';
import { LabelRequest } from '@/domain/label/Label.model';
import { AxiosResponse } from 'axios';
import { API_BASE_URL, buildImportFormData, client } from '../common';

export type DepartmentDTO = Overwrite<
    Department,
    {
        managers: EmployeeAvatarDTO[];
    }
>;

type DepartmentSearchRequestDTO = DepartmentSearchRequest;

export type DepartmentNodeDTO = DepartmentNode;
type DepartmentCreationRequestDTO = DepartmentCreationMutation;
type DepartmentUpdateRequestDTO = DepartmentUpdateMutation;
type DepartmentNodesRequestDTO = DateToString<DepartmentNodesSearchRequest>;

const searchDepartments = async (request: DepartmentSearchRequest = {}): Promise<Department[]> => {
    return (await client.post<DepartmentDTO[], AxiosResponse<DepartmentDTO[]>, DepartmentSearchRequestDTO>(API_BASE_URL + '/departments/search', request)).data;
};

const searchDepartmentNodes = async (search: DepartmentNodesSearchRequest): Promise<DepartmentNode[]> => {
    const departmentNodes = (
        await client.post<DepartmentNodeDTO[], AxiosResponse<DepartmentNodeDTO[]>, DepartmentNodesRequestDTO>(
            API_BASE_URL + `/departments/nodes/search`,
            search,
        )
    ).data;

    return nullLabelTranslationKeyToEmptyString(departmentNodes);
};

const createDepartment = async (mutation: DepartmentCreationMutation): Promise<Department> => {
    return (await client.post<DepartmentDTO, AxiosResponse<DepartmentDTO>, DepartmentCreationRequestDTO>(API_BASE_URL + '/departments', mutation)).data;
};

const updateDepartment = async (departmentId: number, mutation: DepartmentUpdateMutation): Promise<Department> => {
    return (await client.put<DepartmentDTO, AxiosResponse<DepartmentDTO>, DepartmentUpdateRequestDTO>(API_BASE_URL + `/departments/${departmentId}`, mutation))
        .data;
};

const updateDepartmentOrders = async (mutations: OrderMutation[]): Promise<void> => {
    await client.put(API_BASE_URL + `/departments/order`, mutations);
};

const deleteDepartment = async (departmentId: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/departments/${departmentId}`);
};

const archiveDepartment = async (departmentId: number): Promise<void> => {
    await client.post(API_BASE_URL + `/departments/${departmentId}/archive`);
};

const unarchiveDepartment = async (departmentId: number): Promise<void> => {
    await client.post(API_BASE_URL + `/departments/${departmentId}/unarchive`);
};

const nullLabelTranslationKeyToEmptyString = (departmentNodes: DepartmentNode[] = []): DepartmentNode[] => {
    return departmentNodes.map(node => {
        const nameCopy = { ...node.name };
        Object.keys(nameCopy).forEach(key => {
            const labelKey = key as keyof LabelRequest;
            if (!nameCopy[labelKey]) {
                nameCopy[labelKey] = '';
            }
        });
        return {
            ...node,
            name: nameCopy,
            children: node.children.length ? nullLabelTranslationKeyToEmptyString(node.children) : [],
        };
    });
};

const importDepartments = async (request: ImportRequest): Promise<ImportResult> => {
    const formData = buildImportFormData(request);
    return (await client.postForm<ImportResult, AxiosResponse<ImportResult>, FormData>(API_BASE_URL + `/departments/import`, formData)).data;
};

export const departmentAPI = {
    searchDepartments,
    createDepartment,
    updateDepartment,
    updateDepartmentOrders,
    deleteDepartment,
    archiveDepartment,
    unarchiveDepartment,
    searchDepartmentNodes,
    importDepartments,
};
