import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { Document } from '@/domain/document/Document.model';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FieldText } from '@/components/form/field-text/FieldText';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { getLabelTranslation } from '@/utils/language.util';
import { useGetDocumentTags } from '@/hooks/document-tag/DocumentTag.hook';

export type DocumentLinkDialogProps = Omit<DialogWrapperProps, 'onSave'> & {
    document: Document;
    onSave: (values: EditDocumentFormValues) => void;
};

export const DocumentNameDialog: FC<DocumentLinkDialogProps> = ({ onSave, document, ...rest }) => {
    const { t } = useTranslation();
    const { data: documentTags = [] } = useGetDocumentTags();

    const { control, handleSubmit } = useForm<EditDocumentFormValues>({
        defaultValues: {
            name: document?.name,
            tagIds: document?.tags?.map(dt => dt.id) ?? [],
        },
        resolver: yupResolver(schema),
    });

    const handleSave = (values: EditDocumentFormValues) => {
        onSave(values);
    };

    return (
        <DialogWrapper header={t('folders.edit_document_dialog.title')} {...rest}>
            <Stack gap={1} component={DialogContent}>
                <FormControlLabel label={t('folders.document_form.name')} control={<FieldText fullWidth control={control} name={'name'} />} />

                <FormControlLabel
                    control={
                        <FieldSelect
                            multiple={true}
                            control={control}
                            options={documentTags.map(dt => dt.id)}
                            name={'tagIds'}
                            getOptionLabel={optionId => getLabelTranslation(documentTags.find(dt => dt.id === optionId)?.name)}
                            fullWidth={true}
                        />
                    }
                    label={t('documents.upload_document_dialog.tags_field')}
                />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(handleSave, console.error)}>{t('general.save')}</Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const schema = yup.object().shape({
    name: yup.string().trim().required(),
    tagIds: yup.array().of(yup.number().required()).default([]),
});

export type EditDocumentFormValues = yup.InferType<typeof schema>;
