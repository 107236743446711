import { Stack, StackProps, Typography } from '@mui/material';
import { FC } from 'react';
import { FeedbackSectionFormType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import { getLabelTranslation } from '@/utils/language.util';
import { RichTextTypography } from '@/components/rich-text-typography/RichTextTypography';

type FeedbackSectionProps = {
    sectionItem: FeedbackSectionFormType;
} & StackProps;

export const FeedbackSection: FC<FeedbackSectionProps> = ({ sectionItem }) => {
    return (
        <Stack px={1} gap={1}>
            <Typography variant='h1'>{getLabelTranslation(sectionItem.title) ?? ''}</Typography>
            {sectionItem.instruction && <RichTextTypography variant='body2'>{getLabelTranslation(sectionItem.instruction)}</RichTextTypography>}
        </Stack>
    );
};
