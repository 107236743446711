export type ThirdParty = {
    id: number;
    name: string;
    enabled: boolean;
    publicApiIntegration?: ThirdPartyPublicApiIntegration;
    integrations: ThirdPartyIntegration[];
};

export type ThirdPartyPublicApiIntegration = {
    id: number;
    clientId: string;
    lastSignInAt?: Date;
    expireAt: LocalDate;
    scopes: ThirdPartyPublicApiIntegrationScope[];
    description?: string;
};

export type ThirdPartyIntegrationLog = {
    id: number;
    thirdPartyIntegrationId: number;
    eventType: string;
    messageId: string;
    message: string;
    errorMessage: string;
    publisher: string;
    publishedAt: Date;
    processedAt: Date;
    createdAt: Date;
    updatedAt: Date;
    isSuccessful: boolean;
};

export type ThirdPartyIntegration = {
    id: number;
    configuration: string;
    integrationType: IntegrationType;
    description?: string;
};

export enum ThirdPartyPublicApiIntegrationScope {
    READ_EMPLOYEES = 'READ_EMPLOYEES',
    READ_LEAVE_TYPES = 'READ_LEAVE_TYPES',
    READ_LEAVES = 'READ_LEAVES',
    WRITE_LEAVES = 'WRITE_LEAVES',
    READ_TIMESHEET_ADJUSTMENTS = 'READ_TIMESHEET_ADJUSTMENTS',
    WRITE_TIMESHEET_ADJUSTMENTS = 'WRITE_TIMESHEET_ADJUSTMENTS',
    READ_EMPLOYMENTS = 'READ_EMPLOYMENTS',
    WRITE_EMPLOYMENTS = 'WRITE_EMPLOYMENTS',
    READ_ORGANIZATION = 'READ_ORGANIZATION',
    WRITE_REVIEWS = 'WRITE_REVIEWS',
    READ_REVIEW_TEMPLATES = 'READ_REVIEW_TEMPLATES',
    READ_TIMESHEET_SETTINGS = 'READ_TIMESHEET_SETTINGS',
    WRITE_EMPLOYEES = 'WRITE_EMPLOYEES',
    READ_TIMESHEETS = 'READ_TIMESHEETS',
    WRITE_TIMESHEETS = 'WRITE_TIMESHEETS',
    READ_LEAVE_BALANCES = 'READ_LEAVE_BALANCES',
    READ_CUSTOM_LISTS = 'READ_CUSTOM_LISTS',
    READ_SECTION_DEFINITIONS = 'READ_SECTION_DEFINITIONS',
    READ_EMPLOYEE_DOCUMENTS = 'READ_EMPLOYEE_DOCUMENTS',
    WRITE_EMPLOYEE_DOCUMENTS = 'WRITE_EMPLOYEE_DOCUMENTS',
    READ_COMPANY_DOCUMENTS = 'READ_COMPANY_DOCUMENTS',
    WRITE_COMPANY_DOCUMENTS = 'WRITE_COMPANY_DOCUMENTS',
    READ_DOCUMENT_TAGS = 'READ_DOCUMENT_TAGS',
    WRITE_DOCUMENT_TAGS = 'WRITE_DOCUMENT_TAGS',
}

export enum IntegrationType {
    OPALE = 'OPALE',
    TIMEMOTO = 'TIMEMOTO',
}

export type ThirdPartyMutation = {
    name: string;
    publicApiIntegration?: ThirdPartyPublicApiIntegrationRequest;
    integrations: ThirdPartyIntegrationRequest[];
};

export type ThirdPartyPublicApiIntegrationRequest = {
    expireAt: Date;
    scopes: ThirdPartyPublicApiIntegrationScope[];
};

export type ThirdPartyIntegrationRequest = {
    configuration: string;
    integrationType: IntegrationType;
};

export type ThirdPartyConnectorType = 'PUBLIC_API' | IntegrationType;
