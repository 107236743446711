import { FC, PropsWithChildren } from 'react';
import { FeedbackSkillFormType, RatingFormType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import { Text, View } from '@react-pdf/renderer';
import { FeedbackRatingPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackRatingPDF';
import { useTheme } from '@mui/material';
import { StarsIconsPDF } from '@/page/review/employee-review-feedback-form-pdf/StarsIconsPDF';
import { htmlToPlainText } from '@/utils/strings.util';

type FeedbackSkillPDFProps = {
    questionItem: FeedbackSkillFormType;
    rating: RatingFormType | undefined;
};

export const FeedbackSkillPDF: FC<PropsWithChildren<FeedbackSkillPDFProps>> = ({ questionItem, rating, children }) => {
    const theme = useTheme();

    return (
        <View
            style={{
                display: 'flex',
                backgroundColor: theme.palette.grey[50],
                padding: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
                gap: theme.spacing(1),
            }}
        >
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: theme.spacing(1),
                }}
            >
                <Text
                    style={{
                        fontSize: 14,
                        fontWeight: 'bold',
                    }}
                >
                    {questionItem.skill.name}
                </Text>

                {questionItem.skill.description && (
                    <Text
                        style={{
                            fontSize: 10,
                        }}
                    >
                        {htmlToPlainText(questionItem.skill.description)}
                    </Text>
                )}

                {children}

                <StarsIconsPDF score={questionItem.score} />

                <View
                    style={{
                        minHeight: 80,
                        border: '1px black solid',
                        borderColor: theme.palette.common.black,
                        borderRadius: theme.shape.borderRadius,
                        padding: theme.spacing(1),
                        width: '100%',
                    }}
                    wrap={false}
                >
                    <Text>{questionItem.comment}</Text>
                </View>
            </View>

            {/* When there is no score, display rating labels, useful to print the review and fill it */}
            {!questionItem.score && rating && <FeedbackRatingPDF rating={rating} />}
        </View>
    );
};
