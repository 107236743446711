import { FC, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/stores/store';
import { FormHelperText, Link, Paper, Stack, Typography } from '@mui/material';
import { LogoRoger } from '@/components/logo-roger/LogoRoger';
import { AlertCircleIcon } from 'hugeicons-react';
import { PasswordForm } from './PasswordForm';
import { getCurrentEmployee } from '@/domain/employee/Employee.service';
import { setUserLanguage } from '@/utils/language.util';
import { handleError } from '@/utils/api.util';
import { AuthenticationStatus } from '@/domain/authentication/Authentication.model';
import { employeeAuthenticated } from '@/stores/reducers/currentEmployeeSlice';
import { Realm } from '@/domain/realm/Realm.model';
import { confirmNewPassword, signIn } from '@/domain/authentication/Authentication.service';

type Props = {
    username: string | null;
    code: string | null;
    title: string;
    actionLabel: string;
};
export const ChangePasswordForm: FC<Props> = ({ username, code, title, actionLabel }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const realm = useAppSelector(state => state.ui.currentRealm);

    const [validLink, setValidLink] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        async function fetchData() {
            if (username && code && realm) {
                try {
                    const response = await signIn(username, code, realm.tenantId);
                    if (response !== AuthenticationStatus.NEW_PASSWORD_REQUIRED) {
                        // incorrect auth status, link is probably incorrect
                        setValidLink(false);
                    }
                } catch (error) {
                    // Sign-in exception
                    console.error(error);
                    setValidLink(false);
                }
            }
        }

        fetchData();
    }, [realm, username, code]);

    const handleSubmit = async (realm: Realm, username: string, confirmationCode: string, newPassword: string) => {
        if (!username || !confirmationCode || !realm) {
            return;
        }
        setLoading(true);
        try {
            const confirmPasswordResponse = await confirmNewPassword(realm.tenantId, username, confirmationCode, newPassword);

            if (confirmPasswordResponse === AuthenticationStatus.SUCCESS) {
                // at this time the user is logged in! \o/, if no MFA required
                const data = await getCurrentEmployee();
                if (data?.employee?.language) {
                    setUserLanguage(data?.employee?.language).catch(handleError);
                }
                dispatch(employeeAuthenticated(data));
                navigate('/');
            } else {
                console.error('Unsupported response ' + confirmPasswordResponse);
                setErrorMessage(confirmPasswordResponse);
            }
        } catch (error) {
            console.error(error);
            if (error instanceof Error) {
                setErrorMessage(error.message);
            }
        }
        setLoading(false);
    };

    return (
        <Stack height='100vh'>
            <Stack component={Paper} gap={3} alignItems='stretch' margin='auto' p={2} width='440px'>
                <LogoRoger variant='light' />

                {!realm && <ErrorMessage message={t('login_form.realm_not_found')} />}

                {(!username || !code || !validLink) && (
                    <Stack gap={2}>
                        <ErrorMessage message={t('change_password_form.invalid_link')} />
                        <Link to='/' component={RouterLink} color='primary'>
                            {t('change_password_form.back_to_login')}
                        </Link>
                    </Stack>
                )}

                {realm && username && code && validLink && (
                    <Stack>
                        <PasswordForm
                            title={title}
                            buttonLabel={actionLabel}
                            loading={loading}
                            onSubmitPassword={password => handleSubmit(realm, username, code, password)}
                        />
                        {errorMessage && <ErrorMessage message={errorMessage} />}
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

type ErrorMessageProps = {
    message: string;
};
const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => {
    return (
        <FormHelperText component='div' error={true}>
            <Stack direction='row' spacing={1} alignItems='center'>
                <AlertCircleIcon size={16} />
                <Typography variant='body1' color='error'>
                    {message}
                </Typography>
            </Stack>
        </FormHelperText>
    );
};
