import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { InputNumber } from '@/components/form/field-number/InputNumber';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { UnitType } from '@/domain/date/Date.model';
import { EmployeeWorkingPattern, EmployeeWorkingPatternType } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.model';
import { LeaveCorrection } from '@/domain/leave-correction/LeaveCorrection.model';
import { LeaveActivityType, LeaveType } from '@/domain/leave-type/LeaveType.model';
import { removeNullsFromLeaveType } from '@/domain/leave-type/LeaveType.service';
import {
    LeaveCorrectionDialogAction,
    leaveCorrectionFormSchema,
    LeaveCorrectionFormValues,
} from '@/page/leave/leave-correction-dialog/LeaveCorrectionForm.schema';
import { getCurrentLocalDate, getHoursMinutesFromMinutes, getMinutesFromHoursMinutes, HoursMinutes } from '@/utils/datetime.util';

import { FieldText } from '@/components/form/field-text/FieldText';
import { getLabelTranslation } from '@/utils/language.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, capitalize, DialogActions, DialogContent, FormControlLabel, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type LeaveCorrectionValues = {
    leaveType: LeaveType;
    amountInMinutes: number;
    effectiveDate: LocalDate;
    comment: string;
    transferToTimesheetAdjustment: boolean;
};

type LeaveCorrectionDialogProps = Omit<DialogWrapperProps, 'onSave'> & {
    onSave: (values: LeaveCorrectionValues) => void;
    leaveCorrection?: LeaveCorrection;
    workingPattern: EmployeeWorkingPattern;
    leaveType?: LeaveType;
    leaveTypes?: LeaveType[];
};

export const LeaveCorrectionDialog: FC<LeaveCorrectionDialogProps> = props => {
    const { onSave, onClose, leaveCorrection, workingPattern, leaveType, leaveTypes = [] } = props;
    const { t } = useTranslation();

    const nonCompensationLeaveTypes = leaveTypes.filter(lt => lt.leaveActivityType !== LeaveActivityType.TIMESHEET_COMPENSATION).map(removeNullsFromLeaveType);

    const isHourlyWorkingPattern = workingPattern.type === EmployeeWorkingPatternType.HOURLY;

    const leaveCorrectionMinutes = Math.abs(leaveCorrection?.amountInMinutes ?? 0);
    const defaultLeaveType = leaveCorrection ? removeNullsFromLeaveType(leaveCorrection.leaveType) : leaveType;

    const { control, setValue, watch, handleSubmit } = useForm<LeaveCorrectionFormValues>({
        resolver: yupResolver(leaveCorrectionFormSchema),
        defaultValues: {
            leaveType: defaultLeaveType ? removeNullsFromLeaveType(defaultLeaveType) : undefined,
            actionType: leaveCorrection && leaveCorrection.amountInMinutes < 0 ? LeaveCorrectionDialogAction.REMOVE : LeaveCorrectionDialogAction.ADD,
            unitType: UnitType.DAYS,
            amountInDays: convertMinutesToDaysByWorkingTime(leaveCorrectionMinutes, workingPattern.averageDailyWorkingTime),
            hoursMinutes: getHoursMinutesFromMinutes(leaveCorrectionMinutes),
            effectiveDate: leaveCorrection?.effectiveDate ?? getCurrentLocalDate(),
            comment: leaveCorrection?.comment ?? '',
            transferToTimesheetAdjustment: false,
        },
    });

    const hoursMinutes = watch('hoursMinutes');
    const actionType = watch('actionType');
    const unitType = watch('unitType');

    const handleClose = () => {
        onClose();
    };

    const setAmountInDaysFromMinutes = (totalMinutes: number) => {
        const totalDaysByWorkingPattern = convertMinutesToDaysByWorkingTime(totalMinutes, workingPattern.averageDailyWorkingTime);
        setValue('amountInDays', totalDaysByWorkingPattern);
    };

    const handleDaysChange = (amountInDays: number) => {
        setValue('hoursMinutes', getHoursMinutesFromMinutes(amountInDays * workingPattern.averageDailyWorkingTime), { shouldValidate: true });
    };

    const handleHoursChange = (hours: number, onHoursMinutesChange: (data: HoursMinutes) => void) => {
        const newHoursMinutes = {
            ...hoursMinutes,
            hours,
        };
        const totalMinutes = getMinutesFromHoursMinutes(newHoursMinutes);

        onHoursMinutesChange(newHoursMinutes);
        setAmountInDaysFromMinutes(totalMinutes);
    };

    const handleMinutesChange = (minutes: number, onHoursMinutesChange: (data: HoursMinutes) => void) => {
        // extract the supplement hours if user set minutes greater than 60
        const suppHours = Math.trunc(minutes / 60);
        const restMinutes = minutes % 60;
        const newHoursMinutes = {
            hours: hoursMinutes.hours + suppHours,
            minutes: restMinutes,
        };
        const totalMinutes = getMinutesFromHoursMinutes(newHoursMinutes);

        onHoursMinutesChange(newHoursMinutes);

        setAmountInDaysFromMinutes(totalMinutes);
    };

    const handleSave = (data: LeaveCorrectionFormValues) => {
        const { unitType: _, amountInDays: __, actionType, hoursMinutes, ...rest } = data;
        const amountInMinutes = Math.abs(getMinutesFromHoursMinutes(hoursMinutes));

        const values = {
            ...rest,
            amountInMinutes:
                actionType === LeaveCorrectionDialogAction.REMOVE || actionType === LeaveCorrectionDialogAction.TRANSFER ? -amountInMinutes : amountInMinutes,
        };
        onSave(values);
    };

    const onChangeAction = (value: LeaveCorrectionDialogAction) => {
        setValue('transferToTimesheetAdjustment', value === LeaveCorrectionDialogAction.TRANSFER);
    };

    const infoDuration = t('duration.formatDuration', {
        duration: getMinutesFromHoursMinutes(hoursMinutes) / 60,
        unit: UnitType.HOURS,
    });
    const actionTypeLabel = actionType === LeaveCorrectionDialogAction.ADD ? t('add_correction_dialog.action_add') : t('add_correction_dialog.action_remove');
    const infoAction = t('add_correction_dialog.conversion_message', {
        action: actionTypeLabel,
        averageDailyWorkingTime: t('duration.formatDuration', {
            duration: workingPattern.averageDailyWorkingTime / 60,
            unit: UnitType.HOURS,
        }),
    });

    // in case of it is hourly working pattern, we will display an error message instead
    const canDisplayInfoAction = !isHourlyWorkingPattern && unitType === UnitType.DAYS && getMinutesFromHoursMinutes(hoursMinutes) > 0;

    return (
        <DialogWrapper open={true} onClose={handleClose} header={t('add_correction_dialog.adjust_balance')}>
            <DialogContent>
                <Stack gap={2}>
                    {!defaultLeaveType && (
                        <FormControlLabel
                            label={t('add_correction_dialog.leave_type')}
                            control={
                                <FieldSelect
                                    name='leaveType'
                                    control={control}
                                    fullWidth
                                    options={nonCompensationLeaveTypes}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={leaveTypeHistory => getLabelTranslation(leaveTypeHistory.name)}
                                    getOptionKey={leaveTypeHistory => leaveTypeHistory.id}
                                />
                            }
                        />
                    )}
                    <FormControlLabel label={t('add_correction_dialog.effective_date')} control={<FieldLocalDate control={control} name='effectiveDate' />} />
                    <Stack gap={2}>
                        <FormControlLabel
                            sx={{ width: '100%' }}
                            label={t('timesheets.action')}
                            control={
                                <FieldSelect
                                    name={'actionType'}
                                    control={control}
                                    disableClearable
                                    onChange={value => {
                                        onChangeAction(value);
                                    }}
                                    options={[LeaveCorrectionDialogAction.ADD, LeaveCorrectionDialogAction.REMOVE, LeaveCorrectionDialogAction.TRANSFER]}
                                    getOptionLabel={actionType => t(getTranslationKeyForAction(actionType))}
                                    fullWidth
                                />
                            }
                        />
                        <Stack direction={'row'} gap={1} justifyContent={'space-between'} alignItems={'flex-start'}>
                            <FormControlLabel
                                label={capitalize(t('general.unit'))}
                                sx={{ flex: 1 }}
                                control={
                                    <FieldSelect
                                        name={'unitType'}
                                        control={control}
                                        disableClearable
                                        options={[UnitType.DAYS, UnitType.HOURS]}
                                        getOptionLabel={unitType => (unitType === UnitType.DAYS ? t('domain.unit_type.days') : t('domain.unit_type.hours'))}
                                        fullWidth
                                    />
                                }
                            />
                            {unitType === UnitType.DAYS && (
                                <FormControlLabel
                                    label={capitalize(t('add_correction_dialog.amount_label'))}
                                    sx={{ flex: 1 }}
                                    control={
                                        <FieldNumber
                                            name={'amountInDays'}
                                            control={control}
                                            onChange={days => {
                                                handleDaysChange(days ?? 0);
                                            }}
                                            precision={2}
                                        />
                                    }
                                />
                            )}
                            {unitType === UnitType.HOURS && (
                                <Controller
                                    name={'hoursMinutes'}
                                    control={control}
                                    render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                                        <>
                                            <FormControlLabel
                                                label={capitalize(t('add_correction_dialog.hours_label'))}
                                                labelPlacement='top'
                                                sx={{ flex: 1 }}
                                                control={
                                                    <InputNumber
                                                        {...restField}
                                                        value={value.hours}
                                                        onChange={hours => {
                                                            handleHoursChange(hours ?? 0, onChange);
                                                        }}
                                                        onFocus={event => {
                                                            event.target.select();
                                                        }}
                                                        precision={0}
                                                        fullWidth
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label={capitalize(t('add_correction_dialog.minutes_label'))}
                                                labelPlacement='top'
                                                sx={{ flex: 1 }}
                                                control={
                                                    <InputNumber
                                                        {...restField}
                                                        value={value.minutes}
                                                        onChange={minutes => {
                                                            handleMinutesChange(minutes ?? 0, onChange);
                                                        }}
                                                        onFocus={event => {
                                                            event.target.select();
                                                        }}
                                                        precision={0}
                                                        fullWidth
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                }
                                            />
                                        </>
                                    )}
                                />
                            )}
                        </Stack>
                    </Stack>

                    {isHourlyWorkingPattern && (
                        <Alert severity='error' aria-label={t('add_correction_dialog.hourly_working_pattern_not_allowed')}>
                            <Typography>{t('add_correction_dialog.hourly_working_pattern_not_allowed')}</Typography>
                        </Alert>
                    )}
                    {canDisplayInfoAction && (
                        <Alert severity='info'>
                            <Typography>{`${infoDuration} ${infoAction}`}</Typography>
                        </Alert>
                    )}
                    <FormControlLabel
                        label={t('add_correction_dialog.comment')}
                        control={
                            <FieldText
                                name={'comment'}
                                control={control}
                                fullWidth
                                textFieldProps={{
                                    slotProps: {
                                        input: {
                                            multiline: true,
                                            minRows: 2,
                                        },
                                    },
                                }}
                                placeholder={t('request_overtime_dialog.comment_placeholder')}
                            />
                        }
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleSubmit(handleSave, console.error)()} fullWidth disabled={isHourlyWorkingPattern}>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const convertMinutesToDaysByWorkingTime = (minutes: number, averageDailyWorkingTime: number) => {
    return minutes / averageDailyWorkingTime;
};

const getTranslationKeyForAction = (action: LeaveCorrectionDialogAction): string => {
    switch (action) {
        case LeaveCorrectionDialogAction.ADD:
            return 'general.add';
        case LeaveCorrectionDialogAction.REMOVE:
            return 'general.remove';
        case LeaveCorrectionDialogAction.TRANSFER:
            return 'general.transfer';
    }
};
