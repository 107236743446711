import { FilterType, SelectFilter } from '@/components/filters-bar/FilterBar.type';
import { getUserEmploymentStatusTranslationKey } from '@/domain/employee/Employee.service';
import { EmploymentStatus } from '@/domain/employment/Employment.model';
import { useFiltersDirectory } from '@/page/common/filters-directory/useFiltersDirectory';
import { useTranslation } from 'react-i18next';

/**
 * Hook to get the list of filter for employee payroll locks page
 */
export const useEmployeePayrollLocksPageFilters = (): { filters: FilterType[] } => {
    const { t } = useTranslation();

    const defaultValue = {
        label: t(getUserEmploymentStatusTranslationKey(), { context: EmploymentStatus.EMPLOYED }),
        value: EmploymentStatus.EMPLOYED,
    };

    const { filters } = useFiltersDirectory(['EMPLOYMENT_STATUS', 'EMPLOYEE', 'LOCATION', 'DEPARTMENT', 'JOB']);

    return {
        filters: filters.map((filter): FilterType => {
            if (filter.key === 'EMPLOYMENT_STATUS') {
                const employmentStatusFilter = filter as SelectFilter;
                const options = employmentStatusFilter.options?.filter(option => option.value !== EmploymentStatus.HIRED) ?? [];
                return {
                    ...employmentStatusFilter,
                    options,
                    value: [defaultValue],
                    clearable: false,
                };
            }
            return filter;
        }),
    };
};
