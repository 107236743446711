import { FilterType } from '@/components/filters-bar/FilterBar.type';
import { getDayOfWeekTranslationKey } from '@/domain/date/Date.service';
import { useFiltersDirectory } from '@/page/common/filters-directory/useFiltersDirectory';
import { DAY_OF_WEEK_SORT } from '@/utils/datetime.util';
import { capitalize } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const useTimesheetInsightsTableFilters = (): { filters: FilterType[] } => {
    const { t } = useTranslation();

    const { filters: filtersFromDirectory } = useFiltersDirectory(['LOCATION', 'DEPARTMENT', 'JOB']);

    const filters: FilterType[] = [
        {
            filterName: capitalize(t('general.days_plural')),
            type: 'select',
            key: 'dayOfWeek',
            options: DAY_OF_WEEK_SORT.map(day => ({
                value: day,
                label: t(getDayOfWeekTranslationKey(day)),
            })),
            selectMode: 'SYNC',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: t('general.time'),
            type: 'time',
            key: 'timeRange',
        },
        ...filtersFromDirectory,
    ];

    return {
        filters,
    };
};
