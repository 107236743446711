export type FilterCommonType = {
    key: string;
    filterName: string;
    /** hide the filter in more filters button */
    hide?: boolean;
    defaultVisibility?: 'visible' | 'hidden';
    clearable?: boolean;
};

/*
 * Select filter
 */
export type SelectFilterOption = {
    label: string;
    value: string | number;
};
export type SelectFilter = FilterCommonType & {
    type: 'select' | 'multi-select';
    options?: SelectFilterOption[];
    value?: SelectFilterOption[];
    selectMode: 'SYNC';
    rule: 'EQUALS' | 'NOT_EQUALS';
    availableRules: ('EQUALS' | 'NOT_EQUALS')[];
};
/**
 * A select filter that fetches its options asynchronously
 */
export type AsyncSelectFilter = Omit<SelectFilter, 'options' | 'selectMode'> & {
    fetchOptions: () => Promise<SelectFilterOption[]>;
    selectMode: 'ASYNC';
};

/*
 * Date filter
 */
export type DateRangeType = [LocalDate, LocalDate];
// TODO rename this by rule to be more consistent with select filter
export type DateFilterRule = 'WITHIN_THE_LAST' | 'MORE_THAN' | 'BETWEEN';
export type DateFilterCommon = FilterCommonType & {
    type: 'date';
    availableRules: DateFilterRule[];
};
export type DateSimpleFilterType = DateFilterCommon & {
    value?: LocalDate;
    dateType?: 'WITHIN_THE_LAST' | 'MORE_THAN';
};

export type DateRangeFilterType = DateFilterCommon & {
    value?: DateRangeType;
    dateType: 'BETWEEN';
};
export type DateFilter = DateSimpleFilterType | DateRangeFilterType;

/*
 * Text filter
 */
export type TextFilter = FilterCommonType & {
    // TODO improve filter bar to manage number
    // THIS is a hack to accept number and document even if they are not supported
    type: 'text' | 'number' | 'document';
    value?: string;
};

/*
 * boolean filter
 */
export type BooleanFilter = FilterCommonType & {
    type: 'boolean';
    value?: boolean;
};

/*
 * Time filter
 */
export type TimeRangeType = [LocalTime, LocalTime];
export type TimeFilterType = FilterCommonType & {
    type: 'time';
    value?: TimeRangeType;
};
/*
 * Tree select filter
 */
export type TreeSelectFilterOption = SelectFilterOption & {
    children?: TreeSelectFilterOption[];
};
export type TreeSelectFilterType = FilterCommonType & {
    type: 'tree-select' | 'tree-multi-select';
    options: TreeSelectFilterOption[];
    value?: TreeSelectFilterOption[];
    selectMode: 'SYNC';
    rule: 'EQUALS' | 'NOT_EQUALS';
    availableRules: ('EQUALS' | 'NOT_EQUALS')[];
};

export type AsyncTreeSelectFilterType = Omit<TreeSelectFilterType, 'options' | 'selectMode'> & {
    fetchOptions: () => Promise<TreeSelectFilterOption[]>;
    selectMode: 'ASYNC';
};

export type FilterType =
    | SelectFilter
    | AsyncSelectFilter
    | DateFilter
    | TextFilter
    | TimeFilterType
    | TreeSelectFilterType
    | AsyncTreeSelectFilterType
    | BooleanFilter;

const SELECT_TYPES = ['multi-select', 'select', 'tree-multi-select', 'tree-select'] as const;

export const isSelectFilter = (filter: FilterType | undefined): filter is SelectFilter | TreeSelectFilterType => {
    const filterType = filter?.type;
    return SELECT_TYPES.includes(filterType as (typeof SELECT_TYPES)[number]);
};
