import { TranslatableLabelInput } from '@/components/translatable-label-input/TranslatableLabelInput';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { getLocalizedErrorMessage, UserLanguage } from '@/utils/language.util';
import { TextFieldProps } from '@mui/material';
import { Controller, ControllerProps, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';
import { JSX } from 'react';

type TextRootProps = Pick<TextFieldProps, 'autoFocus' | 'fullWidth' | 'placeholder' | 'disabled' | 'label'>;

export type FieldLabelProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    language: UserLanguage;
    textFieldProps?: RemoveFromType<RemoveFromType<TextFieldProps, TextRootProps>, ControllerRenderProps>;
} & TextRootProps &
    Omit<ControllerProps<TFieldValues, TName>, 'render'>;

export const FieldLabel = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    props: FieldLabelProps<TFieldValues, TName>,
): JSX.Element => {
    const { language, label, autoFocus, placeholder, fullWidth, disabled, textFieldProps, ...controllerProps } = props;
    const { slotProps, ...restTextFieldProps } = textFieldProps ?? {};

    return (
        <Controller
            {...controllerProps}
            render={({ field: { value, ...field }, fieldState: { error } }) => (
                <TranslatableLabelInput
                    label={label}
                    autoFocus={autoFocus}
                    value={value ?? createDefaultLabel()}
                    translationLanguage={language}
                    error={!!error}
                    helperText={getLocalizedErrorMessage(error, language)}
                    disabled={disabled}
                    placeholder={placeholder}
                    fullWidth={fullWidth}
                    slotProps={{
                        htmlInput: {
                            'aria-label': controllerProps.name,
                        },
                        ...slotProps,
                    }}
                    {...field}
                    {...restTextFieldProps}
                />
            )}
        />
    );
};
