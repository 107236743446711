import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { Add01Icon } from 'hugeicons-react';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { useTranslation } from 'react-i18next';
import { ObjectiveType } from '@/domain/objective/Objective.model';
import { canCreateOtherObjectives } from '@/domain/permission/Permission.service';
import { useCurrentPolicies } from '@/stores/store';
import { AsyncSelectFilter, SelectFilter } from '@/components/filters-bar/FilterBar.type';

type ObjectivesPageHeaderProps = {
    objectiveType: ObjectiveType;
    filters: (SelectFilter | AsyncSelectFilter)[];
    onFiltersChange: (filters: (SelectFilter | AsyncSelectFilter)[]) => void;
    onAddClick: () => void;
    employeeId: number;
};

export const ObjectivesPageHeader: FC<ObjectivesPageHeaderProps> = ({ objectiveType, filters, onFiltersChange, onAddClick, employeeId }) => {
    const { t } = useTranslation();
    const policies = useCurrentPolicies();

    return (
        <Stack gap={2} p={1}>
            <Stack display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                <Typography variant='h1' pl={1}>
                    {t('objectives.objectives', { context: objectiveType })}
                </Typography>
                {canCreateOtherObjectives(policies, employeeId) && (
                    <Button startIcon={<Add01Icon />} onClick={onAddClick}>
                        {t('objectives.objectives', { context: objectiveType })}
                    </Button>
                )}
            </Stack>

            <FiltersBar filters={filters} onFiltersChange={onFiltersChange} />
        </Stack>
    );
};
