import { API_BASE_URL } from '@/api/common';
import { Realm, RealmUpdateMutation } from '@/domain/realm/Realm.model';
import axios from 'axios';
import { getUserAuthentication } from '@/utils/auth.util';

const searchRealmByName = async (realmName: string): Promise<Realm[]> => {
    const { data } = await axios.post(API_BASE_URL + `/realms/search`, { name: realmName });
    return data;
};

const updateRealm = async (mutation: RealmUpdateMutation, tenantId: string): Promise<void> => {
    const authenticatedUser = getUserAuthentication();

    const config = authenticatedUser
        ? {
              headers: {
                  Authorization: `Bearer ${authenticatedUser?.idToken}`,
              },
          }
        : undefined;

    await axios.put(API_BASE_URL + `/realms/${tenantId}`, mutation, config);
};
export const realmApi = {
    searchRealmByName,
    updateRealm,
};
