import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { RichTextTypography } from '@/components/rich-text-typography/RichTextTypography';

type FeedbackSkillsDescriptionProps = {
    minSkills?: number;
    maxSkills?: number;
    instruction?: string;
};

export const FeedbackSkillsDescription: FC<FeedbackSkillsDescriptionProps> = ({ minSkills, maxSkills, instruction }) => {
    const { t } = useTranslation();

    if (instruction) {
        return <RichTextTypography variant='body2'>{instruction}</RichTextTypography>;
    }

    const numberOfSkillsToReviewDefined = minSkills && maxSkills;

    if (numberOfSkillsToReviewDefined) {
        return (
            <Typography variant='body1'>
                {minSkills === maxSkills
                    ? t('reviews.write_feedback.review_skills_description_min_equal_max', {
                          value: minSkills,
                      })
                    : t('reviews.write_feedback.review_skills_description', {
                          min: minSkills,
                          max: maxSkills,
                      })}
            </Typography>
        );
    }

    return <Typography variant='body1'>{t('reviews.write_feedback.review_skills_description_no_limit')}</Typography>;
};
