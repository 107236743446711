import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { StepperWorkflow } from '@/components/stepper-workflow/StepperWorkflow';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { ContributorType, Review, ReviewEmailMutation, ReviewStartMutation, ReviewUpdateMutation } from '@/domain/review/Review.model';
import { getSetupStep, startReview, updateReview } from '@/domain/review/Review.service';
import { useGetReview } from '@/hooks/review/Review.hook';
import { getInvitationFormSchema, InvitationForm } from '@/page/review/review-create-cycle-workflow/InvitationsFormPage.schema';
import { InvitationEmailFormType } from '@/page/setting/review/template/ReviewTemplateFormPage.schema';
import { handleError } from '@/utils/api.util';
import { addWeeksAndFormat, getCurrentLocalDate, getEndOfMonth } from '@/utils/datetime.util';

import { getRealmLanguage, UserLanguage } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControlLabel, Paper, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { FieldTranslatableRichTextEditor } from '@/components/form/field-translatable-rich-text-editor/FieldTranslatableRichTextEditor';

const DEFAULT_FEEDBACK_DEADLINE_LOCAL_DATE: LocalDate = addWeeksAndFormat(getCurrentLocalDate(), 2);
const DEFAULT_END_LOCAL_DATE: LocalDate = getEndOfMonth(addWeeksAndFormat(getCurrentLocalDate(), 4));

export const InvitationsFormPage: FC = () => {
    const params = useParams();
    const reviewId = Number(params.reviewId) || undefined;
    const { data: review, isError: isReviewError, isLoading: isReviewLoading, refetch: refetchReview, error } = useGetReview(reviewId);

    return (
        <StateHandler isLoading={isReviewLoading} isError={isReviewError} error={error}>
            {!!review && (
                <InvitationsForm
                    review={review}
                    refetchReview={async () => {
                        await refetchReview();
                    }}
                />
            )}
        </StateHandler>
    );
};

type InvitationsFormProps = {
    review: Review;
    refetchReview: () => Promise<void>;
};

const InvitationsForm: FC<InvitationsFormProps> = ({ review }) => {
    const { t } = useTranslation();
    const reviewId = review.id;
    const navigate = useNavigate();
    const [translationLanguage, setTranslationLanguage] = useState<UserLanguage>(getRealmLanguage());
    const availableContributorTypes = review.contributorTypes ?? [];
    const showSelfInvitationEmail = availableContributorTypes.includes('SELF');
    const showPeerInvitationEmail = availableContributorTypes.includes('PEER');
    const showUpwardInvitationEmail = availableContributorTypes.includes('UPWARD');

    const findEmail = (contributorType: ContributorType, review: Review) => {
        return review.emails.find(email => email.contributorType === contributorType);
    };

    const formMethods = useForm<InvitationForm>({
        resolver: yupResolver(
            getInvitationFormSchema(
                {
                    showSelfInvitationEmail,
                    showPeerInvitationEmail,
                    showUpwardInvitationEmail,
                },
                translationLanguage,
            ),
        ),
        defaultValues: {
            endDate: review.endDate ?? DEFAULT_END_LOCAL_DATE,
            feedbackDeadlineDate: review.feedbackDeadlineDate ?? DEFAULT_FEEDBACK_DEADLINE_LOCAL_DATE,
            managerSubject: findEmail('MANAGER', review)?.subject ?? createDefaultLabel(),
            managerBody: findEmail('MANAGER', review)?.content ?? createDefaultLabel(),
            selfSubject: findEmail('SELF', review)?.subject ?? createDefaultLabel(),
            selfBody: findEmail('SELF', review)?.content ?? createDefaultLabel(),
            peerSubject: findEmail('PEER', review)?.subject ?? createDefaultLabel(),
            peerBody: findEmail('PEER', review)?.content ?? createDefaultLabel(),
            upwardSubject: findEmail('UPWARD', review)?.subject ?? createDefaultLabel(),
            upwardBody: findEmail('UPWARD', review)?.content ?? createDefaultLabel(),
        },
    });
    const { handleSubmit, control, watch } = formMethods;

    const watchEndDate = watch('endDate');
    const watchFeedbackDeadlineDate = watch('feedbackDeadlineDate');

    const mapEmails = (invitationForm: InvitationForm) => {
        const emails: ReviewEmailMutation[] = [];
        emails.push({
            subject: invitationForm.managerSubject,
            content: invitationForm.managerBody,
            contributorType: 'MANAGER',
        });

        if (showSelfInvitationEmail) {
            emails.push({
                subject: invitationForm.selfSubject ?? createDefaultLabel(),
                content: invitationForm.selfBody ?? createDefaultLabel(),
                contributorType: 'SELF',
            });
        }

        if (showPeerInvitationEmail) {
            emails.push({
                subject: invitationForm.peerSubject ?? createDefaultLabel(),
                content: invitationForm.peerBody ?? createDefaultLabel(),
                contributorType: 'PEER',
            });
        }

        if (showUpwardInvitationEmail) {
            emails.push({
                subject: invitationForm.upwardSubject ?? createDefaultLabel(),
                content: invitationForm.upwardBody ?? createDefaultLabel(),
                contributorType: 'UPWARD',
            });
        }

        return emails;
    };

    const onStart = async (invitationForm: InvitationForm) => {
        const mutation: ReviewStartMutation = {
            endDate: invitationForm.endDate,
            feedbackDeadlineDate: invitationForm.feedbackDeadlineDate,
            emails: mapEmails(invitationForm),
        };

        try {
            await startReview(reviewId, mutation);
            showSnackbar(t('reviews.messages.review_started'), 'success');
            navigate(`/reviews/manage-reviews`);
        } catch (error) {
            handleError(error);
        }
    };

    const onSaveAsDraft = async (data: InvitationForm) => {
        const reviewUpdateMutation: ReviewUpdateMutation = {
            name: review.name,
            employeeIds: (review.employeeReviews ?? []).map(employeeReview => employeeReview.employee.id),
            ownerIds: review.owners.map(owner => owner.id),
            instructions: review.instructions,
            emailInstructions: review.emailInstructions,
            endDate: data.endDate,
            feedbackDeadlineDate: data.feedbackDeadlineDate,
            emails: mapEmails(data),
        };

        try {
            await updateReview(review.id, reviewUpdateMutation);
            showSnackbar(t('reviews.messages.review_updated'), 'success');
            navigate(`/reviews/manage-reviews`);
        } catch (error) {
            handleError(error);
        }
    };

    const steps = getSetupStep().steps;
    const setupStep = getSetupStep().getStep('INVITATIONS');

    return (
        <Stack justifyContent={'space-between'} flex={1} gap={2} mb={8.5}>
            <Stack gap={2}>
                <Stack component={Paper} elevation={1} p={2} gap={2}>
                    <Typography variant='body1bold'>{t('reviews.performance_review_forms.period')}</Typography>

                    <Stack gap={2} direction='column'>
                        <FormControlLabel
                            label={t('reviews.performance_review_forms.feedback_deadline_date')}
                            control={<FieldLocalDate name='feedbackDeadlineDate' control={control} maxDate={watchEndDate} sx={{ width: '500px' }} />}
                        />

                        <FormControlLabel
                            label={t('reviews.performance_review_forms.end_date')}
                            control={<FieldLocalDate name='endDate' control={control} minDate={watchFeedbackDeadlineDate} sx={{ width: '500px' }} />}
                        />
                    </Stack>
                </Stack>

                <FormProvider {...formMethods}>
                    <Stack gap={2}>
                        <ManagerEmail translationLanguage={translationLanguage} />
                        {showSelfInvitationEmail && <SelfReviewEmail translationLanguage={translationLanguage} />}
                        {showPeerInvitationEmail && <PeerReviewEmail translationLanguage={translationLanguage} />}
                        {showUpwardInvitationEmail && <UpwardFeedbackEmail translationLanguage={translationLanguage} />}
                    </Stack>
                </FormProvider>
            </Stack>

            <StepperWorkflow steps={steps} currentStep={setupStep}>
                <TranslationLanguageSelector translationLanguage={translationLanguage} onLanguageChange={setTranslationLanguage} />
                <Button onClick={() => navigate(`/reviews/manage-reviews/${reviewId}/reviewers`)} variant={'text'}>
                    {t('general.back')}
                </Button>
                <Button onClick={() => onSaveAsDraft(watch())} variant={'contained'}>
                    {t('reviews.wizard.save_draft')}
                </Button>
                <ConfirmDialog
                    title={t('reviews.wizard.confirm_start_dialog.title')}
                    content={t('reviews.wizard.confirm_start_dialog.content')}
                    onConfirm={handleSubmit(onStart, console.error)}
                >
                    <Button variant={'contained'}>{t('reviews.wizard.start')}</Button>
                </ConfirmDialog>
            </StepperWorkflow>
        </Stack>
    );
};

type ManagerEmailProps = {
    translationLanguage: UserLanguage;
};

const ManagerEmail: FC<ManagerEmailProps> = ({ translationLanguage }) => {
    const { control } = useFormContext<InvitationEmailFormType>();
    const { t } = useTranslation();

    return (
        <Stack p={2} gap={2} component={Paper}>
            <Typography variant={'body1bold'}>{t('reviews_settings_page.review_template_form.manager_email')}</Typography>
            <FieldLabel
                control={control}
                name={'managerSubject'}
                language={translationLanguage}
                textFieldProps={{
                    multiline: true,
                    sx: { width: 800 },
                }}
                label={t('reviews_settings_page.review_template_form.subject')}
                fullWidth
            />
            <Stack width={'800px'}>
                <Typography variant={'body1'}>{t('reviews_settings_page.review_template_form.body')}</Typography>
                <FieldTranslatableRichTextEditor name={'managerBody'} control={control} translationLanguage={translationLanguage} />
            </Stack>
        </Stack>
    );
};

type SelfReviewEmailProps = {
    translationLanguage: UserLanguage;
};

const SelfReviewEmail: FC<SelfReviewEmailProps> = ({ translationLanguage }) => {
    const { control } = useFormContext<InvitationEmailFormType>();
    const { t } = useTranslation();

    return (
        <Stack p={2} gap={3} component={Paper}>
            <Typography variant={'body1bold'}>{t('reviews_settings_page.review_template_form.self_review_email')}</Typography>
            <FieldLabel
                control={control}
                name={'selfSubject'}
                language={translationLanguage}
                textFieldProps={{
                    multiline: true,
                    sx: { width: '800px' },
                }}
                label={t('reviews_settings_page.review_template_form.subject')}
                fullWidth
            />
            <Stack width={'800px'}>
                <Typography variant={'body1'}>{t('reviews_settings_page.review_template_form.body')}</Typography>
                <FieldTranslatableRichTextEditor name={'selfBody'} control={control} translationLanguage={translationLanguage} />
            </Stack>
        </Stack>
    );
};

type PeerReviewEmailProps = {
    translationLanguage: UserLanguage;
};

const PeerReviewEmail: FC<PeerReviewEmailProps> = ({ translationLanguage }) => {
    const { control } = useFormContext<InvitationEmailFormType>();
    const { t } = useTranslation();

    return (
        <Stack p={2} gap={3} component={Paper}>
            <Typography variant={'body1bold'}>{t('reviews_settings_page.review_template_form.peer_review_email')}</Typography>
            <FieldLabel
                control={control}
                name={'peerSubject'}
                language={translationLanguage}
                textFieldProps={{
                    multiline: true,
                    sx: { width: '800px' },
                }}
                label={t('reviews_settings_page.review_template_form.subject')}
                fullWidth
            />
            <Stack width={'800px'}>
                <Typography variant={'body1'}>{t('reviews_settings_page.review_template_form.body')}</Typography>
                <FieldTranslatableRichTextEditor name={'peerBody'} control={control} translationLanguage={translationLanguage} />
            </Stack>
        </Stack>
    );
};

type UpwardFeedbackEmailProps = {
    translationLanguage: UserLanguage;
};
const UpwardFeedbackEmail: FC<UpwardFeedbackEmailProps> = ({ translationLanguage }) => {
    const { control } = useFormContext<InvitationEmailFormType>();
    const { t } = useTranslation();

    return (
        <Stack p={2} gap={3} component={Paper}>
            <Typography variant={'body1bold'}>{t('reviews_settings_page.review_template_form.upward_feedback_email')}</Typography>
            <FieldLabel
                control={control}
                name={'upwardSubject'}
                language={translationLanguage}
                textFieldProps={{
                    multiline: true,
                    sx: { width: '800px' },
                }}
                label={t('reviews_settings_page.review_template_form.subject')}
                fullWidth
            />
            <Stack width={'800px'}>
                <Typography variant={'body1'}>{t('reviews_settings_page.review_template_form.body')}</Typography>
                <FieldTranslatableRichTextEditor name={'upwardBody'} control={control} translationLanguage={translationLanguage} />
            </Stack>
        </Stack>
    );
};
