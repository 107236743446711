import { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { RatingFormType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import { getRatingBackground } from '@/page/review/employee-review-summary-feedback-form/FeedbackFromPreparation.util';
import { useTheme } from '@mui/material';

type FeedbackScorePDFProps = {
    score: number;
    rating: RatingFormType;
};

export const FeedbackScorePDF: FC<FeedbackScorePDFProps> = ({ score, rating }) => {
    const theme = useTheme();
    return (
        <View
            style={{
                fontSize: 8,
                alignItems: 'center',
                border: '1px black solid',
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(0.5),
                backgroundColor: getRatingBackground(
                    {
                        score,
                    },
                    rating?.items,
                ),
                maxWidth: '80vw',
            }}
            wrap={false}
        >
            <Text> {rating?.items?.find(i => i.score === score)?.label}</Text>
        </View>
    );
};
