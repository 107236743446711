import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { Department, DepartmentCreationMutation, DepartmentNode } from '@/domain/department/Department.model';
import {
    archiveDepartment,
    createDepartment,
    deleteDepartment,
    renameDepartment,
    unarchiveDepartment,
    updateDepartmentOrders,
} from '@/domain/department/Department.service';
import { mapDepartmentNodeOrganizationsToTreeItems, mapDepartmentNodesListToDepartments } from '@/domain/department/Department.utils';
import { Label } from '@/domain/label/Label.model';
import { isLabelUnique } from '@/domain/label/Label.service';
import { useGetDepartmentNodeOrganizations } from '@/hooks/department/Department.hook';
import { DepartmentDialog } from '@/page/setting/organization/department/department-dialog/DepartmentDialog';
import { DepartmentFormValues, getDepartmentFormSchema } from '@/page/setting/organization/department/department-dialog/DepartmentDialog.schema';
import { DepartmentTreeItem } from '@/page/setting/organization/department/department-tree/DepartmentTreeItem';
import { handleError } from '@/utils/api.util';
import { UserLanguage } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { AddSquareIcon, RemoveSquareIcon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TreeView, TreeViewItem } from '@/components/tree-view/TreeView';
import { TreeViewItemReorderPosition } from '@mui/x-tree-view-pro/internals/plugins/useTreeViewItemsReordering/useTreeViewItemsReordering.types';
import {
    DepartmentEmploymentManagementDialog,
    DepartmentUpdateEmploymentDialogProps,
} from '@/page/setting/organization/department/employment-update-dialog/DepartmentEmploymentManagementDialog';
import { findRecursiveItemById } from '@/utils/object.util';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { isSameCostCenters } from '@/domain/cost-center/CostCenter.service';
import { isEqualArray } from '@/utils/collections.util';

type DepartmentsTreeProps = {
    translationLanguage: UserLanguage;
    atDate?: LocalDate;
};
type DepartmentRevision = {
    departmentNode: DepartmentNode;
    newDepartmentValue: DepartmentUpdateEmploymentDialogProps['newValue'];
};
export const DepartmentsTree: FC<DepartmentsTreeProps> = ({ translationLanguage, atDate }) => {
    const [parentToAdd, setParentToAdd] = useState<DepartmentNode>();
    const [departmentToUpdate, setDepartmentToUpdate] = useState<DepartmentNode>();
    // this state is used to save the previous version of the department and the new values to update
    const [departmentRevision, setDepartmentRevision] = useState<DepartmentRevision>();

    const { t } = useTranslation();

    // Fetching departments
    const {
        data: departmentNodes = [],
        isLoading: isDepartmentNodesLoading,
        isError: isDepartmentNodesError,
        error: departmentNodesError,
        refetch: refetchDepartmentsNodes,
    } = useGetDepartmentNodeOrganizations({ date: atDate });

    const flatDepartments = mapDepartmentNodesListToDepartments(departmentNodes);
    const departmentTreeItems = mapDepartmentNodeOrganizationsToTreeItems(departmentNodes, flatDepartments, translationLanguage);

    // Form to add department at the root
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(getDepartmentFormSchema(translationLanguage)),
    });

    // Handle actions click
    const handleAddNodeClick = (department: DepartmentNode) => {
        setParentToAdd(department);
    };

    const handleEditNodeClick = (department: DepartmentNode) => {
        setDepartmentToUpdate(department);
    };

    const handleDeleteNodeClick = async (department: DepartmentNode) => {
        try {
            await deleteDepartment(department.id);
            await refetchDepartmentsNodes();
        } catch (error) {
            handleError(error);
        }
    };

    const handleArchiveClick = async (department: DepartmentNode) => {
        try {
            await archiveDepartment(department.id);
            await refetchDepartmentsNodes();
        } catch (error) {
            handleError(error);
        }
    };

    const handleUnarchiveClick = async (department: DepartmentNode) => {
        try {
            await unarchiveDepartment(department.id);
            await refetchDepartmentsNodes();
        } catch (error) {
            handleError(error);
        }
    };

    const handleSubmitAdd = async (departmentFormValue: DepartmentFormValues) => {
        const mutation = { ...mapDepartmentFormValuesToMutation(departmentFormValue), order: departmentTreeItems.length };

        const filteredLabels = getLabelsByParent(flatDepartments, mutation.parentId);

        if (!isLabelUnique(mutation.name, filteredLabels)) {
            showSnackbar(t('settings_organization.departments.unique_name_error'), 'error');
            return;
        }
        try {
            await createDepartment(mutation);
            setParentToAdd(undefined);
            await refetchDepartmentsNodes();
            reset();
        } catch (error) {
            handleError(error);
        }
    };

    const handleDepartmentChanges = async (departmentNode: DepartmentNode, departmentFormValues: DepartmentFormValues) => {
        const department = flatDepartments.find(d => d.id === departmentNode.id);
        if (!department) {
            return;
        }
        // check label uniqueness
        const filteredLabels = getLabelsByParent(
            flatDepartments.filter(d => departmentNode.id !== d.id),
            departmentFormValues.parent?.id,
        );

        if (!isLabelUnique(departmentFormValues.name, filteredLabels)) {
            showSnackbar(t('settings_organization.departments.unique_name_error'), 'error');
            return;
        }

        // check if the department has changes in the managers, cost centers or parent
        const needToUpdateEmployments =
            !isSameCostCenters(departmentNode.departmentCostCenters, departmentFormValues.departmentCostCenters) ||
            !isEqualArray(
                departmentNode.managers.map(m => m.id),
                departmentFormValues.managers.map(m => m.id),
            ) ||
            department?.parentId !== departmentFormValues.parent?.id;

        if (needToUpdateEmployments) {
            // the update of department and employments is managed in a different dialog
            handleManageEmployments(departmentNode, departmentFormValues);
        } else {
            // case when only the name is updated
            try {
                await renameDepartment(department, departmentFormValues.name);
                await refetchDepartmentsNodes();
                closeDepartmentUpdateDialog();
            } catch (error) {
                handleError(error);
            }
        }
    };

    const handleManageEmployments = (departmentNode: DepartmentNode, departmentFormValues: DepartmentFormValues) => {
        // different process to update when updating a manager or cost center
        setDepartmentRevision({
            departmentNode: departmentNode,
            newDepartmentValue: {
                ...departmentFormValues,
                parentId: departmentFormValues.parent?.id,
                order: departmentNode.order,
            },
        });
    };

    const handleSaveDepartmentAndEmploymentDialog = async () => {
        closeDepartmentUpdateDialog();
        try {
            await refetchDepartmentsNodes();
        } catch (error) {
            handleError(error);
        }
    };

    const closeDepartmentUpdateDialog = () => {
        setDepartmentRevision(undefined);
        setDepartmentToUpdate(undefined);
    };

    // handle drag and drop
    const handleItemPositionChange = async (params: { itemId: string; oldPosition: TreeViewItemReorderPosition; newPosition: TreeViewItemReorderPosition }) => {
        const { itemId, oldPosition, newPosition } = params;
        const departmentId = Number(itemId);
        const newParentId = newPosition.parentId ? Number(newPosition.parentId) : undefined;

        try {
            if (oldPosition.parentId !== newPosition.parentId) {
                manageEmployments(departmentId, newParentId, newPosition.index);
            } else {
                await reorderChildren({
                    items: departmentTreeItems,
                    parentId: newParentId,
                    oldIndex: oldPosition.index,
                    newIndex: newPosition.index,
                });
                await refetchDepartmentsNodes();
            }
        } catch (error) {
            handleError(error);
        }
    };

    const manageEmployments = (departmentId: number, newParentId: number | undefined, newIndex: number) => {
        const departmentNode = findRecursiveItemById(departmentNodes, departmentId);
        if (!departmentNode) {
            return;
        }
        const newDepartmentValue = {
            // new values
            parentId: newParentId,
            order: newIndex,
            // the rest of the values are the same when drag and drop
            name: departmentNode.name,
            managers: departmentNode.managers,
            departmentCostCenters: departmentNode.departmentCostCenters,
        };
        // move the department to another parent will be managed in the dialog
        setDepartmentRevision({
            departmentNode,
            newDepartmentValue,
        });
    };

    // can move the department if it has no children and is not archived
    const isDepartmentReorderable = (itemId: string) => {
        const departmentNode = findRecursiveItemById(departmentNodes, Number(itemId));
        return departmentNode?.children.length === 0 && !departmentNode?.archived;
    };

    // can move the department to another parent if the new parent is not archived
    const canMoveItemToNewPosition = (params: { itemId: string; oldPosition: TreeViewItemReorderPosition; newPosition: TreeViewItemReorderPosition }) => {
        const { newPosition } = params;
        const departmentNode = findRecursiveItemById(departmentNodes, Number(newPosition.parentId));
        return !departmentNode?.archived;
    };

    return (
        <Stack gap={3} mt={2}>
            {/* FORM TO ADD DEPARTMENT */}
            <Stack direction={'row'} alignItems={'flex-start'} gap={2}>
                <FieldLabel
                    name={'name'}
                    control={control}
                    fullWidth
                    language={translationLanguage}
                    placeholder={t('settings_organization.departments.add_dialog.name_field')}
                />
                <Button
                    onClick={() => {
                        handleSubmit((data: DepartmentFormValues) => {
                            handleSubmitAdd(data).catch(handleError);
                        }, console.error)();
                    }}
                >
                    {t('general.submit')}
                </Button>
            </Stack>

            <StateHandler isLoading={isDepartmentNodesLoading} isError={isDepartmentNodesError} error={departmentNodesError}>
                {/* DEPARTMENT TREE */}
                <TreeView
                    items={departmentTreeItems}
                    itemsReordering={true}
                    onItemPositionChange={handleItemPositionChange}
                    isItemReorderable={isDepartmentReorderable}
                    canMoveItemToNewPosition={canMoveItemToNewPosition}
                    slots={{
                        expandIcon: AddSquareIcon,
                        collapseIcon: RemoveSquareIcon,
                        item: DepartmentTreeItem,
                    }}
                    slotProps={{
                        expandIcon: { width: 16, height: 16 },
                        collapseIcon: { width: 16, height: 16 },
                        item: ownerState => {
                            return {
                                ...ownerState,
                                onAddClick: handleAddNodeClick,
                                onEditClick: handleEditNodeClick,
                                onDeleteClick: handleDeleteNodeClick,
                                onArchiveClick: handleArchiveClick,
                                onUnarchiveClick: handleUnarchiveClick,
                            };
                        },
                    }}
                />
            </StateHandler>

            {/* DIALOGS */}
            {parentToAdd && (
                <DepartmentDialog
                    open={true}
                    onClose={() => setParentToAdd(undefined)}
                    departments={departmentNodes}
                    defaultParent={parentToAdd}
                    translationLanguage={translationLanguage}
                    onSave={handleSubmitAdd}
                />
            )}
            {departmentToUpdate && (
                <DepartmentDialog
                    open={true}
                    onClose={closeDepartmentUpdateDialog}
                    departments={departmentNodes}
                    defaultDepartment={departmentToUpdate}
                    translationLanguage={translationLanguage}
                    onSave={(data: DepartmentFormValues) => handleDepartmentChanges(departmentToUpdate, data)}
                />
            )}
            {departmentRevision && (
                <DepartmentEmploymentManagementDialog
                    open={true}
                    onClose={() => setDepartmentRevision(undefined)}
                    departmentNodes={departmentNodes}
                    departmentNode={departmentRevision.departmentNode}
                    newValue={departmentRevision.newDepartmentValue}
                    onSave={handleSaveDepartmentAndEmploymentDialog}
                />
            )}
        </Stack>
    );
};

const mapDepartmentFormValuesToMutation = (departmentFormValue: DepartmentFormValues): Omit<DepartmentCreationMutation, 'order'> => {
    const { name, parent, managers, departmentCostCenters } = departmentFormValue;
    return {
        name,
        parentId: parent?.id,
        managerIds: managers.map(m => m.id),
        departmentCostCenters: departmentCostCenters.map(dcc => ({
            costCenterId: dcc.costCenter.id,
            percentage: dcc.percentage,
        })),
    };
};

/**
 * Get the labels of the departments that have the same parent.
 * @param departments
 * @param parentId
 */
const getLabelsByParent = (departments: Department[], parentId: number | undefined): Label[] => {
    return departments.filter(d => (!d.parentId ? !parentId : parentId === d.parentId)).map(d => d.name);
};

/**
 * Move a department into the same parent. Only the order of the departments is updated.
 * @param items
 * @param parentId
 * @param oldIndex
 * @param newIndex
 */
const reorderChildren = async ({
    items,
    parentId,
    oldIndex,
    newIndex,
}: {
    items: TreeViewItem[];
    parentId: number | undefined;
    oldIndex: number;
    newIndex: number;
}) => {
    // an undefined parentId means the department is at the root
    const children = parentId ? (findRecursiveItemById(items, parentId)?.children ?? []) : items;
    const itemToReorder = children[oldIndex];
    const updatedReorderItems = [...children];
    // remove the item from the old index and insert it at the new index
    updatedReorderItems.splice(oldIndex, 1);
    updatedReorderItems.splice(newIndex, 0, itemToReorder);

    try {
        const orderMutations = updatedReorderItems.map((item, index) => ({
            resourceId: item.id,
            order: index,
        }));
        await updateDepartmentOrders(orderMutations);
    } catch (error) {
        handleError(error);
    }
};
