import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/system';

// Extend TypographyProps to include the ellipsis prop
type ClampedTypographyProps = TypographyProps & {
    /**
     * Number of lines to clamp, or boolean for default clamping.
     * - Pass a number to specify the line count (e.g., 2 for two lines).
     * - Pass `true` for default clamping to 1 line.
     */
    ellipsis?: number | boolean;
};

const shouldForwardProp = (prop: string) => prop !== 'ellipsis';

// Styled Typography component with conditional ellipsis styles
export const ClampedTypography = styled(Typography, {
    shouldForwardProp,
})<ClampedTypographyProps>(({ ellipsis }) => {
    return {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: typeof ellipsis === 'number' ? ellipsis : 1,
    };
});
