import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { AsyncSelectFilter, AsyncTreeSelectFilterType, SelectFilter } from '@/components/filters-bar/FilterBar.type';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { mapDepartmentNodesToTreeSelectFilterOptions } from '@/domain/department/Department.utils';
import { PendingDayTimesheet } from '@/domain/timesheet/Timesheet.model';
import { useGetAreas } from '@/hooks/area/Area.hook';
import { useGetDepartmentsNodes } from '@/hooks/department/Department.hook';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { getLabelTranslation } from '@/utils/language.util';
import sortBy from 'lodash.sortby';
import uniqBy from 'lodash.uniqby';

export type TimesheetPendingFilter = SelectFilter | AsyncSelectFilter | AsyncTreeSelectFilterType;
export const useTimesheetsPendingPageFilters = (
    pendingDayTimesheets: PendingDayTimesheet[],
    enable: boolean,
): [filters: TimesheetPendingFilter[], (filters: TimesheetPendingFilter[]) => void] => {
    function uniqSortBy<T>(array: (T | undefined)[] | null | undefined, sortByFunctions: ((item: T) => string)[]): T[] {
        return sortBy(
            uniqBy(
                array?.filter((item): item is T => !!item),
                'id',
            ),
            sortByFunctions.map((_, index) => `0.${index}`),
        );
    }

    const uniqueLocations = uniqSortBy(
        pendingDayTimesheets.flatMap(dayTimesheet => dayTimesheet.employee?.currentEmployments?.map(employment => employment.location) || []),
        [o => o?.name],
    );

    const uniqueDepartments = uniqSortBy(
        pendingDayTimesheets.flatMap(dayTimesheet => dayTimesheet.employee?.currentEmployments?.map(employment => employment.department) || []),
        [o => getLabelTranslation(o.name)],
    );

    const uniqueJobs = uniqSortBy(
        pendingDayTimesheets.flatMap(dayTimesheet => dayTimesheet.employee?.currentEmployments?.map(employment => employment.job) || []),
        [o => getLabelTranslation(o.name)],
    );

    const { refetch: refetchDepartments } = useGetDepartmentsNodes(undefined, { enabled: false });
    const { refetch: refetchAreas } = useGetAreas(undefined, { enabled: false });
    const { refetch: refetchEmployees } = useGetEmployees(undefined, { enabled: false });

    const filters: TimesheetPendingFilter[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'SYNC',
            options: uniqueLocations?.map(location => ({
                label: location.name,
                value: location.id,
            })),
            key: 'locationIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'tree-multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                // we need to get the structure of the departments to be able to display as a tree
                const { data = [] } = await refetchDepartments();
                const departmentIdsToFilter = uniqueDepartments.map(d => d.id);
                return mapDepartmentNodesToTreeSelectFilterOptions(data, departmentIdsToFilter);
            },
            key: 'departmentIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'SYNC',
            options: uniqueJobs?.map(job => ({
                label: getLabelTranslation(job.name),
                value: job.id,
            })),
            key: 'jobIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: 'Areas',
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const { data = [] } = await refetchAreas();
                return data.map(area => ({
                    label: area.name,
                    value: area.id,
                }));
            },
            key: 'areaIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_MANAGER' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const { data = [] } = await refetchEmployees();
                return data.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            key: 'managerIds',
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return useFiltersStorage('pending-timesheets-filters', enable ? filters : []);
};
