import { Chip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EmployeeReviewStatus } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';

type Props = {
    status: EmployeeReviewStatus;
};

const getStatusColor = (status: EmployeeReviewStatus) => {
    switch (status) {
        case EmployeeReviewStatus.INPUT_NEEDED:
            return 'default';
        case EmployeeReviewStatus.DISCUSSION_STARTED:
            return 'info';
        case EmployeeReviewStatus.DISCUSSION_SUBMITTED:
            return 'warning';
        case EmployeeReviewStatus.EMPLOYEE_APPROVED:
            return 'success';
        case EmployeeReviewStatus.CANCELLED:
            return 'error';
        case EmployeeReviewStatus.CLOSED:
            return 'success';
    }
};

export const SummaryStatusChip: FC<Props> = ({ status }) => {
    const { t } = useTranslation();
    return <Chip color={getStatusColor(status)} size='small' label={t('reviews.employee_review.status_COMMON', { context: status })} />;
};
