import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { EmployeeReviewStatus } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { EmployeeReview, EmployeeReviewer, UpdateEmployeeReviewMutation } from '@/domain/employee-review/EmployeeReview.model';
import {
    closeEmployeeReview,
    getEmployeeReviewContributorType,
    getEmployeeReviewNavigationLink,
    isManagerReviewSubmitted,
    startEmployeeReviewDiscussion,
} from '@/domain/employee-review/EmployeeReview.service';
import { Employee } from '@/domain/employee/Employee.model';
import { Review } from '@/domain/review/Review.model';
import { isManagerReviewRequired } from '@/domain/review/Review.service';
import { EmployeeReviewChangeStatusDialog } from '@/page/review/employee-review-change-status-dialog/EmployeeReviewChangeStatusDialog';
import { PreviewDialog } from '@/page/review/employee-review-preview-dialog/PreviewDialog';
import { SendReminderDialog } from '@/page/review/employee-review-reminder-dialog/SendReminderDialog';
import { StartDiscussionDialog } from '@/page/review/employee-review-start-discussion-dialog/StartDiscussionDialog';
import { SummaryStatusChip } from '@/page/review/employee-review-summary-status-chip/SummaryStatusChip';
import { UpdateReviewersDialog } from '@/page/review/employee-review-update-reviewers-dialog/UpdateReviewersDialog';
import { useCurrentEmployee } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack, TextField } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-enterprise';
import { Tick02Icon } from 'hugeicons-react';
import { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type EmployeeReviewCycleListProps = {
    employeeReviews: EmployeeReview[];
    filterControls?: ReactElement;
    onUpdate: () => void;
    onUpdateReviewers: (request: UpdateEmployeeReviewMutation, employeeReviewId: number) => void;
    agGridWrapper: ReturnType<typeof useAgGridWrapper<EmployeeReview>>;
    isSearchAvailable?: boolean;
    showReviewNameColumn?: boolean;
    showContextMenu?: boolean;
    disableSelection?: boolean;
    review?: Review;
};

// TODO: this component is probably redundant with EmployeeReviews.tsx and maybe also with EmployeeReviewTaskTable/EmployeeReviewProfileTable ?
// why we have so many components that are so similar?
export const EmployeeReviewCycleList: FC<EmployeeReviewCycleListProps> = ({
    isSearchAvailable = false,
    filterControls,
    employeeReviews,
    onUpdate,
    onUpdateReviewers,
    agGridWrapper,
    showReviewNameColumn = false,
    showContextMenu = false,
    disableSelection = false,
    review,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [employeeReview, setEmployeeReview] = useState<EmployeeReview>();
    const [openStartDiscussionDialog, setOpenStartDiscussionDialog] = useState<boolean>(false);

    const [previewDialogOpen, setPreviewDialogOpen] = useState<boolean>(false);
    const [updateReviewersDialogOpen, setUpdateReviewersDialogOpen] = useState<boolean>(false);

    const [reviewToClose, setReviewToClose] = useState<EmployeeReview>();
    // TODO put this state in the dialog
    const [comment, setComment] = useState<string>('');
    const [activeEmployeeReview, setActiveEmployeeReview] = useState<EmployeeReview>();
    // Todo: It's better to have only one state than have an open state for each dialog
    const [openSendReminderDialog, setOpenSendReminderDialog] = useState<boolean>(false);
    const [activeEmployeeReviewToChangeStatus, setActiveEmployeeReviewToChangeStatus] = useState<EmployeeReview>();

    const currentEmployee = useCurrentEmployee();

    const hasPeers = employeeReviews?.some(review => review?.peerReviewers?.length > 0);
    const hasUpwards = employeeReviews?.some(review => review?.upwardReviewers?.length > 0);
    const reviewNameColumn: ColDef<EmployeeReview> = {
        field: 'review.name',
        headerName: t('reviews.employee_reviews_table.review_name'),
        type: 'label',
    };

    const getReviewersRatio = (reviewers: EmployeeReviewer[]) => {
        const totalReviewers = reviewers.length;
        const reviewedReviewers = reviewers.filter(reviewer => reviewer.reviewed).length;

        if (totalReviewers === 0) {
            return '';
        }
        return `${reviewedReviewers}/${totalReviewers}`;
    };

    const peerReviewsColumn: ColDef<EmployeeReview> = {
        field: 'peerReviewers',
        headerName: t('reviews.employee_reviews_table.peer_reviews'),
        valueGetter: ({ data }) => (data?.peerReviewers ? getReviewersRatio(data.peerReviewers) : ''),
    };

    const upwardReviewsColumn: ColDef<EmployeeReview> = {
        field: 'upwardReviewers',
        headerName: t('reviews.employee_reviews_table.upward_reviews'),
        valueGetter: ({ data }) => (data?.upwardReviewers ? getReviewersRatio(data.upwardReviewers) : ''),
    };

    const endDateColumn: ColDef<EmployeeReview> = {
        field: 'review.endDate',
        headerName: t('reviews.employee_reviews_table.end_date'),
        type: 'date',
    };

    const contextMenuRender = ({ data }: { data: EmployeeReview }) => {
        const menuItems = [
            {
                title: t('reviews.employee_review.preview_template'),
                onClick: () => {
                    setActiveEmployeeReview(data);
                    setPreviewDialogOpen(true);
                },
            },
            {
                title: t('reviews.employee_review.update_reviewers'),
                onClick: () => {
                    setActiveEmployeeReview(data);
                    setUpdateReviewersDialogOpen(true);
                },
            },
            {
                title: t('reviews.employee_review.change_step'),
                onClick: () => {
                    setActiveEmployeeReviewToChangeStatus(data);
                },
            },
        ];

        return <BasicMenu items={menuItems} />;
    };

    const contextMenuColumn: ColDef<EmployeeReview> = {
        type: 'actionMenu',
        cellRenderer: ({ data }: ICellRendererParams<EmployeeReview>) => (data ? contextMenuRender({ data }) : undefined),
    };

    const statusColumn: ColDef<EmployeeReview> = {
        field: 'status',
        headerName: t('reviews.employee_review.status'),
        cellRenderer: StatusCell,
        cellClass: ['display-flex'],
    };

    const addReviewerColumns = (reviewerColumns: ColDef<EmployeeReview>[]) => {
        if (hasPeers) {
            reviewerColumns.push(peerReviewsColumn);
        }
        if (hasUpwards) {
            reviewerColumns.push(upwardReviewsColumn);
        }
        reviewerColumns.push(endDateColumn);
    };

    const renderDynamicColumns = (): ColDef<EmployeeReview>[] => {
        const reviewerColumns: ColDef<EmployeeReview>[] = [];
        addReviewerColumns(reviewerColumns);

        if (showReviewNameColumn) {
            reviewerColumns.push(reviewNameColumn);
        }
        reviewerColumns.push(statusColumn);
        if (showContextMenu) {
            reviewerColumns.push(contextMenuColumn);
        }
        return reviewerColumns;
    };

    const openDiscussionDialog = (employeeReview: EmployeeReview) => {
        setEmployeeReview(employeeReview);
        setOpenStartDiscussionDialog(true);
    };

    const shouldShowDiscussionDialog = (employeeReview: EmployeeReview, currentEmployee: Employee) => {
        const contributorType = getEmployeeReviewContributorType(employeeReview, currentEmployee);
        const isInInputNeededStatus = employeeReview.status === EmployeeReviewStatus.INPUT_NEEDED;
        const isContributorTypeManager = contributorType === 'MANAGER';

        if (!employeeReview.review) {
            throw new Error('Employee review review should not be undefined');
        }

        return (
            isContributorTypeManager &&
            isInInputNeededStatus &&
            !(isManagerReviewRequired(employeeReview.review) && !isManagerReviewSubmitted(employeeReview, currentEmployee))
        );
    };

    const handleRowClick = (employeeReview: EmployeeReview | undefined, currentEmployee: Employee) => {
        if (!employeeReview) {
            throw new Error('Employee review should not be undefined');
        }

        const link = getEmployeeReviewNavigationLink(employeeReview, currentEmployee);
        if (shouldShowDiscussionDialog(employeeReview, currentEmployee)) {
            openDiscussionDialog(employeeReview);
            return;
        }
        if (!link) {
            return;
        }
        navigate(link);
    };

    const tableOptions: ColDef<EmployeeReview>[] = [
        {
            field: 'employee',
            type: 'employee',
            cellRendererParams: () => ({
                cellNavDisabled: true,
            }),
        },
        {
            headerName: t('reviews.employee_reviews_table.manager'),
            valueGetter: ({ data }) => data?.managers.flatMap(manager => manager.employee),
            type: 'stackedAvatars',
            cellRendererParams: () => ({
                cellNavDisabled: true,
            }),
        },
        {
            field: 'managers',
            headerName: t('reviews.employee_reviews_table.reviewed_by_one_manager'),
            cellRenderer: ManagerReviewedCell,
            comparator: (managers: EmployeeReviewer[]) => (managers.some(manager => manager.reviewed) ? -1 : 1),
            cellClass: 'display-flex',
        },
        {
            field: 'selfReviewed',
            headerName: t('reviews.employee_reviews_table.self_review'),
            cellRenderer: SelfReviewedCell,
            comparator: (selfReviewed: boolean) => (selfReviewed ? -1 : 1),
            cellClass: 'display-flex',
        },
        ...renderDynamicColumns(),
    ];

    const onStartDiscussion = (employeeReview: EmployeeReview) => {
        startEmployeeReviewDiscussion(employeeReview.id)
            .then(() => {
                showSnackbar(t('reviews.review_summary.messages.discussion_started'), 'success');
                setOpenStartDiscussionDialog(false);
                navigate(`/reviews/${employeeReview.id}/manager-summary`);
            })
            .catch(handleError);
    };

    const onReviewersChange = (request: UpdateEmployeeReviewMutation, employeeReviewId: number) => {
        onUpdateReviewers(request, employeeReviewId);
        setUpdateReviewersDialogOpen(false);
    };

    const handleReviewClose = async () => {
        try {
            if (!reviewToClose?.id) {
                throw new Error('Review to close should not be undefined');
            }

            await closeEmployeeReview(reviewToClose.id, comment);
            setReviewToClose(undefined);
            setComment('');

            const reviewIndex = employeeReviews?.findIndex(review => review.id === reviewToClose.id);

            employeeReviews[reviewIndex].status = EmployeeReviewStatus.CLOSED;
            onUpdate();
            showSnackbar(t('reviews.messages.employee_review_closed'), 'success');
        } catch {
            showSnackbar(t('reviews.manage_reviews.close_review_error'), 'error');
        }
    };

    return (
        <Stack flex={1} gap={2}>
            <Stack direction='row' justifyContent='space-between' alignItems='flex-end'>
                <Stack>{filterControls}</Stack>
                {isSearchAvailable && <DatatableAdditionalAction quickFilter={agGridWrapper.quickFilter} />}
            </Stack>
            <AgGridWrapper<EmployeeReview>
                initRef={agGridWrapper.setGridRef}
                rowData={employeeReviews}
                columnDefs={tableOptions}
                rowSelection={disableSelection ? undefined : { mode: 'multiRow' }}
                getRowId={params => params.data.id.toString()}
                onRowClicked={({ data, event }) => {
                    if (!event || !currentEmployee) {
                        return;
                    }

                    if (!event.defaultPrevented && data?.status !== EmployeeReviewStatus.CANCELLED) {
                        return handleRowClick(data, currentEmployee);
                    }
                }}
                compact={false}
                loading={false}
                toolbarActions={<Button onClick={() => setOpenSendReminderDialog(true)}>{t('reviews.reminder.send_reminder')}</Button>}
            />
            {activeEmployeeReview && <PreviewDialog open={previewDialogOpen} activeReview={activeEmployeeReview} onClose={() => setPreviewDialogOpen(false)} />}
            {updateReviewersDialogOpen && activeEmployeeReview && (
                <UpdateReviewersDialog
                    open={updateReviewersDialogOpen}
                    activeEmployeeReview={activeEmployeeReview}
                    onSave={onReviewersChange}
                    onClose={() => setUpdateReviewersDialogOpen(false)}
                />
            )}
            {employeeReview && openStartDiscussionDialog && (
                <StartDiscussionDialog
                    employeeReview={employeeReview}
                    onStartDiscussion={() => onStartDiscussion(employeeReview)}
                    onClose={() => setOpenStartDiscussionDialog(false)}
                />
            )}
            {reviewToClose && (
                <DialogWrapper open onClose={() => setReviewToClose(undefined)} header={t('reviews.manage_reviews.close_review')}>
                    <Stack component={DialogContent}>
                        <FormControlLabel
                            label={t('general.comment')}
                            value={comment}
                            control={<TextField fullWidth onChange={event => setComment(event.target.value)} multiline minRows={4} />}
                        />
                    </Stack>
                    <DialogActions>
                        <Button onClick={handleReviewClose} fullWidth>
                            {t('general.save')}
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            )}

            {openSendReminderDialog && review && (
                <SendReminderDialog
                    open={true}
                    onClose={() => setOpenSendReminderDialog(false)}
                    review={review}
                    employeeReviews={agGridWrapper.gridRef?.current?.api.getSelectedRows() ?? []}
                />
            )}
            {activeEmployeeReviewToChangeStatus && (
                <EmployeeReviewChangeStatusDialog
                    employeeReview={activeEmployeeReviewToChangeStatus}
                    onClose={() => {
                        setActiveEmployeeReviewToChangeStatus(undefined);
                        onUpdate();
                    }}
                    open
                />
            )}
        </Stack>
    );
};
const ManagerReviewedCell = ({ value }: { value: EmployeeReviewer[] }) => {
    const reviewed = value.some(manager => manager.reviewed);
    if (reviewed) {
        return <Tick02Icon />;
    }
};

const SelfReviewedCell = ({ value }: { value: boolean }) => {
    return value && <Tick02Icon />;
};

const StatusCell = ({ value }: { value: EmployeeReview['status'] }) => <SummaryStatusChip status={value} />;
