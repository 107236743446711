import { getAppConfig } from '@/config/config';
import { DayPeriod } from '@/domain/date/Date.model';
import { DurationUnit } from '@/i18n/i18n';
import { getLocale, getUserLanguage } from '@/utils/language.util';

import { getNull } from '@/utils/object.util';
import {
    addDays as addDaysFNS,
    addHours as addHoursFNS,
    addMinutes as addMinutesFNS,
    addMonths as addMonthsFNS,
    addSeconds as addSecondsFNS,
    addWeeks as addWeeksFNS,
    addYears as addYearsFNS,
    compareAsc as compareAscFNS,
    compareDesc as compareDescFNS,
    differenceInDays as differenceInDaysFNS,
    differenceInHours as differenceInHoursFNS,
    differenceInMinutes as differenceInMinutesFNS,
    differenceInSeconds as differenceInSecondsFNS,
    differenceInYears as differenceInYearsFNS,
    differenceInCalendarYears as differenceInCalendarYearsFNS,
    Duration as DurationFNS,
    endOfDay as endOfDayFNS,
    endOfMonth as endOfMonthFNS,
    endOfWeek as endOfWeekFNS,
    endOfYear as endOfYearFNS,
    format,
    formatDuration as formatDurationFNS,
    FormatOptions,
    getDaysInMonth as getDaysInMonthFNS,
    getHours as getHoursFNS,
    getMinutes as getMinutesFNS,
    getWeek as getWeekFNS,
    getYear as getYearFNS,
    GetYearOptions,
    intervalToDuration as intervalToDurationFNS,
    isAfter,
    isBefore,
    isSameDay as isSameDayFNS,
    isValid as isValidFNS,
    Locale,
    set,
    setHours as setHoursFNS,
    setMinutes as setMinutesFNS,
    setMonth as setMonthFNS,
    startOfDay as startOfDayFNS,
    startOfMonth as startOfMonthFNS,
    startOfWeek as startOfWeekFNS,
    StartOfWeekOptions,
    startOfYear as startOfYearFNS,
    subDays as subDaysFNS,
    subHours as subHoursFNS,
    subMilliseconds,
    subMinutes as subMinutesFNS,
    subMonths as subMonthsFNS,
    subYears as subYearsFNS,
} from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import i18next, { t } from 'i18next';
import { TestConfig } from 'yup';

const config = getAppConfig();

export const MIN_DATE: LocalDate = '1900-01-01';
export const MAX_DATE: LocalDate = '2100-12-31';

export enum MONTHS {
    JANUARY = 'JANUARY',
    FEBRUARY = 'FEBRUARY',
    MARCH = 'MARCH',
    APRIL = 'APRIL',
    MAY = 'MAY',
    JUNE = 'JUNE',
    JULY = 'JULY',
    AUGUST = 'AUGUST',
    SEPTEMBER = 'SEPTEMBER',
    OCTOBER = 'OCTOBER',
    NOVEMBER = 'NOVEMBER',
    DECEMBER = 'DECEMBER',
}

export const getMonthTranslationKey = (month: MONTHS): string => {
    return 'general.month.' + month.toLocaleLowerCase();
};

export const getMonthFormat = (monthIndex: number, formatString = 'MMMM'): string => {
    const date = startOfMonth(getTodayDate());
    date.setMonth(monthIndex);
    return format(date, formatString);
};

export enum DayOfWeek {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

export const DAY_OF_WEEK_SORT = Object.values(DayOfWeek);

/**
 * Converts a total number of minutes into a formatted string of hours and minutes.
 *
 * @param {number} durationInMinutes - The total number of minutes to convert.
 * @returns {string} - A formatted string representing the hours and minutes.
 */
export const formatDuration = (durationInMinutes: number): string => {
    const padTo2Digits = (num: number): string => {
        return num.toString().padStart(2, '0');
    };

    // Check if the input is valid
    if (!durationInMinutes || isNaN(durationInMinutes)) {
        return '';
    }

    // Determine if the number is negative
    let isNegativeNumber = false;
    if (durationInMinutes < 0) {
        isNegativeNumber = true;
    }

    // Calculate minutes and hours
    const nonRoundedMinutes = isNegativeNumber ? (durationInMinutes * -1) % 60 : durationInMinutes % 60;
    const minutes = Math.round(nonRoundedMinutes * 100) / 100; // round to 2 decimal places to avoid floating point errors
    const hours = Math.floor(isNegativeNumber ? (durationInMinutes * -1) / 60 : durationInMinutes / 60);

    // Format the result based on hours and minutes
    if (hours > 0 && minutes > 0) {
        return `${isNegativeNumber ? '-' : ''}${hours}h${padTo2Digits(minutes)}`;
    } else if (minutes === 0) {
        return `${isNegativeNumber ? '-' : ''}${hours}h`;
    } else {
        return `${isNegativeNumber ? '-' : ''}${padTo2Digits(minutes)}m`;
    }
};

/**
 * Converts a given time in minutes to a formatted string in 'HH:mm' format.
 * Example: 140 minutes => '02:20'
 *
 * @param durationInMinutes - The time duration in minutes.
 * @returns A string representing the time in 'HH:mm' format.
 */
export const formatDurationInTime = (durationInMinutes: number): LocalTime => {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;

    const hoursSS = hours.toString().padStart(2, '0');
    const minutesSS = minutes.toString().padStart(2, '0');

    return `${hoursSS}:${minutesSS}`;
};

// TODO : Check difference between formatDurationInHours and formatDurationInTime https://rogerhr.atlassian.net/browse/RP-5321

/**
 * Converts a given time in minutes to a formatted string in 'HH:mm' format.
 * Example: 140 minutes => '02:20'
 *
 * @param durationInMinutes - The time duration in minutes.
 * @returns A string representing the time in 'HH:mm' format.
 */
export const formatDurationInHours = (durationInMinutes: number): string => {
    return i18next.t('duration.formatDuration', {
        duration: durationInMinutes / 60,
        unit: DurationUnit.HOURS,
    });
};

/**
 * Converts a given time in LocalTime (09:30) to a date object with the current date.
 * @param time : ex: '09:30'
 */
export const getDateFromTimeFormat = <T extends LocalTime | undefined>(time: T): T extends undefined ? null : Date => {
    if (!time) {
        return getNull() as T extends undefined ? null : Date;
    }

    return setTime(getCurrentLocalDate(), time) as T extends undefined ? null : Date;
};

type OptionalTimeString<T> = T extends undefined | null ? undefined : LocalTime;

/**
 * Returns a formatted time string from a given Date object.
 *
 * @template T - The type of the input date, which can be Date, undefined, or null.
 * @param {T} date - The date object to format. If the date is invalid, undefined, or null, the function returns undefined.
 * @returns {OptionalTimeString<T>} - A formatted time string in 'HH:mm' format if the date is valid, otherwise undefined.
 */
export const getTimeFormatFromDate = <T extends Date | undefined | null>(date: T): OptionalTimeString<T> => {
    if (!date || !isValid(date)) {
        return undefined as OptionalTimeString<T>;
    }

    return format(date, 'HH:mm') as OptionalTimeString<T>;
};

/**
 * Converts a time string in 'HH:mm' format to the total number of minutes.
 *
 * @param {string} time - The time string to convert. It should be in 'HH:mm' format.
 * @returns {number | undefined} - The total number of minutes if the input is valid, otherwise undefined.
 */
export const getDurationFromTime = (time: LocalTime | undefined): number => {
    if (!isValidTime(time)) {
        return 0;
    }

    const { hours, minutes } = splitHoursMinutes(time);
    return hours * 60 + minutes;
};

export const isAfterTime = (time: LocalTime, timeToCompare: LocalTime): boolean => {
    const duration = getDurationFromTime(time);
    const durationToCompare = getDurationFromTime(timeToCompare);
    return duration > durationToCompare;
};

export const isBeforeTime = (time: LocalTime, timeToCompare: LocalTime): boolean => {
    const duration = getDurationFromTime(time);
    const durationToCompare = getDurationFromTime(timeToCompare);
    return duration < durationToCompare;
};

export const isSameTime = (time: LocalTime, timeToCompare: LocalTime): boolean => {
    const duration = getDurationFromTime(time);
    const durationToCompare = getDurationFromTime(timeToCompare);
    return duration === durationToCompare;
};

export type HoursMinutes = {
    hours: number;
    minutes: number;
};

/**
 * Converts a duration in minutes to an HoursMinutes object.
 *
 * @param {number} durationInMinutes - The duration in minutes.
 * @returns {HoursMinutes} - An object containing the hours and minutes.
 */
export const getHoursMinutesFromMinutes = (durationInMinutes: number): HoursMinutes => {
    const hours = Math.trunc(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return { hours, minutes };
};

export const getMinutesFromHoursMinutes = (hoursMinutes: HoursMinutes): number => {
    return hoursMinutes.hours * 60 + hoursMinutes.minutes;
};

export const getTotalMinutes = (date: Date): number => {
    if (!isValidDate(date)) {
        return 0;
    }
    return getHours(date) * 60 + getMinutes(date);
};

/**
 * Splits a LocalTime string into hours and minutes.
 * The function does not check if hours and minutes are the right range.
 * @param hoursMinutes - a LocalTime string '09:30' or '09:30:00'
 * @returns {HoursMinutes} - an object containing the hours and minutes.
 */
// TODO return an object Duration with hours and minutes as number | undefined
//  to handle case where LocalTime contains something like 'bla:bla'
export const splitHoursMinutes = (hoursMinutes: LocalTime): HoursMinutes => {
    return { hours: parseInt(hoursMinutes?.split(':')[0]), minutes: parseInt(hoursMinutes?.split(':')[1]) };
};

export const clearSeconds = (time: string | LocalTime | undefined): string => {
    if (!time) {
        return '';
    }
    return time.split(':').slice(0, 2).join(':');
};

/**
 * Converts a given date and time to a UTC date.
 *
 * @param date - The local date to be converted
 * @param time - The local time to be converted
 * @returns The UTC date object
 * @deprecated use setTime instead
 */
export const getDateTimeUTCFromDateAndTime = (date: LocalDate, time: Date): Date => {
    // convert date into default timezone and then in UTC
    const newUTCDate = getUTCFromZonedTime(toDate(date));
    const newUTCTime = getUTCFromZonedTime(time);
    newUTCDate.setUTCHours(newUTCTime.getUTCHours(), newUTCTime.getUTCMinutes(), newUTCTime.getUTCSeconds(), newUTCTime.getUTCMilliseconds());
    return newUTCDate;
};

/**
 * Sets the hours and minutes of a given LocalDate object and returns a new Date object.
 *
 * @param {LocalDate} date - The LocalDate object to be modified.
 * @param {number} hours - The hours to set.
 * @param {number} minutes - The minutes to set.
 * @param {number} sec - The seconds to set. Default is 0.
 * @param {number} ms - The milliseconds to set. Default is 0.
 * @returns {Date} - A new Date object with the specified hours and minutes.
 */
export const setHoursMinutes = (date: LocalDate, hours: number, minutes: number, sec = 0, ms = 0): Date => {
    let newDate = toDate(date);
    newDate = set(newDate, { hours, minutes, seconds: sec, milliseconds: ms });
    return newDate;
};

/**
 * Sets the hours and minutes of a given LocalDate object and returns a new Date object.
 * @param date - The LocalDate object to be modified.
 * @param time - The LocalTime object to be set.
 * @returns A new Date object with the specified hours and minutes.
 */
export const setTime = (date: LocalDateOrDate, time: LocalTime): Date => {
    const { hours, minutes } = splitHoursMinutes(time);
    if (typeof date === 'string') {
        return setHoursMinutes(date, hours, minutes);
    }
    return setHoursMinutes(formatToLocalDate(date), hours, minutes);
};

/**
 * Checks if the provided LocalTime is a valid time.
 *
 * @param {Nullable<LocalTime>} time - The time to be validated.
 * @returns {time is LocalTime} - Returns true if the time is a valid LocalTime object, otherwise false.
 */
export const isValidTime = (time: Nullable<LocalTime>): time is LocalTime => {
    if (!time) {
        return false;
    }

    const { hours, minutes } = splitHoursMinutes(time);
    return !(isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59);
};

/**
 * Checks if the provided date is a valid Date object within a specified range.
 *
 * @param {Nullable<Date> | Nullable<LocalDate>} date - The date to be validated.
 * @returns {date is Date} - Returns true if the date is a valid Date object within the range, otherwise false.
 */
export const isValidDate = (date: Nullable<Date> | Nullable<LocalDate>): date is Date => {
    if (!date) {
        return false;
    }

    const isInValidRange = (currentDate: Date): currentDate is Date => {
        return currentDate >= toDate(MIN_DATE) && currentDate <= toDate(MAX_DATE);
    };

    if (typeof date === 'string') {
        return isInValidRange(toDate(date));
    }
    return isInValidRange(date);
};

export const formatRelativeDate = (
    endDate: Date | undefined,
    {
        dayPeriod = undefined,
        baseDate = new Date(),
        locale = getLocale(getUserLanguage()),
    }: {
        dayPeriod?: DayPeriod;
        baseDate?: Date;
        locale?: Locale;
    } = {},
): string => {
    if (!endDate || !isValid(endDate)) {
        return '';
    }

    // differenceInDays checks the difference between the two dates and returns the number of full days
    // as we only care for the difference in days, we need to move the hours to the start of the day so that both of them are the same
    const diffInDays = differenceInDays(startOfDay(endDate), startOfDay(baseDate));
    const isToday = diffInDays === 0;
    const isTomorrow = diffInDays === 1;
    const isThisWeek = diffInDays > 0 && diffInDays < 7;

    if (isToday) {
        return i18next.t('duration.today', { context: dayPeriod });
    }
    if (isTomorrow) {
        return i18next.t('duration.tomorrow', { context: dayPeriod });
    } else if (isThisWeek) {
        return format(endDate, config.DEFAULT_DAY_FORMAT, { locale });
    } else {
        return format(endDate, config.DEFAULT_DATE_MONTH_DAY_FORMAT, { locale });
    }
};

/**
 *
 *  Important functions for date manipulation
 *
 **/

// TODO replace date-fns-tz with date-fns/tz
export const getUTCFromZonedTime = (date: Date, timeZone?: string): Date => fromZonedTime(date, timeZone ?? config.DEFAULT_TIMEZONE);

export const getZonedTimeFromUTC = (date: Date, timeZone?: string): Date => toZonedTime(date, timeZone ?? config.DEFAULT_TIMEZONE);

// API date string to Date
export const convertUTCIsoStringToDate = <T extends string | undefined | null>(date: T): T extends undefined ? undefined : Date => {
    if (!date || !isValid(new Date(date))) {
        return undefined as T extends undefined ? undefined : Date;
    }
    return getZonedTimeFromUTC(new Date(date)) as T extends undefined ? undefined : Date;
};

// Date to API date string
export const convertDateToUTCIsoString = <T extends Date | undefined | null>(date: T): T extends undefined ? undefined : string => {
    if (!date) {
        return undefined as T extends undefined ? undefined : string;
    }
    return getUTCFromZonedTime(date).toISOString() as T extends undefined ? undefined : string;
};

// LocalDate to Date
export const toDate = <T extends LocalDate | undefined | null>(date: T, timezone?: string): T extends undefined ? undefined : Date => {
    if (!date) {
        return undefined as T extends undefined ? undefined : Date;
    }
    return toZonedTime(new Date(date), timezone ?? config.DEFAULT_TIMEZONE) as T extends undefined ? undefined : Date;
};

export const formatToLocalDate = <T extends Date | undefined | null>(date: T): T extends undefined | null ? undefined : LocalDate => {
    if (!date || !isValid(date)) {
        return undefined as T extends undefined | null ? undefined : LocalDate;
    }
    return format(date, config.API_REQUEST_DATE_FORMAT) as T extends undefined | null ? undefined : LocalDate;
};

export const getCurrentLocalDate = (): LocalDate => {
    return formatToLocalDate(new Date());
};

export const getTodayDate = (): Date => toZonedTime(new Date(), config.DEFAULT_TIMEZONE);

/**
 * End of important functions for date manipulation
 */

export const formatInDefaultDate = (date?: Date | LocalDate): string => {
    if (!date) {
        return '';
    }

    if (typeof date === 'string') {
        return isValid(toDate(date)) ? format(toDate(date), config.DEFAULT_DATE_FORMAT) : '';
    }
    return isValid(date) ? format(date, config.DEFAULT_DATE_FORMAT) : '';
};

export const formatInDayMonthYear = (date?: LocalDate): string => {
    if (!date) {
        return '';
    }

    return isValid(toDate(date)) ? format(toDate(date), config.DEFAULT_DATE_MONTH_DAY_FORMAT) : '';
};

export const formatInDefaultHours = (date?: Date): string => {
    if (!date) {
        return '';
    }

    return isValid(date) ? format(date, config.DEFAULT_HOURS_FORMAT) : '';
};

export const formatInDefaultWeekName = (date?: LocalDate | Date): string => {
    if (!date) {
        return '';
    }

    if (typeof date === 'string') {
        return isValid(toDate(date)) ? format(toDate(date), config.DEFAULT_DATE_WEEK_NAME) : '';
    }
    return isValid(date) ? format(date, config.DEFAULT_DATE_WEEK_NAME) : '';
};

export const formatDate = (date: LocalDate | Date, formatStr: string, options?: FormatOptions): string => {
    if (typeof date === 'string') {
        return format(toDate(date), formatStr, options);
    }
    return format(date, formatStr, options);
};

export const formatInMonthYear = (date: LocalDate): MonthYear => {
    return format(date, 'MM-yyyy') as MonthYear;
};

export const formatInDefaultLiteral = (date: Date, locale: Locale): string =>
    date && isValid(date) ? format(new Date(date), getAppConfig().DEFAULT_LITERAL_DATE, { locale }) : '';

export const formatDurationForDates = (start: LocalDate, end: LocalDate): string => {
    return formatDurationFNS(intervalToDurationFNS({ start, end }), {
        format: ['years', 'months', 'weeks', 'days'],
        delimiter: ', ',
    });
};

export const getAllYears = (start = 2021): number[] => {
    const end = getYear(getTodayDate()) + 1;
    return Array(end - start + 1)
        .fill(1)
        .map((_, idx) => start + idx);
};

/*
    From minutes to days
    If it's a whole number, return the number of days
    If it's a decimal, return the number of days with 2 decimal places
 */
export const convertMinutesToDays = (minutes: number): number => {
    const days = minutes / 60 / 24;
    return Number.isInteger(days) ? days : Number(days.toFixed(2));
};

export const convertToUTCStartOfDay = (date: Date): Date => fromZonedTime(startOfDayFNS(date), 'utc');

export const getLocalDateTestConfig = (): TestConfig<Nullable<LocalDate>> => {
    return {
        message: t('general.validations.valid_date'),
        test: date => !date || isValidDate(date),
    };
};

export const getLocalDateMinTestConfig = (minDate: LocalDate, message?: string): TestConfig<Nullable<LocalDate>> => {
    return {
        name: 'end_date_after_start_date',
        message: message ?? t('general.validations.min', { min: formatInDefaultDate(minDate) }),
        test: endDate => {
            if (!endDate) {
                return true;
            }
            return isAfterOrEqualDate(toDate(endDate), toDate(minDate));
        },
    };
};

export const getLocalDateMaxTestConfig = (maxDate: LocalDate, message?: string): TestConfig<Nullable<LocalDate>> => {
    return {
        name: 'end_date_before_max_date',
        message: message ?? t('general.validations.max', { max: formatInDefaultDate(maxDate) }),
        test: endDate => {
            if (!endDate) {
                return true;
            }
            return isAfterOrEqualDate(toDate(maxDate), toDate(endDate));
        },
    };
};

type LocalDateOrDate = LocalDate | Date;

/**
 * Check if the date is valid. This function does not check the range of the date (> 1900).
 * Use isValidDate for this case.
 * @param date
 * @returns true if the date is valid, otherwise false
 */
export const isValid = (date: LocalDateOrDate): boolean => {
    if (typeof date === 'string') {
        return isValidFNS(toDate(date));
    }
    return isValidFNS(date);
};
export const addSeconds = (date: LocalDateOrDate, seconds: number): Date => {
    if (typeof date === 'string') {
        return addSecondsFNS(toDate(date), seconds);
    }
    return addSecondsFNS(date, seconds);
};

export const addMinutes = (date: LocalDateOrDate, minutes: number): Date => {
    if (typeof date === 'string') {
        return addMinutesFNS(toDate(date), minutes);
    }
    return addMinutesFNS(date, minutes);
};

export const addHours = (date: LocalDateOrDate, hours: number): Date => {
    if (typeof date === 'string') {
        return addHoursFNS(toDate(date), hours);
    }
    return addHoursFNS(date, hours);
};

export const subHours = (date: LocalDateOrDate, hours: number): Date => {
    if (typeof date === 'string') {
        return subHoursFNS(toDate(date), hours);
    }
    return subHoursFNS(date, hours);
};

export const addDays = (date: LocalDateOrDate, days: number): Date => {
    if (typeof date === 'string') {
        return addDaysFNS(toDate(date), days);
    }
    return addDaysFNS(date, days);
};

export const addDaysAndFormat = (date: LocalDateOrDate, days: number): LocalDate => {
    return formatToLocalDate(addDays(date, days));
};

export const addWeeks = (date: LocalDateOrDate, weeks: number): Date => {
    if (typeof date === 'string') {
        return addWeeksFNS(toDate(date), weeks);
    }
    return addWeeksFNS(date, weeks);
};

export const addWeeksAndFormat = (date: LocalDateOrDate, weeks: number): LocalDate => {
    return formatToLocalDate(addWeeks(date, weeks));
};

export const addMonths = (date: LocalDateOrDate, months: number): Date => {
    if (typeof date === 'string') {
        return addMonthsFNS(toDate(date), months);
    }
    return addMonthsFNS(date, months);
};

export const addMonthsAndFormat = (date: LocalDateOrDate, months: number): LocalDate => {
    return formatToLocalDate(addMonths(date, months));
};

export const addYears = (date: LocalDateOrDate, years: number): Date => {
    if (typeof date === 'string') {
        return addYearsFNS(toDate(date), years);
    }
    return addYearsFNS(date, years);
};

export const addYearsAndFormat = (date: LocalDateOrDate, years: number): LocalDate => {
    return formatToLocalDate(addYears(date, years));
};

export const subMinutes = (date: LocalDateOrDate, minutes: number): Date => {
    if (typeof date === 'string') {
        return subMinutesFNS(toDate(date), minutes);
    }
    return subMinutesFNS(date, minutes);
};

export const subDays = (date: LocalDateOrDate, days: number): Date => {
    if (typeof date === 'string') {
        return subDaysFNS(toDate(date), days);
    }
    return subDaysFNS(date, days);
};
export const subDaysAndFormat = (date: LocalDateOrDate, days: number): LocalDate => {
    return formatToLocalDate(subDays(date, days));
};

export const subMonths = (date: LocalDateOrDate, months: number): Date => {
    if (typeof date === 'string') {
        return subMonthsFNS(toDate(date), months);
    }
    return subMonthsFNS(date, months);
};

export const subMonthsAndFormat = (date: LocalDateOrDate, months: number): LocalDate => {
    return formatToLocalDate(subMonths(date, months));
};

export const subYears = (date: LocalDateOrDate, years: number): Date => {
    if (typeof date === 'string') {
        return subYearsFNS(toDate(date), years);
    }
    return subYearsFNS(date, years);
};

export const startOfDay = (date: LocalDateOrDate): Date => {
    if (typeof date === 'string') {
        return startOfDayFNS(toDate(date));
    }
    return startOfDayFNS(date);
};
export const startOfWeek = (date: Date, option?: StartOfWeekOptions): Date => startOfWeekFNS(date, option);
export const startOfMonth = (date: Date): Date => startOfMonthFNS(date);
export const startOfYear = (date: Date): Date => startOfYearFNS(date);

export const endOfDay = (date: LocalDateOrDate): Date => {
    if (typeof date === 'string') {
        return endOfDayFNS(toDate(date));
    }
    return endOfDayFNS(date);
};
export const endOfWeek = (date: Date): Date => endOfWeekFNS(date);
export const endOfMonth = (date: Date): Date => endOfMonthFNS(date);
export const endOfYear = (date: Date): Date => endOfYearFNS(date);

export const getDaysInMonth = (date: Date): number => getDaysInMonthFNS(date);

export const getStartOfWeek = (date?: LocalDate, option?: StartOfWeekOptions): LocalDate =>
    formatToLocalDate(startOfWeekFNS(toDate(date ?? getCurrentLocalDate()), option));
export const getEndOfWeek = (date?: LocalDate, option?: StartOfWeekOptions): LocalDate =>
    formatToLocalDate(endOfWeekFNS(toDate(date ?? getCurrentLocalDate()), option));

export const getStartOfMonth = (date?: LocalDate): LocalDate => formatToLocalDate(startOfMonthFNS(toDate(date ?? getCurrentLocalDate())));
export const getEndOfMonth = (date?: LocalDate): LocalDate => formatToLocalDate(endOfMonthFNS(toDate(date ?? getCurrentLocalDate())));

export const getStartOfYear = (date?: LocalDate): LocalDate => formatToLocalDate(startOfYearFNS(toDate(date ?? getCurrentLocalDate())));
export const getEndOfYear = (date?: LocalDate): LocalDate => formatToLocalDate(endOfYearFNS(toDate(date ?? getCurrentLocalDate())));

export const isBeforeDate = <T extends LocalDateOrDate>(date: T, dateToCompare: T): boolean => isBefore(date, dateToCompare);
export const isAfterDate = <T extends LocalDateOrDate | Date>(date: T, dateToCompare: T): boolean => isAfter(date, dateToCompare);
export const isSameDate = <T extends LocalDateOrDate>(dateLeft: T, dateRight: T): boolean => isSameDayFNS(dateLeft, dateRight);

export const isAfterOrEqualDate = <T extends LocalDateOrDate>(date: T, dateToCompare: T): boolean => {
    return isAfterDate(date, dateToCompare) || isSameDate(date, dateToCompare);
};

export const isBeforeOrEqualDate = <T extends LocalDateOrDate>(date: T, dateToCompare: T): boolean => {
    return isBeforeDate(date, dateToCompare) || isSameDate(date, dateToCompare);
};

export const isBetweenDates = <T extends LocalDateOrDate>(date: T, startDate: T, endDate: T): boolean => {
    return isAfterOrEqualDate(date, startDate) && isBeforeOrEqualDate(date, endDate);
};

export const isFutureDate = (date: LocalDateOrDate): boolean => {
    if (typeof date === 'string') {
        return toDate(date) > getTodayDate();
    }

    return date > getTodayDate();
};

export const isPastDate = (date: LocalDateOrDate): boolean => {
    if (typeof date === 'string') {
        return toDate(date) < getTodayDate();
    }

    return date < getTodayDate();
};
export const isTodayDate = (date: LocalDateOrDate): boolean => {
    if (typeof date === 'string') {
        return isSameDayFNS(toDate(date), getTodayDate());
    }
    return isSameDayFNS(date, getTodayDate());
};

export const getTodayDateForTest = (): Date => fromZonedTime(getTodayDate(), getAppConfig().DEFAULT_TIMEZONE);
export const getTomorrow = (): LocalDate => addDaysAndFormat(getTodayDate(), 1);
export const getYesterday = (): LocalDate => subDaysAndFormat(getTodayDate(), 1);

export const getStartOfDayUTC = (date: LocalDateOrDate): string => {
    if (typeof date === 'string') {
        return convertToUTCStartOfDay(toDate(date)).toISOString();
    }
    return convertToUTCStartOfDay(date).toISOString();
};
export const getEndOfDayUTC = (date: LocalDateOrDate): string => {
    const startOfDayAfterUTC = convertToUTCStartOfDay(addDays(date, 1));
    return subMilliseconds(startOfDayAfterUTC, 1).toISOString();
};

export const differenceInSeconds = (dateLeft: Date, dateRight: Date): number => differenceInSecondsFNS(dateLeft, dateRight);

export const differenceInDays = <T extends LocalDateOrDate>(dateLeft: T, dateRight: T): number => {
    if (typeof dateLeft === 'string' && typeof dateRight === 'string') {
        return differenceInDaysFNS(toDate(dateLeft as LocalDate), toDate(dateRight as LocalDate));
    }
    return differenceInDaysFNS(dateLeft, dateRight);
};

export const differenceInYears = <T extends LocalDateOrDate>(dateLeft: T, dateRight: T): number => {
    if (typeof dateLeft === 'string' && typeof dateRight === 'string') {
        return differenceInYearsFNS(toDate(dateLeft as LocalDate), toDate(dateRight as LocalDate));
    }
    return differenceInYearsFNS(dateLeft, dateRight);
};

export const differenceInCalendarYears = <T extends LocalDateOrDate>(dateLeft: T, dateRight: T): number => {
    if (typeof dateLeft === 'string' && typeof dateRight === 'string') {
        return differenceInCalendarYearsFNS(toDate(dateLeft as LocalDate), toDate(dateRight as LocalDate));
    }
    return differenceInCalendarYearsFNS(dateLeft, dateRight);
};

export const differenceInMinutes = (dateLeft: Date, dateRight: Date): number => differenceInMinutesFNS(dateLeft, dateRight);

export const differenceInHours = (dateLeft: Date, dateRight: Date): number => differenceInHoursFNS(dateLeft, dateRight);

export const compareAsc = (dateLeft: LocalDateOrDate, dateRight: LocalDateOrDate): number => {
    if (typeof dateLeft === 'string' && typeof dateRight === 'string') {
        return compareAscFNS(toDate(dateLeft), toDate(dateRight));
    }
    return compareAscFNS(dateLeft, dateRight);
};

export const compareDesc = (dateLeft: LocalDateOrDate, dateRight: LocalDateOrDate): number => {
    if (typeof dateLeft === 'string' && typeof dateRight === 'string') {
        return compareDescFNS(toDate(dateLeft), toDate(dateRight));
    }
    return compareDescFNS(dateLeft, dateRight);
};

export const getWeek = (date: LocalDateOrDate, options?: StartOfWeekOptions): number => {
    if (typeof date === 'string') {
        return getWeekFNS(toDate(date), options);
    }
    return getWeekFNS(date, options);
};

export const getYear = (date: LocalDateOrDate, options?: GetYearOptions): number => {
    if (typeof date === 'string') {
        return getYearFNS(toDate(date), options);
    }
    return getYearFNS(date, options);
};

export const getHours = (date: Date): number => getHoursFNS(date);

export const getMinutes = (date: Date): number => getMinutesFNS(date);

export const setMinutes = (date: Date, minutes: number): Date => setMinutesFNS(date, minutes);
export const setHours = (date: Date, hours: number): Date => setHoursFNS(date, hours);
export const setMonth = (date: Date, month: number): Date => setMonthFNS(date, month);

export const intervalToDuration = (interval: { start: Date; end: Date }): Duration => {
    return intervalToDurationFNS(interval);
};

export type Duration = DurationFNS;
