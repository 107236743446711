import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { EmptyState } from '@/components/empty-state/EmptyState';
import { EmptyStateIcon } from '@/components/empty-state/EmptyStateIcons';
import { StackedAvatars } from '@/domain-ui/employee/stacked-avatar/StackedAvatars';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { Label } from '@/domain/label/Label.model';
import { canManageReports } from '@/domain/permission/Permission.service';
import { Report, REPORT_CATEGORIES, ReportCategory, ReportCreateMutation } from '@/domain/report/Report.model';
import { AVAILABLE_REPORT_CATEGORY as AVAILABLE_REPORT_CATEGORIES, deleteReport, renameReport } from '@/domain/report/Report.service';
import { useCreateReport, useGetReports } from '@/hooks/report/Report.hook';
import { RenameReportDialog } from '@/page/report/rename-report-dialog/RenameReportDialog';
import { ReportDialog } from '@/page/report/report-dialog/ReportDialog';
import { useCurrentPolicies } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import { Box, Button, Divider, List, ListItem, ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText, Paper, Stack, Typography } from '@mui/material';
import { MatrixIcon, Layout3ColumnIcon as ReportIcon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkProps, Link as RouterLink, useNavigate, useSearchParams } from 'react-router';

export const ReportsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const policies = useCurrentPolicies();
    const isReportManager = canManageReports(policies);

    const [searchParams] = useSearchParams();

    const reportCategories = (Object.keys(REPORT_CATEGORIES) as ReportCategory[]).map(key => key);
    const defaultReportCategory: ReportCategory = 'REPORT_EMPLOYEE';
    const currentReportCategory = (searchParams.get('category') as ReportCategory) ?? defaultReportCategory;

    // State to open the dialog to create a new
    const [isCreateReportDialogOpen, setIsCreateReportDialogOpen] = useState<boolean>(false);

    const [reportToRename, setReportToRename] = useState<Report>();

    const { data: reports, isError, isLoading, error, refetch: refetchReports } = useGetReports(currentReportCategory);

    const { mutate: createReport } = useCreateReport();

    const handleReportSave = async (request: ReportCreateMutation) => {
        try {
            const report = await createReport(request);
            setIsCreateReportDialogOpen(false);
            goToReport(report);
        } catch (error) {
            handleError(error);
        }
    };

    const handleReportRename = (report: Report) => async (title: Label) => {
        try {
            await renameReport(report.id, report.reportType, title);
            await refetchReports();
            setReportToRename(undefined);
        } catch (error) {
            handleError(error);
        }
    };

    const handleReportDelete = async (report: Report) => {
        try {
            await deleteReport(report.id, report.reportType);
            await refetchReports();
        } catch (error) {
            handleError(error);
        }
    };

    const goToReport = (report: Report) => {
        navigate(`/reports/${report.reportType}/${report.id}`);
    };

    const newReportButton = isReportManager ? (
        <Button
            onClick={() => {
                setIsCreateReportDialogOpen(true);
            }}
        >
            {t('reports.new_report')}
        </Button>
    ) : undefined;

    const emptyState = <EmptyState icon={<EmptyStateIcon />} flex={1} title={t('reports.no_reports')} action={newReportButton} />;

    const getActionMenuItems = (report: Report): BasicMenuItem[] => {
        // Action menu is only available by employee with the permission to manage reports
        if (!canManageReports(policies)) {
            return [];
        }
        return [
            {
                title: t('reports.rename_report'),
                onClick: () => {
                    setReportToRename(report);
                },
            },
            {
                title: t('reports.delete_report'),
                onClick: () => {
                    handleReportDelete(report).catch(handleError);
                },
                confirmationRequired: true,
            },
        ];
    };

    const getReportTypeLabel = (report: Report) => {
        return t('reports.report_type.enum', {
            context: report.reportType,
            defaultValue: report.reportType,
        });
    };
    return (
        <Stack component={Paper} direction='row' gap={3} flex='1' p={2.5}>
            <Stack component={List} alignSelf='stretch' minWidth={200} maxWidth={300} disablePadding>
                {reportCategories?.map(category => (
                    <ListItem key={category} disablePadding>
                        <Stack
                            component={ListItemLink}
                            to={{
                                pathname: `/reports`,
                                search: `?category=${category}`,
                            }}
                            borderRadius={1}
                            direction='row'
                            flex={1}
                            gap={1}
                            alignItems='center'
                            p={1}
                            flexWrap='nowrap'
                            selected={category === currentReportCategory}
                            disabled={!AVAILABLE_REPORT_CATEGORIES.includes(category)}
                        >
                            <ListItemIcon>
                                <ReportIcon />
                            </ListItemIcon>
                            <ListItemText>{t('reports.report_category.enum', { context: category })}</ListItemText>
                        </Stack>
                    </ListItem>
                ))}
            </Stack>
            <Stack width='100%' gap={2}>
                <StateHandler isLoading={isLoading} isError={isError} isEmpty={!reports?.length} emptyStateComponent={emptyState} error={error}>
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                        <Typography variant='h1'>{`${t('reports.report_category.enum', { context: currentReportCategory })} ${t(
                            'reports.report',
                        )?.toLocaleLowerCase()}`}</Typography>
                        <Stack alignItems='center'>{newReportButton}</Stack>
                    </Stack>
                    <Stack component={List} flex={1} width='100%' divider={<Divider />} disablePadding>
                        {reports?.map(report => (
                            <ListItem key={report.id} disablePadding>
                                <Stack
                                    component={ListItemLink}
                                    direction='row'
                                    gap={1}
                                    alignItems='center'
                                    py={1}
                                    borderRadius={1}
                                    disableRipple
                                    to={`/reports/${report.reportType}/${report.id}`}
                                >
                                    <ListItemIcon>{report.displayMode === 'TABLE' ? <ReportIcon /> : <MatrixIcon />}</ListItemIcon>
                                    <Typography variant='body1' flex='1'>
                                        {getLabelTranslation(report.title)}
                                    </Typography>
                                    <Typography variant='body1' flex='1'>
                                        {getReportTypeLabel(report)}
                                    </Typography>
                                    <Stack direction={'row'} width={200}>
                                        <StackedAvatars employeeAvatars={report.viewers} size='small' />
                                    </Stack>

                                    {!!getActionMenuItems(report)?.length && (
                                        <Box alignSelf={'flex-end'}>
                                            <BasicMenu items={getActionMenuItems(report)} />
                                        </Box>
                                    )}
                                </Stack>
                            </ListItem>
                        ))}
                    </Stack>
                    {!!reportToRename && (
                        <RenameReportDialog
                            open={true}
                            onClose={() => setReportToRename(undefined)}
                            onSave={handleReportRename(reportToRename)}
                            report={reportToRename}
                        />
                    )}
                </StateHandler>
                {isCreateReportDialogOpen && (
                    <ReportDialog
                        open={true}
                        onClose={() => setIsCreateReportDialogOpen(false)}
                        onSave={handleReportSave}
                        reportCategory={currentReportCategory}
                    />
                )}
            </Stack>
        </Stack>
    );
};

type ListItemLinkProps = ListItemButtonProps & LinkProps;
const ListItemLink: FC<ListItemLinkProps> = props => <ListItemButton disableRipple component={RouterLink} {...props} />;
