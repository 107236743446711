import { FilterType } from '@/components/filters-bar/FilterBar.type';
import { useTranslation } from 'react-i18next';
import { getDocumentTags } from '@/domain/document-tag/DocumentTag.service';
import { getLabelTranslation } from '@/utils/language.util';

export const useDocumentFilters = (): { filters: FilterType[] } => {
    const { t } = useTranslation();

    const filters = [
        {
            key: 'DOCUMENT_TAG_IDS',
            filterName: t('documents.tag_filter'),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const documentTags = await getDocumentTags();
                return documentTags?.map(documentTag => ({
                    label: getLabelTranslation(documentTag.name),
                    value: documentTag.id,
                }));
            },
            rule: 'EQUALS',
            availableRules: [],
        },
    ] satisfies FilterType[];
    return {
        filters,
    };
};
