import { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { SummaryFeedbackSkillPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/SummaryFeedbackSkillPDF';
import { SummaryFeedbackSkillsFormType } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';
import { hasAtLeastOneSummaryOrFeedback } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';

type SummaryFeedbackSkillsPDFProps = {
    skills: SummaryFeedbackSkillsFormType;
};

export const SummaryFeedbackSkillsPDF: FC<SummaryFeedbackSkillsPDFProps> = ({ skills }) => {
    const skillsThatHaveBeenReviewed = skills?.skills?.filter(skill => hasAtLeastOneSummaryOrFeedback(skill));
    const theme = useTheme();
    const { t } = useTranslation();
    if (!skillsThatHaveBeenReviewed?.length) {
        return <></>;
    }

    return (
        <View style={{ gap: theme.spacing(1) }}>
            <Text
                style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    paddingLeft: theme.spacing(2),
                }}
            >
                {t('reviews.write_feedback.review_skills')}
            </Text>

            {skillsThatHaveBeenReviewed?.map(skill => <SummaryFeedbackSkillPDF key={skill.skill.id} questionItem={skill} rating={skills.rating} />)}
        </View>
    );
};
