/**
 * Returns the provided value if it's not null or undefined, otherwise returns null.
 * @param value The value to check.
 * @param predicate Optional predicate to conditionally apply the transformation.
 * @template T The type of the input value.
 * @template R The type of the transformed value.
 */
import { v4 as uuidv4 } from 'uuid';

export const defaultToNull = <T>(value: Nullable<T>, predicate = true): T | null => {
    if (!predicate || value === getNull() || value === undefined) {
        return getNull();
    }

    return value;
};

export const getNull = (): null => {
    // eslint-disable-next-line no-null/no-null
    return null;
};

export const nonNullable = <T>(value: Nullable<T>): value is NonNullable<T> => {
    return value !== getNull() && value !== undefined;
};

export const assignLocalId = <T extends object>(
    value: T,
): T & {
    localId: string;
} => {
    return {
        ...value,
        localId: 'id' in value && value.id && typeof value.id === 'number' ? value.id.toString() : uuidv4(),
    };
};

export const assignLocalIds = <T extends object>(
    items: T[],
): (T & {
    localId: string;
})[] => {
    return items.map(assignLocalId);
};

type RecursiveType<T> = {
    id: number | string;
    children: T[];
};

/**
 * Finds a recursive item by its id.
 * @param items The list of recursive items.
 * @param id The id of the item to find.
 */
export const findRecursiveItemById = <T extends RecursiveType<T>>(items: readonly T[], id: RecursiveType<T>['id']): T | undefined => {
    for (const item of items) {
        if (item.id === id) {
            return item;
        }

        const found = findRecursiveItemById(item.children, id);
        if (found) {
            return found;
        }
    }

    return undefined;
};

/**
 * Finds all parents of a recursive item by its id.
 * @param items The list of recursive items.
 * @param id The id of the item to find its parents.
 */
export const findRecursiveItemParents = <T extends RecursiveType<T>>(items: readonly T[], id: RecursiveType<T>['id']): T[] => {
    return items.reduce<T[]>((acc, currentValue) => {
        if (currentValue.children.some(child => child.id === id)) {
            return [currentValue, ...acc];
        }

        const found = findRecursiveItemParents(currentValue.children, id);
        if (found.length > 0) {
            return [currentValue, ...found];
        }

        return acc;
    }, []);
};

/**
 * Finds the direct parent of a recursive item by its id.
 * @param items The list of recursive items.
 * @param id The id of the item to find its parent.
 */
export const findRecursiveItemParent = <T extends RecursiveType<T>>(items: readonly T[], id: RecursiveType<T>['id']): T | undefined => {
    for (const item of items) {
        if (item.children.some(child => child.id === id)) {
            return item;
        }

        const found = findRecursiveItemParent(item.children, id);
        if (found) {
            return found;
        }
    }
    return undefined;
};

/**
 * Finds all children of a recursive item by its id.
 * @param items The list of recursive items.
 * @param id The id of the item to find its children.
 */
export const getAllRecursiveItemChildren = <T extends RecursiveType<T>>(items: readonly T[], id: RecursiveType<T>['id']): T[] => {
    const item = findRecursiveItemById(items, id);
    if (!item) {
        return [];
    }

    return item.children.flatMap(child => [child, ...getAllRecursiveItemChildren(item.children, child.id)]);
};
