import { TemporaryFile } from '@/components/file-picker/FilePicker';
import { fileSchema } from '@/components/file-picker/FilePicker.schema';
import { CreateAnnouncementMutation, EditAnnouncementMutation } from '@/domain/announcement/Announcement.model';
import { Department } from '@/domain/department/Department.model';
import { Location } from '@/domain/location/Location.model';
import { getLocalDateMinTestConfig, getLocalDateTestConfig } from '@/utils/datetime.util';

import { t } from 'i18next';
import * as yup from 'yup';

export const getAnnouncementSchema = () => {
    return yup.object().shape({
        title: yup.string().required(),
        content: yup.string().required(),
        // image can be a file or a stored file
        image: fileSchema.nullable(),
        startDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
        endDate: yup
            .string<LocalDate>()
            .test(getLocalDateTestConfig())
            .when('startDate', ([startDate], schema) =>
                schema.test(getLocalDateMinTestConfig(startDate, t('announcement.create_dialog.end_date_after_start_date'))),
            ),
        sendEmailNotification: yup.boolean().default(false).required(),
        publishToAllDepartments: yup.boolean().required(),
        departments: yup
            .array<Department>()
            .required()
            .when('publishToAllDepartments', ([publishToAllDepartments], schema) => (publishToAllDepartments ? schema : schema.min(1))),
        publishToAllLocations: yup.boolean().required(),
        locations: yup
            .array<Location>()
            .required()
            .when('publishToAllLocations', ([publishToAllLocations], schema) => (publishToAllLocations ? schema : schema.min(1))),
    });
};

export type AnnouncementImageFormValues = yup.InferType<typeof fileSchema>;

export type AnnouncementFormValues = yup.InferType<ReturnType<typeof getAnnouncementSchema>>;

export const mapAnnouncementFormValuesToCreateMutation = (data: AnnouncementFormValues): CreateAnnouncementMutation => {
    const { image, departments, locations, publishToAllDepartments, publishToAllLocations, ...rest } = data;

    const imageFile = (image as Nullable<TemporaryFile>)?.data;
    return {
        ...rest,
        imageFile: imageFile ?? undefined,
        departmentIds: publishToAllDepartments ? [] : departments.map(department => department.id),
        locationIds: publishToAllLocations ? [] : locations.map(location => location.id),
        sendNotification: data.sendEmailNotification,
    };
};

export const mapAnnouncementFormValuesToEditMutation = (announcementId: number, data: AnnouncementFormValues): EditAnnouncementMutation => {
    const { image, departments, locations, publishToAllDepartments, publishToAllLocations, ...rest } = data;
    const imageFile = (image as Nullable<TemporaryFile>)?.data;

    // Edit without changing the image
    // image.id === -1 && !image.data

    // Edit by adding a new image
    // image.id !== -1 (a new ID) && image.data

    // Edit by removing the existing image
    // image === null && imageFile === undefined

    // Edit when there was no image initially
    // image === null, so image?.id === undefined, and imageFile === undefined

    return {
        ...rest,
        id: announcementId,
        imageFile: imageFile ?? undefined,
        hasNoImage: !image,
        departmentIds: publishToAllDepartments ? [] : departments.map(department => department.id),
        locationIds: publishToAllLocations ? [] : locations.map(location => location.id),
        sendNotification: data.sendEmailNotification,
    };
};
