import { FC, Ref } from 'react';
import { Alert, Paper, Stack, Typography, useTheme } from '@mui/material';
import { SkillState } from '@/page/review/employee-review-feedback-form/FeedbackSkill';
import { FieldError } from 'react-hook-form';
import { FeedbackSummarySkillFormType, SummaryFeedbackSkillsFormType } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';
import { FeedbackSummarySkill } from '@/page/review/employee-review-summary-feedback-form/FeedbackSummarySkill';
import { FeedbackSkillsDescription } from '@/page/review/employee-review-feedback-form/FeedbackSkillsDescription';
import { getGroupedSkillsByCategoryId, GroupedSkillsByCategoryId } from '@/domain/skill/Skill.service';
import { CategoryFormType, FeedbackSkillFormType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import { getLabelTranslation } from '@/utils/language.util';

type FeedbackSummarySkillsProps = {
    disabled: boolean;
    skillsItem: SummaryFeedbackSkillsFormType;
    handleSkillChanged: (employeeReviewSummaryFeedbackSkillForm: FeedbackSummarySkillFormType) => void;
    error: FieldError | undefined;
    innerRef: Ref<HTMLDivElement>;
};

export const FeedbackSummarySkills: FC<FeedbackSummarySkillsProps> = ({ innerRef, handleSkillChanged, skillsItem, error, disabled }) => {
    const { minSkills, maxSkills, skills, instruction } = skillsItem;
    const theme = useTheme();
    const groupedSkillsByCategoryId = getGroupedSkillsByCategoryId(skills) as GroupedSkillsByCategoryId<FeedbackSkillFormType, CategoryFormType>[];

    if (skillsItem.skills.length === 0) {
        return <></>;
    }

    return (
        <Stack component={Paper} sx={{ p: 2, border: error ? `1px solid ${theme.palette.error.main}` : 'none' }} gap={2} ref={innerRef}>
            {!!error && error.message && (
                <Alert severity='error' elevation={0}>
                    <Typography variant='body2'>{error.message}</Typography>
                </Alert>
            )}
            <FeedbackSkillsDescription minSkills={minSkills} maxSkills={maxSkills} instruction={getLabelTranslation(instruction)} />

            {groupedSkillsByCategoryId.map(({ category, groupedSkills }) => (
                <SkillCategoryBlock
                    disabled={disabled}
                    key={category.id}
                    categoryName={category.name ?? ''}
                    groupedFeedbackSkillForm={groupedSkills}
                    handleSkillChanged={handleSkillChanged}
                />
            ))}
        </Stack>
    );
};

type SkillCategoryBlockProps = {
    categoryName: string;
    groupedFeedbackSkillForm: FeedbackSummarySkillFormType[];
    handleSkillChanged: (feedbackSkillForm: FeedbackSummarySkillFormType) => void;
    disabled: boolean;
};

const SkillCategoryBlock: FC<SkillCategoryBlockProps> = ({ categoryName, groupedFeedbackSkillForm, handleSkillChanged, disabled }) => {
    const isAtLeastOneFeedback = (feedbackSkillForm: FeedbackSummarySkillFormType): boolean => {
        const isAtLeastOneSelfFeedback = !!feedbackSkillForm?.selfFeedbackQuestion?.score && feedbackSkillForm.selfFeedbackQuestion.score > 0;

        const isAtLeastOneManagerFeedback = (feedbackSkillForm.managerFeedbackQuestion ?? []).some(
            managerFeedbackSkill => managerFeedbackSkill?.score && managerFeedbackSkill.score > 0,
        );

        const isAtLeastOnePeerFeedback = (feedbackSkillForm.peerFeedbackQuestion ?? []).some(
            peerFeedbackSkill => peerFeedbackSkill?.score && peerFeedbackSkill.score > 0,
        );

        const isAtLeastOneUpwardFeedback = (feedbackSkillForm.upwardFeedbackQuestion ?? []).some(
            upwardFeedbackSkill => upwardFeedbackSkill?.score && upwardFeedbackSkill.score > 0,
        );

        return isAtLeastOneSelfFeedback || isAtLeastOneManagerFeedback || isAtLeastOnePeerFeedback || isAtLeastOneUpwardFeedback;
    };

    const getSummarySkillState = (feedbackSkillForm: FeedbackSummarySkillFormType): SkillState => {
        if (feedbackSkillForm.score && feedbackSkillForm.score > 0) {
            return 'reviewed';
        }

        if (isAtLeastOneFeedback(feedbackSkillForm)) {
            return 'summary_meeting';
        }

        return 'not_reviewed';
    };

    return (
        <Stack gap={2}>
            <Typography variant={'body1bold'}>{categoryName}</Typography>
            <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
                {groupedFeedbackSkillForm.map(feedbackSkillForm => (
                    <FeedbackSummarySkill
                        disabled={disabled}
                        key={feedbackSkillForm.skill.id}
                        skillState={getSummarySkillState(feedbackSkillForm)}
                        feedbackSkillItem={feedbackSkillForm}
                        handleSkillChanged={handleSkillChanged}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
