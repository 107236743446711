import { FC } from 'react';
import { MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FilterReviewers } from '@/domain/review/Review.model';

type Props = {
    onChange: (value: FilterReviewers) => void;
    value: FilterReviewers;
};

export const EmployeeReviewFilter: FC<Props> = ({ onChange, value = FilterReviewers.ALL_REVIEWS }) => {
    const { t } = useTranslation();

    const showReviewsOptions = [
        {
            title: t('reviews.write_feedback.all_reviews'),
            value: FilterReviewers.ALL_REVIEWS,
        },
        {
            title: t('reviews.write_feedback.ongoing_reviews'),
            value: FilterReviewers.ONGOING_REVIEWS,
        },
    ];

    return (
        <Select
            id='review-filter-select'
            value={value}
            fullWidth
            onChange={event => onChange(FilterReviewers[event.target.value as keyof typeof FilterReviewers])}
        >
            {showReviewsOptions.map(option => {
                return (
                    <MenuItem className='Mui-selected-dropdown' key={option.value} value={option.value}>
                        {option.title}
                    </MenuItem>
                );
            })}
        </Select>
    );
};
