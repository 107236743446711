import { Objective } from '@/domain/objective/Objective.model';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { FeedbackObjectives } from '@/page/review/employee-review-feedback-form/FeedbackObjectives';
import { FeedbackSection } from '@/page/review/employee-review-feedback-form/FeedbackSection';
import { FeedbackSummaryQuestion } from '@/page/review/employee-review-summary-feedback-form/FeedbackSummaryQuestion';
import { FeedbackSummarySkills } from '@/page/review/employee-review-summary-feedback-form/FeedbackSummarySkills';
import {
    FeedbackItemsType,
    FeedbackStepItemsType,
    FeedbackSummarySkillFormType,
    QuestionFeedbackFormType,
    SummaryFeedbackItemFormType,
    SummaryFeedbackSkillsFormType,
} from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';
import { linkRef } from '@/utils/dom.util';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { FC, MutableRefObject, useRef } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { EmployeeReviewAction } from '@/domain/employee-review-action/EmployeeReviewAction.model';
import { FeedbackObjectiveActions } from '@/page/review/employee-review-feedback-form/FeedbackObjectiveActions';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { FeedbackObjectiveFormType, FeedbackObjectivesActionFormType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';

type SummaryFeedbackFormProps = {
    isFormDisabled: boolean;
    employeeId: number;
    employeeReview: EmployeeReview;
    scrollRef: MutableRefObject<HTMLDivElement | undefined>;
    objectives: Objective[];
    actions: EmployeeReviewAction[];
    refetchActions: () => void;
    refetchObjectives: () => void;
    onSaveItem?: (item: SummaryFeedbackItemFormType, skill?: FeedbackSummarySkillFormType) => Promise<void>;
    expandAll: boolean;
};

export const SummaryFeedbackForm: FC<SummaryFeedbackFormProps> = ({
    isFormDisabled,
    employeeId,
    scrollRef,
    objectives,
    actions,
    refetchObjectives,
    refetchActions,
    onSaveItem,
    employeeReview,
    expandAll,
}) => {
    const itemsRefs = useRef<HTMLDivElement[]>([]);
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    const { control } = useFormContext<FeedbackItemsType>();
    const { fields } = useFieldArray<FeedbackItemsType>({
        control,
        name: 'summaryFeedbackItems',
    });

    const handleSkillChanged = (
        summaryFeedbackSkillForm: FeedbackSummarySkillFormType,
        value: SummaryFeedbackSkillsFormType,
        onChange: (value: SummaryFeedbackSkillsFormType) => void,
    ) => {
        const skillsItem = value;
        const updatedSkills = skillsItem.skills.map(skill => {
            if (skill.skill.id === summaryFeedbackSkillForm.skill.id) {
                return summaryFeedbackSkillForm;
            }
            return skill;
        });
        onChange({ ...skillsItem, skills: updatedSkills });
        if (onSaveItem) {
            onSaveItem(skillsItem, summaryFeedbackSkillForm).catch(console.error);
        }
    };

    return (
        <ContentContainer>
            <Box ref={scrollRef} />
            <Stack direction='column' sx={{ pb: isMobile ? 0 : 5 }}>
                <Stack
                    direction='column'
                    gap={2}
                    sx={{
                        width: {
                            lg: '1000px',
                            xs: '100%',
                        },
                    }}
                    alignSelf={'center'}
                >
                    <Stack spacing={2} direction='column'>
                        {/* todo : fix as FeedbackStepItemsType[] */}
                        {(fields as FeedbackStepItemsType[]).map((item, index) => {
                            switch (item.type) {
                                case 'SECTION':
                                    return <FeedbackSection sectionItem={item} key={item.id} />;
                                case 'SKILL':
                                    return (
                                        <Controller
                                            name={`summaryFeedbackItems.${index}`}
                                            control={control}
                                            key={item.id}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <FeedbackSummaryQuestion
                                                    innerRef={linkRef(index, itemsRefs)}
                                                    feedbackSummaryQuestionForm={value as QuestionFeedbackFormType}
                                                    disabled={isFormDisabled}
                                                    error={error}
                                                    key={`${item?.type}_${item.id}`}
                                                    onUpdate={value => {
                                                        onChange(value);
                                                        if (onSaveItem) {
                                                            onSaveItem(value).catch(console.error);
                                                        }
                                                    }}
                                                    expandAll={expandAll}
                                                />
                                            )}
                                        />
                                    );
                                case 'SKILLS':
                                    return (
                                        <Controller
                                            name={`summaryFeedbackItems.${index}`}
                                            control={control}
                                            key={item.id}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <FeedbackSummarySkills
                                                    innerRef={linkRef(index, itemsRefs)}
                                                    skillsItem={value as SummaryFeedbackSkillsFormType}
                                                    handleSkillChanged={data => handleSkillChanged(data, value as SummaryFeedbackSkillsFormType, onChange)}
                                                    error={error}
                                                    disabled={isFormDisabled}
                                                />
                                            )}
                                        />
                                    );
                                case 'OBJECTIVE_ACTIONS':
                                    return (
                                        <Controller
                                            name={`summaryFeedbackItems.${index}`}
                                            control={control}
                                            key={item.id}
                                            render={({ field: { value } }) => (
                                                <FeedbackObjectiveActions
                                                    feedbackObjectiveActionsForm={value as FeedbackObjectivesActionFormType}
                                                    actions={actions}
                                                    employeeReview={employeeReview}
                                                    employeeId={employeeId}
                                                    refetchActions={refetchActions}
                                                    disabled={isFormDisabled}
                                                />
                                            )}
                                        />
                                    );
                                case 'REVIEW_OBJECTIVES':
                                    return (
                                        <Controller
                                            name={`summaryFeedbackItems.${index}`}
                                            control={control}
                                            key={item.id}
                                            render={({ field: { value } }) => (
                                                <FeedbackObjectives
                                                    feedbackObjectiveForm={value as FeedbackObjectiveFormType}
                                                    employeeId={employeeId}
                                                    disabled={isFormDisabled}
                                                    key={item.id}
                                                    objectives={objectives}
                                                    refetchObjectives={refetchObjectives}
                                                />
                                            )}
                                        />
                                    );
                                case 'QUESTION':
                                default:
                                    return (
                                        <Controller
                                            name={`summaryFeedbackItems.${index}`}
                                            control={control}
                                            key={item.id}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <FeedbackSummaryQuestion
                                                    innerRef={linkRef(index, itemsRefs)}
                                                    feedbackSummaryQuestionForm={value as QuestionFeedbackFormType}
                                                    disabled={isFormDisabled}
                                                    error={error}
                                                    key={item.id}
                                                    onUpdate={value => {
                                                        onChange(value);
                                                        if (onSaveItem) {
                                                            onSaveItem(value).catch(console.error);
                                                        }
                                                    }}
                                                    expandAll={expandAll}
                                                />
                                            )}
                                        />
                                    );
                            }
                        })}
                    </Stack>
                </Stack>
            </Stack>
        </ContentContainer>
    );
};
