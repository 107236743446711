import Bugsnag, { Event, NotifiableError, OnErrorCallback } from '@bugsnag/js';
import axios, { AxiosError, isAxiosError } from 'axios';
import { t } from 'i18next';
import snackbar from '@/utils/snackbar.util';

export const handleError = (error: unknown): void => {
    if (!axios.isAxiosError(error)) {
        if (isNotifiableError(error)) {
            console.error(error);
            Bugsnag.notify(error);
            return;
        }
        console.error('Error not handled and not sent to Bugsnag', { error });
        return;
    }

    // We can improve this kind of error handling in the future with a retry mechanism
    if (error?.code === AxiosError.ERR_NETWORK) {
        snackbar.showSnackbar(t('error_message.network_error'), 'error');
        return;
    }

    if (error.response?.status === 403) {
        snackbar.showSnackbar(t('error_message.403'), 'error');
        return;
    }

    // TODO: https://rogerhr.atlassian.net/browse/RP-4249 Improve error message handling
    snackbar.showSnackbar(error.response?.data?.message, 'error');
};

const isNotifiableError = (error: unknown): error is NotifiableError => {
    if (!error) {
        return false;
    }

    if (typeof error === 'string') {
        return true;
    }
    if (typeof error === 'object' && 'errorClass' in error && 'errorMessage' in error) {
        return true;
    }
    if (typeof error === 'object' && 'name' in error && 'message' in error) {
        return true;
    }

    return error instanceof Error;
};

/**
 *
 * @param error
 * @returns
 */
export const getOnErrorCallbackForAxiosError =
    (error: AxiosError): OnErrorCallback =>
    (event: Event) =>
        event.addMetadata('endpoint', {
            url: error.config?.url,
            method: error.config?.method,
            status: error.response?.status,
            error: error.response?.data,
        });

export const sendErrorInBugsnag = (error: unknown): void => {
    if (isAxiosError(error)) {
        console.error('Sending axios error to Bugsnag', error);
        Bugsnag.notify(error, getOnErrorCallbackForAxiosError(error));
    } else {
        console.error('Sending application error to Bugsnag', error);
        Bugsnag.notify(error as NotifiableError);
    }
};
