import { BadProgressIcon, GoodProgressIcon, ModerateProgressIcon } from '@/assets/icons/Icons';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { Objective, ObjectiveStatus } from '@/domain/objective/Objective.model';
import { getParentsObjective } from '@/domain/objective/Objective.service';
import { useGetObjective } from '@/hooks/objective/Objective.hook';
import { DialogContent, Divider, Paper, Stack, Tabs, Typography } from '@mui/material';
import { ArrowMoveDownRightIcon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { ObjectiveHistory } from '@/domain/objective-history/ObjectiveHistory.model';
import { getLabelTranslation } from '@/utils/language.util';
import { ObjectiveHistoryDialogDetailsPanel } from '@/page/objective/objective-history-dialog/ObjectiveHistoryDialogDetailPanel';
import { ObjectiveHistoryDialogStatusTab } from '@/page/objective/objective-history-dialog/ObjectiveHistoryDialogStatusTab';
import { ObjectiveHistoryDialogChangeHistoryTab } from '@/page/objective/objective-history-dialog/ObjectiveHistoryDialogChangeHistoryTab';
import { OBJECTIVE_HISTORY_DIALOG_TAB_HEIGHT } from './ObjectiveHistoryDialog.constants';
import { handleError } from '@/utils/api.util';
import { useGetObjectiveHistories } from '@/hooks/objective-history/ObjectiveHistory';

type ObjectiveHistoryDialogProps = Omit<DialogWrapperProps, 'onSave'> & {
    objectiveId: number;
    displayWeight: boolean;
};

export const ObjectiveHistoryDialog: FC<ObjectiveHistoryDialogProps> = ({ open, onClose, objectiveId, displayWeight }) => {
    const { t } = useTranslation();
    const {
        data: objective,
        isError: isObjectiveError,
        error: objectiveError,
        isLoading: isObjectiveLoading,
        refetch: refetchObjective,
    } = useGetObjective(objectiveId);

    const {
        data: objectiveHistories = [],
        isError: isObjectiveHistoriesError,
        error: objectiveHistoriesError,
        isLoading: isObjectiveHistoriesLoading,
        refetch: refetchObjectiveHistories,
    } = useGetObjectiveHistories(objectiveId);

    const isError = isObjectiveError || isObjectiveHistoriesError;
    const error = objectiveError || objectiveHistoriesError;
    const isLoading = isObjectiveLoading || isObjectiveHistoriesLoading;

    const refetch = async () => {
        try {
            await refetchObjective();
            await refetchObjectiveHistories();
        } catch (e) {
            handleError(e);
        }
    };

    return (
        <DialogWrapper header={t('objectives.history_dialog.title')} open={open} onClose={onClose} maxWidth='lg'>
            <DialogContent>
                <StateHandler isLoading={isLoading} isError={isError} error={error}>
                    {objective && (
                        <Stack pb={2} gap={2} direction={'row'} justifyContent={'space-between'} divider={<Divider orientation='vertical' flexItem />}>
                            <ObjectiveHistoryDialogContent
                                displayWeight={displayWeight}
                                objective={objective}
                                onStatusUpdated={() => refetch()}
                                objectiveHistories={objectiveHistories}
                            />
                            <ObjectiveHistoryDialogDetailsPanel objective={objective} />
                        </Stack>
                    )}
                </StateHandler>
            </DialogContent>
        </DialogWrapper>
    );
};

type ObjectiveStatusProgressIconProps = {
    objectiveStatus: ObjectiveStatus;
};

export const ObjectiveStatusProgressIcon: FC<ObjectiveStatusProgressIconProps> = ({ objectiveStatus }) => {
    switch (objectiveStatus) {
        case 'DELAYED':
            return <BadProgressIcon />;
        case 'AT_RISK':
            return <ModerateProgressIcon />;
        case 'ON_TRACK':
            return <GoodProgressIcon />;
        default:
            return <></>;
    }
};

type ObjectiveHistoryDialogContentProps = {
    objective: Objective;
    displayWeight: boolean;
    onStatusUpdated: () => void;
    objectiveHistories: ObjectiveHistory[];
};

const ObjectiveHistoryDialogContent: FC<ObjectiveHistoryDialogContentProps> = ({ objective, displayWeight, onStatusUpdated, objectiveHistories }) => {
    const { t } = useTranslation();

    return (
        <Stack direction='column' gap={1} flex={3}>
            <Stack gap={0.5}>
                <Stack direction='row' gap={1} alignItems='center'>
                    {objective.lastStatusUpdate?.status && <ObjectiveStatusProgressIcon objectiveStatus={objective.lastStatusUpdate.status} />}

                    <Typography variant='h2'>{objective.name + (objective.weight && displayWeight ? ' (' + objective.weight + 'x)' : '')}</Typography>
                </Stack>

                {objective.completionStatus && (
                    <Stack component={Paper} bgcolor='primary.light' direction={'column'} p={1}>
                        <Typography variant='body1'>
                            <b>{getLabelTranslation(objective.completionStatus.name)}</b>
                            {` ${t('objectives.history_dialog.completion_status_on', { date: objective.completedAt })}`}
                        </Typography>
                        <Typography variant='body1' component='pre'>
                            {/* pre to inherit the style of the text */}
                            {objective.completionComment}
                        </Typography>
                    </Stack>
                )}

                {objective.description && (
                    <Typography
                        variant='body1'
                        sx={{
                            // apply break lines from the description
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {objective.description}
                    </Typography>
                )}
            </Stack>

            <HistoryDialogParentObjectives objective={objective} />

            <ObjectiveHistoryTabs objective={objective} onStatusUpdated={onStatusUpdated} objectiveHistories={objectiveHistories} />
        </Stack>
    );
};

type ObjectiveHistoryTabsProps = {
    objective: Objective;
    onStatusUpdated: () => void;
    objectiveHistories: ObjectiveHistory[];
};

const ObjectiveHistoryTabs: FC<ObjectiveHistoryTabsProps> = ({ objective, onStatusUpdated, objectiveHistories }) => {
    const [tabValue, setTabValue] = useState<'status' | 'history'>('status');
    const { t } = useTranslation();

    return (
        <Stack>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs onChange={(_, newValue) => setTabValue(newValue)} value={tabValue}>
                    <Tab label={t('objectives.history_dialog.status_tab')} value='status' />
                    <Tab label={t('objectives.history_dialog.history_tab')} value='history' />
                </Tabs>
            </Box>
            <Stack gap={2} height={OBJECTIVE_HISTORY_DIALOG_TAB_HEIGHT}>
                {tabValue === 'status' && <ObjectiveHistoryDialogStatusTab objective={objective} onStatusUpdated={onStatusUpdated} />}
                {tabValue === 'history' && <ObjectiveHistoryDialogChangeHistoryTab objectiveHistories={objectiveHistories} />}
            </Stack>
        </Stack>
    );
};

type HistoryDialogParentObjectivesProps = {
    objective: Objective;
};

const HistoryDialogParentObjectives: FC<HistoryDialogParentObjectivesProps> = ({ objective }) => {
    const { t } = useTranslation();

    if (!objective.parent) {
        return <></>;
    }

    const parentObjectives = getParentsObjective(objective);

    return (
        <Stack gap={0.5}>
            <Typography variant='body1bold'>{t('objectives.history_dialog.parent_objectives')}</Typography>
            <Stack gap={0.25}>
                {parentObjectives.map((objective, index) => {
                    return (
                        <Stack key={objective.id} direction='row' alignItems='flex-end' gap={0.5} pl={index * 2}>
                            {index !== 0 && <ArrowMoveDownRightIcon />}

                            <Typography variant='body1'>{objective.name}</Typography>
                        </Stack>
                    );
                })}
            </Stack>
            <Stack direction='row' alignItems='flex-end' gap={0.5} pl={getParentsObjective(objective).length * 2}>
                <ArrowMoveDownRightIcon />
                <Typography variant='body1bold'>{objective.name}</Typography>
            </Stack>
        </Stack>
    );
};
