import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { AsyncSelectFilter, FilterType, SelectFilter } from '@/components/filters-bar/FilterBar.type';
import { searchSkillCategories } from '@/domain/skill-category/SkillCategory.service';
import { useFiltersDirectory } from '@/page/common/filters-directory/useFiltersDirectory';
import { getLabelTranslation } from '@/utils/language.util';

export type EmployeeSkillFilter = SelectFilter | AsyncSelectFilter;

/**
 * Hook to get the list of filter for employee
 */
export const useEmployeeSkillFilter = (): { filters: FilterType[] } => {
    const { filters: filtersFromDirectory } = useFiltersDirectory(['EMPLOYEE', 'DEPARTMENT', 'LOCATION', 'JOB_FAMILY', 'JOB']);
    const filters: FilterType[] = [
        ...filtersFromDirectory,
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'SKILL_CATEGORY' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const skillCategories = await searchSkillCategories();
                return skillCategories?.map(skillCategory => ({
                    label: getLabelTranslation(skillCategory.name),
                    value: skillCategory.id,
                }));
            },
            key: 'skillCategoryIds',
            rule: 'EQUALS',
            availableRules: [],
        } satisfies FilterType,
    ];

    return {
        filters,
    };
};
