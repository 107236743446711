import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { EmployeesSelectionDialog } from '@/domain-ui/employee/employee-selection-dialog/EmployeesSelectionDialog';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { StackedAvatars } from '@/domain-ui/employee/stacked-avatar/StackedAvatars';
import { Employee } from '@/domain/employee/Employee.model';
import { Review, ReviewUpdateMutation } from '@/domain/review/Review.model';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { addWeeksAndFormat, getCurrentLocalDate, getEndOfMonth, getLocalDateMinTestConfig, getLocalDateTestConfig, isAfterDate } from '@/utils/datetime.util';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack, Typography } from '@mui/material';
import { AddTeamIcon } from 'hugeicons-react';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const DEFAULT_FEEDBACK_DEADLINE_LOCAL_DATE: LocalDate = addWeeksAndFormat(getCurrentLocalDate(), 2);
const DEFAULT_END_LOCAL_DATE: LocalDate = getEndOfMonth(addWeeksAndFormat(getCurrentLocalDate(), 4));

type Props = {
    open: boolean;
    handleSave: (id: number, data: ReviewUpdateForm) => void;
    closeDialog: () => void;
    activeReviewData: Review;
    id: number;
};

type ReviewUpdateForm = Pick<ReviewUpdateMutation, 'employeeIds' | 'ownerIds' | 'endDate' | 'feedbackDeadlineDate'>;

export const UpdateReviewDialog: FC<Props> = ({ open, handleSave, closeDialog, id, activeReviewData }) => {
    const { t } = useTranslation();

    const [assignedOwners, setAssignedOwners] = useState<Employee[]>([]);
    const [ownersDialogOpen, setOwnersDialogOpen] = useState<boolean>(false);
    const [assignedEmployees, setAssignedEmployees] = useState<Employee[]>([]);
    const [employeesDialogOpen, setEmployeesDialogOpen] = useState<boolean>(false);
    const { data: allEmployees = [] } = useGetEmployees();

    const schema: yup.ObjectSchema<ReviewUpdateForm> = yup.object().shape({
        feedbackDeadlineDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
        endDate: yup
            .string<LocalDate>()
            .when('feedbackDeadlineDate', ([feedbackDeadlineDate], schema) =>
                schema.test(getLocalDateMinTestConfig(feedbackDeadlineDate, t('reviews.performance_review_forms.end_date_after_feedback_deadline_date'))),
            )
            .required(),
        ownerIds: yup.array().of(yup.number().required()).required().min(1, t('general.validations.required')),
        employeeIds: yup.array().of(yup.number().required()).required().min(1, t('general.validations.required')),
    });

    const resolver = yupResolver(schema);

    const {
        handleSubmit,
        reset,
        control,
        watch,
        setValue,
        getFieldState,
        formState: { errors },
    } = useForm<ReviewUpdateForm>({
        resolver,
        defaultValues: {
            feedbackDeadlineDate: DEFAULT_FEEDBACK_DEADLINE_LOCAL_DATE,
            endDate: DEFAULT_END_LOCAL_DATE,
            ownerIds: [],
            employeeIds: [],
        },
    });

    const feedbackDeadLineDate = watch('feedbackDeadlineDate');
    const endDate = watch('endDate');

    useEffect(() => {
        if (activeReviewData) {
            const employees = (activeReviewData?.employeeReviews ?? []).map(review => review.employee);
            setAssignedEmployees(employees);
            setAssignedOwners(activeReviewData.owners);
            const formData = {
                ...activeReviewData,
                endDate: activeReviewData.endDate ?? DEFAULT_END_LOCAL_DATE,
                feedbackDeadlineDate: activeReviewData.feedbackDeadlineDate ?? DEFAULT_FEEDBACK_DEADLINE_LOCAL_DATE,
                ownerIds: activeReviewData.owners.map(owner => owner.id),
                employeeIds: (activeReviewData?.employeeReviews ?? []).map(employeeReview => employeeReview.employee.id),
            };

            reset(formData, { keepDirty: false });
        }
    }, [activeReviewData, reset]);

    // Update the end date when the feedback deadline date is changed
    useEffect(() => {
        if (getFieldState('feedbackDeadlineDate').isDirty && feedbackDeadLineDate && endDate && isAfterDate(feedbackDeadLineDate, endDate)) {
            setValue('endDate', feedbackDeadLineDate, { shouldDirty: true });
        }
    }, [feedbackDeadLineDate, endDate, getFieldState, setValue]);

    const onSave = (data: ReviewUpdateForm) => {
        handleSave(id, data);
    };

    const onClose = () => {
        closeDialog();
    };

    const saveOwners = (assigned: Employee[]) => {
        setOwnersDialogOpen(false);
        setAssignedOwners(assigned);
        const assignedIds = assigned.map((employee: Employee) => employee.id);
        setValue('ownerIds', assignedIds, { shouldDirty: true });
    };

    const saveEmployees = (assigned: Employee[]) => {
        setEmployeesDialogOpen(false);
        setAssignedEmployees(assigned);
        const assignedIds = assigned.map((employee: Employee) => employee.id);
        setValue('employeeIds', assignedIds, { shouldDirty: true });
    };

    return (
        <>
            <DialogWrapper open={open} onClose={onClose} header={t('reviews.manage_reviews.update_review')}>
                <Stack gap={2} component={DialogContent}>
                    <FormControlLabel
                        label={t('reviews.performance_review_forms.feedback_deadline_date')}
                        control={<FieldLocalDate name='feedbackDeadlineDate' control={control} />}
                    />

                    <FormControlLabel
                        label={t('reviews.performance_review_forms.end_date')}
                        control={<FieldLocalDate name='endDate' control={control} minDate={feedbackDeadLineDate} />}
                    />

                    <Typography variant='body1bold'>{t('reviews.performance_review_forms.owners')}</Typography>
                    <Stack direction={'row'} spacing={2} sx={{ mt: 0.7 }}>
                        <Button variant='contained' endIcon={<AddTeamIcon />} color='primary' onClick={() => setOwnersDialogOpen(true)}>
                            {t('permissions_setting_page.modify')}
                        </Button>
                        <StackedAvatars employeeAvatars={assignedOwners} />
                    </Stack>
                    {errors.ownerIds?.message && (
                        <Typography variant='body1' color='error'>
                            {errors.ownerIds.message}
                        </Typography>
                    )}
                    <Typography variant='body1bold'>{t('reviews.new_review.who_will_be_reviewed')}</Typography>
                    <Stack direction={'row'} spacing={2} sx={{ mt: 0.7 }}>
                        <Button variant='contained' endIcon={<AddTeamIcon />} color='primary' onClick={() => setEmployeesDialogOpen(true)}>
                            {t('permissions_setting_page.modify')}
                        </Button>
                        <StackedAvatars employeeAvatars={assignedEmployees} />
                    </Stack>
                    {errors.employeeIds?.message && (
                        <Typography variant='body1' color='error'>
                            {errors.employeeIds.message}
                        </Typography>
                    )}
                </Stack>
                <DialogActions>
                    <Button onClick={handleSubmit(data => onSave(data), console.error)} fullWidth>
                        {t('general.save')}
                    </Button>
                </DialogActions>
            </DialogWrapper>
            {ownersDialogOpen && (
                <EmployeesSelectionDialog
                    assignedEmployees={assignedOwners}
                    allEmployees={allEmployees}
                    open={ownersDialogOpen}
                    onClose={() => setOwnersDialogOpen(false)}
                    onSave={saveOwners}
                />
            )}
            {employeesDialogOpen && (
                <EmployeesSelectionDialog
                    assignedEmployees={assignedEmployees}
                    allEmployees={allEmployees}
                    open={employeesDialogOpen}
                    onClose={() => setEmployeesDialogOpen(false)}
                    onSave={saveEmployees}
                />
            )}
        </>
    );
};
