import { useGetProfilePages } from '@/page/employee-profile/EmployeeProfilePages.hook';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type Props = {
    employeeId: number;
    open: boolean;
    onClose: () => void;
};
export const MobileProfileTabsNavigation: FC<Props> = ({ employeeId, open, onClose }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const menuItems = useGetProfilePages();

    return (
        <Drawer anchor={'bottom'} open={open} onClose={onClose}>
            <Box role='presentation' onClick={onClose} onKeyDown={onClose}>
                <List>
                    {menuItems.map(item => (
                        <ListItem key={item.labelKey} disablePadding>
                            <ListItemButton
                                onClick={event => {
                                    event.stopPropagation();
                                    onClose();
                                    const newPath = `/profile/${employeeId}${item.path.replace('profile/', '')}`;
                                    navigate(newPath);
                                }}
                            >
                                <ListItemText primary={t(item.labelKey)} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
    );
};
