import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { EmployeeAvatar } from '@/domain-ui/employee/employee-avatar/EmployeeAvatar';
import { Objective, OBJECTIVES_STATUSES } from '@/domain/objective/Objective.model';
import { getDueLabelKey, getParentsObjective } from '@/domain/objective/Objective.service';
import { ObjectiveStatusProgressIcon } from '@/page/objective/objective-history-dialog/ObjectiveHistoryDialog';
import { formatInDefaultDate, getCurrentLocalDate, isBeforeDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { Box, Button, Collapse, Paper, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ArrowMoveDownRightIcon, Building06Icon, Target01Icon as ObjectivesIcon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

type ObjectiveItemProps = {
    objective: Objective;
    displayWeight: boolean;
    onCloseObjective: () => void;
    onEdit: (id: number) => void;
    onReopen: (id: number) => void;
    onArchive: (id: number) => void;
    onUnarchive: (id: number) => void;
    onDelete: (id: number) => void;
    onOpenDetailClick: () => void;
    showAssignee: boolean;
    editable: boolean;
    showUsersMobile: boolean;
    disabled?: boolean;
};

export const ObjectiveItem: FC<ObjectiveItemProps> = ({
    objective,
    displayWeight,
    onCloseObjective,
    onArchive,
    onUnarchive,
    onEdit,
    onDelete,
    onReopen,
    editable,
    onOpenDetailClick,
    showUsersMobile,
    disabled = false,
    showAssignee = true,
}) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isOpen, setIsOpen] = useState(false);

    const dueDate = objective?.dueDate;
    const dueDateColor = isBeforeDate(dueDate, getCurrentLocalDate()) && !objective?.completionStatus && !objective?.archived ? 'error' : 'inherit';

    const closeButton = !objective?.completionStatus && editable && (
        <Button
            sx={{ ml: 2 }}
            onClick={e => {
                e.stopPropagation();
                onCloseObjective();
            }}
            disabled={disabled}
        >
            {t('objectives.close')}
        </Button>
    );

    const statusAndDate = (
        <Stack alignItems={'flex-end'}>
            <Typography variant='body2' whiteSpace={'nowrap'}>
                {getLabelTranslation(objective?.completionStatus?.name)}
            </Typography>
            {objective?.completionStatus && !!objective?.completedAt && (
                <Typography variant='body2bold'>{formatInDefaultDate(objective?.completedAt)}</Typography>
            )}
        </Stack>
    );
    const items = getMenuItems(objective, onReopen, onEdit, onDelete, onArchive, onUnarchive, onCloseObjective);

    const displayObjectiveWeight = objective?.weight && displayWeight;
    const parentObjective = getParentsObjective(objective).pop();

    if (isMobile) {
        return (
            <Paper onClick={() => setIsOpen(prev => !prev)}>
                <Stack minHeight={'44px'} display='flex' flexDirection='column' p={1} justifyContent='center'>
                    {showUsersMobile && (
                        <Box display='flex' alignItems='center'>
                            <EmployeeAvatar employeeAvatar={objective.assignee} />
                        </Box>
                    )}
                    <Stack alignItems={'center'} direction={'row'} gap={1}>
                        <ObjectiveIcon objectiveType={objective.objectiveType} />
                        <Stack>
                            {parentObjective && <Typography variant='body1'>{parentObjective.name}</Typography>}

                            <Stack direction={'row'}>
                                {parentObjective && (
                                    <ArrowMoveDownRightIcon
                                        style={{
                                            alignSelf: 'flex-start',
                                        }}
                                    />
                                )}
                                <Typography alignSelf={'flex-end'} variant='body1bold'>
                                    {objective?.name + (displayObjectiveWeight ? ' (' + objective?.weight + 'x)' : '')}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Collapse in={isOpen}>
                        <Typography variant='body2'>{objective?.description}</Typography>

                        {/* Row with date and action menu */}
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Typography variant='body2'>
                                {t(getDueLabelKey(dueDate))}: <b>{formatInDefaultDate(dueDate)}</b>
                            </Typography>
                            <Box display='flex' alignItems='center' justifyContent='flex-end' gap={theme.spacing(1)}>
                                {!objective?.completionStatus && editable ? closeButton : statusAndDate}
                                {editable && <BasicMenu items={items} />}
                            </Box>
                        </Box>
                    </Collapse>
                </Stack>
            </Paper>
        );
    }
    const status = OBJECTIVES_STATUSES.find(o => o.value === objective?.lastStatusUpdate?.status);

    return (
        <Stack
            gap={1}
            p={1}
            minHeight={'44px'}
            alignItems={'center'}
            sx={{
                justifyContent: 'flex-start',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: theme.palette.grey[100],
                },
            }}
            onClick={disabled ? undefined : onOpenDetailClick}
            direction={'row'}
        >
            <Stack alignItems={'center'} direction={'row'} gap={1} flex={3}>
                <ObjectiveIcon objectiveType={objective.objectiveType} />

                <Stack>
                    {parentObjective && <Typography variant='body1'>{parentObjective.name}</Typography>}

                    <Stack direction={'row'}>
                        {parentObjective && (
                            <ArrowMoveDownRightIcon
                                style={{
                                    alignSelf: 'flex-start',
                                }}
                            />
                        )}
                        <Typography alignSelf={'flex-end'} variant='body1bold'>
                            {objective?.name + (displayObjectiveWeight ? ' (' + objective?.weight + 'x)' : '')}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            {showAssignee && (
                <Stack style={{ justifyContent: 'center' }} flex={1}>
                    {objective?.department && (
                        <Tooltip title={getLabelTranslation(objective.department.name)}>
                            <Typography alignSelf='flex-start' variant='body1'>
                                {getLabelTranslation(objective.department.name)}
                            </Typography>
                        </Tooltip>
                    )}

                    {!objective?.department && <EmployeeAvatar employeeAvatar={objective.assignee} />}
                </Stack>
            )}

            <Stack style={{ justifyContent: 'center' }} flex={1}>
                {objective?.completionStatus ? (
                    <Tooltip title={`${getLabelTranslation(objective.completionStatus.name)} · ${objective.completionComment}`}>
                        <Typography alignSelf={'flex-start'} variant='body1'>
                            {getLabelTranslation(objective.completionStatus.name)}
                        </Typography>
                    </Tooltip>
                ) : (
                    <Typography variant='body2' color={dueDateColor}>
                        {formatInDefaultDate(objective?.dueDate)}
                    </Typography>
                )}
            </Stack>
            <Stack style={{ justifyContent: 'center' }} flex={1}>
                <Typography variant='body1'>
                    {objective?.lastStatusUpdate?.updatedAt ? formatInDefaultDate(objective?.lastStatusUpdate.updatedAt) : ''}
                </Typography>
            </Stack>
            <Stack
                flex={1}
                style={{
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                }}
            >
                {objective?.lastStatusUpdate?.status && !objective.completionStatus && !!status && (
                    <ObjectiveStatusProgressIcon objectiveStatus={status.value} />
                )}
            </Stack>
            <Stack style={{ width: '40px', justifyContent: 'center' }}>{editable && <BasicMenu items={items} sx={{ marginBottom: '20px' }} />}</Stack>
        </Stack>
    );
};

type ObjectiveIconProps = {
    objectiveType: 'INDIVIDUAL' | 'DEPARTMENT';
};

const ObjectiveIcon: FC<ObjectiveIconProps> = ({ objectiveType }) => (
    <Stack>
        {objectiveType === 'INDIVIDUAL' && <ObjectivesIcon />}
        {objectiveType === 'DEPARTMENT' && <Building06Icon />}
    </Stack>
);

const getMenuItems = (
    objective: Objective,
    onReopen: (id: number) => void,
    onEdit: (id: number) => void,
    onDelete: (id: number) => void,
    onArchive: (id: number) => void,
    onUnarchive: (id: number) => void,
    onCloseObjective: () => void,
): BasicMenuItem[] => {
    const objectiveId = objective.id;

    const canReopen = objective?.completionStatus && !objective?.archived;
    const canEdit = !objective?.completionStatus && !objective?.archived;
    const canArchive = !objective?.archived;
    const canUnarchive = objective?.archived;
    const canClose = !objective?.completionStatus && !objective?.archived;
    const canDelete = objective?.archived;

    const menuItems: BasicMenuItem[] = [];
    if (canReopen) {
        menuItems.push({
            title: i18next.t('objectives.reopen'),
            onClick: () => onReopen(objectiveId),
        });
    }
    if (canEdit) {
        menuItems.push({
            title: i18next.t('general.edit'),
            onClick: () => onEdit(objectiveId),
        });
    }
    if (canDelete) {
        menuItems.push({
            title: i18next.t('general.delete'),
            onClick: () => onDelete(objectiveId),
        });
    }
    if (canArchive) {
        menuItems.push({
            title: i18next.t('objectives.archive'),
            onClick: () => onArchive(objectiveId),
        });
    }
    if (canUnarchive) {
        menuItems.push({
            title: i18next.t('objectives.unarchive'),
            onClick: () => onUnarchive(objectiveId),
        });
    }

    if (canClose) {
        menuItems.push({
            title: i18next.t('objectives.close'),
            onClick: onCloseObjective,
        });
    }

    return menuItems;
};
