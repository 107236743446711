import { StateHandler } from '@/components/state-handler/StateHandler';
import { Objective, ObjectiveCreateMutation, ObjectiveUpdateMutation } from '@/domain/objective/Objective.model';
import { canEditObjectives, canViewObjectives } from '@/domain/permission/Permission.service';
import { useGetObjectiveSetting } from '@/hooks/objective-setting/ObjectiveSetting.hook';
import { ObjectivesList } from '@/page/objective/objectives-list/ObjectivesList';
import { useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { Paper, Stack, StackProps, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddIndividualObjectiveDialog, IndividualObjectiveDialogFormType } from '@/page/objective/add-individual-objective-dialog/AddIndividualObjectiveDialog';
import { showSnackbar } from '@/utils/snackbar.util';
import { handleError } from '@/utils/api.util';
import { createObjective, updateObjective } from '@/domain/objective/Objective.service';
import { RichTextTypography } from '@/components/rich-text-typography/RichTextTypography';
import { getLabelTranslation } from '@/utils/language.util';
import { FeedbackObjectiveFormType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';

type FeedbackObjectivesProps = {
    employeeId: number;
    disabled?: boolean;
    objectives: Objective[];
    refetchObjectives: () => void;
    feedbackObjectiveForm: FeedbackObjectiveFormType;
} & StackProps;

export const FeedbackObjectives: FC<FeedbackObjectivesProps> = ({ employeeId, disabled, objectives, feedbackObjectiveForm, refetchObjectives, ...rest }) => {
    const { t } = useTranslation();
    const policies = useCurrentPolicies();
    const realm = useCurrentRealm();
    const [activeObjective, setActiveObjective] = useState<Objective>();
    const [objectiveDialogOpen, setObjectiveDialogOpen] = useState<boolean>(false);
    const {
        data: objectiveSetting,
        isLoading: isLoadingObjectiveSetting,
        isError: isErrorObjectiveSetting,
        error: errorObjectiveSetting,
    } = useGetObjectiveSetting();

    const emptyState = (
        <Stack p={2}>
            <Typography variant={'body1'}>{t('reviews.review_summary.no_open_objectives')}</Typography>
        </Stack>
    );

    if (!canViewObjectives(realm.realmFeatures, policies, employeeId)) {
        return <></>;
    }

    const handleObjectiveEdit = (id: number) => {
        const editObjective = (objectives ?? []).find(objective => objective.id === id);
        setActiveObjective(editObjective);
        setObjectiveDialogOpen(true);
    };

    const handleSave = async (formValues: IndividualObjectiveDialogFormType, objectiveId?: number) => {
        if (objectiveId) {
            const objectiveMutation = {
                ...formValues,
                assigneeId: formValues.assigneeIds[0],
                parentId: formValues.parentId ?? undefined,
            };
            await onUpdateObjective(objectiveMutation, objectiveId);
        } else {
            const objectiveMutation = {
                ...formValues,
                parentId: formValues.parentId ?? undefined,
            };
            await onCreateObjective(objectiveMutation);
        }
    };

    const onCreateObjective = async (objectiveRequest: ObjectiveCreateMutation) => {
        try {
            await createObjective(objectiveRequest);
            showSnackbar(t('objectives.messages.created'), 'success');
            refetchObjectives();
            setObjectiveDialogOpen(false);
        } catch (error) {
            handleError(error);
        }
    };

    const onUpdateObjective = async (objectiveRequest: ObjectiveUpdateMutation, objectiveId: number) => {
        try {
            await updateObjective(objectiveId, objectiveRequest);
            showSnackbar(t('objectives.messages.updated'), 'success');
            refetchObjectives();
            setObjectiveDialogOpen(false);
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <Stack component={Paper} p={2} {...rest}>
            <StateHandler
                isLoading={isLoadingObjectiveSetting}
                isError={isErrorObjectiveSetting}
                error={errorObjectiveSetting}
                isEmpty={!objectives?.length}
                emptyStateComponent={emptyState}
            >
                <Stack gap={2}>
                    <Typography variant='h2'>{t('reviews.review_summary.review_objectives_title')}</Typography>

                    {feedbackObjectiveForm.instruction && (
                        <RichTextTypography variant='body2'>{getLabelTranslation(feedbackObjectiveForm.instruction)}</RichTextTypography>
                    )}
                    <ObjectivesList
                        objectives={objectives || []}
                        onSuccess={() => refetchObjectives()}
                        onEditObjective={handleObjectiveEdit}
                        editable={canEditObjectives(policies, employeeId)}
                        disabled={disabled}
                        displayWeight={objectiveSetting?.objectiveWeightEnabled ?? false}
                        displayAvatarsMobile={true}
                        showAssignee={false}
                        p={0}
                    />
                </Stack>
            </StateHandler>

            {objectiveDialogOpen && (
                <AddIndividualObjectiveDialog
                    open={objectiveDialogOpen}
                    activeObjective={activeObjective}
                    disabledEmployeeSelection={true}
                    onSave={formValues => handleSave(formValues, activeObjective?.id)}
                    employeeId={employeeId}
                    parentObjectiveEnabled={objectiveSetting?.parentObjectiveEnabled ?? false}
                    onClose={() => {
                        setActiveObjective(undefined);
                        setObjectiveDialogOpen(false);
                    }}
                />
            )}
        </Stack>
    );
};
