import { Footer, FooterActions, FooterActionsProps } from '@/page/layout/Footer';
import { handleError } from '@/utils/api.util';
import { equals } from '@/utils/collections.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControlLabel, Paper, Stack, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';

import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';
import { EmployeesSelectionDialog } from '@/domain-ui/employee/employee-selection-dialog/EmployeesSelectionDialog';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { StackedAvatars } from '@/domain-ui/employee/stacked-avatar/StackedAvatars';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { Employee, EmployeeAvatar } from '@/domain/employee/Employee.model';
import { Location } from '@/domain/location/Location.model';
import { Planning, PlanningMutation } from '@/domain/planning/Planning.model';
import { createPlanning, deletePlanning, updatePlanning } from '@/domain/planning/Planning.service';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { SelectOption } from '@/page/planning/scheduler-calendar/types';
import { useGetLocations } from '@/page/setting/location/Location.hook';
import { useGetPlanningById } from '@/page/setting/planning/Planning.hook';
import { AddTeamIcon } from 'hugeicons-react';

type PlanningForm = Planning & { allMembersAreViewers: boolean };

export const PlanningConfigurationFormPage: FC = () => {
    const { t } = useTranslation();
    const { planningIdParam } = useParams();
    const navigate = useNavigate();

    const planningId = isNaN(Number(planningIdParam)) ? undefined : Number(planningIdParam);

    const { data: allEmployees = [], isLoading: isEmployeesLoading, isError: isEmployeesError } = useGetEmployees();
    const { data: locationOptions = [], isLoading: isLocationsLoading, isError: isLocationsError } = useGetLocations();
    const { data: planning, isLoading: isPlanningLoading, isError: isPlanningError, error: planningError } = useGetPlanningById(planningId);

    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

    const [assignedManagers, setAssignedManagers] = useState<EmployeeAvatar[]>([]);
    const [assignedMembers, setAssignedMembers] = useState<EmployeeAvatar[]>([]);
    const [assignedViewers, setAssignedViewers] = useState<EmployeeAvatar[]>([]);

    const [managersDialogOpen, setManagersDialogOpen] = useState<boolean>(false);
    const [membersDialogOpen, setMembersDialogOpen] = useState<boolean>(false);
    const [viewersDialogOpen, setViewersDialogOpen] = useState<boolean>(false);

    const schema = yup.object().shape({
        name: yup.string().trim().required(),
        locations: yup.array().required(),
        managers: yup.array().required(),
        members: yup.array().required(),
        viewers: yup.array().required(),
        allMembersAreViewers: yup.boolean().default(true),
    });

    const formMethods = useForm<PlanningForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            locations: [],
            managers: [],
            members: [],
            viewers: [],
            allMembersAreViewers: true,
        },
    });

    const {
        handleSubmit,
        reset,
        setValue,
        formState: { isDirty },
        watch,
    } = formMethods;

    useEffect(() => {
        if (planningId && planning) {
            setAssignedMembers(planning.members);
            setAssignedManagers(planning.managers);
            setAssignedViewers(planning.viewers);

            const memberIds = planning.members?.map(member => member.id) ?? [];
            const viewerIds = planning.viewers?.map(viewer => viewer.id) ?? [];
            const managerIds = planning.managers?.map(manager => manager.id) ?? [];

            // Init switch state
            const memberAndManagerIds = [...memberIds, ...managerIds];

            // If the unique ids of the members and managers are the same as the unique ids of the viewers, then all members are viewers
            reset({
                ...planning,
                allMembersAreViewers: equals(viewerIds, memberAndManagerIds),
                locations: planning.locations,
            });
        }
    }, [planningId, planning, reset]);

    useEffect(() => {
        if (!planningId) {
            setValue('locations', locationOptions, { shouldDirty: false });
        }
    }, [locationOptions, planningId, setValue]);

    const onSave = async (data: Planning) => {
        const mutation: PlanningMutation = {
            name: data.name,
            locationIds: data.locations.map(location => location.id),
            managerIds: data.managers.map(manager => manager.id),
            memberIds: data.members.map(employee => employee.id),
            viewerIds: watch('allMembersAreViewers') ? data.members.map(manager => manager.id) : data.viewers.map(employee => employee.id),
        };
        if (!planningId) {
            try {
                const data = await createPlanning(mutation);
                reset(data);
                showSnackbar(t('planning_setting_page.planning_added'), 'success');
                navigate('/settings/permissions/planning');
            } catch (error) {
                handleError(error);
            }
        } else {
            try {
                const data = await updatePlanning(planningId, mutation);
                reset(data);
                showSnackbar(t('planning_setting_page.planning_updated'), 'success');
                navigate('/settings/permissions/planning');
            } catch (error) {
                handleError(error);
            }
        }
    };

    const onDelete = async () => {
        if (planningId) {
            try {
                await deletePlanning(planningId);
                showSnackbar(t('planning_setting_page.planning_deleted'), 'success');
                navigate('/settings/permissions/planning');
            } catch (error) {
                handleError(error);
            }
        }
    };

    const saveManagers = (assigned: Employee[]) => {
        setManagersDialogOpen(false);
        setAssignedManagers(assigned);
        setValue('managers', assigned, { shouldDirty: true });
    };

    const saveMembers = (assigned: Employee[]) => {
        setMembersDialogOpen(false);
        setAssignedMembers(assigned);
        setValue('members', assigned, { shouldDirty: true });
    };

    const saveViewers = (assigned: Employee[]) => {
        setViewersDialogOpen(false);
        setAssignedViewers(assigned);
        setValue('viewers', assigned, { shouldDirty: true });
    };

    const openConfirmationDialog = () => {
        setConfirmationDialogOpen(!confirmationDialogOpen);
    };

    const footerButtons: FooterActionsProps['actions'] = [];
    if (planningId) {
        footerButtons.push({
            name: 'delete',
            onClick: openConfirmationDialog,
            children: t('general.delete'),
            color: 'error',
            variant: 'contained',
        });
    }
    footerButtons.push({
        name: 'save',
        onClick: () => {
            handleSubmit(onSave, console.error)();
        },
        disabled: !isDirty,
        children: planningId ? t('general.update') : t('general.create'),
        variant: 'contained',
    });

    const isLoading = isPlanningLoading || isEmployeesLoading || isLocationsLoading;
    const isError = isEmployeesError || isLocationsError || isPlanningError;

    return (
        <Stack direction='column' justifyContent={'space-between'} flex={1}>
            <StateHandler isLoading={isLoading} isError={isError} error={planningError}>
                <FormProvider {...formMethods}>
                    <PlanningConfigurationForm
                        locationOptions={locationOptions}
                        assignedManagers={assignedManagers}
                        assignedMembers={assignedMembers}
                        assignedViewers={assignedViewers}
                        onAddManagers={() => setManagersDialogOpen(true)}
                        onAddMembers={() => setMembersDialogOpen(true)}
                        onAddViewers={() => setViewersDialogOpen(true)}
                    />
                </FormProvider>
            </StateHandler>

            {managersDialogOpen && (
                <EmployeesSelectionDialog
                    assignedEmployees={assignedManagers}
                    allEmployees={allEmployees}
                    open={managersDialogOpen}
                    onClose={() => setManagersDialogOpen(false)}
                    onSave={saveManagers}
                />
            )}

            {membersDialogOpen && (
                <EmployeesSelectionDialog
                    allEmployees={allEmployees}
                    assignedEmployees={assignedMembers}
                    open={membersDialogOpen}
                    onClose={() => setMembersDialogOpen(false)}
                    onSave={saveMembers}
                />
            )}

            {viewersDialogOpen && (
                <EmployeesSelectionDialog
                    assignedEmployees={assignedViewers}
                    allEmployees={allEmployees}
                    open={viewersDialogOpen}
                    onClose={() => setViewersDialogOpen(false)}
                    onSave={saveViewers}
                />
            )}
            {confirmationDialogOpen && (
                <ConfirmDialog
                    open={confirmationDialogOpen}
                    title={t('planning_setting_page.are_you_sure_delete_planning')}
                    content={t('general.action_irreversible')}
                    onClose={() => {
                        setConfirmationDialogOpen(!confirmationDialogOpen);
                    }}
                    onConfirm={() => {
                        onDelete().catch(handleError);
                        setConfirmationDialogOpen(!confirmationDialogOpen);
                    }}
                    // Override default confirm button props
                    confirmButtonProps={{
                        children: t('general.delete'),
                        color: 'error',
                    }}
                />
            )}
            <Footer>
                <FooterActions actions={footerButtons} />
            </Footer>
        </Stack>
    );
};

type PlanningConfigurationFormProps = {
    locationOptions: SelectOption[];
    assignedManagers: EmployeeAvatar[];
    assignedMembers: EmployeeAvatar[];
    assignedViewers: EmployeeAvatar[];
    onAddManagers: () => void;
    onAddMembers: () => void;
    onAddViewers: () => void;
};

const PlanningConfigurationForm: FC<PlanningConfigurationFormProps> = ({
    locationOptions,
    assignedManagers,
    assignedMembers,
    assignedViewers,
    onAddManagers,
    onAddMembers,
    onAddViewers,
}) => {
    const { t } = useTranslation();
    const {
        register,
        control,
        watch,
        formState: { errors },
    } = useFormContext<PlanningForm>();

    return (
        <Stack sx={{ width: '100%' }} component={Paper} p={2} elevation={1} gap={3}>
            <Stack gap={2} width={'500px'}>
                <Typography variant='h2'>{t('planning_setting_page.about')}</Typography>
                <FormControlLabel
                    label={t('planning_setting_page.name')}
                    labelPlacement='top'
                    control={<TextField fullWidth error={!!errors.name} helperText={errors.name?.message} {...register('name')} />}
                />
                <FormControlLabel
                    label={t('planning_setting_page.site')}
                    control={
                        <FieldSelect
                            name='locations'
                            control={control}
                            options={locationOptions as Location[]}
                            getOptionKey={option => option.id}
                            multiple
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={option => option.name}
                            fullWidth
                        />
                    }
                />
            </Stack>
            <Stack gap={2}>
                <Typography variant='h2'>{t('planning_setting_page.people')}</Typography>
                <Stack alignItems={'flex-start'}>
                    <Typography variant='body2'>{t('planning_setting_page.managers')}</Typography>

                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                        <Button sx={{ width: 'fit-content' }} variant='contained' endIcon={<AddTeamIcon />} color='primary' onClick={onAddManagers}>
                            {t('permissions_setting_page.choose_button_label')}
                        </Button>
                        <StackedAvatars employeeAvatars={assignedManagers} />
                    </Stack>
                </Stack>
                <Stack alignItems={'flex-start'}>
                    <Typography variant='body2'>{t('planning_setting_page.members')}</Typography>
                    <Stack direction={'row'} gap={1}>
                        <Button sx={{ width: 'fit-content' }} variant='contained' endIcon={<AddTeamIcon />} color='primary' onClick={onAddMembers}>
                            {t('permissions_setting_page.choose_button_label')}
                        </Button>
                        <StackedAvatars employeeAvatars={assignedMembers} />
                    </Stack>
                </Stack>
                <Stack>
                    <FormControlLabel
                        label={t('planning_setting_page.members_are_viewers')}
                        labelPlacement='end'
                        control={<FieldSwitch name='allMembersAreViewers' control={control} />}
                    />

                    {!watch('allMembersAreViewers') && (
                        <Stack>
                            <Typography variant='body2'>{t('planning_setting_page.viewers')}</Typography>
                            <Stack direction={'row'} gap={1}>
                                <Button variant='contained' endIcon={<AddTeamIcon />} color='primary' onClick={onAddViewers} sx={{ width: 'fit-content' }}>
                                    {t('permissions_setting_page.choose_button_label')}
                                </Button>
                                <StackedAvatars employeeAvatars={assignedViewers} />
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};
