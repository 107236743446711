import { employeeReviewFeedbackApi } from '@/api/employee-review-feedback/EmployeeReviewFeedback.api';
import {
    EmployeeReviewFeedback,
    EmployeeReviewFeedbackItem,
    EmployeeReviewFeedbackItemSearch,
    EmployeeReviewFeedbackMutation,
    EmployeeReviewFeedbackResult,
} from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { ReviewTemplateItemType } from '@/domain/review-template/ReviewTemplate.model';
import { ReviewItem } from '@/domain/review/Review.model';

import { EmployeeReviewSearch } from '@/domain/employee-review/EmployeeReview.model';
import {
    EmployeeReviewFeedbackForm,
    FeedbackSummarySkillFormType,
    QuestionFeedbackFormType,
    SkillQuestionFeedbackFormType,
} from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';
import { getLabelTranslation } from '@/utils/language.util';

export const flatEmployeeReviewFeedbacksItems = (feedbacksItems: EmployeeReviewFeedbackResult[]): EmployeeReviewFeedbackResult['items'] => {
    return feedbacksItems.flatMap(({ items }) => items);
};

export const groupEmployeeReviewFeedbackItemByQuestion = (
    feedbacksItems: EmployeeReviewFeedbackResult['items'],
): {
    question: string;
    items: EmployeeReviewFeedbackResult['items'];
}[] => {
    return feedbacksItems.reduce<
        {
            question: string;
            items: EmployeeReviewFeedbackResult['items'];
        }[]
    >((acc, feedbackItem) => {
        const question = getLabelTranslation(feedbackItem.reviewItem.title);
        const existingQuestion = acc.find(it => it.question === question);
        if (existingQuestion) {
            existingQuestion.items.push(feedbackItem);
        } else {
            acc.push({ question, items: [feedbackItem] });
        }
        return acc;
    }, []);
};

const questionTypes: ReviewTemplateItemType[] = ['QUESTION', 'PRIVATE_EMPLOYEE_QUESTION', 'PRIVATE_MANAGER_QUESTION'];

export const isQuestionFeedbackItem = (feedbackItem: { reviewItem: ReviewItem }): boolean => {
    return questionTypes.includes(feedbackItem.reviewItem.type);
};

export const isRatingQuestionFeedbackItem = (feedbackItem: { reviewItem: ReviewItem }): boolean => {
    return isQuestionFeedbackItem(feedbackItem) && !!feedbackItem.reviewItem.rating;
};

export const getQuestionsFromFeedbacksItems = (feedbacksItems: { reviewItem: ReviewItem }[]): string[] => {
    const questions = feedbacksItems.filter(isQuestionFeedbackItem).map(feedbackItem => getLabelTranslation(feedbackItem.reviewItem.title));
    return Array.from(new Set(questions)).filter(Boolean);
};

export const getEmployeeReviewFeedback = async (employeeReviewId: number): Promise<EmployeeReviewFeedback> => {
    return employeeReviewFeedbackApi.getFeedback(employeeReviewId);
};

export const getEmployeeReviewFeedbacksResultsItems = async (search: EmployeeReviewFeedbackItemSearch): Promise<EmployeeReviewFeedbackResult[]> => {
    return employeeReviewFeedbackApi.getFeedbacksResultsItems(search);
};

export const submitEmployeeReviewFeedback = async (
    employeeReviewId: number,
    mutation: EmployeeReviewFeedbackMutation[],
): Promise<EmployeeReviewFeedbackItem> => {
    return employeeReviewFeedbackApi.submitFeedback(employeeReviewId, mutation);
};

export const updateEmployeeReviewFeedbackItem = async (
    employeeReviewId: number,
    mutation: EmployeeReviewFeedbackMutation,
): Promise<EmployeeReviewFeedbackItem> => {
    return employeeReviewFeedbackApi.updateFeedbacksItem(employeeReviewId, mutation);
};

export const searchEmployeeReviewFeedbacks = async (request: EmployeeReviewSearch): Promise<EmployeeReviewFeedback[]> => {
    return employeeReviewFeedbackApi.searchFeedbacks(request);
};

export const hasEmployeeReviewFeedback = (reviewFeedback: Partial<EmployeeReviewFeedbackForm['selfFeedbackQuestion']>): boolean => {
    if (!reviewFeedback) {
        return false;
    }
    const { score, comment } = reviewFeedback;
    return (score && score > 0) || !!comment;
};

export const hasAtLeastOneSummaryOrFeedback = (summaryFeedbackSkillForm: EmployeeReviewFeedbackForm): boolean => {
    const hasAtLeastOneSummaryFeedback = hasEmployeeReviewFeedback(summaryFeedbackSkillForm);
    return hasAtLeastOneFeedback(summaryFeedbackSkillForm) || hasAtLeastOneSummaryFeedback;
};

export const hasAtLeastOneFeedback = (summaryFeedbackSkillForm: EmployeeReviewFeedbackForm): boolean => {
    const isAtLeastOneSelfFeedback = hasEmployeeReviewFeedback(summaryFeedbackSkillForm.selfFeedbackQuestion);

    const isAtLeastOneManagerFeedback = (summaryFeedbackSkillForm.managerFeedbackQuestion ?? []).some(managerFeedbackQuestion =>
        hasEmployeeReviewFeedback(managerFeedbackQuestion),
    );

    return isAtLeastOneSelfFeedback || isAtLeastOneManagerFeedback || isAtLeastOnePeerOrUpwardFeedback(summaryFeedbackSkillForm);
};

export const hasOnlyOneFeedback = (
    summaryFeedbackSkillForm: QuestionFeedbackFormType | SkillQuestionFeedbackFormType | FeedbackSummarySkillFormType,
): boolean => {
    const hasSelfFeedback = summaryFeedbackSkillForm?.selfFeedbackQuestion?.score ?? summaryFeedbackSkillForm?.selfFeedbackQuestion?.comment;
    const hasManagerFeedback = (summaryFeedbackSkillForm.managerFeedbackQuestion ?? []).some(
        managerFeedbackQuestion => managerFeedbackQuestion?.score ?? managerFeedbackQuestion?.comment,
    );
    const hasOnlySelfFeedback = hasSelfFeedback && !hasManagerFeedback;
    const hasOnlyManagerFeedback = !hasSelfFeedback && hasManagerFeedback;
    return hasOnlySelfFeedback || hasOnlyManagerFeedback;
};

export const isAtLeastOnePeerOrUpwardFeedback = (
    summaryFeedbackSkillForm: QuestionFeedbackFormType | SkillQuestionFeedbackFormType | FeedbackSummarySkillFormType,
): boolean => {
    const isAtLeastOnePeerFeedback = (summaryFeedbackSkillForm.peerFeedbackQuestion ?? []).some(
        peerFeedbackQuestion => (peerFeedbackQuestion?.score && peerFeedbackQuestion.score > 0) || peerFeedbackQuestion?.comment,
    );
    const isAtLeastOneUpwardFeedback = (summaryFeedbackSkillForm.upwardFeedbackQuestion ?? []).some(
        upwardFeedbackQuestion => (upwardFeedbackQuestion?.score && upwardFeedbackQuestion.score > 0) || upwardFeedbackQuestion?.comment,
    );
    return isAtLeastOnePeerFeedback || isAtLeastOneUpwardFeedback;
};
