import { FilePickerProps } from '@/components/file-picker/FilePicker';
import { FieldText } from '@/components/form/field-text/FieldText';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';
import { LeaveRequestFormValues } from '@/page/leave/leave-request-dialog/LeaveRequestForm.schema';
import { Alert, FormControlLabel, Typography } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LeaveRequestAttachmentsUploader } from './LeaveRequestAttachmentsUploader';

export type CommonLeaveTypeDetailsProps = FilePickerProps & {
    leaveType: LeaveType;
    durationInDays: number;
};

export const CommonLeaveTypeDetails: FC<CommonLeaveTypeDetailsProps> = ({ leaveType, durationInDays, ...rest }) => {
    const { t } = useTranslation();

    const { control, watch } = useFormContext<LeaveRequestFormValues>();

    const attachmentIsRecommended = (leaveType.attachmentRequiredAfterDays ?? 0) < durationInDays;

    const isMultipleDays = watch('isMultipleDays');

    return (
        <>
            {leaveType.allowAttachments && !isMultipleDays && (
                <>
                    <LeaveRequestAttachmentsUploader leaveType={leaveType} {...rest} />
                    {!rest?.files?.length && attachmentIsRecommended && (
                        <Alert severity='warning'>
                            <Typography variant='body2'>
                                {t('request_leave_dialog.messages.upload_necessary', { days: leaveType.attachmentRequiredAfterDays })}
                            </Typography>
                        </Alert>
                    )}
                </>
            )}
            <FormControlLabel
                label={t('request_leave_dialog.comment')}
                style={{ width: '100%' }}
                control={<FieldText name={'comment'} control={control} fullWidth placeholder={t('request_leave_dialog.comment_placeholder')} />}
            />
        </>
    );
};
