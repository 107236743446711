import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { ReorderItem, ReorderModal } from '@/components/reorder-modal/ReorderModal';
import { mapToReorderRequest } from '@/domain/common';
import { getLeaveActivityTypeTranslationKey, LeaveType } from '@/domain/leave-type/LeaveType.model';
import { deleteLeaveType, getLeaveTypes, updateLeavesTypeOrder } from '@/domain/leave-type/LeaveType.service';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { ConfigType } from '@/page/setting/types';
import { LeavesActionType } from '@/stores/reducers/leavesActions';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { ICellRendererParams } from 'ag-grid-community';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { ColorDot } from '@/components/color-dot/ColorDot';

export const LeaveTypesSettingsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const leaveTypes = useAppSelector(state => state.leaves.leaveTypes);
    const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);

    useEffect(() => {
        getLeaveTypes()
            .then(leaveTypes => {
                dispatch({ type: LeavesActionType.LEAVE_TYPES_LOADED, leaveTypes });
            })
            .catch(handleError);
    }, [dispatch]);

    const handleDelete = async (id: number) => {
        try {
            await deleteLeaveType(id);
            showSnackbar(t('leave_settings_page.deleted_leave_type'), 'success');
            const newLeaveTypes = await getLeaveTypes();
            dispatch({
                type: LeavesActionType.LEAVE_TYPES_LOADED,
                leaveTypes: newLeaveTypes,
            });
        } catch (e) {
            handleError(e);
        }
    };

    const getMenuItems = (params: ICellRendererParams<LeaveType>) => {
        return [
            {
                title: t('general.edit'),
                onClick: () => {
                    if (params.data?.id) {
                        navigate(`/settings/time-management/leaves/${params.data.id}`);
                    }
                },
            },
            {
                title: t('general.delete'),
                onClick: () => params.data?.id && handleDelete(params.data.id),
            },
        ];
    };

    const cellActionRenderer = (params: ICellRendererParams<LeaveType>) => {
        return <BasicMenu items={getMenuItems(params)} />;
    };

    const dotColorRenderer = (params: ICellRendererParams<LeaveType>) => {
        return <ColorDot color={params.data?.color} height={'15px'} width={'15px'} />;
    };

    const config: ConfigType<LeaveType> = {
        type: 'table',
        header: {
            primaryButtonCaption: `${t('general.add')} ${t('leave_settings_page.leave_type')}`,
            defaultButtonCaption: t('permissions_setting_page.reorder'),
            defaultButtonAction: () => handleOrderModal(),
        },
        isOnRowClickActive: true,
        table: {
            columns: [
                {
                    headerName: '',
                    cellRenderer: dotColorRenderer,
                    maxWidth: 31, //perfect size for the dot
                    cellClass: ['display-flex'],
                },
                {
                    field: 'name',
                    type: 'label',
                    headerName: t('leave_settings_page.table_headers.leave_type'),
                },
                {
                    field: 'leaveActivityType',
                    headerName: t('leave_settings_page.table_headers.activity'),
                    cellRenderer: (row: { data: LeaveType }) => t(getLeaveActivityTypeTranslationKey(row.data.leaveActivityType)),
                },
                {
                    type: 'actionMenu',
                    cellRenderer: cellActionRenderer,
                },
            ],
        },
    };

    const handleOrderModal = () => {
        setIsOrderModalOpen(true);
    };

    const getReorderItems = (leaveTypes: LeaveType[]) => {
        return leaveTypes.map(item => {
            return {
                id: item.id,
                name: getLabelTranslation(item.name),
                order: item.order,
            };
        });
    };

    const handleReorderSave = async (reorderItems: ReorderItem[]) => {
        const reorderRequest = mapToReorderRequest(reorderItems);

        try {
            await updateLeavesTypeOrder(reorderRequest);
            const newLeaveTypes = await getLeaveTypes();
            dispatch({
                type: LeavesActionType.LEAVE_TYPES_LOADED,
                leaveTypes: newLeaveTypes,
            });
        } catch (error) {
            handleError(error);
        } finally {
            setIsOrderModalOpen(false);
        }
    };

    return (
        <>
            <CompanySettingsLayout options={config} data={leaveTypes} />

            {isOrderModalOpen && (
                <ReorderModal
                    initialReorderItems={getReorderItems(leaveTypes ?? [])}
                    open={true}
                    onSave={handleReorderSave}
                    onClose={() => {
                        setIsOrderModalOpen(false);
                    }}
                />
            )}
        </>
    );
};
