import { employmentAPI } from '@/api/employment/Employment.api';
import { CalendarDay, CalendarDayType } from '@/domain/calendar/Calendar.model';
import { Employee } from '@/domain/employee/Employee.model';
import {
    CONTRACT_PROBATION_PERIOD_MONTHS,
    ContractCreationMutation,
    Employment,
    EmploymentBulkMutation,
    EmploymentCreateReason,
    EmploymentCreationMutation,
    EmploymentsSearchRequest,
    EmploymentUpdateMutation,
    ModifyContractMutation,
} from '@/domain/employment/Employment.model';
import { LongLeave } from '@/domain/long-leave/LongLeave.model';
import {
    addMonths,
    compareAsc,
    compareDesc,
    differenceInDays,
    formatDurationForDates,
    getCurrentLocalDate,
    getYesterday,
    isAfterDate,
    isAfterOrEqualDate,
    isBeforeDate,
    isBeforeOrEqualDate,
    isBetweenDates,
    isFutureDate,
    isSameDate,
    isTodayDate,
    subDaysAndFormat,
} from '@/utils/datetime.util';
import { t } from 'i18next';
import { EmploymentStatus } from './Employment.model';
import { Job } from '@/domain/job/Job.model';

export const getEmployments = (employmentsSearchMutation: EmploymentsSearchRequest): Promise<Employment[]> => {
    return employmentAPI.searchEmployments(employmentsSearchMutation);
};

export const cancelTermination = (id: number): Promise<Employment> => {
    return employmentAPI.cancelTermination(id);
};

export const modifyContract = (id: number, mutation: ModifyContractMutation): Promise<Employment> => {
    return employmentAPI.modifyContract(id, mutation);
};

export const createEmployment = (mutation: EmploymentCreationMutation): Promise<Employment[]> => {
    return employmentAPI.createEmployment(mutation);
};

export const updateEmployment = (id: number, mutation: EmploymentUpdateMutation): Promise<Employment[]> => {
    return employmentAPI.updateEmployment(id, mutation);
};

export const bulkCreateEmployment = (mutations: EmploymentBulkMutation[]): Promise<Employment[]> => {
    return employmentAPI.bulkCreateEmployment(mutations);
};

export const deleteEmployment = (id: number): Promise<void> => {
    return employmentAPI.deleteEmployment(id);
};

export const createContract = (mutation: ContractCreationMutation): Promise<Employment[]> => {
    return employmentAPI.createContract(mutation);
};

export const deleteContract = (id: number): Promise<void> => {
    return employmentAPI.deleteContract(id);
};

export const getDaysUntilProbationEnd = (probationEndDate: LocalDate): number => {
    const today = getCurrentLocalDate();
    const diffInDays = differenceInDays(probationEndDate, today);
    if (diffInDays === 0 && isSameDate(probationEndDate, today)) {
        return 0;
    }
    return diffInDays;
};

export const getCurrentContract = (employments: Employment[]): Employment | undefined => {
    // group employment into contracts
    const contracts = getContracts(employments);

    const getPresentContract = (employments: Employment[]) => {
        return employments.find(
            employment =>
                employment.startDate &&
                employment.principal &&
                // check if start date is today or before today
                (isTodayDate(employment.startDate) || isBeforeDate(employment.startDate, getCurrentLocalDate())) &&
                (!employment.endDate || isTodayDate(employment.endDate) || isFutureDate(employment.endDate)),
        );
    };

    const getPreviousContract = (employments: Employment[]) => {
        const pastEmployments = employments.filter(employment => employment.endDate && isBeforeDate(employment.endDate, getCurrentLocalDate()));

        if (pastEmployments?.length === 0) {
            return undefined;
        }

        pastEmployments.sort((a, b) => (a.startDate && b.startDate ? compareDesc(a.startDate, b.startDate) : 0));

        return pastEmployments[0];
    };

    const getNextContract = (employments: Employment[]) => {
        const futureEmployments = employments.filter(employment => employment.startDate && isAfterDate(employment.startDate, getCurrentLocalDate()));

        if (futureEmployments?.length === 0) {
            return undefined;
        }

        futureEmployments.sort((a, b) => (a.startDate && b.startDate ? compareAsc(a.startDate, b.startDate) : 0));

        return futureEmployments[0];
    };

    return (
        getPresentContract(contracts) || // current between start - end
        getNextContract(contracts) || // future employment
        getPreviousContract(contracts) // left company > last employment
    );
};

export const getContracts = (employments: Employment[]): Employment[] => {
    const sortedEmployments = [...employments].sort((a, b) => (a.startDate && b.startDate ? compareAsc(a.startDate, b.startDate) : 0));
    return sortedEmployments.reduce((acc, employment) => {
        switch (employment.employmentCreateReason) {
            case EmploymentCreateReason.NEW_EMPLOYEE:
            case EmploymentCreateReason.REHIRED:
                if (employment.principal) {
                    acc.push(employment);
                }
                return acc;
            default:
                acc[acc.length - 1] = {
                    ...acc[acc.length - 1],
                    endDate: employment.endDate,
                    contractType:
                        employment.startDate && isBeforeDate(employment.startDate, getCurrentLocalDate())
                            ? employment.contractType
                            : acc[acc.length - 1].contractType,
                    terminationNoticeDate: employment.terminationNoticeDate,
                    terminationLastDayAtWork: employment.terminationLastDayAtWork,
                    terminationType: employment.terminationType,
                    terminationReason: employment.terminationReason,
                    terminationComment: employment.terminationComment,
                };

                return acc;
        }
    }, [] as Employment[]);
};

/**
 * Get the contract related to the principal employment based on the start date
 * @param employments
 * @param principalEmploymentStartDate
 */
export const getContractByEmployment = (employments: Employment[], principalEmploymentStartDate: LocalDate): Employment | undefined => {
    const contracts = getContracts(employments);
    return contracts.find(contract => {
        return contract.endDate
            ? isBetweenDates(principalEmploymentStartDate, contract.startDate, contract.endDate)
            : isAfterOrEqualDate(principalEmploymentStartDate, contract.startDate);
    });
};

export const isContractTerminated = (contract: Employment | undefined): boolean => {
    return !!contract && !!contract.endDate && !!contract.terminationType;
};

/**
 * Check if the start date is allowed based on the allowed ranges
 * @param date
 * @param allowedRanges
 * @returns true if the start date is allowed based on the allowed ranges or if there are no allowed ranges
 */
export const isDateInAllowedRanges = (date: LocalDate, allowedRanges: [LocalDate, LocalDate | undefined][]): boolean => {
    const isInRange = (date: LocalDate, range: [LocalDate, LocalDate | undefined]) => {
        const [start, end] = range;
        return end ? isBetweenDates(date, start, end) : isAfterOrEqualDate(date, start);
    };
    // return true if the start date is in one of the allowed ranges
    return allowedRanges.length === 0 || allowedRanges.some(range => isInRange(date, range));
};

const getEmployedStatusDisplay = (contract: Employment, defaultStartDay: LocalDate): string => {
    const endDate = contract.endDate;
    const startDate = contract.startDate ? contract.startDate : getCurrentLocalDate();
    if (endDate) {
        if (isTodayDate(endDate)) {
            return t('employee.employment.employment_status_full.future_former_employee_today');
        }
        return t('employee.employment.employment_status_full.future_former_employee', {
            duration: formatDurationForDates(defaultStartDay, endDate),
        });
    }

    return t('employee.employment.employment_status_full.employee', {
        duration: formatDurationForDates(startDate, getCurrentLocalDate()),
    });
};

export const getCurrentEmploymentStatusLabelDisplay = (employmentStatus: EmploymentStatus, employments: Employment[], longLeave?: LongLeave): string => {
    const contract = getCurrentContract(employments);
    if (!contract) {
        return '';
    }

    const endDate = contract?.endDate ?? getCurrentLocalDate();

    const statusDisplay = {
        [EmploymentStatus.HIRED]: t('employee.employment.employment_status_full.hired', {
            duration: formatDurationForDates(getCurrentLocalDate(), contract?.startDate ?? getCurrentLocalDate()),
        }),
        [EmploymentStatus.EMPLOYED]: getEmployedStatusDisplay(contract, getYesterday()),
        [EmploymentStatus.ON_LONG_LEAVE]: t('employee.employment.employment_status_full.on_long_leave', {
            duration: formatDurationForDates(getCurrentLocalDate(), longLeave?.endDate ?? getCurrentLocalDate()),
        }),
        [EmploymentStatus.TERMINATED]: isSameDate(endDate, getYesterday())
            ? t('employee.employment.employment_status_full.former_yesterday')
            : t('employee.employment.employment_status_full.former', {
                  duration: formatDurationForDates(endDate, getCurrentLocalDate()),
              }),
    };

    return statusDisplay[employmentStatus] || '';
};

export const getCurrentPrincipalEmployment = (employee: Employee): Employment | undefined =>
    employee?.currentEmployments?.find(employment => employment.principal);

const getPublicHolidays = (calendarDays: CalendarDay[], min?: LocalDate, max?: LocalDate): CalendarDay[] => {
    // return a copy because sort function sort the original array
    return [...calendarDays]
        .filter(day => {
            const isHoliday = day.dayType === CalendarDayType.HOLIDAY;
            const isInRange = (!min || isAfterOrEqualDate(day.date, min)) && (!max || isBeforeOrEqualDate(day.date, max));
            return isHoliday && isInRange;
        })
        .sort((calendarDay1, calendarDay2) => compareAsc(calendarDay1.date, calendarDay2.date));
};

export const calculateProbationEndDate = (startDate: LocalDate): LocalDate => {
    // The probation end date not included the last day of the probation period
    return subDaysAndFormat(addMonths(startDate, CONTRACT_PROBATION_PERIOD_MONTHS), 1);
};

/**
 * Get the allowed ranges for the start date to avoid creating an employment outside of contracts
 * @param startDate startDate of the employment when editing
 * @param allEmployments all existing employments
 */
export const getAllowedStartDateRanges = (startDate: LocalDate | undefined, allEmployments: Employment[]): [LocalDate, LocalDate | undefined][] => {
    // related contract when editing an employment
    const relatedContract = startDate ? getContractByEmployment(allEmployments, startDate) : undefined;
    // all existing contract
    const allContracts = getContracts(allEmployments);

    // Get the allowed ranges for the start date to avoid creating an employment outside of contracts
    // or  editing the start date outside the related contract
    return relatedContract ? [[relatedContract.startDate, relatedContract.endDate]] : allContracts.map(contract => [contract.startDate, contract.endDate]);
};

export const getUniqueEmploymentsJobs = (employments: Employment[]): Job[] => {
    return [...new Map(employments.map(({ job }) => [job.id, job])).values()];
};

export const employmentService = {
    getPublicHolidays,
};
