import { StateHandler } from '@/components/state-handler/StateHandler';
import { useGetLeaveTypeById, useGetLeaveTypes } from '@/hooks/leave-type/LeaveType.hook';
import { LeaveTypeSettingForm } from '@/page/setting/time-management/leave-type-setting-form/LeaveTypeSettingForm';
import { FC } from 'react';
import { useParams } from 'react-router';

export const LeaveTypeSettingPage: FC = () => {
    const params = useParams();
    const leaveTypeId = Number(params.leaveTypeId);

    const { data: leaveType, isFetching, isError: isErrorLeaveType, error: errorLeaveType } = useGetLeaveTypeById(leaveTypeId);
    const { data: leaveTypes, isLoading: isLoadingLeaveTypes, isError: isErrorLeaveTypes } = useGetLeaveTypes();

    const isEmpty = !!leaveTypeId && !leaveType;

    const biggestOrder = leaveTypes?.reduce((acc, leaveType) => (leaveType.order > acc ? leaveType.order : acc), 0) ?? 0;
    const order = biggestOrder > 0 ? biggestOrder + 1 : 0;

    return (
        <StateHandler isLoading={isFetching || isLoadingLeaveTypes} isError={isErrorLeaveType || isErrorLeaveTypes} isEmpty={isEmpty} error={errorLeaveType}>
            <LeaveTypeSettingForm leaveType={leaveType} order={leaveType?.order ?? order} />
        </StateHandler>
    );
};
