import {
    EmployeeSection,
    EmployeeSectionRow as EmployeeSectionRowDomain,
    EmployeeSectionRowCreationMutation,
    EmployeeSectionSearch,
} from '@/domain/employee-section/EmployeeSection.model';
import { employeeSectionApi } from '@/api/employee-section/EmployeeSection.api';
import { DocumentContentDisposition } from '@/domain/document/Document.model';

export const getEmployeeSections = (employeeSectionSearchMutation: EmployeeSectionSearch): Promise<EmployeeSection[]> => {
    return employeeSectionApi.searchEmployeeSections(employeeSectionSearchMutation);
};

export const getEmployeeSectionRow = (ids: { employeeSectionId: number; rowId: number }): Promise<EmployeeSectionRowDomain> => {
    return employeeSectionApi.getEmployeeSectionRow(ids.employeeSectionId, ids.rowId);
};

export const addEmployeeSectionRow = (employeeSectionId: number, mutation: EmployeeSectionRowCreationMutation): Promise<EmployeeSectionRowDomain> => {
    return employeeSectionApi.addEmployeeSectionRow(employeeSectionId, mutation);
};

export const createEmployeeSectionRowPendingRequest = (
    employeeSectionId: number,
    mutation: EmployeeSectionRowCreationMutation,
): Promise<EmployeeSectionRowDomain> => {
    return employeeSectionApi.createEmployeeSectionRowPendingRequest(employeeSectionId, mutation);
};

export const updateEmployeeSectionRowPendingRequest = (
    employeeSectionId: number,
    pendingRowId: number,
    mutation: EmployeeSectionRowCreationMutation,
): Promise<EmployeeSectionRowDomain> => {
    return employeeSectionApi.updateEmployeeSectionRowPendingRequest(employeeSectionId, pendingRowId, mutation);
};

export const updateEmployeeSectionRowApprovedRequest = (
    employeeSectionId: number,
    pendingRowId: number,
    mutation: EmployeeSectionRowCreationMutation,
): Promise<EmployeeSectionRowDomain> => {
    return employeeSectionApi.updateEmployeeSectionRowApprovedRequest(employeeSectionId, pendingRowId, mutation);
};

export const updateEmployeeSectionRow = (
    employeeSectionId: number,
    rowId: number,
    mutation: EmployeeSectionRowCreationMutation,
): Promise<EmployeeSectionRowDomain> => {
    return employeeSectionApi.updateEmployeeSectionRow(employeeSectionId, rowId, mutation);
};

export const deleteEmployeeSectionRow = (employeeSectionId: number, rowId: number): Promise<void> => {
    return employeeSectionApi.deleteEmployeeSectionRow(employeeSectionId, rowId);
};

export const deleteEmployeeSectionRowPendingRequest = (employeeSectionId: number, rowId: number): Promise<void> => {
    return employeeSectionApi.deleteEmployeeSectionRowPendingRequest(employeeSectionId, rowId);
};

export const approveEmployeeCustomSectionRowPendingRequest = (employeeSectionId: number, rowId: number): Promise<void> => {
    return employeeSectionApi.approveEmployeeCustomSectionRowPendingRequest(employeeSectionId, rowId);
};

export const getEmployeeSectionFieldDocumentUrl = (
    employeeSectionFieldDocumentId: number,
    documentContentDisposition: DocumentContentDisposition,
): Promise<string> => {
    return employeeSectionApi.getEmployeeSectionFieldDocumentUrl(employeeSectionFieldDocumentId, documentContentDisposition);
};
