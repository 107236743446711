import {
    Box,
    Button,
    Drawer,
    Fade,
    IconButton,
    List,
    ListItemButton,
    listItemButtonClasses,
    ListItemText,
    Stack,
    styled,
    Toolbar,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
    useTheme,
} from '@mui/material';
import { FC, forwardRef, Fragment, useEffect, useState } from 'react';

import { LogoRoger } from '@/components/logo-roger/LogoRoger';
import { SIDEBAR_SUBMENUS_Z_INDEX } from '@/config/ui.constants';
import { useSideBar } from '@/hooks/layout/Sidebar.hook';
import { useLocalStorage } from '@/hooks/Storage.hook';
import { homePage } from '@/page/home/pending/HomePages';
import { managePeopleSidebarLabelKey, myProfileSidebarLabelKey, PagesGroupConfig, useGetPagesGroupsConfig } from '@/page/Pages';
import { settingsPage } from '@/page/setting/CompanySettingsPages';
import { UiActionType } from '@/stores/reducers/uiSlice';
import { useAppDispatch } from '@/stores/store';
import { ArrowLeft01Icon as ArrowLeftIcon, CircleArrowLeft02Icon } from 'hugeicons-react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, LinkProps as RouterLinkProps, useLocation, useNavigate } from 'react-router';

export const Sidebar: FC = () => {
    const [expandedMenu, setExpandedMenu] = useLocalStorage('expandedMenu', false);
    const [currentElement, setCurrentElement] = useState<PagesGroupConfig | undefined>(undefined);
    const navigate = useNavigate();
    const open = !!currentElement;
    const dispatch = useAppDispatch();
    const { drawerWidth } = useSideBar();

    const navigationGroups = useGetPagesGroupsConfig();
    const navigationGroupsWithoutSetting = navigationGroups.filter(element => element.labelKey !== settingsPage.labelKey);
    const settingNavigationGroup = navigationGroups.filter(element => element.labelKey === settingsPage.labelKey);

    const closeDrawer = () => () => {
        setCurrentElement(undefined);
    };

    // Sync expandedMenu state with redux
    useEffect(() => {
        dispatch({ type: UiActionType.EXPAND_MENU, expand: expandedMenu });
    }, [dispatch, expandedMenu]);

    const shouldNavigateOnClick = (pagesGroupConfig: PagesGroupConfig) => {
        // for now, we don't want to show the drawer for my profile
        const isProfile = pagesGroupConfig.labelKey === myProfileSidebarLabelKey;
        return pagesGroupConfig.pages.length === 1 || isProfile;
    };

    const isSpecialKeyPressed = (e: React.MouseEvent) => {
        return e.metaKey || e.ctrlKey || e.shiftKey || e.altKey;
    };

    const handleNavigation = (navigationGroup: PagesGroupConfig) => {
        navigate(navigationGroup.pages.at(0)?.path ?? '/');
        setCurrentElement(undefined);
    };

    const toggleCurrentElement = (navigationGroup: PagesGroupConfig, currentElement: PagesGroupConfig | undefined) => {
        if (currentElement?.labelKey === navigationGroup.labelKey) {
            setCurrentElement(undefined);
        } else {
            setCurrentElement(navigationGroup);
        }
    };

    const onMenuClick = (navigationGroup: PagesGroupConfig, e: React.MouseEvent) => {
        // Prevent additional navigation when cmd/ctrl (new tab), shift key (new window), alt key (download) is pressed
        if (isSpecialKeyPressed(e)) {
            return;
        }

        if (shouldNavigateOnClick(navigationGroup)) {
            handleNavigation(navigationGroup);
            return;
        }

        toggleCurrentElement(navigationGroup, currentElement);
    };

    return (
        <>
            <Toolbar disableGutters sx={{ paddingLeft: 1, elevation: 2 }}>
                <LogoRoger variant='light' logoOnly={!expandedMenu} />
            </Toolbar>
            <Stack flex='1' flexWrap='nowrap' px={1} justifyContent='space-between'>
                <List sx={{ height: '100%' }}>
                    <Stack justifyContent='space-between' sx={{ height: '100%' }}>
                        <Stack>
                            {navigationGroupsWithoutSetting.map(element => (
                                <MenuListItem
                                    key={element.labelKey}
                                    pagesGroupConfig={element}
                                    onMenuClick={onMenuClick}
                                    currentElement={currentElement}
                                    expandedMenu={expandedMenu}
                                    shouldNavigateOnClick={shouldNavigateOnClick}
                                />
                            ))}
                        </Stack>
                        <Stack>
                            {settingNavigationGroup.map(element => (
                                <MenuListItem
                                    key={element.labelKey}
                                    pagesGroupConfig={element}
                                    onMenuClick={onMenuClick}
                                    currentElement={currentElement}
                                    expandedMenu={expandedMenu}
                                    shouldNavigateOnClick={shouldNavigateOnClick}
                                />
                            ))}
                            <Stack p={1}>
                                <CircleArrowLeft02Icon
                                    onClick={() => setExpandedMenu(prev => !prev)}
                                    style={{
                                        transform: expandedMenu ? 'rotate(0deg)' : 'rotate(-180deg)',
                                        cursor: 'pointer',
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </List>

                <Drawer
                    PaperProps={{
                        style: {
                            position: 'absolute',
                            width: '220px',
                            left: drawerWidth,
                        },
                        elevation: 2,
                    }}
                    variant={'temporary'}
                    anchor='left'
                    open={open}
                    onClose={closeDrawer()}
                    ModalProps={{ style: { zIndex: SIDEBAR_SUBMENUS_Z_INDEX } }}
                    BackdropProps={{ style: { backgroundColor: 'transparent' } }}
                >
                    {currentElement && <DrawerContent pagesGroupConfig={currentElement} handleClose={closeDrawer()} />}
                </Drawer>
            </Stack>
        </>
    );
};

type MenuListItemProps = {
    pagesGroupConfig: PagesGroupConfig;
    onMenuClick: (pagesGroupConfig: PagesGroupConfig, e: React.MouseEvent) => void;
    currentElement: PagesGroupConfig | undefined;
    shouldNavigateOnClick: (pagesGroupConfig: PagesGroupConfig) => boolean;
    expandedMenu: boolean;
};

const MenuListItem: FC<MenuListItemProps> = ({ pagesGroupConfig, onMenuClick, currentElement, expandedMenu, shouldNavigateOnClick }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { labelKey, icon } = pagesGroupConfig;

    const isSelectedMenu = (pagesGroupConfig: PagesGroupConfig): boolean => {
        const { labelKey, pages } = pagesGroupConfig;

        const isRootPath = location.pathname === '/';
        const isProfilePath = location.pathname.startsWith('/profile');
        const isPeoplePath = location.pathname.startsWith('/people');
        const isOtherLabelKey = ![homePage.labelKey, myProfileSidebarLabelKey, managePeopleSidebarLabelKey].includes(labelKey);

        const isHomePage = labelKey === homePage.labelKey && isRootPath;
        // The profile page is selected when the path is '/profile' and not '/profile/{id}'
        const isProfilePage = labelKey === myProfileSidebarLabelKey && isProfilePath && !isOtherProfilePage(location.pathname);
        // the people page is selected when the path is '/people' or '/profile/{id}'
        const isPeoplePage = labelKey === managePeopleSidebarLabelKey && (isPeoplePath || isOtherProfilePage(location.pathname));
        const isOtherPage = isOtherLabelKey && pages.some(page => isMatchingPagePath(page.path));

        return isHomePage || isProfilePage || isPeoplePage || isOtherPage;
    };

    const isMatchingPagePath = (pagePath: string): boolean => {
        const firstPathSegment = pagePath.split('/')[1]; // Extracts the first segment after the root '/'
        const targetPath = `/${firstPathSegment}`; // Constructs the path segment to compare
        return location.pathname.startsWith(targetPath); // Checks if the current location starts with the segment
    };

    const isOtherProfilePage = (path: string): boolean => {
        // Matches '/profile/{id}' format
        return /^\/profile\/\d/.test(path);
    };

    const isElementSelected = (pagesGroupConfig: PagesGroupConfig) => {
        return currentElement?.labelKey === pagesGroupConfig.labelKey;
    };

    return (
        <Fragment key={labelKey}>
            <SidebarTooltip title={!expandedMenu ? <Typography variant='body1'>{t(labelKey)}</Typography> : undefined}>
                <ListItemButton
                    onClick={e => onMenuClick(pagesGroupConfig, e)}
                    // Allows right-click functionality on the button when the element is a direct navigation link.
                    // Uses the Link component for direct navigation, otherwise falls back to a Button component
                    {...(shouldNavigateOnClick(pagesGroupConfig)
                        ? {
                              component: Link,
                              to: pagesGroupConfig.pages.at(0)?.path,
                          }
                        : {
                              component: Button,
                              to: undefined,
                          })}
                    sx={{
                        height: '36px',
                        marginY: 0.25,
                        paddingX: 0,
                        borderRadius: 1,
                        backgroundColor: isElementSelected(pagesGroupConfig) ? theme.palette.primary.light : 'inherit',
                        color: isElementSelected(pagesGroupConfig) ? theme.palette.primary.main : 'inherit',
                        [`&.${listItemButtonClasses.selected}`]: {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.common.white,
                        },
                        '&:hover': {
                            backgroundColor: theme.palette.primary.light,
                            color: theme.palette.primary.dark,
                        },

                        [`&.${listItemButtonClasses.selected}:focus, &.${listItemButtonClasses.selected}:hover`]: {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.common.white,
                        },
                        transition: '0.3s',
                    }}
                    dense
                    selected={isSelectedMenu(pagesGroupConfig)}
                    aria-label={t(pagesGroupConfig.labelKey)}
                >
                    <Stack direction='row' flex={1} gap={1} alignItems='center' p={1} flexWrap='nowrap'>
                        <Box display='flex'>{icon}</Box>
                        {expandedMenu && (
                            <Fade
                                in={expandedMenu}
                                timeout={{
                                    enter: 300,
                                }}
                            >
                                <ListItemText
                                    primaryTypographyProps={{ color: 'inherit' }}
                                    sx={{
                                        marginY: 0,
                                    }}
                                >
                                    {t(labelKey)}
                                </ListItemText>
                            </Fade>
                        )}
                    </Stack>
                </ListItemButton>
            </SidebarTooltip>
        </Fragment>
    );
};

const SidebarTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow={false} placement={'right'} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        boxShadow: theme.shadows[1],
        padding: theme.spacing(1),
    },
}));

type DrawerContentProps = {
    pagesGroupConfig: PagesGroupConfig;
    handleClose: () => void;
};

const DrawerContent: FC<DrawerContentProps> = ({ pagesGroupConfig, handleClose }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const location = useLocation();

    const isCurrentPage = (path: string) => {
        return location.pathname.startsWith(path);
    };

    return (
        <Stack direction={'row'} flex={1}>
            <Stack flex={1}>
                <Stack alignSelf={'flex-end'} p={1}>
                    <IconButton onClick={handleClose} name={'closeDrawer'}>
                        <ArrowLeftIcon />
                    </IconButton>
                </Stack>
                <Stack p={1} gap={1}>
                    <Typography variant={'h2'} sx={{ px: 1 }}>
                        {t(pagesGroupConfig.labelKey)}
                    </Typography>
                    <List>
                        <Stack gap={1}>
                            {pagesGroupConfig.pages.map(page => (
                                <ListItemButton
                                    sx={{
                                        p: 1,
                                        py: 0.5,
                                        borderRadius: 1,
                                        '&:hover': {
                                            backgroundColor: theme.palette.primary.light,
                                            '& .MuiTypography-root': {
                                                color: theme.palette.primary.main,
                                            },
                                        },
                                        [`&.${listItemButtonClasses.selected}`]: {
                                            backgroundColor: theme.palette.primary.light,
                                            '& .MuiTypography-root': {
                                                color: theme.palette.primary.main,
                                            },
                                        },
                                    }}
                                    selected={isCurrentPage(page.path)}
                                    key={page.path}
                                    component={Link}
                                    to={page.path}
                                    onClick={handleClose}
                                >
                                    <Typography variant={'body1'}>{t(page.labelKey)}</Typography>
                                </ListItemButton>
                            ))}
                        </Stack>
                    </List>
                </Stack>
            </Stack>
        </Stack>
    );
};

const Link = forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(itemProps, ref) {
    return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});
