import { documentAPI } from '@/api/document/Document.api';
import { getAppConfig } from '@/config/config';
import {
    CompanyDocumentCreationMutation,
    CompanyDocumentLinkCreationMutation,
    Document,
    DocumentContentDisposition,
    EmployeeDocumentSearch,
    DocumentUpdateMutation,
    EmployeeDocumentCreationMutation,
    EmployeeDocumentLinkCreationMutation,
    EmployeeFolder,
    Folder,
    FolderMutation,
    FolderType,
    CompanyDocumentSearch,
} from '@/domain/document/Document.model';
import { OrderMutation } from '@/domain/common';

export const getFolders = (): Promise<Folder[]> => {
    return documentAPI.getFolders();
};
export const getCompanyFolders = (): Promise<Folder[]> => {
    return documentAPI.getCompanyFolders();
};
export const getEmployeeFolders = (employeeId: number): Promise<EmployeeFolder[]> => {
    return documentAPI.getEmployeeFolders(employeeId);
};

export const updateFolderOrder = (mutation: OrderMutation[]): Promise<void> => {
    return documentAPI.updateFoldersOrder(mutation);
};

export const getFolderById = (id: number): Promise<Folder> => {
    return documentAPI.getFolder(id);
};

export const createFolder = (folder: FolderMutation): Promise<Folder> => {
    return documentAPI.createFolder(folder);
};
export const updateFolder = (folderId: number, folder: FolderMutation): Promise<Folder> => {
    return documentAPI.updateFolder(folder, folderId);
};
export const deleteFolder = (folderId: number): Promise<void> => {
    return documentAPI.deleteFolder(folderId);
};

export const getCompanyDocuments = (search: CompanyDocumentSearch): Promise<Document[]> => {
    return documentAPI.getCompanyDocuments(search);
};
export const getCompanyDocumentDownloadUrl = (documentId: number, documentContentDisposition: DocumentContentDisposition): Promise<string> => {
    return documentAPI.getCompanyDocumentDownloadUrl(documentId, documentContentDisposition);
};

export const getEmployeeDocuments = (search: EmployeeDocumentSearch): Promise<Document[]> => {
    return documentAPI.getEmployeeDocuments(search);
};

export const getEmployeeDocumentDownloadUrl = (documentId: number, documentContentDisposition: DocumentContentDisposition): Promise<string> => {
    return documentAPI.getEmployeeDocumentDownloadUrl(documentId, documentContentDisposition);
};

export const createCompanyDocuments = (mutation: CompanyDocumentCreationMutation): Promise<Document[]> => {
    return documentAPI.createCompanyDocuments(mutation);
};

export const createEmployeeDocuments = (mutation: EmployeeDocumentCreationMutation): Promise<Document[]> => {
    return documentAPI.createEmployeeDocuments(mutation);
};

export const createEmployeeDocumentsLinks = (mutation: EmployeeDocumentLinkCreationMutation[]): Promise<Document[]> => {
    return documentAPI.createEmployeeDocumentsLinks(mutation);
};

export const createCompanyDocumentLink = (mutation: CompanyDocumentLinkCreationMutation[]): Promise<Document[]> => {
    return documentAPI.createCompanyDocumentLink(mutation);
};

export const deleteEmployeeDocument = (documentId: number): Promise<void> => {
    return documentAPI.deleteEmployeeDocument(documentId);
};
export const deleteCompanyDocument = (documentId: number): Promise<void> => {
    return documentAPI.deleteCompanyDocument(documentId);
};

export const updateEmployeeDocument = (documentId: number, mutation: DocumentUpdateMutation): Promise<Document> => {
    return documentAPI.updateEmployeeDocument(documentId, mutation);
};

export const updateCompanyDocument = (documentId: number, mutation: DocumentUpdateMutation): Promise<Document> => {
    return documentAPI.updateCompanyDocument(documentId, mutation);
};

export const getPreviewURLLink = (document: Document, link: string): string => {
    const config = getAppConfig();
    if (!document?.mimeType) {
        return '';
    }
    if (config.MIME_TYPES.MICROSOFT.includes(document.mimeType)) {
        return `${config.OFFICE_PREVIEW_URL}${encodeURIComponent(link)}`;
    } else if (config.MIME_TYPES.IMAGES.includes(document.mimeType) || config.MIME_TYPES.PDF === document.mimeType) {
        return `${link}`;
    }
    return '';
};

export const getFoldersByType = async (type: FolderType): Promise<Folder[]> => {
    const folders = await documentAPI.getFolders();
    return folders.filter(folder => type === folder.folderType);
};
