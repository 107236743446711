import { getCurrentEmployeeAllLeaveTypePolicies } from '@/domain/employee-leave-type/EmployeeLeaveType.service';
import { Employee, EmployeeSearch } from '@/domain/employee/Employee.model';
import {
    getEmployeeAvatar,
    getEmployeeById,
    searchEmployeeBirthdays,
    searchEmployees,
    searchEmployeeWorkAnniversaries,
} from '@/domain/employee/Employee.service';
import { createQueryHook, createRequiredParamsQueryHook, UseInfiniteQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetEmployee = createRequiredParamsQueryHook('employee', getEmployeeById);

export const useGetEmployees = createQueryHook('employees', searchEmployees);

export const useGetEmployeesBirthdays = createQueryHook('employeesBirthdays', searchEmployeeBirthdays);

export const useGetEmployeesWorkAnniversaries = createQueryHook('employeesWorkAnniversaries', searchEmployeeWorkAnniversaries);

export const useGetEmployeeLeaveTypePolicies = createQueryHook('employeeLeaveTypePolicies', getCurrentEmployeeAllLeaveTypePolicies);

export const useGetEmployeeAvatarUrl = createRequiredParamsQueryHook('employeeAvatars', getEmployeeAvatar, {
    // expires after 5 minutes
    staleTime: 1000 * 60 * 5,
});
// TODO Replace by tanstack/react-query
export const useGetPaginatedEmployees = (
    search: EmployeeSearch = {},
    options: { limitPerPage?: number; enabled?: boolean } = {},
): UseInfiniteQueryResult<Employee[]> => {
    const [employees, setEmployees] = useState<Employee[]>();
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();
    const [page, setPage] = useState(0);
    const [isFetchingNextPage, setIsFetchingNextPage] = useState<boolean>(false);
    const [hasNextPage, setHasNextPage] = useState<boolean>(true);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [allEmployees, setAllEmployees] = useState<Employee[]>([]);

    const { limitPerPage = 50, enabled = true } = options;

    // fakeFetch is used to simulate pagination and avoid calling the BE multiple times
    const fetchEmployees = useCallback(
        async (search: EmployeeSearch & { offset: number; limit: number }, fakeFetch = false) => {
            // TODO set offset and limit when the BE is ready to make pagination and remove the slice
            const employeeData = !fakeFetch
                ? await searchEmployees({
                      ...search,
                      offset: undefined,
                      limit: undefined,
                  })
                : allEmployees;
            if (!fakeFetch) {
                // save all employees to be able to simulate pagination, should be removed when the BE is ready to make pagination
                setAllEmployees(employeeData);
                // TODO get the total from the BE when the BE is ready to make pagination
                setTotalCount(employeeData.length);
            }
            const employeeSliced = employeeData.slice(search.offset, search.offset + limitPerPage);
            // if the length of the data returned is less than the limit per page, there are no more pages
            setHasNextPage(employeeSliced.length === limitPerPage);

            return employeeSliced;
        },
        [allEmployees, limitPerPage],
    );

    const initialFetch = useCallback(
        async (search: EmployeeSearch, enabled: boolean) => {
            if (!enabled) {
                return;
            }
            try {
                setIsFetching(true);
                if (page !== 0) {
                    setPage(0);
                }
                const employeesData = await fetchEmployees({ ...search, offset: 0, limit: limitPerPage });
                setEmployees(employeesData);
            } catch (error) {
                setError(error);
            } finally {
                setIsFetching(false);
                setIsLoading(false);
            }
        },
        [fetchEmployees, limitPerPage, page],
    );

    const fetchNextPage = useCallback(async () => {
        setIsFetchingNextPage(true);
        setPage(page => page + 1);
        const newOffset = limitPerPage * (page + 1);
        try {
            const employeesData = await fetchEmployees({ ...search, offset: newOffset, limit: limitPerPage }, true);
            setEmployees(employees => [...(employees ?? []), ...employeesData]);
        } catch (error) {
            handleError(error);
        } finally {
            setIsFetchingNextPage(false);
        }
    }, [fetchEmployees, limitPerPage, page, search]);

    useDeepCompareEffect(() => {
        initialFetch(search, enabled).catch(handleError);
    }, [search, enabled]);

    return {
        data: employees,
        setData: setEmployees,
        refetch: () => initialFetch(search, enabled),
        isLoading,
        isFetching,
        isError: !!error,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        totalCount,
    };
};
