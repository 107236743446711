import {
    countPendingChanges,
    getEmployeePendingChanges,
    searchEmployeeProfilePendingChanges,
} from '@/domain/employee-pending-change/EmployeePendingChange.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';

export const useGetEmployeeProfilePendingChanges = createRequiredParamsQueryHook('employeeProfilePendingChanges', getEmployeePendingChanges);

export const useCountEmployeePendingChanges = createQueryHook('countEmployeePendingChanges', countPendingChanges);

export const useGetPendingRequests = createQueryHook('pendingRequests', searchEmployeeProfilePendingChanges);
