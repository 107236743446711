import { ColorDot } from '@/components/color-dot/ColorDot';
import { Footer, FooterActions, FooterActionsProps } from '@/page/layout/Footer';
import { StateHandler } from '@/components/state-handler/StateHandler';
import {
    hasContributorTypeInObjectiveActions,
    hasEmployeeReviewObjectiveActions,
    isEmployeeReviewInputNeeded,
} from '@/domain/employee-review/EmployeeReview.service';
import { ContributorType } from '@/domain/review/Review.model';
import { isReviewClosed } from '@/domain/review/Review.service';
import { FeedbackForm } from '@/page/review/employee-review-feedback-form/FeedbackForm';
import { Stack, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router';
import { useGetEmployeeReview } from '@/hooks/employee-review/EmployeeReview.hook';
import { useGetEmployeeReviewFeedback } from '@/hooks/employee-review-feedback/EmployeeReviewFeedback.hook';
import { useGetObjectives } from '@/hooks/objective/Objective.hook';
import { useGetEmployeeSkills } from '@/hooks/employee-skill/EmployeeSkill.hook';
import { useCurrentEmployee } from '@/stores/store';
import { useGetEmployeeReviewActions } from '@/hooks/employee-review-action/EmployeeReviewAction.hook';
import { useEmployeeReviewId } from '@/page/review/useEmployeeReviewId';

type FeedbackPageProps = {
    isPreview: boolean;
};

type LoadingButtonProps = FooterActionsProps['actions'][number];

export const FeedbackPage: FC<FeedbackPageProps> = ({ isPreview }) => {
    const employeeReviewId = useEmployeeReviewId();
    const { data: employeeReview, error: employeeReviewError, isLoading: employeeReviewLoading } = useGetEmployeeReview(employeeReviewId);
    const [searchParams] = useSearchParams();
    const previewRoleSearchParam = searchParams.get('role');
    const previewRole = previewRoleSearchParam as ContributorType;
    const hasPreviewRole = !!previewRole;
    const employeeId = employeeReview?.employee?.id;
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isCorrectionFeedbackMode, setIsCorrectionFeedbackMode] = useState(false);
    const currentEmployee = useCurrentEmployee();

    const {
        data: employeeSkills,
        error: employeeSkillError,
        isLoading: employeeSkillLoading,
    } = useGetEmployeeSkills(employeeId, { options: { enabled: hasPreviewRole } });
    const { data: feedback, error: feedbackError, isLoading: feedbackLoading } = useGetEmployeeReviewFeedback(hasPreviewRole ? undefined : employeeReviewId);

    const {
        data: objectives,
        error: objectivesError,
        isLoading: objectivesLoading,
        refetch: refetchObjectives,
    } = useGetObjectives(employeeId ? { employeeIds: [employeeId] } : undefined);

    const isActionsEnabled =
        !isPreview &&
        !!employeeReview &&
        hasEmployeeReviewObjectiveActions(employeeReview) &&
        hasContributorTypeInObjectiveActions(employeeReview, currentEmployee);

    const {
        data: actions = [],
        error: actionsError,
        isLoading: actionsLoading,
        refetch: refetchActions,
    } = useGetEmployeeReviewActions(employeeReviewId, { options: { enabled: isActionsEnabled } });

    const isLoading = employeeReviewLoading || employeeSkillLoading || feedbackLoading || objectivesLoading || actionsLoading;
    const isError = !!employeeReviewError || !!employeeSkillError || !!feedbackError || !employeeId || !!objectivesError || !!actionsError;
    const error = employeeReviewError || employeeSkillError || feedbackError || objectivesError || actionsError;
    const isReviewAlreadySubmitted = feedback?.submitted || false;
    const isFormDisabled =
        (isReviewAlreadySubmitted && !isCorrectionFeedbackMode) || (employeeReview?.review && isReviewClosed(employeeReview?.review)) || isPreview;

    const canCorrectHisFeedback = feedback?.submitted && employeeReview && isEmployeeReviewInputNeeded(employeeReview.status);

    const closeButton: LoadingButtonProps = {
        name: 'close',
        onClick: () => {
            navigate(-1);
        },
        children: t('reviews.write_feedback.close'),
        variant: 'contained',
    };

    const continueLaterButton: LoadingButtonProps = {
        name: 'continue-later',
        onClick: () => {
            navigate('/reviews/tasks');
        },
        children: t('reviews.write_feedback.continue_later'),
        variant: 'outlined',
    };

    const isPrimaryDisabled = () => {
        return (
            isPreview ||
            (employeeReview && !isEmployeeReviewInputNeeded(employeeReview.status)) ||
            (employeeReview?.review && isReviewClosed(employeeReview?.review))
        );
    };

    const submitButton: LoadingButtonProps = {
        name: 'submit',
        type: 'submit',
        form: 'employee-review-feedback-form',
        disabled: isPrimaryDisabled(),
        children: t('reviews.write_feedback.submit'),
        variant: 'contained',
    };

    const correctFeedbackButton: LoadingButtonProps = {
        name: 'correct',
        onClick: () => {
            setIsCorrectionFeedbackMode(true);
        },
        children: t('reviews.write_feedback.correct_feedback'),
        variant: 'text',
    };

    const getFooterActions = (): LoadingButtonProps[] => {
        if (isPreview) {
            return [closeButton];
        }

        if (isCorrectionFeedbackMode) {
            return [submitButton];
        }

        if (canCorrectHisFeedback) {
            return [correctFeedbackButton, closeButton];
        }
        if (isReviewAlreadySubmitted) {
            return [closeButton];
        }
        return [continueLaterButton, submitButton];
    };

    const skills = employeeSkills ? employeeSkills.map(employeeSkill => employeeSkill.skill) : [];

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            {employeeReview && employeeId && currentEmployee && skills && (
                <FeedbackForm
                    objectives={objectives ?? []}
                    employeeReview={employeeReview}
                    skills={skills}
                    actions={actions}
                    refetchActions={() => refetchActions()}
                    employeeId={employeeId}
                    isPreview={isPreview}
                    feedback={feedback}
                    previewRole={previewRole}
                    refetchObjectives={() => refetchObjectives()}
                    isFormDisabled={isFormDisabled}
                    currentEmployee={currentEmployee}
                />
            )}
            <Footer>
                {!isReviewAlreadySubmitted && !isPreview && (
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <ColorDot color={theme.palette.success.main} />
                        <Typography variant='body2'>{t('reviews.write_feedback.automatically_saved')}</Typography>
                    </Stack>
                )}
                <FooterActions actions={getFooterActions()} />
            </Footer>
        </StateHandler>
    );
};
