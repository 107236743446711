import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { FilterType } from '@/components/filters-bar/FilterBar.type';
import { getLeaveTypes } from '@/domain/leave-type/LeaveType.service';
import { useFiltersDirectory } from '@/page/common/filters-directory/useFiltersDirectory';
import { getLabelTranslation } from '@/utils/language.util';

export const useGetLeavesFilters = (): {
    filters: FilterType[];
} => {
    const { filters: filtersFromDirectory } = useFiltersDirectory(['JOB', 'LOCATION', 'DEPARTMENT', 'MANAGER']);
    const filters: FilterType[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'LEAVE_TYPE_POLICY_LEAVE_TYPE_TITLE' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getLeaveTypes().then(leaveTypes => {
                    return leaveTypes?.map(leaveType => ({
                        label: getLabelTranslation(leaveType.name),
                        value: leaveType.id,
                    }));
                });
            },
            key: 'leaveTypeIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        ...filtersFromDirectory,
    ];
    return { filters };
};
