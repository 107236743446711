import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { getAvailableContributorTypes } from '@/domain/review-template/ReviewTemplate.service';
import { CompleteReviewTemplateForm, InvitationEmailFormType } from '@/page/setting/review/template/ReviewTemplateFormPage.schema';
import { UserLanguage } from '@/utils/language.util';
import { Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldTranslatableRichTextEditor } from '@/components/form/field-translatable-rich-text-editor/FieldTranslatableRichTextEditor';

type InvitationEmailsContentFormProps = {
    translationLanguage: UserLanguage;
};

export const InvitationEmailsContentForm: FC<InvitationEmailsContentFormProps> = ({ translationLanguage }) => {
    const { watch } = useFormContext<CompleteReviewTemplateForm>();
    const reviewTemplateFormValues = watch();
    const availableContributorTypes = getAvailableContributorTypes(reviewTemplateFormValues);
    const showSelfInvitationEmail = availableContributorTypes.includes('SELF') && reviewTemplateFormValues.includePreparationStep;
    const showPeerInvitationEmail = availableContributorTypes.includes('PEER') && reviewTemplateFormValues.includePreparationStep;
    const showUpwardInvitationEmail = availableContributorTypes.includes('UPWARD') && reviewTemplateFormValues.includePreparationStep;

    return (
        <Stack gap={2}>
            <ManagerEmail translationLanguage={translationLanguage} />
            {showSelfInvitationEmail && <SelfReviewEmail translationLanguage={translationLanguage} />}
            {showPeerInvitationEmail && <PeerReviewEmail translationLanguage={translationLanguage} />}
            {showUpwardInvitationEmail && <UpwardFeedbackEmail translationLanguage={translationLanguage} />}
        </Stack>
    );
};

type ManagerEmailProps = {
    translationLanguage: UserLanguage;
};
const ManagerEmail: FC<ManagerEmailProps> = ({ translationLanguage }) => {
    const { control } = useFormContext<InvitationEmailFormType>();
    const { t } = useTranslation();

    return (
        <Stack p={2} gap={2} component={Paper}>
            <Typography variant={'body1bold'}>{t('reviews_settings_page.review_template_form.manager_email')}</Typography>
            <FieldLabel
                language={translationLanguage}
                textFieldProps={{ sx: { width: 800 } }}
                label={t('reviews_settings_page.review_template_form.subject')}
                autoFocus
                control={control}
                name={'managerSubject'}
            />

            <Stack width={'800px'}>
                <Typography variant={'body1'}>{t('reviews_settings_page.review_template_form.body')}</Typography>
                <FieldTranslatableRichTextEditor name={`managerBody`} control={control} translationLanguage={translationLanguage} />
            </Stack>
        </Stack>
    );
};

type SelfReviewEmailProps = {
    translationLanguage: UserLanguage;
};

const SelfReviewEmail: FC<SelfReviewEmailProps> = ({ translationLanguage }) => {
    const { control } = useFormContext<InvitationEmailFormType>();
    const { t } = useTranslation();
    return (
        <Stack p={2} gap={3} component={Paper}>
            <Typography variant={'body1bold'}>{t('reviews_settings_page.review_template_form.self_review_email')}</Typography>
            <FieldLabel
                control={control}
                name={'selfSubject'}
                language={translationLanguage}
                textFieldProps={{
                    sx: { width: '800px' },
                }}
                label={t('reviews_settings_page.review_template_form.subject')}
                autoFocus
            />
            <Stack width={'800px'}>
                <Typography variant={'body1'}>{t('reviews_settings_page.review_template_form.body')}</Typography>
                <FieldTranslatableRichTextEditor name={`selfBody`} control={control} translationLanguage={translationLanguage} />
            </Stack>
        </Stack>
    );
};

type PeerReviewEmailProps = {
    translationLanguage: UserLanguage;
};

const PeerReviewEmail: FC<PeerReviewEmailProps> = ({ translationLanguage }) => {
    const { control } = useFormContext<InvitationEmailFormType>();
    const { t } = useTranslation();

    return (
        <Stack p={2} gap={3} component={Paper}>
            <Typography variant={'body1bold'}>{t('reviews_settings_page.review_template_form.peer_review_email')}</Typography>
            <FieldLabel
                language={translationLanguage}
                textFieldProps={{ sx: { width: 800 } }}
                label={t('reviews_settings_page.review_template_form.subject')}
                autoFocus
                control={control}
                name={'peerSubject'}
            />
            <Stack width={'800px'}>
                <Typography variant={'body1'}>{t('reviews_settings_page.review_template_form.body')}</Typography>
                <FieldTranslatableRichTextEditor name={`peerBody`} control={control} translationLanguage={translationLanguage} />
            </Stack>
        </Stack>
    );
};

type UpwardFeedbackEmailProps = {
    translationLanguage: UserLanguage;
};

const UpwardFeedbackEmail: FC<UpwardFeedbackEmailProps> = ({ translationLanguage }) => {
    const { control } = useFormContext<InvitationEmailFormType>();
    const { t } = useTranslation();

    return (
        <Stack p={2} gap={3} component={Paper}>
            <Typography variant={'body1bold'}>{t('reviews_settings_page.review_template_form.upward_feedback_email')}</Typography>
            <FieldLabel
                language={translationLanguage}
                textFieldProps={{ sx: { width: 800 } }}
                label={t('reviews_settings_page.review_template_form.subject')}
                autoFocus
                control={control}
                name={'upwardSubject'}
            />
            <Stack width={'800px'}>
                <Typography variant={'body1'}>{t('reviews_settings_page.review_template_form.body')}</Typography>
                <FieldTranslatableRichTextEditor name={`upwardBody`} control={control} translationLanguage={translationLanguage} />
            </Stack>
        </Stack>
    );
};
