import { FC } from 'react';
import { Box } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';

type ColorDotProps = {
    color: string | undefined;
} & BoxProps;

export const ColorDot: FC<ColorDotProps> = ({ color, ...props }) => {
    return <Box height={'10px'} width={'10px'} borderRadius={'50%'} {...props} bgcolor={color} />;
};
