import {
    FieldDefinition,
    SECTION_FIELD_VALUE_TYPES,
    SectionDefinition,
    SectionDefinitionCreateRequest,
    SectionDefinitionIncludeInPayrollMutation,
    SectionDefinitionSearchRequest,
    SectionDefinitionUpdateRequest,
    SectionFieldDefinition,
    SectionFieldValueType,
    SectionRowStatus,
    SectionType,
} from '@/domain/section-setting/Section.model';
import { sectionDefinitionApi } from '@/api/section-definition/SectionDefinition.api';
import { ReportFieldType } from '@/domain/report/Report.model';
import {
    getContractTypeTranslationKey,
    getGenderTranslationKey,
    getMaritalStatusTranslationKey,
    getUserEmploymentStatusTranslationKey,
    getUserStatusTranslationKey,
} from '@/domain/employee/Employee.service';
import i18next from 'i18next';

import { OrderMutation } from '@/domain/common';
import { EmployeeStatus, Gender, MaritalStatus } from '@/domain/employee/Employee.model';
import { ContractType, EmploymentCreateReason, EmploymentStatus, TerminationReason, TerminationType } from '@/domain/employment/Employment.model';
import { ContributorArray, FeedbackType } from '@/domain/review/Review.model';
import { ReviewTemplateItemArray } from '@/domain/review-template/ReviewTemplate.model';
import { AllowanceType } from '@/domain/leave-type/LeaveType.model';
import { TIMESHEET_PAYMENT_STATUS } from '@/domain/timesheet/Timesheet.model';
import { WorkingPatternType } from '@/domain/working-pattern-template/WorkingPatternTemplate.model';
import { convertEnumToOptions } from '@/utils/enum.util';
import { EmployeeFieldTypeMapping } from '@/domain/employee/EmployeeFields.model';
import { SectionDefinitionFormValues } from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeSectionDefinition.schema';

export function getSectionDefinitions(): Promise<SectionDefinition[]> {
    return sectionDefinitionApi.getSectionDefinitions();
}

export const searchSectionDefinitions = (search: SectionDefinitionSearchRequest): Promise<SectionDefinition[]> => {
    return sectionDefinitionApi.searchSectionDefinitions(search);
};

export const createSectionDefinition = (mutation: SectionDefinitionCreateRequest): Promise<SectionDefinition> => {
    return sectionDefinitionApi.createSectionDefinition(mutation);
};

export const updateSectionDefinition = (id: number, mutation: SectionDefinitionUpdateRequest): Promise<SectionDefinition> => {
    return sectionDefinitionApi.updateSectionDefinition(id, mutation);
};

export const deleteSectionDefinition = (id: number): Promise<void> => {
    return sectionDefinitionApi.deleteSectionDefinition(id);
};

export const updateSectionDefinitionsOrder = (mutation: OrderMutation[]): Promise<void> => {
    return sectionDefinitionApi.updateSectionDefinitionsOrder(mutation);
};

export const archiveSectionDefinition = (id: number): Promise<SectionDefinition> => {
    return sectionDefinitionApi.archiveSectionDefinition(id);
};

export const unarchiveSectionDefinition = (id: number): Promise<SectionDefinition> => {
    return sectionDefinitionApi.unarchiveSectionDefinition(id);
};

export const archiveSectionFieldDefinition = (fieldId: number): Promise<SectionFieldDefinition> => {
    return sectionDefinitionApi.archiveSectionFieldDefinition(fieldId);
};

export const unarchiveSectionFieldDefinition = (fieldId: number): Promise<SectionFieldDefinition> => {
    return sectionDefinitionApi.unarchiveSectionFieldDefinition(fieldId);
};

/**
 * Update the includeInPayroll field of a section definition
 * @param id
 * @param mutation
 * @returns the updated section definition
 */
export const updateIncludeInPayrollSectionDefinition = async (
    id: SectionDefinition['id'],
    mutation: SectionDefinitionIncludeInPayrollMutation,
): Promise<SectionDefinition> => {
    return sectionDefinitionApi.updateIncludeInPayrollSectionDefinition(id, mutation);
};

/**
 * Check if the given type is a valid SectionType
 * @param type
 * @returns true if the given type is a valid SectionType
 */
export const isSectionType = (type: string): type is SectionType => {
    return type in SectionType;
};
/**
 * Check if the given type is a valid SectionFieldType
 * @param type
 * @returns true if the given type is a valid SectionFieldType
 */
export const isSectionFieldType = (type: string): type is SectionFieldValueType => {
    return SECTION_FIELD_VALUE_TYPES.includes(type as SectionFieldValueType);
};

export const isSameFieldDefinition = (a: Pick<FieldDefinition, 'id' | 'fieldType'>, b: Pick<FieldDefinition, 'id' | 'fieldType'>): boolean => {
    const isSameNotCustomField = (a: { fieldType: ReportFieldType }, b: { fieldType: ReportFieldType }) =>
        a.fieldType === b.fieldType && !isCustomFieldType(a.fieldType) && !isCustomFieldType(b.fieldType);

    const isSameCustomField = (a: Pick<FieldDefinition, 'id' | 'fieldType'>, b: Pick<FieldDefinition, 'id' | 'fieldType'>) =>
        isCustomFieldType(a.fieldType) && isCustomFieldType(b.fieldType) && a.id === b.id;

    return isSameNotCustomField(a, b) || isSameCustomField(a, b);
};

/**
 * Check if the given section is a custom section
 * @param type the section type to check
 * @returns true if the given section type is a custom one
 */
export const isCustomSectionType = (type: SectionType): boolean => type === SectionType.CUSTOM_SINGLE_ROW || type === SectionType.CUSTOM_MULTI_ROW;

/**
 * Check if the given field definition is a custom field definition
 * @param fieldType the field type of the field definition to check
 * @returns true if the given field type is a custom one
 */
export const isCustomFieldType = (fieldType: SectionFieldDefinition['fieldType']): boolean => fieldType === 'EMPLOYEE_CUSTOM_FIELD';

/**
 * Get the translation of an enum value
 * @param fieldType the field type of the enum
 * @param enumValue the enum value to translate
 * @returns the translation of the enum value
 */
export const getEnumFieldValueTranslation = (fieldType: ReportFieldType, enumValue: string): string => {
    const getEnumTranslationKey = (): string => {
        switch (fieldType) {
            case 'EMPLOYEE_GENDER':
                return getGenderTranslationKey();
            case 'EMPLOYEE_MARITAL_STATUS':
                return getMaritalStatusTranslationKey();
            case 'EMPLOYEE_STATUS':
                return getUserStatusTranslationKey();
            case 'CURRENT_EMPLOYMENT_CONTRACT_TYPE':
            case 'EMPLOYMENT_CONTRACT_TYPE':
                return getContractTypeTranslationKey();
            case 'CURRENT_EMPLOYMENT_CREATION_REASON':
            case 'EMPLOYMENT_CREATION_REASON':
                return 'employee.employment.employment_create_reason';
            case 'REVIEW_FEEDBACK_CONTRIBUTOR_TYPE':
                return 'reviews.enums.contributor_types.enum';
            case 'REVIEW_FEEDBACK_TYPE':
                return 'reviews.enums.feedback_types.enum';
            case 'REVIEW_FEEDBACK_ITEM_TYPE':
                return 'reviews.enums.item_types.enum';
            case 'CURRENT_EMPLOYMENT_STATUS':
            case 'EMPLOYMENT_STATUS':
                return getUserEmploymentStatusTranslationKey();
            case 'LEAVE_TYPE_POLICY_LEAVE_TYPE_ALLOWANCE_TYPE':
                return 'leaves.leave_types.enums.allowance_types.enum';
            case 'TIMESHEET_PAYMENT_STATUS':
                return 'timesheets.enums.payments_status.enum';
            case 'EMPLOYEE_SECTION_ROW_STATUS':
            case 'ADDRESS_STATUS':
                return 'employee.sections.enums.status.enum';
            case 'CURRENT_WORKING_PATTERN_TYPE':
            case 'WORKING_PATTERN_TYPE':
                return 'employee.work_pattern.type.enum';
            case 'CURRENT_EMPLOYMENT_TERMINATION_REASON':
            case 'EMPLOYMENT_TERMINATION_REASON':
                return 'employee.employment.termination_reason.enum';
            case 'CURRENT_EMPLOYMENT_TERMINATION_TYPE':
            case 'EMPLOYMENT_TERMINATION_TYPE':
                return 'employee.employment.termination_type.enum';
            default:
                return fieldType;
        }
    };

    return enumValue ? i18next.t(getEnumTranslationKey(), { context: enumValue }) : '';
};

export const getEnumFieldTypeOptions = (fieldType: SectionFieldDefinition['fieldType']): SelectOption[] => {
    const getOptionsFromEnum = <T extends Record<string, string>>(enumObject: T): SelectOption[] => {
        return convertEnumToOptions(enumObject, (key: string) => getEnumFieldValueTranslation(fieldType, key));
    };

    switch (fieldType) {
        case 'EMPLOYEE_STATUS':
            return getOptionsFromEnum(EmployeeStatus);
        case 'EMPLOYEE_GENDER':
            return getOptionsFromEnum(Gender);
        case 'EMPLOYEE_MARITAL_STATUS':
            return getOptionsFromEnum(MaritalStatus);
        case 'CURRENT_EMPLOYMENT_STATUS':
            return getOptionsFromEnum(EmploymentStatus);
        case 'CURRENT_EMPLOYMENT_CONTRACT_TYPE':
            return getOptionsFromEnum(ContractType);
        case 'CURRENT_EMPLOYMENT_TERMINATION_REASON':
            return getOptionsFromEnum(TerminationReason);
        case 'CURRENT_EMPLOYMENT_TERMINATION_TYPE':
            return getOptionsFromEnum(TerminationType);
        case 'CURRENT_EMPLOYMENT_CREATION_REASON':
            return getOptionsFromEnum(EmploymentCreateReason);
        case 'EMPLOYMENT_TERMINATION_REASON':
            return getOptionsFromEnum(TerminationReason);
        case 'EMPLOYMENT_TERMINATION_TYPE':
            return getOptionsFromEnum(TerminationType);
        case 'EMPLOYMENT_CONTRACT_TYPE':
            return getOptionsFromEnum(ContractType);
        case 'EMPLOYMENT_CREATION_REASON':
            return getOptionsFromEnum(EmploymentCreateReason);
        case 'REVIEW_FEEDBACK_CONTRIBUTOR_TYPE':
            return Object.keys(ContributorArray).map(key => ({
                label: getEnumFieldValueTranslation(fieldType, key),
                value: key,
            }));
        case 'REVIEW_FEEDBACK_TYPE':
            return getOptionsFromEnum(FeedbackType);
        case 'REVIEW_FEEDBACK_ITEM_TYPE':
            return Object.keys(ReviewTemplateItemArray).map(key => ({
                label: getEnumFieldValueTranslation(fieldType, key),
                value: key,
            }));
        case 'LEAVE_TYPE_POLICY_LEAVE_TYPE_ALLOWANCE_TYPE':
            return getOptionsFromEnum(AllowanceType);
        case 'TIMESHEET_PAYMENT_STATUS':
            return Object.keys(TIMESHEET_PAYMENT_STATUS).map(key => ({
                label: getEnumFieldValueTranslation(fieldType, key),
                value: key,
            }));
        case 'EMPLOYEE_SECTION_ROW_STATUS':
            return getOptionsFromEnum(SectionRowStatus);
        case 'ADDRESS_STATUS':
            return getOptionsFromEnum(SectionRowStatus);
        case 'CURRENT_WORKING_PATTERN_TYPE':
            return getOptionsFromEnum(WorkingPatternType);
        case 'WORKING_PATTERN_TYPE':
            return getOptionsFromEnum(WorkingPatternType);
        default:
            return [];
    }
};

/**
 * Get the form value of a field by its field type for a section definition
 * If the field supposed to be mandatory and the field is not found, an error is thrown
 * @param sectionDefinition
 * @param fieldType
 * @param formValues
 * @param mandatory
 * @returns the form value of the field
 */
export const getFormValueByFieldType = <TFieldType extends SectionFieldDefinition['fieldType'], TMandatory extends boolean = false>({
    sectionDefinition,
    fieldType,
    formValues,
    mandatory,
}: {
    sectionDefinition: SectionDefinition | undefined;
    fieldType: TFieldType;
    formValues: Partial<SectionDefinitionFormValues>;
    mandatory?: TMandatory;
}): TMandatory extends true ? EmployeeFieldTypeMapping[TFieldType] : EmployeeFieldTypeMapping[TFieldType] | undefined => {
    const fieldDefinition = sectionDefinition?.fields.find(field => field.fieldType === fieldType);
    if (!fieldDefinition) {
        if (mandatory) {
            throw new Error(`Field with fieldType ${fieldType} not found in section definition ${sectionDefinition?.id} of type ${sectionDefinition?.type}`);
        } else {
            return undefined as TMandatory extends true ? EmployeeFieldTypeMapping[TFieldType] : EmployeeFieldTypeMapping[TFieldType] | undefined;
        }
    }
    return formValues[fieldDefinition.formId] as unknown as EmployeeFieldTypeMapping[TFieldType];
};
