import { FC } from 'react';
import { RatingFormType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import {
    FeedbackSummarySkillFormType,
    QuestionFeedbackFormType,
    SkillQuestionFeedbackFormType,
} from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';
import { FeedbackQuestionPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackQuestionPDF';
import { FeedbackFromPreparationPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/FeedbackFromPreparationPDF';
import { useTranslation } from 'react-i18next';
import { Text } from '@react-pdf/renderer';
import { useTheme } from '@mui/material';
import { assignLocalId } from '@/utils/object.util';

type SummaryFeedbackQuestionPDFProps<
    T extends
        | QuestionFeedbackFormType
        | SkillQuestionFeedbackFormType
        | (FeedbackSummarySkillFormType & {
              title: string;
          }),
> = {
    questionItem: T | undefined;
    rating: RatingFormType | undefined;
};

export const SummaryFeedbackQuestionPDF: FC<
    SummaryFeedbackQuestionPDFProps<
        | QuestionFeedbackFormType
        | SkillQuestionFeedbackFormType
        | (FeedbackSummarySkillFormType & {
              title: string;
          })
    >
> = ({ questionItem, rating }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    if (!questionItem) {
        return <></>;
    }

    return (
        <FeedbackQuestionPDF questionItem={questionItem} rating={rating}>
            {questionItem.selfFeedbackQuestion && <FeedbackFromPreparationPDF feedbackForm={questionItem.selfFeedbackQuestion} rating={rating} />}

            {questionItem.managerFeedbackQuestion?.map(managerFeedbackSkillQuestion => (
                <FeedbackFromPreparationPDF
                    key={assignLocalId(managerFeedbackSkillQuestion).localId}
                    feedbackForm={managerFeedbackSkillQuestion}
                    rating={rating}
                />
            ))}

            {questionItem.upwardFeedbackQuestion?.map(upwardFeedbackSkillQuestion => (
                <FeedbackFromPreparationPDF
                    key={assignLocalId(upwardFeedbackSkillQuestion).localId}
                    feedbackForm={upwardFeedbackSkillQuestion}
                    rating={rating}
                />
            ))}
            {questionItem.peerFeedbackQuestion?.map(peerFeedbackSkillQuestion => (
                <FeedbackFromPreparationPDF key={assignLocalId(peerFeedbackSkillQuestion).localId} feedbackForm={peerFeedbackSkillQuestion} rating={rating} />
            ))}

            <Text
                style={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    paddingTop: theme.spacing(1),
                }}
            >
                {t('reviews.summary.summary_subtitle')}
            </Text>
        </FeedbackQuestionPDF>
    );
};
