import { UseQueryResult as RQUseQueryResult, skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Dispatch, SetStateAction } from 'react';

/**
 * While waiting to use a library like React Query or SWR, we use this type to represent the result of a query.
 * @deprecated
 */
export declare type UseQueryResult<TData = unknown, TError = unknown> = {
    data: TData | undefined;
    error: TError | null;
    isError: boolean;
    isLoading: boolean;
    isFetching?: boolean;
    // This will be replaced by cache api like with react-query
    setData: Dispatch<SetStateAction<TData | undefined>>;
    refetch: () => Promise<void>;
};

export interface UseMutationResult<TData = unknown, TVariables = void, TError = unknown> {
    mutate: (variables: TVariables) => Promise<TData>;
    isPending: boolean;
    isError: boolean;
    error: TError | null;
}

export type UseInfiniteQueryResult<TData = unknown, TError = unknown> = UseQueryResult<TData, TError> & {
    fetchNextPage: () => Promise<void>;
    hasNextPage: boolean;
    isFetchingNextPage: boolean;
    // totalCount is not inspired by react-query, but it's a common need in our app
    totalCount: number;
};

export const createQueryHook = <TData, TOptionalParams>(
    key: string,
    queryFn: (params: TOptionalParams) => Promise<TData>,
    defaultOptions?: Omit<UseQueryOptions<TData, Error>, 'queryKey' | 'queryFn'>,
) => {
    return (queryParams?: TOptionalParams, options?: Omit<UseQueryOptions<TData, Error>, 'queryKey' | 'queryFn'>): RQUseQueryResult<TData, Error> => {
        return useQuery({
            queryKey: [key, { queryParams }], // Include params to uniquely identify the query
            queryFn: () => queryFn(queryParams ?? ({} as TOptionalParams)),
            ...defaultOptions,
            ...options,
        });
    };
};

export const createRequiredParamsQueryHook = <TData, TRequiredParams, TOptionalParams>(
    key: string,
    queryFn: (requiredParams: TRequiredParams, params?: TOptionalParams) => Promise<TData>,
    defaultOptions?: Omit<UseQueryOptions<TData, Error>, 'queryKey' | 'queryFn'>,
) => {
    return (
        requiredParams: TRequiredParams | undefined,
        {
            queryParams,
            options,
        }: {
            queryParams?: TOptionalParams;
            options?: Omit<UseQueryOptions<TData, Error>, 'queryKey' | 'queryFn'>;
        } = {},
    ): RQUseQueryResult<TData, Error> => {
        return useQuery({
            queryKey: [key, { requiredParams }, { queryParams }], // Include params to uniquely identify the query
            queryFn: requiredParams ? () => queryFn(requiredParams, queryParams) : skipToken,
            ...defaultOptions,
            ...options,
        });
    };
};

/**
 * Default query options are overridden in global configuration (AppEntryPoint.tsx)
 *
 * This variable is used to rollback to default options in case of a specific query.
 * Should be used on new queries or migrated queries.
 */
export const defaultQueryOptions = {
    gcTime: 5000,
    refetchOnWindowFocus: true,
};
