import { getUserEmploymentStatusTranslationKey } from '@/domain/employee/Employee.service';

import { FilterType, SelectFilter } from '@/components/filters-bar/FilterBar.type';
import { EmploymentStatus, NOT_TERMINATED_EMPLOYMENT_STATUSES } from '@/domain/employment/Employment.model';
import { useFiltersDirectory } from '@/page/common/filters-directory/useFiltersDirectory';
import { useTranslation } from 'react-i18next';

/**
 * Hook to get the list of filter for employee
 */
export const useEmployeeSelectionFilters = (): { filters: FilterType[] } => {
    const { t } = useTranslation();

    const { filters: filtersFromDirectory } = useFiltersDirectory(['EMPLOYMENT_STATUS', 'LOCATION', 'DEPARTMENT', 'JOB', 'MANAGER']);

    const [employmentStatus, ...restFilters] = filtersFromDirectory as SelectFilter[];

    const filters = [
        {
            ...employmentStatus,
            // Override the default key
            key: 'employmentStatus',
            // Filter out terminated employment statuses
            options: employmentStatus.options?.filter(option => NOT_TERMINATED_EMPLOYMENT_STATUSES.includes(option.value as EmploymentStatus)),
            // Default filter to show only employed employees
            value: [
                {
                    label: t(getUserEmploymentStatusTranslationKey(), { context: EmploymentStatus.EMPLOYED }),
                    value: EmploymentStatus.EMPLOYED,
                },
            ],
        } satisfies FilterType,
        ...restFilters,
    ];

    return {
        filters,
    };
};
