import { FeedbackSkillFormType, FeedbackSkillsFormType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import { FeedbackSummarySkillFormType, SummaryFeedbackSkillsFormType } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';
import { SkillState } from '@/page/review/employee-review-feedback-form/FeedbackSkill';

export const getEmployeeReviewFeedbackSkillState = (
    feedbackSkillForm: FeedbackSkillFormType | FeedbackSummarySkillFormType,
    skillsItem: FeedbackSkillsFormType | SummaryFeedbackSkillsFormType,
): SkillState => {
    const { maxSkills, allSkillsRequired } = skillsItem;

    if (feedbackSkillForm.score && feedbackSkillForm.score > 0) {
        return 'reviewed';
    }

    const skillsAlreadyReviewed = skillsItem.skills.filter(skill => skill.score && skill.score > 0).length;
    const disabled = !!maxSkills && skillsAlreadyReviewed >= maxSkills && !allSkillsRequired;
    if (disabled) {
        return 'disabled';
    }
    return 'not_reviewed';
};
