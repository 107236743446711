import { Objective } from '@/domain/objective/Objective.model';
import { useTheme } from '@mui/material';
import { Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectiveItemPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackObjectivePDF';
import { htmlToPlainText } from '@/utils/strings.util';
import { getLabelTranslation } from '@/utils/language.util';
import { Label } from '@/domain/label/Label.model';
import { FeedbackObjectivesActionFormType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';

type FeedbackObjectivesActionsPDFProps = {
    objectives: Objective[];
    objectiveItem: FeedbackObjectivesActionFormType;
};

export const FeedbackObjectivesActionsPDF: FC<FeedbackObjectivesActionsPDFProps> = ({ objectives, objectiveItem }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <View style={{ gap: theme.spacing(1), paddingLeft: theme.spacing(2) }}>
            <Text
                style={{
                    fontSize: 16,

                    fontWeight: 'bold',
                }}
            >
                {t('reviews.write_feedback.create_objective_action_title')}
            </Text>

            {objectiveItem.instruction && (
                <Text
                    style={{
                        fontSize: 12,
                    }}
                >
                    {htmlToPlainText(getLabelTranslation(objectiveItem.instruction as Label))}
                </Text>
            )}

            {objectives?.map(objective => <ObjectiveItemPDF objective={objective} key={objective?.id} />)}
        </View>
    );
};
