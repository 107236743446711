import { FC } from 'react';
import { DocumentTag } from '@/domain/document-tag/DocumentTag.model';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { UserLanguage } from '@/utils/language.util';
import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { useTranslation } from 'react-i18next';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { useTranslatableLabelInput } from '@/components/translatable-label-input/useTranslatableLabelInput';

type DocumentTagDialogProps = {
    documentTag: DocumentTag | undefined;
    onSave: (data: DocumentTagFormValues) => void;
} & DialogWrapperProps;
export const DocumentTagSettingsDialog: FC<DocumentTagDialogProps> = ({ documentTag, onSave, ...restDialogProps }) => {
    const { t } = useTranslation();
    const { translationLanguage, onLanguageChange } = useTranslatableLabelInput();

    const { control, handleSubmit } = useForm<DocumentTagFormValues>({
        resolver: yupResolver(getDocumentTagSchema()),
        defaultValues: {
            label: documentTag?.name ?? createDefaultLabel(),
        },
    });

    const handleSave = (data: DocumentTagFormValues) => {
        onSave(data);
    };

    const DialogHeader = (
        <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'} flex={1}>
            <Typography variant='body1bold'>{t(documentTag ? 'document_tags_settings_page.edit_tag' : 'document_tags_settings_page.create_tag')}</Typography>
            <TranslationLanguageSelector translationLanguage={translationLanguage} onLanguageChange={onLanguageChange} />
        </Stack>
    );

    return (
        <DialogWrapper {...restDialogProps} header={DialogHeader}>
            <DialogContent>
                <FormControlLabel
                    control={<FieldLabel control={control} language={translationLanguage} name={'label'} autoFocus={true} fullWidth={true} />}
                    label={t('general.name')}
                    sx={{ width: '100%' }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit(handleSave, console.error)} fullWidth>
                    {t('general.confirm')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
const getDocumentTagSchema = (translationLanguage?: UserLanguage) => {
    return yup.object().shape({
        label: getLabelFormSchema(translationLanguage).required(),
    });
};
export type DocumentTagFormValues = yup.InferType<ReturnType<typeof getDocumentTagSchema>>;
