import { CustomList, CustomListCreateMutation, CustomListUpdateMutation } from '@/domain/custom-list/CustomList.model';
import { customListApi } from '@/api/custom-list/CustomList.api';

export const searchCustomLists = (): Promise<CustomList[]> => {
    return customListApi.searchCustomLists();
};

export const createCustomList = (mutation: CustomListCreateMutation): Promise<CustomList> => {
    return customListApi.createCustomList(mutation);
};

export const updateCustomList = (id: number, mutation: CustomListUpdateMutation): Promise<CustomList> => {
    return customListApi.updateCustomList(id, mutation);
};

export const deleteCustomListById = (id: number): Promise<void> => {
    return customListApi.deleteCustomListById(id);
};
