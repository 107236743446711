import { EmployeeAvatarWithDetails } from '@/domain-ui/employee/employee-avatar/EmployeeAvatarWithDetails';
import { UnitType } from '@/domain/date/Date.model';
import { Employee } from '@/domain/employee/Employee.model';
import { convertLeavesMinutesToUnit } from '@/domain/leave-request/LeaveRequest.service';
import { LeaveTypeCycleHistory, LeaveTypeHistory } from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { AllowanceType, LeaveActivityType } from '@/domain/leave-type/LeaveType.model';
import { formatDate, formatInDefaultDate, MONTHS } from '@/utils/datetime.util';
import { getSign } from '@/utils/math.util';
import { MenuItem, Paper, Select, Stack, Tooltip, Typography } from '@mui/material';
import { Calendar02Icon, InformationCircleIcon } from 'hugeicons-react';
import i18next from 'i18next';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { getLabelTranslation } from '@/utils/language.util';

type LeaveHistoryHeaderProps = {
    getCycleYear: (leaveTypeCycleHistory: LeaveTypeCycleHistory) => number;
    setSelectedCycle: (leaveTypeCycleHistory: LeaveTypeCycleHistory | undefined) => void;
    selectedCycle: LeaveTypeCycleHistory;
    leaveTypeHistory: LeaveTypeHistory;
    employee: Employee;
    displayUnitType: UnitType;
};

const getFormattedUnit = (convertedTime: number, unit: UnitType): string => {
    return i18next.t('duration.formatDuration', {
        duration: convertedTime,
        unit: unit,
    });
};
const leaveTypeHistoryIsLimitedAllowanceType = (leaveTypeHistory: LeaveTypeHistory): boolean => {
    return leaveTypeHistory.leaveType.allowanceType === AllowanceType.NOT_UNLIMITED;
};
const leaveTypeHistoryIsTimesheetCompensationType = (leaveTypeHistory: LeaveTypeHistory): boolean => {
    return leaveTypeHistory.leaveType.leaveActivityType === LeaveActivityType.TIMESHEET_COMPENSATION;
};

export const LeaveHistoryHeader: FC<LeaveHistoryHeaderProps> = ({
    getCycleYear,
    setSelectedCycle,
    selectedCycle,
    leaveTypeHistory,
    employee,
    displayUnitType,
}) => {
    const isLimitedAllowanceType = leaveTypeHistoryIsLimitedAllowanceType(leaveTypeHistory);
    const isTimesheetCompensationType = leaveTypeHistoryIsTimesheetCompensationType(leaveTypeHistory);

    return (
        <Stack component={Paper} p={2} direction='row' alignItems='center' gap={4}>
            <EmployeeAvatarWithDetails avatarProps={{ size: 'large' }} employee={employee} />
            <SelectedCycleDisplay
                leaveTypeHistory={leaveTypeHistory}
                selectedCycle={selectedCycle}
                getCycleYear={getCycleYear}
                setSelectedCycle={setSelectedCycle}
            />
            {isLimitedAllowanceType && !isTimesheetCompensationType && selectedCycle.carryoverAmountInMinutes !== 0 && (
                <CarryoverDisplay leaveTypeHistory={leaveTypeHistory} selectedCycle={selectedCycle} displayUnitType={displayUnitType} />
            )}
            {isLimitedAllowanceType && !isTimesheetCompensationType && (
                <GrantedDisplay leaveTypeHistory={leaveTypeHistory} selectedCycle={selectedCycle} displayUnitType={displayUnitType} />
            )}
            {isLimitedAllowanceType && !isTimesheetCompensationType && (
                <TimeOffDisplay leaveTypeHistory={leaveTypeHistory} selectedCycle={selectedCycle} displayUnitType={displayUnitType} />
            )}
            {isLimitedAllowanceType && selectedCycle?.adjustedAmountInMinutes !== 0 && (
                <LeaveCorrectionDisplay leaveTypeHistory={leaveTypeHistory} selectedCycle={selectedCycle} displayUnitType={displayUnitType} />
            )}
            {isTimesheetCompensationType && (
                <CompensationRequestedDisplay leaveTypeHistory={leaveTypeHistory} selectedCycle={selectedCycle} displayUnitType={displayUnitType} />
            )}
            <BalanceDisplay leaveTypeHistory={leaveTypeHistory} selectedCycle={selectedCycle} displayUnitType={displayUnitType} />
        </Stack>
    );
};

type CarryoverDisplayProps = {
    selectedCycle: LeaveTypeCycleHistory;
    leaveTypeHistory: LeaveTypeHistory;
    displayUnitType: UnitType;
};
const CarryoverDisplay: FC<CarryoverDisplayProps> = ({ selectedCycle, leaveTypeHistory, displayUnitType }) => {
    const { t } = useTranslation();

    const convertedCarryover = convertLeavesMinutesToUnit({
        input: displayUnitType === UnitType.DAYS ? selectedCycle.carryoverAmountInDays : selectedCycle.carryoverAmountInMinutes,
        outputUnit: displayUnitType,
        roundingType: leaveTypeHistory.leaveType.roundingType,
    });
    return (
        <Stack direction='column'>
            <Typography variant='body2'>{t('my_leaves_history.starting_balance')}</Typography>
            <Typography variant='body1bold'>{getFormattedUnit(convertedCarryover, displayUnitType)}</Typography>
        </Stack>
    );
};

type GrantedDisplayProps = {
    selectedCycle: LeaveTypeCycleHistory;
    leaveTypeHistory: LeaveTypeHistory;
    displayUnitType: UnitType;
};
const GrantedDisplay: FC<GrantedDisplayProps> = ({ selectedCycle, leaveTypeHistory, displayUnitType }) => {
    const { t } = useTranslation();
    const convertedTime = convertLeavesMinutesToUnit({
        input:
            displayUnitType === UnitType.DAYS
                ? selectedCycle.grantedAmountInDays - selectedCycle.carryoverAmountInDays
                : selectedCycle.grantedAmountInMinutes - selectedCycle.carryoverAmountInMinutes,
        outputUnit: displayUnitType,
        roundingType: leaveTypeHistory.leaveType.roundingType,
    });
    return (
        <Stack direction='column'>
            <Typography variant='body2'>
                {displayUnitType === UnitType.DAYS ? t('my_leaves_history.granted_days') : t('my_leaves_history.granted_hours')}
            </Typography>
            <Typography variant='body1bold'>
                {getSign(convertedTime)}
                {getFormattedUnit(convertedTime, displayUnitType)}
            </Typography>
        </Stack>
    );
};
type TimeOffDisplayProps = {
    selectedCycle: LeaveTypeCycleHistory;
    leaveTypeHistory: LeaveTypeHistory;
    displayUnitType: UnitType;
};
const TimeOffDisplay: FC<TimeOffDisplayProps> = ({ selectedCycle, leaveTypeHistory, displayUnitType }) => {
    const { t } = useTranslation();
    const convertedTakenAmount = convertLeavesMinutesToUnit({
        input: displayUnitType === UnitType.DAYS ? selectedCycle.takenAmountInDays : selectedCycle.takenAmountInMinutes,
        outputUnit: displayUnitType,
        roundingType: leaveTypeHistory.leaveType.roundingType,
    });

    const convertedPlannedAmount = convertLeavesMinutesToUnit({
        input: displayUnitType === UnitType.DAYS ? selectedCycle.plannedAmountInDays : selectedCycle.plannedAmountInMinutes,
        outputUnit: displayUnitType,
        roundingType: leaveTypeHistory.leaveType.roundingType,
    });

    const convertedRequestAmount = convertLeavesMinutesToUnit({
        input: displayUnitType === UnitType.DAYS ? selectedCycle.requestedAmountInDays : selectedCycle.requestedAmountInMinutes,
        outputUnit: displayUnitType,
        roundingType: leaveTypeHistory.leaveType.roundingType,
        useAbsoluteValue: true,
    });

    return (
        <Stack direction='column'>
            <Stack direction={'row'} alignItems={'center'} gap={0.25}>
                <Typography variant='body2'>{t('my_leaves_history.time_off_requests')}</Typography>

                <Tooltip
                    title={
                        <Fragment>
                            {t('my_leaves_history.taken')}: {getFormattedUnit(convertedTakenAmount, displayUnitType)}
                            <br />
                            {t('my_leaves_history.planned')}: {getFormattedUnit(convertedPlannedAmount, displayUnitType)}
                        </Fragment>
                    }
                >
                    <InformationCircleIcon size={18} />
                </Tooltip>
            </Stack>
            <Typography variant='body1bold'>
                {convertedRequestAmount !== 0 ? '-' : ''}
                {getFormattedUnit(convertedRequestAmount, displayUnitType)}
            </Typography>
        </Stack>
    );
};

type LeaveCorrectionDisplayProps = {
    selectedCycle: LeaveTypeCycleHistory;
    leaveTypeHistory: LeaveTypeHistory;
    displayUnitType: UnitType;
};
const LeaveCorrectionDisplay: FC<LeaveCorrectionDisplayProps> = ({ selectedCycle, leaveTypeHistory, displayUnitType }) => {
    const { t } = useTranslation();
    const convertedAdjustedAmount = convertLeavesMinutesToUnit({
        input: displayUnitType === UnitType.DAYS ? selectedCycle.adjustedAmountInDays : selectedCycle.adjustedAmountInMinutes,
        outputUnit: displayUnitType,
        roundingType: leaveTypeHistory.leaveType.roundingType,
    });
    return (
        <Stack direction='column'>
            <Typography variant='body2'>{t('my_leaves_history.admin_adjustments')}</Typography>
            <Typography variant='body1bold'>
                {getSign(convertedAdjustedAmount)}
                {getFormattedUnit(convertedAdjustedAmount, displayUnitType)}
            </Typography>
        </Stack>
    );
};

type BalanceDisplayProps = {
    selectedCycle: LeaveTypeCycleHistory;
    leaveTypeHistory: LeaveTypeHistory;
    displayUnitType: UnitType;
};
const BalanceDisplay: FC<BalanceDisplayProps> = ({ selectedCycle, leaveTypeHistory, displayUnitType }) => {
    const { t } = useTranslation();

    const remainingBalance = convertLeavesMinutesToUnit({
        input: displayUnitType === UnitType.DAYS ? selectedCycle.remainingBalanceInDays : selectedCycle.remainingBalanceInMinutes,
        outputUnit: displayUnitType,
        roundingType: leaveTypeHistory.leaveType.roundingType,
    });

    const getBalanceTitle = (date: string) => {
        if (leaveTypeHistoryIsLimitedAllowanceType(leaveTypeHistory)) {
            return t('my_leaves_history.closing_balance', { date: date });
        }

        if (leaveTypeHistoryIsTimesheetCompensationType(leaveTypeHistory)) {
            return t('my_leaves_history.overtime_balance', { date: date });
        }

        return t('my_leaves_history.used_in', { date: date });
    };

    const getBalanceDate = () => {
        return leaveTypeHistory.leaveType.allowanceType === AllowanceType.UNLIMITED
            ? formatDate(selectedCycle.leaveCycle.cycleEndDate, 'yyyy')
            : formatInDefaultDate(selectedCycle.leaveCycle.cycleEndDate);
    };

    return (
        <Stack direction='column'>
            <Typography variant='body2'>{getBalanceTitle(getBalanceDate())}</Typography>
            <Typography variant='body1bold'>{getFormattedUnit(remainingBalance, displayUnitType)}</Typography>
        </Stack>
    );
};

const CompensationRequestedDisplay: FC<{
    selectedCycle: LeaveTypeCycleHistory;
    leaveTypeHistory: LeaveTypeHistory;
    displayUnitType: UnitType;
}> = ({ selectedCycle, leaveTypeHistory, displayUnitType }) => {
    const { t } = useTranslation();

    const convertedAvailableAmountInMinutes = convertLeavesMinutesToUnit({
        input: displayUnitType === UnitType.DAYS ? selectedCycle?.requestedAmountInDays : selectedCycle?.requestedAmountInMinutes,
        outputUnit: displayUnitType,
        roundingType: leaveTypeHistory.leaveType.roundingType,
    });

    return (
        <Stack direction='column'>
            <Typography variant='body2'>
                {t('my_leaves_history.days_compensated')} {formatDate(selectedCycle.leaveCycle.cycleEndDate, 'yyyy')}
            </Typography>
            <Typography variant='body1bold'>{getFormattedUnit(convertedAvailableAmountInMinutes, displayUnitType)}</Typography>
        </Stack>
    );
};

type SelectedCycleDisplayProps = {
    selectedCycle: LeaveTypeCycleHistory;
    leaveTypeHistory: LeaveTypeHistory;
    getCycleYear: (leaveTypeCycleHistory: LeaveTypeCycleHistory) => number;
    setSelectedCycle: (leaveTypeCycleHistory: LeaveTypeCycleHistory | undefined) => void;
};
const SelectedCycleDisplay: FC<SelectedCycleDisplayProps> = ({ selectedCycle, leaveTypeHistory, getCycleYear, setSelectedCycle }) => {
    return (
        <Stack direction={'row'} gap={1} alignItems='center'>
            <Calendar02Icon />
            <Select
                value={getCycleYear(selectedCycle)}
                onChange={event => setSelectedCycle(leaveTypeHistory.cycles.find(cycle => getCycleYear(cycle) === event.target.value))}
            >
                {leaveTypeHistory.cycles.map(cycleHistory => (
                    <MenuItem value={getCycleYear(cycleHistory)} key={getCycleYear(cycleHistory)}>
                        {getLabelTranslation(leaveTypeHistory.leaveType.name)}{' '}
                        {formatDate(cycleHistory.leaveCycle.cycleStartDate, cycleHistory.leaveCycle?.startMonth === MONTHS.JANUARY ? 'yyyy' : 'MMM yyyy')}
                    </MenuItem>
                ))}
            </Select>
        </Stack>
    );
};
