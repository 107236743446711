import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { JSX } from 'react';
import { TranslatableRichTextEditor } from '@/components/translatable-rich-text-editor/TranslatableRichTextEditor';
import { getLocalizedErrorMessage, UserLanguage } from '@/utils/language.util';
import { RichTextEditorProps } from '@/components/rich-text-editor/RichTextEditor';
import { createDefaultLabel } from '@/domain/label/Label.service';

export type FieldTranslatableRichTextEditorProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> & {
    translationLanguage: UserLanguage;
} & Omit<RichTextEditorProps, 'onUpdate'>;

export const FieldTranslatableRichTextEditor = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: FieldTranslatableRichTextEditorProps<TFieldValues, TName>,
): JSX.Element => {
    const { disabled, translationLanguage, minHeight, ...controllerProps } = props;

    return (
        <Controller
            {...controllerProps}
            render={({ field, fieldState: { error } }) => {
                return (
                    <TranslatableRichTextEditor
                        minHeight={minHeight ?? '120px'}
                        onUpdate={field.onChange}
                        translationLanguage={translationLanguage}
                        errorMessage={getLocalizedErrorMessage(error, translationLanguage)}
                        id={controllerProps.name}
                        disabled={disabled}
                        {...field}
                        value={field.value ?? createDefaultLabel()}
                    />
                );
            }}
        />
    );
};
