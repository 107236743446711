import { EmployeeReviewManagerSummary } from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import { EmployeeReviewStatus } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { approveEmployeeReview, hasEmployeeReviewObjectiveActions, isEmployeeReviewSubmitted } from '@/domain/employee-review/EmployeeReview.service';
import { useGetEmployeeReview, useGetEmployeeReviewSelfSummary, useGetEmployeeReviewSummary } from '@/hooks/employee-review/EmployeeReview.hook';
import { useGetObjectives } from '@/hooks/objective/Objective.hook';
import { useCurrentEmployee } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { Stack } from '@mui/system';
import { SummaryFeedbackFormHeader } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackFormHeader';
import { StepperButtonConfigs, StepperForm } from '@/components/stepper-form/StepperForm';
import {
    CombinedFeedbackItemsType,
    FeedbackStepItemsType,
    feedbackStepSchema,
    MergedFeedbackItemsType,
    PrivateFeedbackStepItemsType,
    privateFeedbackStepSchema,
    SummaryFeedbackItemFormType,
} from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';
import { useStepper } from '@/components/stepper-form/StepperForm.util';
import { mapSummaryFeedbackItem } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { Objective } from '@/domain/objective/Objective.model';
import { SummaryFeedbackForm } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { Button, DialogActions, DialogContent, FormControlLabel, TextField } from '@mui/material';
import { PrivateSummaryFeedbackFormStep } from '@/page/review/employee-review-summary-feedback-form/PrivateSummaryFeedbackForm';
import { useEmployeeReviewId } from '@/page/review/useEmployeeReviewId';
import { useGetEmployeeReviewActionSelfSummary, useGetEmployeeReviewActionSummary } from '@/hooks/employee-review-action/EmployeeReviewAction.hook';
import { EmployeeReviewAction } from '@/domain/employee-review-action/EmployeeReviewAction.model';

export const FeedbackSummaryPage: FC = () => {
    const employeeReviewId = useEmployeeReviewId();

    // TODO create custom to load everything with one hook
    const {
        data: employeeReview,
        isLoading: isEmployeeReviewLoading,
        isError: isEmployeeReviewError,
        error: employeeReviewError,
    } = useGetEmployeeReview(employeeReviewId);

    const currentEmployee = useCurrentEmployee();
    const employeeId = employeeReview?.employee.id;
    const isSelfSummary = employeeId === currentEmployee.id;

    const isSummaryFetchEnabled = !isSelfSummary && !!employeeId && !!currentEmployee.id;

    const {
        data: employeeReviewSummary,
        isLoading: isEmployeeReviewSummaryLoading,
        isError: isEmployeeReviewSummaryError,
        error: employeeReviewSummaryError,
    } = useGetEmployeeReviewSummary(employeeReviewId, { options: { enabled: isSummaryFetchEnabled } });

    const {
        data: employeeReviewSelfSummary,
        isLoading: isEmployeeReviewSelfSummaryLoading,
        isError: isEmployeeReviewSelfSummaryError,
        error: employeeReviewSelfSummaryError,
    } = useGetEmployeeReviewSelfSummary(employeeReviewId, { options: { enabled: isSelfSummary } });

    const {
        data: objectives = [],
        error: objectivesError,
        isLoading: objectivesLoading,
        refetch: refetchObjectives,
    } = useGetObjectives(employeeId ? { employeeIds: [employeeId] } : undefined);

    const isEmployeeReviewActionSummaryEnabled = !!employeeReview && hasEmployeeReviewObjectiveActions(employeeReview);

    const {
        data: actions = [],
        error: actionsError,
        isError: isActionsError,
        isLoading: actionsLoading,
        refetch: refetchActions,
    } = useGetEmployeeReviewActionSummary(Number(employeeReviewId), { options: { enabled: isEmployeeReviewActionSummaryEnabled } });

    const isSelfActionEnabled = !!employeeReview && hasEmployeeReviewObjectiveActions(employeeReview) && isSelfSummary;
    const {
        data: selfActions = [],
        error: selfActionsError,
        isError: isSelfActionsError,
        isLoading: selfActionsLoading,
        refetch: selfActionsRefetch,
    } = useGetEmployeeReviewActionSelfSummary(Number(employeeReviewId), { options: { enabled: isSelfActionEnabled } });

    const reviewActions = isSelfSummary ? selfActions : actions;

    const reviewSummary: EmployeeReviewManagerSummary | undefined = employeeReviewSelfSummary
        ? {
              ...employeeReviewSelfSummary,
              upwardReviewers: undefined,
              peerReviewers: undefined,
          }
        : employeeReviewSummary;

    const isLoading =
        isEmployeeReviewLoading ||
        isEmployeeReviewSummaryLoading ||
        objectivesLoading ||
        isEmployeeReviewSelfSummaryLoading ||
        actionsLoading ||
        selfActionsLoading;

    const isError =
        isEmployeeReviewError ||
        isEmployeeReviewSummaryError ||
        !employeeId ||
        !!objectivesError ||
        isEmployeeReviewSelfSummaryError ||
        isActionsError ||
        isSelfActionsError;

    const error = objectivesError || employeeReviewError || employeeReviewSummaryError || employeeReviewSelfSummaryError || actionsError || selfActionsError;
    const hasAllRequiredData = employeeReview && reviewSummary && objectives && employeeReviewId && employeeId && reviewActions;

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            {hasAllRequiredData && (
                <FeedbackSummaryFormPage
                    employeeReview={employeeReview}
                    managerSummary={reviewSummary}
                    objectives={objectives}
                    refetchObjectives={refetchObjectives}
                    isSelfSummary={isSelfSummary}
                    employeeId={employeeId}
                    actions={reviewActions}
                    refetchEmployeeReviewActions={() => {
                        return isSelfSummary ? selfActionsRefetch() : refetchActions();
                    }}
                />
            )}
        </StateHandler>
    );
};

type FeedbackSummaryFormPageProps = {
    employeeReview: EmployeeReview;
    managerSummary: EmployeeReviewManagerSummary;
    objectives: Objective[];
    refetchObjectives: () => void;
    isSelfSummary: boolean;
    employeeId: number;
    actions: EmployeeReviewAction[];
    refetchEmployeeReviewActions: () => void;
};

export const FeedbackSummaryFormPage: FC<FeedbackSummaryFormPageProps> = ({
    employeeReview,
    managerSummary,
    objectives,
    refetchObjectives,
    isSelfSummary,
    employeeId,
    actions,
    refetchEmployeeReviewActions,
}) => {
    const scrollRef = useRef<HTMLDivElement>();
    const { t } = useTranslation();
    const employeeReviewId = employeeReview.id;
    const navigate = useNavigate();
    const [comment, setComment] = useState<string>('');
    const [approveOrRejectDiscussionOpen, setApproveOrRejectDiscussionOpen] = useState<boolean>(false);
    const [expandAll, setExpandAll] = useState(true);

    const approveDiscussion = async () => {
        try {
            await approveEmployeeReview(employeeReviewId, {
                comment,
            });
            showSnackbar(t('reviews.review_summary.messages.review_approved'), 'success');
            navigate('/profile/reviews');
        } catch (error) {
            handleError(error);
        }
    };

    const getPrimaryActionLabel = (employeeReviewStatus: EmployeeReviewStatus | undefined): string => {
        switch (employeeReviewStatus) {
            case EmployeeReviewStatus.DISCUSSION_STARTED:
                return t('reviews.review_summary.close');
            case EmployeeReviewStatus.DISCUSSION_SUBMITTED:
                return isSelfSummary ? t('reviews.review_summary.validate_and_close') : t('reviews.review_summary.close');
            case EmployeeReviewStatus.EMPLOYEE_APPROVED:
                return t('reviews.review_summary.close');
            case EmployeeReviewStatus.INPUT_NEEDED:
            case EmployeeReviewStatus.CANCELLED:
            default:
                return t('reviews.review_summary.close');
        }
    };

    const primaryAction = (): void => {
        if (!isSelfSummary || !isEmployeeReviewSubmitted(employeeReview.status)) {
            navigate(-1);
            return;
        }

        setApproveOrRejectDiscussionOpen(true);
    };

    const getSteps = () => {
        const hasPrivateQuestions = managerSummary.items.some(item => ['PRIVATE_EMPLOYEE_QUESTION', 'PRIVATE_MANAGER_QUESTION'].includes(item.type));

        const summaryStep = {
            stepComponent: (
                <SummaryFeedbackForm
                    isFormDisabled={true}
                    employeeId={employeeId}
                    scrollRef={scrollRef}
                    objectives={objectives}
                    refetchObjectives={refetchObjectives}
                    expandAll={expandAll}
                    employeeReview={employeeReview}
                    actions={actions}
                    refetchActions={refetchEmployeeReviewActions}
                />
            ),
            stepName: 'EMPLOYEE_REVIEW_SUMMARY_FEEDBACK',
            stepSchema: feedbackStepSchema,
            stepTranslationKey: 'reviews.review_summary.discussion',
        };

        const privateStep = {
            stepComponent: <PrivateSummaryFeedbackFormStep isFormDisabled={true} scrollRef={scrollRef} expandAll={expandAll} />,
            stepName: 'EMPLOYEE_REVIEW_PRIVATE_SUMMARY_FEEDBACK',
            stepSchema: privateFeedbackStepSchema,
            stepTranslationKey: 'reviews.review_summary.private_feedback',
        };

        return hasPrivateQuestions ? [summaryStep, privateStep] : [summaryStep];
    };

    const steps = getSteps();

    const stepperValues = useStepper({
        steps,
    });

    const { onNextStep, currentStep } = stepperValues;
    const stepName = steps?.find(step => step?.stepName === currentStep?.stepName)?.stepName;

    const isPrivateQuestion = (item: SummaryFeedbackItemFormType): boolean =>
        item.type === 'PRIVATE_EMPLOYEE_QUESTION' || item.type === 'PRIVATE_MANAGER_QUESTION';

    const getDefaultValues = (managerSummary: EmployeeReviewManagerSummary, employeeReview: EmployeeReview): MergedFeedbackItemsType => {
        const sortedFeedbackItems = (employeeReview.review?.items ?? [])
            .map(item => mapSummaryFeedbackItem(item, managerSummary))
            // An item might not have a feedback, so we filter out the undefined items
            .filter(item => !!item)
            .sort((a, b) => (a?.order && b?.order ? a.order - b.order : 0));

        const privateQuestions = sortedFeedbackItems.filter(val => isPrivateQuestion(val)) as PrivateFeedbackStepItemsType[];
        const nonPrivateQuestions = sortedFeedbackItems.filter(val => !isPrivateQuestion(val)) as FeedbackStepItemsType[];

        return {
            summaryFeedbackItems: nonPrivateQuestions,
            summaryPrivateFeedbackItems: privateQuestions,
        };
    };

    const defaultValues = getDefaultValues(managerSummary, employeeReview);

    const getResolverForStepName = (
        step_name: 'EMPLOYEE_REVIEW_SUMMARY_FEEDBACK' | 'EMPLOYEE_REVIEW_PRIVATE_SUMMARY_FEEDBACK',
    ): Resolver<CombinedFeedbackItemsType> => {
        switch (step_name) {
            case 'EMPLOYEE_REVIEW_SUMMARY_FEEDBACK':
                return yupResolver<CombinedFeedbackItemsType>(feedbackStepSchema);
            case 'EMPLOYEE_REVIEW_PRIVATE_SUMMARY_FEEDBACK':
                return yupResolver<CombinedFeedbackItemsType>(privateFeedbackStepSchema);
        }
    };

    const resolver: Resolver<MergedFeedbackItemsType> = getResolverForStepName(
        stepName as 'EMPLOYEE_REVIEW_SUMMARY_FEEDBACK' | 'EMPLOYEE_REVIEW_PRIVATE_SUMMARY_FEEDBACK',
    ) as unknown as Resolver<MergedFeedbackItemsType>;

    const formMethods = useForm<MergedFeedbackItemsType>({
        resolver: resolver,
        defaultValues: defaultValues,
    });

    const onCancelDiscussionDialog = () => {
        setApproveOrRejectDiscussionOpen(false);
    };

    const customButtonConfigs: StepperButtonConfigs = {
        saveButton: {
            onClick: primaryAction,
            children: getPrimaryActionLabel(employeeReview?.status),
        },
    };
    return (
        <FormProvider {...formMethods}>
            <Stack gap={2} flex={1}>
                <Stack
                    direction='column'
                    gap={2}
                    sx={{
                        width: {
                            lg: '1000px',
                            xs: '100%',
                        },
                    }}
                    alignSelf={'center'}
                >
                    <SummaryFeedbackFormHeader
                        managerSummary={managerSummary}
                        objectives={objectives}
                        summaryFeedbackItems={defaultValues.summaryFeedbackItems ?? []}
                        expandAll={expandAll}
                        handleExpandAllChange={() => setExpandAll(prevExpandAll => !prevExpandAll)}
                        employeeReview={employeeReview}
                        actions={actions}
                    />
                </Stack>

                <StepperForm steps={steps} stepperValues={stepperValues} onNextStep={onNextStep} onSave={() => {}} customButtonConfigs={customButtonConfigs} />

                <DialogWrapper
                    open={approveOrRejectDiscussionOpen}
                    onClose={onCancelDiscussionDialog}
                    header={t('reviews.review_summary.approval_dialog.title')}
                >
                    <DialogContent>
                        <FormControlLabel
                            label={t('reviews.review_summary.approval_dialog.label')}
                            style={{ width: '100%' }}
                            value={comment}
                            control={
                                <TextField
                                    fullWidth
                                    onChange={event => setComment(event.target.value)}
                                    placeholder={t('reviews.review_summary.approval_dialog.label')}
                                    multiline
                                    minRows={4}
                                />
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button fullWidth color='error' onClick={onCancelDiscussionDialog}>
                            {t('general.cancel')}
                        </Button>
                        <Button fullWidth onClick={approveDiscussion}>
                            {t('reviews.review_summary.approval_dialog.action_label')}
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            </Stack>
        </FormProvider>
    );
};
