import { UnitType } from '@/domain/date/Date.model';
import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';

export const hasPartialConflicts = (conflictingRequests: LeaveRequest[]): boolean =>
    !!conflictingRequests?.length && getTotalConflictingRequestsLeavePercentage(conflictingRequests) < 100;

export const hasConflicts = (conflictingRequests: LeaveRequest[]): boolean =>
    !!conflictingRequests?.length && getTotalConflictingRequestsLeavePercentage(conflictingRequests) >= 100;

export const getTotalConflictingRequestsLeavePercentage = (conflictingRequests: LeaveRequest[]): number => {
    return conflictingRequests.reduce((acc, conflictingRequest) => {
        return acc + conflictingRequest.leavePercentage;
    }, 0);
};

export const getLeaveRemainingTimePercentage = (
    conflictingRequests: LeaveRequest[],
    leaveRequestUnitType: UnitType,
    leaveRequestPercentage: number,
): number => {
    const hasConflictingRequests = !!conflictingRequests?.length;
    const isUnitTypeDays = leaveRequestUnitType === UnitType.DAYS;
    if (hasConflictingRequests && isUnitTypeDays && getTotalConflictingRequestsLeavePercentage(conflictingRequests) < 100) {
        return 100 - getTotalConflictingRequestsLeavePercentage(conflictingRequests);
    }
    return leaveRequestPercentage;
};
