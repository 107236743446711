import { FC, useEffect, useState } from 'react';
import { AsyncTreeSelectFilterType, TreeSelectFilterOption, TreeSelectFilterType } from '@/components/filters-bar/FilterBar.type';
import { RecursiveValue, TreeSelect } from '@/components/tree-select/TreeSelect';
import { Stack } from '@mui/material';
import { FilterRuleSelector } from '@/components/filters-bar/filter-bar-components/FilterRuleSelector';

export const TreeSelectFilter: FC<{
    filter: TreeSelectFilterType | AsyncTreeSelectFilterType;
    onFilterUpdated: (filter: TreeSelectFilterType | AsyncTreeSelectFilterType) => void;
}> = ({ filter, onFilterUpdated }) => {
    const isMultiSelect = filter.type === 'tree-multi-select';
    const [items, setItems] = useState<TreeSelectFilterOption[]>([]);
    const [optionsLoading, setOptionsLoading] = useState(true);

    useEffect(() => {
        if (!items.length && optionsLoading) {
            if (filter.selectMode === 'SYNC') {
                setItems(filter.options);
                setOptionsLoading(false);
            } else {
                filter.fetchOptions().then(options => {
                    setItems(options);
                    setOptionsLoading(false);
                });
            }
        }
    }, [filter, items.length, optionsLoading]);

    const handleSelectionChange = (value: RecursiveValue<string | number>[] | RecursiveValue<string | number> | null) => {
        if (isMultiSelect) {
            if (!value) {
                onFilterUpdated({ ...filter, value: [] });
                return;
            }
            onFilterUpdated({ ...filter, value: (value as RecursiveValue[]).map(mapRecursiveValueToTreeFilterOption) });
            return;
        }
        onFilterUpdated({ ...filter, value: value ? [mapRecursiveValueToTreeFilterOption(value as RecursiveValue)] : undefined });
    };

    const handleRuleChange = (rule: TreeSelectFilterType['rule']) => {
        const updatedFilter = { ...filter, rule };
        onFilterUpdated(updatedFilter);
    };

    // TreeSelect needs an id in the option to work
    const mapToOptionWithId = (option: TreeSelectFilterOption): RecursiveValue<string | number> => {
        return {
            ...option,
            id: option.value,
            children: option.children?.map(mapToOptionWithId) ?? [],
        };
    };

    // we need the reverse map to get the original type. Could be improved by add a generic type TValue in TreeSelect
    const mapRecursiveValueToTreeFilterOption = (recursiveValue: RecursiveValue<string | number>): TreeSelectFilterOption => {
        return { value: recursiveValue.id, label: recursiveValue.label, children: recursiveValue.children.map(mapRecursiveValueToTreeFilterOption) };
    };

    const getTreeSelectValue = (
        value: TreeSelectFilterOption[] | undefined,
    ): RecursiveValue<string | number>[] | RecursiveValue<string | number> | undefined => {
        // clear the label of selected value because we don't want to show it in the input (behaviour of the filter bar)
        const valueEmptyLabel = value?.map(v => ({ ...v, label: '' }));
        if (isMultiSelect) {
            return valueEmptyLabel?.map(mapToOptionWithId);
        }
        return valueEmptyLabel ? mapToOptionWithId(valueEmptyLabel[0]) : undefined;
    };

    return (
        <Stack gap={0.5} p={1} pb={0}>
            <FilterRuleSelector rules={filter.availableRules} value={filter.rule} onChange={handleRuleChange} />

            <TreeSelect
                multiSelect={isMultiSelect}
                value={getTreeSelectValue(filter.value)}
                itemsData={items.map(mapToOptionWithId)}
                onChange={handleSelectionChange}
                disablePopper={true}
                selectionPropagation={isMultiSelect}
                autoFocus={true}
                slotProps={{
                    // in the filter bar, we don't want to show selected values and the clearValue button
                    input: {
                        startAdornment: undefined,
                        endAdornment: undefined,
                    },
                }}
            />
        </Stack>
    );
};
