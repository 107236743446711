import { Page } from '@/page/Pages';
import { useGetObjectiveSetting } from '@/hooks/objective-setting/ObjectiveSetting.hook';
import { canManageOtherEmployeeObjectives } from '@/domain/permission/Permission.service';
import { useCurrentEmployee, useCurrentPolicies, useCurrentRealm } from '@/stores/store';

export const individualObjectivePage = {
    labelKey: 'pages.individual_objectives',
    path: '/objectives/individual',
};

export const departmentObjectivePage = {
    labelKey: 'pages.department_objectives',
    path: '/objectives/department',
};

export const useGetObjectivePages = (): Page[] => {
    const realm = useCurrentRealm();
    const realmFeatures = realm.realmFeatures;
    const employeeId = useCurrentEmployee().id;
    const policies = useCurrentPolicies();
    const fetchEnabled = canManageOtherEmployeeObjectives(realmFeatures, policies, employeeId);
    const { data: objectiveSetting, isLoading, isError } = useGetObjectiveSetting(undefined, { enabled: fetchEnabled });
    const shouldIncludeDepartment = !isLoading && !isError && objectiveSetting?.parentObjectiveEnabled;

    return [individualObjectivePage, ...(shouldIncludeDepartment ? [departmentObjectivePage] : [])];
};
