import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldText } from '@/components/form/field-text/FieldText';
import { declinePendingLeaveRequest } from '@/domain/leave-request/LeaveRequest.service';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type Props = {
    leaveRequestId: number | undefined;
    onCancel: () => void;
    onDeclineRequest: () => void;
};

export const DeclineLeaveRequestDialog: FC<Props> = ({ leaveRequestId, onCancel, onDeclineRequest }) => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        declineComment: yup.string().trim().required(),
    });

    const { handleSubmit, control, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            declineComment: '',
        },
    });

    type FormValues = yup.InferType<typeof schema>;

    const declineRequest = (values: FormValues) => {
        if (!leaveRequestId) {
            return;
        }
        declinePendingLeaveRequest(leaveRequestId, values.declineComment)
            .then(() => {
                showSnackbar(t('leaves_page.messages.leave_request_declined'), 'success');
                reset();
                onDeclineRequest();
            })
            .catch(handleError);
    };

    return (
        <DialogWrapper open={!!leaveRequestId} onClose={() => onCancel()} header={t('leaves_page.decline_request')}>
            <Stack component={DialogContent}>
                <FormControlLabel
                    label={t('leaves_page.comment')}
                    name={'declineComment'}
                    control={
                        <FieldText
                            name='declineComment'
                            control={control}
                            fullWidth
                            placeholder={t('leaves_page.place_provide_decline_reason')}
                            textFieldProps={{
                                slotProps: {
                                    input: {
                                        multiline: true,
                                        minRows: 4,
                                    },
                                },
                            }}
                        />
                    }
                />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(declineRequest, console.error)}>{t('leaves_page.decline')}</Button>
            </DialogActions>
        </DialogWrapper>
    );
};
