export enum AuthenticationStatus {
    SUCCESS = 'SUCCESS',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
    ERROR = 'ERROR',
}

export type AuthenticationResponse = {
    status: AuthenticationStatus;
    accessToken: string;
    refreshToken: string;
    idToken: string;
    expiredAt: Date;
};
