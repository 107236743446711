import { shiftApi } from '@/api/shift/Shift.api';
import {
    AreaShift,
    EmployeeShift,
    LeaveShiftSearchRequest,
    Shift,
    ShiftCoverage,
    ShiftCoverageCreationRequest,
    ShiftCoverageSearchRequest,
    ShiftCoverageUpdateRequest,
    ShiftCreationRequest,
    ShiftPublishRequest,
    ShiftReleaseRequest,
    ShiftSearchRequest,
    ShiftUpdateRequest,
    TagShiftCoverage,
} from '@/domain/shift/Shift.model';
import { GenericAbortSignal } from 'axios';

export const getShift = (id: number): Promise<Shift> => {
    return shiftApi.getShift(id);
};

export const getLeaveShifts = (request: LeaveShiftSearchRequest): Promise<EmployeeShift[]> => {
    return shiftApi.getLeaveShifts(request);
};

export const getEmployeeShifts = (request: ShiftSearchRequest, signal?: GenericAbortSignal): Promise<EmployeeShift[]> => {
    return shiftApi.getEmployeeShifts(request, signal);
};

export const getAreasShifts = (request: ShiftSearchRequest, signal?: GenericAbortSignal): Promise<AreaShift[]> => {
    return shiftApi.getAreasShifts(request, signal);
};

export const createShift = (newShift: ShiftCreationRequest): Promise<Shift> => {
    return shiftApi.createShift(newShift);
};

export const shiftRelease = (request: ShiftReleaseRequest): Promise<void> => {
    return shiftApi.shiftRelease(request);
};

export const updateShift = (updatedShift: ShiftUpdateRequest, id: number): Promise<Shift> => {
    return shiftApi.updateShift(updatedShift, id);
};

export const deleteShift = (id: number): Promise<void> => {
    return shiftApi.deleteShift(id);
};

export const publishShifts = (request: ShiftPublishRequest): Promise<void> => {
    return shiftApi.publishShifts(request);
};

export const createShiftCoverage = (newShift: ShiftCoverageCreationRequest): Promise<ShiftCoverage> => {
    return shiftApi.createShiftCoverage(newShift);
};

export const getTagShiftCoverages = (request: ShiftCoverageSearchRequest, signal?: GenericAbortSignal): Promise<TagShiftCoverage[]> => {
    return shiftApi.getTagShiftCoverages(request, signal);
};

export const updateShiftCoverage = (id: number, updatedShift: ShiftCoverageUpdateRequest): Promise<ShiftCoverage> => {
    return shiftApi.updateShiftCoverage(id, updatedShift);
};

export const deleteShiftCoverage = (id: number): Promise<void> => {
    return shiftApi.deleteShiftCoverage(id);
};
