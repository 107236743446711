import * as yup from 'yup';
import { isEmployeeReviewRatingCommentRequired } from '@/page/review/employee-review-feedback-form/FeedbackForm.util';
import { ReviewRatingScale } from '@/domain/review-rating-scale/ReviewRatingScale.model';
import { t } from 'i18next';
import { getLabelFormSchema } from '@/domain/label/Label.schema';

export const ratingItemSchema = yup.object().shape({
    score: yup.number().default(undefined),
    label: yup.string().default(undefined),
    description: yup.string().default(undefined),
    commentRequired: yup.boolean().default(undefined),
    name: yup.string().default(undefined),
});
export type RatingItemFormType = yup.InferType<typeof ratingItemSchema>;

export const ratingItemsSchema = yup.array().of(ratingItemSchema).default([]).required();

export const ratingSchema = yup
    .object()
    .shape({
        items: ratingItemsSchema,
    })
    .default(undefined)
    .notRequired();
export type RatingFormType = yup.InferType<typeof ratingSchema>;

export const feedbackSectionTypeSchema = yup
    .string()
    .required()
    .oneOf(['SECTION'] as const);

export const feedbackSectionSchema = yup.object().shape({
    id: yup.number().required(),
    order: yup.number().required(),
    title: getLabelFormSchema(),
    instruction: getLabelFormSchema().default(undefined),
    type: feedbackSectionTypeSchema,
});
export type FeedbackSectionFormType = yup.InferType<typeof feedbackSectionSchema>;

export const categorySchema = yup.object().shape({
    id: yup.number().required(),
    name: yup.string(),
});
export type CategoryFormType = yup.InferType<typeof categorySchema>;

const levelSchema = yup.object().shape({
    id: yup.number().required(),
    description: yup.string(),
    score: yup.number().required(),
    commentRequired: yup.boolean(),
    name: yup.string(),
});
export type LevelFormType = yup.InferType<typeof levelSchema>;

export const skillSchema = yup.object().shape({
    id: yup.number().required(),
    name: yup.string(),
    description: yup.string(),
    levels: yup.array().of(levelSchema).required(),
});

export type SkillFormType = yup.InferType<typeof skillSchema>;

export const feedbackSkillSchema = yup.object().shape({
    score: yup.number().default(undefined),
    comment: yup.string().default(''),
    category: categorySchema,
    skill: skillSchema.required(),
});
export type FeedbackSkillFormType = yup.InferType<typeof feedbackSkillSchema>;

export const feedbackSkillsTypeSchema = yup
    .string()
    .required()
    .oneOf(['SKILLS'] as const);

export const feedbackSkillsSchema = yup.object().shape({
    id: yup.number().required(),
    order: yup.number().required(),
    type: feedbackSkillsTypeSchema,
    rating: ratingSchema,
    skills: yup.array().of(feedbackSkillSchema).required(),
    minSkills: yup.number().default(undefined),
    maxSkills: yup.number().default(undefined),
    allSkillsRequired: yup.boolean().required(),
    instruction: getLabelFormSchema().default(undefined),
});
export type FeedbackSkillsFormType = yup.InferType<typeof feedbackSkillsSchema>;

export const feedbackObjectiveSchema = yup.object().shape({
    id: yup.number().required(),
    order: yup.number().required(),
    instruction: getLabelFormSchema().default(undefined),
    type: yup
        .string()
        .required()
        .oneOf(['REVIEW_OBJECTIVES'] as const),
});

export const feedbackObjectivesActionSchema = yup.object().shape({
    id: yup.number().required(),
    order: yup.number().required(),
    instruction: getLabelFormSchema().default(undefined),
    type: yup
        .string()
        .required()
        .oneOf(['OBJECTIVE_ACTIONS'] as const),
});

export type FeedbackObjectivesActionFormType = yup.InferType<typeof feedbackObjectivesActionSchema>;

export type FeedbackObjectiveFormType = yup.InferType<typeof feedbackObjectiveSchema>;

export const feedbackQuestionScoreSchema = yup.number().when(['required', 'rating'], {
    is: (required: boolean, rating: ReviewRatingScale) => {
        return required && rating && rating.items.length > 0;
    },
    then: schema => schema.required(),
    otherwise: schema => schema.nullable(),
});

export const feedbackQuestionCommentSchema = yup
    .string()
    .trim()
    .default('')
    .when(['required', 'score', 'rating'], {
        is: (required: boolean, score: number, rating: RatingFormType) => {
            return (!score && required) || (score && rating && isEmployeeReviewRatingCommentRequired(rating, score));
        },
        then: schema => schema.required(),
        otherwise: schema => schema,
    });

export const feedbackQuestionTypeSchema = yup.string().required().oneOf(['QUESTION', 'PRIVATE_EMPLOYEE_QUESTION', 'PRIVATE_MANAGER_QUESTION']);

export const feedbackQuestionSchema = yup.object().shape({
    id: yup.number().required(),
    order: yup.number().required(),
    score: feedbackQuestionScoreSchema,
    comment: feedbackQuestionCommentSchema,
    rating: ratingSchema,
    required: yup.boolean().required(),
    title: getLabelFormSchema(),
    instruction: getLabelFormSchema().default(undefined),
    description: yup.string(),
    type: feedbackQuestionTypeSchema,
});
export type FeedbackQuestionFormType = yup.InferType<typeof feedbackQuestionSchema>;

export const feedbackQuestionSkillTypeSchema = yup
    .string()
    .required()
    .oneOf(['SKILL'] as const);
export const feedbackSkillQuestionSchema = yup.object().shape({
    id: yup.number().required(),
    order: yup.number().required(),
    required: yup.boolean().required(),
    score: feedbackQuestionScoreSchema,
    rating: ratingSchema,
    comment: feedbackQuestionCommentSchema,
    title: yup.string(),
    instruction: yup.string().default(undefined),
    description: yup.string(),
    type: feedbackQuestionSkillTypeSchema,
    skillId: yup.number(),
});
export type FeedbackSkillQuestionFormType = yup.InferType<typeof feedbackSkillQuestionSchema>;

const validateSkills = (skills: FeedbackSkillFormType[], minSkills: number | undefined, allSkillsRequired: boolean): boolean => {
    const skillsRatedNumber = skills.filter(skill => skill.score && skill.score > 0).length;
    if (allSkillsRequired) {
        return skillsRatedNumber === skills.length;
    }
    return !minSkills || skillsRatedNumber >= minSkills;
};

const feedbackItemSchema = yup.lazy(item => {
    const { type } = item;
    switch (type) {
        case 'SECTION':
            return feedbackSectionSchema;
        case 'SKILL':
            return feedbackSkillQuestionSchema;
        case 'REVIEW_OBJECTIVES':
            return feedbackObjectiveSchema;
        case 'OBJECTIVE_ACTIONS':
            return feedbackObjectivesActionSchema;
        case 'SKILLS':
            // Skills test validation is done here because it's not the same test in summary skills test validation
            // and summary skills tests validation is using employeeReviewFeedbackSkillsSchema
            return feedbackSkillsSchema.test({
                name: 'skills-validation',
                test: function (value) {
                    const { skills, minSkills, allSkillsRequired } = value;
                    return validateSkills(skills, minSkills, allSkillsRequired);
                },
                message: function ({ value }) {
                    const { minSkills, maxSkills, allSkillsRequired } = value;
                    if (allSkillsRequired) {
                        return t('reviews.write_feedback.all_skills_required');
                    }
                    if (minSkills === maxSkills) {
                        return t('reviews.write_feedback.review_skills_error_min_equal_max', { value: minSkills });
                    }
                    return t('reviews.write_feedback.review_skills_error', {
                        min: minSkills,
                        max: maxSkills,
                    });
                },
            });
        case 'QUESTION':
        case 'PRIVATE_EMPLOYEE_QUESTION':
        case 'PRIVATE_MANAGER_QUESTION':
        default:
            return feedbackQuestionSchema;
    }
});
export type FeedbackItemForm = yup.InferType<typeof feedbackItemSchema>;

const feedbackItemsSchema = yup.array().of(feedbackItemSchema).required();
export type FeedbackItemsType = yup.InferType<typeof feedbackItemsSchema>;

export const feedbackFormSchema = yup.object().shape({
    feedbackItems: feedbackItemsSchema,
});
export type FeedbackFormType = yup.InferType<typeof feedbackFormSchema>;
