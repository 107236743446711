import { EmployeeReviewFeedback, EmployeeReviewFeedbackItem } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import {
    FeedbackFormType,
    FeedbackItemForm,
    FeedbackObjectiveFormType,
    FeedbackObjectivesActionFormType,
    FeedbackQuestionFormType,
    FeedbackSectionFormType,
    FeedbackSkillQuestionFormType,
    FeedbackSkillsFormType,
    RatingFormType,
    SkillFormType,
} from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import { ReviewRatingScale } from '@/domain/review-rating-scale/ReviewRatingScale.model';
import { getLabelTranslation } from '@/utils/language.util';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { ContributorType, ReviewItem } from '@/domain/review/Review.model';
import {
    isReviewTemplateItemObjectives,
    isReviewTemplateItemObjectivesAction,
    isReviewTemplateItemPrivateEmployeeQuestion,
    isReviewTemplateItemPrivateManagerQuestion,
    isReviewTemplateItemQuestion,
    isReviewTemplateItemSection,
    isReviewTemplateItemSkill,
    isReviewTemplateItemSkills,
} from '@/domain/review-template/ReviewTemplate.service';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { Skill } from '@/domain/skill/Skill.model';
import { Employee } from '@/domain/employee/Employee.model';
import { hasContributorTypeInItem } from '@/domain/employee-review/EmployeeReview.service';

export const mapRatingScale = (rating: ReviewRatingScale): RatingFormType => {
    return {
        items: (rating.items ?? []).map(item => {
            return {
                score: item.score,
                label: getLabelTranslation(item.label) ?? '',
                description: getLabelTranslation(item.description) ?? '',
                commentRequired: item.commentRequired,
            };
        }),
    };
};
export const mapEmployeeReviewFeedbackSectionForm = (item: ReviewItem): FeedbackSectionFormType => {
    if (!isReviewTemplateItemSection(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        id: item.id,
        order: item.order,
        instruction: item?.instruction ?? createDefaultLabel(),
        title: item?.title ?? createDefaultLabel(),
        type: item.type,
    };
};

const getEmployeeReviewFeedbackItem = (id: number, feedbacks: EmployeeReviewFeedback['feedbacks']): EmployeeReviewFeedbackItem | undefined => {
    return feedbacks.find(feedback => feedback.reviewItemId === id);
};

export const mapEmployeeReviewFeedbackSkillQuestionForm = (
    item: ReviewItem,
    feedbacks: EmployeeReviewFeedback['feedbacks'],
): FeedbackSkillQuestionFormType | undefined => {
    if (!isReviewTemplateItemSkill(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    const feedbackItem = getEmployeeReviewFeedbackItem(item.id, feedbacks);

    if (!feedbackItem) {
        return;
    }

    return {
        id: item.id,
        order: item.order,
        required: item.required,
        score: feedbackItem.feedbackSkillQuestion?.score ?? undefined,
        comment: feedbackItem.feedbackSkillQuestion?.comment ?? '',
        title: getLabelTranslation(item.skill?.name),
        description: getLabelTranslation(item.skill?.description),
        type: item.type,
        skillId: item.skill?.id,
        rating: item?.rating ? mapRatingScale(item.rating) : undefined,
    };
};

export const mapEmployeeReviewFeedbackObjectiveForm = (item: ReviewItem): FeedbackObjectiveFormType | undefined => {
    if (!isReviewTemplateItemObjectives(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        id: item.id,
        type: item.type,
        order: item.order,
        instruction: item?.instruction ?? createDefaultLabel(),
    };
};

export const mapEmployeeReviewFeedbackSkillsForm = (item: ReviewItem, feedbacks: EmployeeReviewFeedback['feedbacks']): FeedbackSkillsFormType | undefined => {
    if (!isReviewTemplateItemSkills(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    const feedback = getEmployeeReviewFeedbackItem(item.id, feedbacks);

    if (!feedback) {
        return;
    }

    return {
        rating: item?.rating ? mapRatingScale(item.rating) : undefined,
        id: item.id,
        type: item.type,
        order: item.order,
        skills: (feedback.feedbackSkills?.skills ?? [])
            .sort((a, b) => a.skill.category.order - b.skill.category.order)
            .map(skill => ({
                score: skill.score ?? undefined,
                comment: skill.comment ?? '',
                skill: {
                    id: skill.skill.id,
                    name: getLabelTranslation(skill.skill.name),
                    description: getLabelTranslation(skill.skill.description) ?? '',
                    levels: skill.skill.category.levels.map(level => ({
                        id: level.id,
                        description: getLabelTranslation(level.description) ?? '',
                        commentRequired: level.commentRequired,
                        score: level.score,
                        name: getLabelTranslation(level.name),
                    })),
                },
                category: {
                    id: skill.skill.category.id,
                    name: getLabelTranslation(skill.skill.category.name) ?? '',
                },
            })),
        minSkills: item.minSkills ?? undefined,
        maxSkills: item.maxSkills ?? undefined,
        allSkillsRequired: item.allSkillsRequired ?? false,
        instruction: item.instruction ?? createDefaultLabel(),
    };
};

export const mapEmployeeReviewFeedbackQuestionForm = (
    item: ReviewItem,
    feedbacks: EmployeeReviewFeedback['feedbacks'],
): FeedbackQuestionFormType | undefined => {
    if (
        !isReviewTemplateItemQuestion(item.type) &&
        !isReviewTemplateItemPrivateEmployeeQuestion(item.type) &&
        !isReviewTemplateItemPrivateManagerQuestion(item.type)
    ) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    const feedback = getEmployeeReviewFeedbackItem(item.id, feedbacks);

    if (!feedback) {
        return;
    }

    return {
        rating: item?.rating ? mapRatingScale(item.rating) : undefined,
        required: item.required,
        order: item.order,
        id: item.id,
        score: feedback.feedbackQuestion?.score ?? undefined,
        comment: feedback.feedbackQuestion?.comment ?? '',
        instruction: item?.instruction ?? createDefaultLabel(),
        title: item?.title ?? createDefaultLabel(),
        type: item.type,
    };
};

const mapEmployeeReviewObjectivesActionForm = (item: ReviewItem): FeedbackObjectivesActionFormType => {
    if (!isReviewTemplateItemObjectivesAction(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        id: item.id,
        type: 'OBJECTIVE_ACTIONS',
        order: item.order,
        instruction: item?.instruction ?? createDefaultLabel(),
    };
};

export const mapEmployeeReviewFeedback = (reviewItem: ReviewItem, feedbacks: EmployeeReviewFeedback['feedbacks']): FeedbackItemForm | undefined => {
    switch (reviewItem.type) {
        case 'SECTION':
            return mapEmployeeReviewFeedbackSectionForm(reviewItem);
        case 'SKILL':
            return mapEmployeeReviewFeedbackSkillQuestionForm(reviewItem, feedbacks);
        case 'REVIEW_OBJECTIVES':
            return mapEmployeeReviewFeedbackObjectiveForm(reviewItem);
        case 'OBJECTIVE_ACTIONS':
            return mapEmployeeReviewObjectivesActionForm(reviewItem);
        case 'SKILLS':
            return feedbackFormUtil.mapFeedbackSkillsForm(reviewItem, feedbacks);
        case 'QUESTION':
        case 'PRIVATE_EMPLOYEE_QUESTION':
        case 'PRIVATE_MANAGER_QUESTION':
            return mapEmployeeReviewFeedbackQuestionForm(reviewItem, feedbacks);
        default:
            throw new Error(`Unsupported item type: ${reviewItem.type}`);
    }
};

export const isEmployeeReviewRatingCommentRequired = (rating: RatingFormType, score: number): boolean => {
    const isCommentRequired = (rating?.items ?? []).find(item => item.score === score)?.commentRequired;
    const isScoreNotNull = score >= 0;
    return isScoreNotNull && !!isCommentRequired;
};

export const isEmployeeReviewSkillRatingCommentRequired = (skill: SkillFormType, score: number): boolean => {
    const isCommentRequired = skill.levels.find(level => level.score === score)?.commentRequired;
    const isScoreNotNull = score >= 0;
    return isScoreNotNull && !!isCommentRequired;
};

export const getPreviewInitialValues = (employeeReview: EmployeeReview, previewRole: ContributorType, skills: Skill[]): FeedbackFormType => {
    const filteredItems = employeeReview?.review?.items.filter(item => {
        if (item.contributorTypes?.includes(previewRole)) {
            if (isReviewTemplateItemSkill(item.type) && item.skill) {
                return skills.some(skill => skill.id === item.skill.id);
            }
            return true;
        }
    });
    return {
        feedbackItems: (filteredItems ?? [])
            .map(reviewItem => mapEmployeeReviewPreviewFeedbackItem(reviewItem))
            .filter((item): item is FeedbackItemForm => !!item),
    };
};

export const mapEmployeeReviewPreviewFeedbackItem = (reviewItem: ReviewItem): FeedbackItemForm | undefined => {
    switch (reviewItem.type) {
        case 'SECTION':
            return {
                id: reviewItem.id,
                order: reviewItem.order,
                title: reviewItem.title,
                instruction: reviewItem.instruction,
                type: 'SECTION' as const,
            } satisfies FeedbackSectionFormType;
        case 'SKILL':
            return {
                id: reviewItem.id,
                required: reviewItem.required,
                order: reviewItem.order,
                score: undefined,
                comment: '',
                title: getLabelTranslation(reviewItem.skill?.name),
                description: getLabelTranslation(reviewItem.skill?.description),
                type: 'SKILL' as const,
                skillId: reviewItem.skill?.id,
                rating: reviewItem.rating ? mapRatingScale(reviewItem.rating) : undefined,
            } satisfies FeedbackSkillQuestionFormType;
        case 'REVIEW_OBJECTIVES':
            return {
                id: reviewItem.id,
                order: reviewItem.order,
                type: 'REVIEW_OBJECTIVES' as const,
                instruction: reviewItem.instruction ?? createDefaultLabel(),
            } satisfies FeedbackObjectiveFormType;
        case 'QUESTION':
            return {
                id: reviewItem.id,
                order: reviewItem.order,
                score: undefined,
                comment: '',
                required: reviewItem.required,
                rating: reviewItem.rating ? mapRatingScale(reviewItem.rating) : undefined,
                instruction: reviewItem.instruction ?? createDefaultLabel(),
                title: reviewItem.title ?? createDefaultLabel(),
                type: 'QUESTION' as const,
            } satisfies FeedbackQuestionFormType;
        case 'SKILLS':
            return {
                rating: reviewItem.rating ? mapRatingScale(reviewItem.rating) : undefined,
                id: reviewItem.id,
                type: 'SKILLS' as const,
                order: reviewItem.order,
                // todo: skills is empty here, we need to find a way to get the skills of the employee we want to preview
                skills: [],
                minSkills: reviewItem?.minSkills ?? undefined,
                maxSkills: reviewItem?.maxSkills ?? undefined,
                allSkillsRequired: reviewItem?.allSkillsRequired ?? false,
                instruction: reviewItem?.instruction ?? createDefaultLabel(),
            } satisfies FeedbackSkillsFormType;
        case 'OBJECTIVE_ACTIONS':
            return {
                id: reviewItem.id,
                type: 'OBJECTIVE_ACTIONS' as const,
                instruction: reviewItem?.instruction ?? createDefaultLabel(),
                order: reviewItem.order,
            };
    }
};

const getEmployeeReviewDefaultValues = (
    feedbacks: EmployeeReviewFeedback['feedbacks'],
    skills: Skill[],
    previewRole: ContributorType | undefined,
    employeeReview: EmployeeReview,
    isPreview: boolean,
    currentEmployee: Employee,
): FeedbackFormType => {
    if (isPreview && previewRole) {
        return getPreviewInitialValues(employeeReview, previewRole, skills);
    }
    return {
        // An item might not have a feedback, so we filter out the undefined items
        feedbackItems:
            employeeReview.review?.items
                ?.filter(reviewItem => hasContributorTypeInItem(reviewItem, employeeReview, currentEmployee))
                ?.map(item => mapEmployeeReviewFeedback(item, feedbacks))
                .filter(item => !!item) ?? [],
    };
};

export const feedbackFormUtil = {
    getDefaultValues: getEmployeeReviewDefaultValues,
    mapFeedback: mapEmployeeReviewFeedback,
    mapFeedbackSectionForm: mapEmployeeReviewFeedbackSectionForm,
    mapFeedbackSkillQuestionForm: mapEmployeeReviewFeedbackSkillQuestionForm,
    mapFeedbackObjectiveForm: mapEmployeeReviewFeedbackObjectiveForm,
    mapFeedbackSkillsForm: mapEmployeeReviewFeedbackSkillsForm,
    mapFeedbackQuestionForm: mapEmployeeReviewFeedbackQuestionForm,
    isRatingCommentRequired: isEmployeeReviewRatingCommentRequired,
    getPreviewInitialValues,
    mapPreviewFeedbackItem: mapEmployeeReviewPreviewFeedbackItem,
};
