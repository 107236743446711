import { costCenterAPI } from '@/api/cost-center/CostCenter.api';
import { CostCenter, CostCenterAssignment, CostCenterMutation } from '@/domain/cost-center/CostCenter.model';

export const searchCostCenters = async (): Promise<CostCenter[]> => {
    return costCenterAPI.searchCostCenters();
};

export const createCostCenter = async (request: CostCenterMutation): Promise<CostCenter> => {
    return costCenterAPI.createCostCenter(request);
};

export const updateCostCenter = async (costCenterId: number, request: CostCenterMutation): Promise<CostCenter> => {
    return costCenterAPI.updateCostCenter(costCenterId, request);
};

export const deleteCostCenter = async (costCenterId: number): Promise<void> => {
    return costCenterAPI.deleteCostCenter(costCenterId);
};

export const formatCostCentersAssignment = (costCenters: CostCenterAssignment[]): string => {
    return costCenters.map(costCenter => `${costCenter.percentage}% ${costCenter.costCenter.name}`).join(', ');
};

export const isSameCostCenters = (costCenterAssignments1: CostCenterAssignment[], costCenterAssignments2: CostCenterAssignment[]): boolean =>
    costCenterAssignments1.length === costCenterAssignments2.length &&
    costCenterAssignments1.every(ccA1 => {
        const found = costCenterAssignments2.find(ccA2 => ccA2.costCenter.id === ccA1.costCenter.id);
        return ccA1.percentage === found?.percentage;
    });
