import '@/i18n/i18n';
import './index.css';

import { getAppConfig } from '@/config/config';
import { createRoot } from 'react-dom/client';

import { AppEntryPoint } from '@/app-entry-point/AppEntryPoint';
import { Button, Stack, Typography } from '@mui/material';
import { LicenseInfo } from '@mui/x-license';
import { ErrorBoundary } from '@/bugsnag';

// Import to load the interceptor
import '@/api/interceptor';

const config = getAppConfig();

LicenseInfo.setLicenseKey(config.MUI_PRO.LICENCE_KEY);

export const ErrorView = ({ clearError }: { clearError: () => void }): JSX.Element => {
    return (
        <Stack gap={2} alignItems='center' justifyContent='center'>
            <Typography color='white'>An error occurred</Typography>
            <Button onClick={clearError} variant='outlined'>
                Retry
            </Button>
        </Stack>
    );
};

const rootElement = document.getElementById('root');
if (rootElement) {
    const root = createRoot(rootElement);
    // Use to catch errors in the app throws before the router is mounted
    // Otherwise, the error will be caught by the router error boundary
    root.render(
        <ErrorBoundary FallbackComponent={ErrorView}>
            <AppEntryPoint />
        </ErrorBoundary>,
    );
}

// https://vitejs.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', event => {
    console.error(event);
    window.location.reload();
});
