import { FC, useState } from 'react';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { ConfigType } from '@/page/setting/types';
import { useTranslation } from 'react-i18next';
import { useGetDocumentTags } from '@/hooks/document-tag/DocumentTag.hook';
import { DocumentTag, DocumentTagMutation } from '@/domain/document-tag/DocumentTag.model';
import { DocumentTagFormValues, DocumentTagSettingsDialog } from '@/page/setting/document-tag/DocumentTagSettingsDialog';
import { createDocumentTag, deleteDocumentTag, updateDocumentTag } from '@/domain/document-tag/DocumentTag.service';
import { handleError } from '@/utils/api.util';
import { ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { isLabelUnique } from '@/domain/label/Label.service';
import { showSnackbar } from '@/utils/snackbar.util';
import { StateHandler } from '@/components/state-handler/StateHandler';

export const DocumentTagsSettingsPage: FC = () => {
    const { t } = useTranslation();
    const [openCreateTagModal, setOpenCreateTagModal] = useState<boolean>(false);
    const [documentTagToEdit, setDocumentTagToEdit] = useState<DocumentTag>();

    const { data: documentTags = [], isLoading, isError, error, refetch } = useGetDocumentTags();

    const handleCreateTag = async (tagFormValues: DocumentTagFormValues) => {
        try {
            const labelList = documentTags.map(tag => tag.name);
            if (!isLabelUnique(tagFormValues.label, labelList)) {
                showSnackbar(t('general.validations.unique_name'), 'error');
                return;
            }
            const mutation = {
                name: tagFormValues.label,
            } satisfies DocumentTagMutation;

            await createDocumentTag(mutation);
            setOpenCreateTagModal(false);
            await refetch();
        } catch (error) {
            handleError(error);
        }
    };

    const handleEditTag = async (documentTagId: number, tagFormValues: DocumentTagFormValues) => {
        try {
            const labelList = documentTags.filter(tag => tag.id !== documentTagId).map(tag => tag.name);
            if (!isLabelUnique(tagFormValues.label, labelList)) {
                showSnackbar(t('general.validations.unique_name'), 'error');
                return;
            }

            const mutation = {
                name: tagFormValues.label,
            } satisfies DocumentTagMutation;

            await updateDocumentTag(documentTagId, mutation);
            setDocumentTagToEdit(undefined);
            await refetch();
        } catch (error) {
            handleError(error);
        }
    };

    const handleDelete = async (id: number) => {
        try {
            await deleteDocumentTag(id);
            await refetch();
        } catch (e) {
            handleError(e);
        }
    };

    const getMenuItems = (documentTag: DocumentTag) => {
        return [
            {
                title: t('general.edit'),
                onClick: () => {
                    setDocumentTagToEdit(documentTag);
                },
            },
            {
                title: t('general.delete'),
                onClick: () => handleDelete(documentTag.id),
                confirmationRequired: true,
            },
        ] satisfies BasicMenuItem[];
    };
    const cellActionRenderer = ({ data }: ICellRendererParams<DocumentTag>) => {
        if (!data) {
            return;
        }
        return <BasicMenu items={getMenuItems(data)} />;
    };

    const config: ConfigType<DocumentTag> = {
        type: 'table',
        header: {
            primaryButtonCaption: t('document_tags_settings_page.add_tag'),
            primaryButtonAction: () => setOpenCreateTagModal(true),
        },
        isOnRowClickActive: true,
        table: {
            agGridProps: {
                onRowClicked: (params: RowClickedEvent<DocumentTag>) => {
                    setDocumentTagToEdit(params.data);
                },
            },
            columns: [
                {
                    field: 'name',
                    type: 'label',
                    headerName: t('document_tags_settings_page.table.tagName'),
                },
                {
                    type: 'actionMenu',
                    cellRenderer: cellActionRenderer,
                },
            ],
        },
    };

    return (
        <>
            <StateHandler isLoading={isLoading} isError={isError} error={error}>
                <CompanySettingsLayout options={config} data={documentTags} />
            </StateHandler>
            {openCreateTagModal && (
                <DocumentTagSettingsDialog open={true} onSave={handleCreateTag} onClose={() => setOpenCreateTagModal(false)} documentTag={undefined} />
            )}
            {documentTagToEdit && (
                <DocumentTagSettingsDialog
                    open={true}
                    onSave={formValues => handleEditTag(documentTagToEdit.id, formValues)}
                    onClose={() => setDocumentTagToEdit(undefined)}
                    documentTag={documentTagToEdit}
                />
            )}
        </>
    );
};
