import { getRealmLanguage, UserLanguage } from '@/utils/language.util';
import { useState } from 'react';

type useTranslatableLabelInputReturnType = {
    translationLanguage: UserLanguage;
    onLanguageChange: (lang: UserLanguage) => void;
};
export const useTranslatableLabelInput = (defaultLang = getRealmLanguage()): useTranslatableLabelInputReturnType => {
    const [translationLanguage, setTranslationLanguage] = useState(defaultLang);

    return {
        translationLanguage,
        onLanguageChange: setTranslationLanguage,
    };
};
