import { JSX } from 'react';
import { FormControlLabel, List, ListItem, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

type FilterRuleSelectorProps<T> = {
    rules: T[];
    value: T;
    onChange: (value: T) => void;
};
export const FilterRuleSelector = <T extends string>({ rules, value, onChange }: FilterRuleSelectorProps<T>): JSX.Element => {
    const { t } = useTranslation();

    const availableRulesOptions: {
        type: T;
        label: string;
    }[] = rules.map(rule => ({
        type: rule,
        label: t('filters_bar.rules.enum', { context: rule }),
    }));

    if (!availableRulesOptions.length) {
        return <></>;
    }

    return (
        <RadioGroup value={value ?? ''}>
            <List disablePadding dense>
                {availableRulesOptions.map(option => (
                    <ListItem key={option.type}>
                        <FormControlLabel
                            value={option.type}
                            labelPlacement='end'
                            label={option.label}
                            control={
                                <Radio
                                    edge='start'
                                    disableRipple
                                    size='small'
                                    onChange={e => {
                                        onChange(e.target.value as T);
                                    }}
                                    sx={{
                                        py: 0,
                                        pr: 0.25,
                                    }}
                                />
                            }
                            sx={{
                                m: 0,
                                // margin left to be aligned with the input
                                ml: -2,
                                pl: 0.5,
                            }}
                        />
                    </ListItem>
                ))}
            </List>
        </RadioGroup>
    );
};
