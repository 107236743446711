import { TreeSelect, TreeSelectProps, TreeSelectValue } from '@/components/tree-select/TreeSelect';
import { Controller, ControllerProps, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';
import { JSX } from 'react';

type TreeSelectRootProps<Multiple extends boolean | undefined, TId extends string | number> = Pick<
    TreeSelectProps<Multiple, TId>,
    | 'multiSelect'
    | 'itemsData'
    | 'loading'
    | 'onChange'
    | 'variant'
    | 'autoFocus'
    | 'fullWidth'
    | 'placeholder'
    | 'disabled'
    | 'selectionPropagation'
    | 'disableCloseOnSelect'
>;

export type FieldTreeSelectProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
    Multiple extends boolean | undefined = undefined,
    TId extends string | number = number,
> = {
    textFieldProps?: RemoveFromType<RemoveFromType<TreeSelectProps<Multiple, TId>, TreeSelectRootProps<Multiple, TId>>, ControllerRenderProps>;
} & TreeSelectRootProps<Multiple, TId> &
    Omit<ControllerProps<TFieldValues, TName>, 'render'>;

export const FieldTreeSelect = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
    Multiple extends boolean | undefined = undefined,
    TId extends string | number = number,
>(
    props: FieldTreeSelectProps<TFieldValues, TName, Multiple, TId>,
): JSX.Element => {
    const {
        multiSelect,
        itemsData,
        loading,
        onChange,
        variant,
        autoFocus,
        fullWidth,
        placeholder,
        disabled,
        selectionPropagation,
        disableCloseOnSelect,
        textFieldProps,
        ...controllerProps
    } = props;
    const { slotProps, ...restTextFieldProps } = textFieldProps ?? {};

    return (
        <Controller
            {...controllerProps}
            render={({ field, fieldState: { error } }) => (
                <TreeSelect
                    multiSelect={multiSelect}
                    selectionPropagation={selectionPropagation}
                    itemsData={itemsData}
                    loading={loading}
                    variant={variant}
                    autoFocus={autoFocus}
                    fullWidth={fullWidth}
                    placeholder={placeholder}
                    disabled={disabled}
                    disableCloseOnSelect={disableCloseOnSelect}
                    {...restTextFieldProps}
                    {...field}
                    slotProps={{
                        htmlInput: {
                            'aria-label': controllerProps.name,
                            ...slotProps?.htmlInput,
                        },
                        ...slotProps,
                    }}
                    value={field.value as TreeSelectValue<Multiple, TId>}
                    name={controllerProps.name}
                    onChange={newItem => {
                        field.onChange(newItem);
                        onChange?.(newItem as TreeSelectValue<Multiple, TId>);
                    }}
                    error={!!error}
                    helperText={error?.message}
                />
            )}
        />
    );
};
