import { Employee } from '@/domain/employee/Employee.model';
import { ReviewTemplate, ReviewTemplateItemType } from '@/domain/review-template/ReviewTemplate.model';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { ReviewRatingScale } from '@/domain/review-rating-scale/ReviewRatingScale.model';
import { Skill } from '@/domain/skill/Skill.model';
import { Label } from '@/domain/label/Label.model';

export type ManageReviewCycle = 'SETUP' | 'REVIEWERS_AND_PREVIEW' | 'INVITATIONS';

export enum ReviewStatus {
    DRAFT = 'DRAFT',
    STARTED = 'STARTED',
    CLOSED = 'CLOSED',
}

export const ContributorArray = ['SELF', 'MANAGER', 'PEER', 'UPWARD'] as const;
export type ContributorType = (typeof ContributorArray)[number];

export enum FeedbackType {
    FEEDBACK = 'FEEDBACK',
    SUMMARY = 'SUMMARY',
}

export const ReviewArray = ['CYCLE', 'ONBOARDING', 'OFFBOARDING', 'ONE_SHOT'] as const;

export type ReviewType = (typeof ReviewArray)[number];

export type ReviewItem = {
    id: number;
    type: ReviewTemplateItemType;
    order: number;
    title: Label;
    instruction: Label;
    required: boolean;
    skill: Skill;
    rating: ReviewRatingScale | undefined;
    contributorTypes: ContributorType[];
    allSkillsRequired: boolean;
    minSkills: number;
    maxSkills: number;
};

export type Review = {
    id: number;
    name: Label;
    items: ReviewItem[];
    createdBy: Employee | undefined;
    reviewStatus: ReviewStatus;
    instructions: string;
    emailInstructions: string;
    endDate: LocalDate | undefined;
    startDate: LocalDate | undefined;
    feedbackDeadlineDate: LocalDate | undefined;
    reviewTemplate: ReviewTemplate | undefined;
    contributorTypes: ContributorType[];
    employeeReviews: EmployeeReview[] | undefined;
    owners: Employee[];
    type: ReviewType;
    includePreparationStep: boolean;
    managerPreparationAccess: boolean;
    includeValidationStep: boolean;
    includePrivateEmployeeQuestions: boolean;
    includePrivateManagerQuestions: boolean;
    allowManagerToSkipPreparationStep: boolean;
    emails: ReviewEmail[];
};

export enum ReviewNotificationType {
    SUBMIT_PREPARATION_REMINDER = 'SUBMIT_PREPARATION_REMINDER',
    SUBMIT_DISCUSSION_REMINDER = 'SUBMIT_DISCUSSION_REMINDER',
    EMPLOYEE_VALIDATION_REMINDER = 'EMPLOYEE_VALIDATION_REMINDER',
}

export enum FilterReviewers {
    ALL_REVIEWS = 'ALL_REVIEWS',
    ONGOING_REVIEWS = 'ONGOING_REVIEWS',
}

export const NUMBER_DAYS_BEFORE_EOT_REVIEW = 14;

export type ReviewEmailMutation = {
    subject: Label;
    content: Label;
    contributorType: ContributorType;
};

export type ReviewEmail = {
    subject: Label;
    content: Label;
    contributorType: ContributorType;
};

export type ReviewPatchMutation = {
    feedbackDeadlineDate?: LocalDate;
    startDate?: LocalDate;
    endDate?: LocalDate;
    name: Label;
    employeeReviews: EmployeeReviewPatchMutation[];
};

export type EmployeeReviewPatchMutation = {
    employeeReviewId: number;
    managerIds?: number[];
    peerIds?: number[];
    upwardIds?: number[];
};

export type ReviewUpdateMutation = {
    employeeIds: number[];
    ownerIds: number[];
    feedbackDeadlineDate?: LocalDate;
    startDate?: LocalDate;
    endDate?: LocalDate;
    emailInstructions?: string;
    name: Label;
    instructions?: string;
    managerIds?: number[];
    emails?: ReviewEmailMutation[];
};

export type ReviewCycleCreationMutation = {
    name: Label;
    reviewTemplateId: number;
    employeeIds: number[];
    ownerIds: number[];
    instructions?: string;
};

export type ReviewOneShotCreationMutation = {
    name: Label;
    reviewTemplateId: number;
    employeeId: number;
    managerIds: number[];
    endDate?: LocalDate; //mandatory when there is preparation step
    feedbackDeadlineDate?: LocalDate; //mandatory when there is preparation step
};
type BoardingReviewMutation = {
    name: Label;
    reviewTemplateId: number;
    employeeId: number;
    managerIds: number[];
    startDate?: LocalDate;
    endDate?: LocalDate;
    feedbackDeadlineDate?: LocalDate;
};

export type ReviewOnboardingCreationMutation = Redundant<BoardingReviewMutation>;
export type ReviewOffboardingCreationMutation = Redundant<BoardingReviewMutation>;

export type ReviewStartMutation = {
    emailInstructions?: string;
    endDate: LocalDate;
    feedbackDeadlineDate: LocalDate;
    emails: ReviewEmailMutation[];
};

export type ReviewSearch = {
    templateName?: string;
    reviewTypes?: ReviewType[];
};

type ReviewReminderEmailMutation = {
    subject?: Label;
    content?: Label;
    contributorType: ContributorType;
};
export type ReviewSendReminderMutation = {
    type: ReviewNotificationType;
    employeeReviewIds: number[];
    emails: ReviewReminderEmailMutation[];
};
export type ReviewFeedback = {
    score: number;
    comment: string;
    reviewer: Employee | undefined;
};
