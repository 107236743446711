import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { FilterType } from '@/components/filters-bar/FilterBar.type';
import { EmployeeReviewStatus } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { useFiltersDirectory } from '@/page/common/filters-directory/useFiltersDirectory';
import { useTranslation } from 'react-i18next';

/**
 * Hook to get the list of filter for employee review
 */
export const useEmployeeReviewFilters = (): { filters: FilterType[] } => {
    const { t } = useTranslation();

    const { filters: filtersFromDirectory } = useFiltersDirectory(['LOCATION', 'DEPARTMENT']);

    const filters: FilterType[] = [
        ...filtersFromDirectory,
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYEE_REVIEW_STATUS' }),
            type: 'multi-select',
            key: 'status',
            selectMode: 'SYNC',
            options: Object.values(EmployeeReviewStatus).map(status => ({
                label: t('reviews.employee_review.status', { context: status }),
                value: status,
            })),
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
